import React from "react";
import classes from "./SocialLink.module.css";
import TwitterIcon from "../../../assets/img/social/twitterIcon.svg";
import InstagramIcon from "../../../assets/img/social/instagramIcon.svg";
import FacebookIcon from "../../../assets/img/social/facebookIcon.svg";
import TelegramIcon from "../../../assets/img/social/telegram.svg";
import LinkedinIcon from "../../../assets/img/social/linkedin.svg";
import PinterestIcon from "../../../assets/img/pinterestIcon.svg";

export default function SocialLink({ network, to }) {

  const getNetworkIcon = (network) => {
    switch (network) {
      case 'twitter':
        return TwitterIcon;
      case 'instagram':
        return InstagramIcon;
      case 'facebook':
        return FacebookIcon;
      case 'pinterest':
        return PinterestIcon;
      case 'telegram':
        return TelegramIcon;
      case 'linkedin':
        return LinkedinIcon;
      default:
        return null;
    }
  }

  return (
    <a href={to} className={classes.socialMainLink} target="__black">
      <img
        src={ getNetworkIcon(network) }
        alt="Socail-icon"
      />
    </a>
  );
}
