import React from "react";
import classes from "./SingleCollectiblePageTitleAndSwithBtn.module.css";


export default function SingleCollectiblePageTitleAndSwithBtn(props) {

  return (
    <div className={classes.swithBtnWithHeadingContainer}>
      {props.template ?
        <h2>
          Create Template
        </h2>
        :
        <h2>
          Create {props.isSingle === true ? "single collectible" : "multiple NFTs"}
        </h2>
      }
    </div>
  );
}
