import React, { useState, useEffect } from "react";
import classes from "./StatsColumn.module.css";
import AppService from "../../../../../services/AppService";

const appService = new AppService();

const StatsColumn = ({ title, value, isPrice, tokenSymbol, tokenPrecision }) => {
  const [price, setPrice] = useState(null);
  const [usdPrice, setUsdPrice] = useState(null);

  const getPrice = (value) => setPrice(+(value) / Math.pow(10, tokenPrecision));

  const geUsdPrice = (price) => setUsdPrice((price * (appService.getUsdPrice() ?? 1)));

  useEffect(() => {
    if(value && isPrice) {
      getPrice(value);
    }
  }, [value, isPrice])

  useEffect(() => {
    if(price) {
      geUsdPrice(price);
    }
  }, [price])

  return (
    <div className={classes.columnWrapper}>
      <p className={classes.title}>
        {title}
      </p>
      {
        isPrice && usdPrice ? (
          <p className={classes.value}>
            <span className={classes.tokensAmount}>{`${Math.round(price)} ${tokenSymbol} `}</span>
            <span className={classes.tokensDollars}>{`($${usdPrice.toFixed(2)})`}</span>
          </p>
        ) : (
          <p className={classes.value}>
            {value}
          </p>
        )
      }
    </div>
  )
}

export default StatsColumn;
