import React from "react";
import SocialLink from "../../UI/SocialButton/SocialLink";
import classes from "./ProfilePageUserDescCard.module.css";
import CoinIcon from "../../../assets/img/coinIcon.svg";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import EditProfileImg from "../../../assets/img/editProfileImg.svg";
import Tooltip from "../../UI/Tooltip";

const LIMIT = 14;

export default function ProfilePageUserDescCard(props) {
  return (
    <>
      <div className={classes.profileDescCardContainer}>
        {props.userImg ? (
          <img src={props.userImg} alt="User-profile-img" />
        ) : (
          <i className="fas fa-user-circle"></i>
        )}
        {
          props.userName && props.userName.length > LIMIT 
            ? <Tooltip id="user-name" tip={props.userName} string={props.userName} limit={LIMIT} isProfile/>
            : <h1>{props.userName}</h1>
        }
        
        {props.userAddress && (
          <h2>
            {props.userAddress.substring(0, 11)}
            {"..."}
            {props.userAddress.substring(props.userAddress.length - 4)}
            <img src={CoinIcon} alt="Coin-icon" />{" "}
          </h2>
        )}

        <p>{props.userDesc}</p>
        <div className={classes.descSocailLinkContainer}>
          {props.twitter &&
            <SocialLink network='twitter' to={`https://twitter.com/${props.twitter}`} />
          }
          {props.instagram && 
            <SocialLink network='instagram' to={`https://www.instagram.com/${props.instagram}`} />
          }
          {props.facebook && 
            <SocialLink network='facebook' to={props.facebook} />
          }
        </div>
        {
          props.isOwner &&
          <Link to="/edit-profile">
            <Button>
              <div>
                Edit profile <img src={EditProfileImg} alt="Edit-profile" />
              </div>
            </Button>
          </Link>
        }
      </div>
      
    </>
  );
}
