import React, { Component } from "react";
import classes from "./MultipleNfts.module.css";
import SingleCollectiblePageTitleAndSwithBtn from "../../components/SingleCollectiblePage/SingleCollectiblePageTitleAndSwithBtn/SingleCollectiblePageTitleAndSwithBtn";
import SingleCollectiblePageBtnAndAutoSave from "../../components/SingleCollectiblePage/SingleCollectiblePageBtnAndAutoSave/SingleCollectiblePageBtnAndAutoSave";
import SingleCollectiblePagePreview from "../../components/SingleCollectiblePage/SingleCollectiblePagePreview/SingleCollectiblePagePreview";
import ApiServices from "../../services/ApiServices";
import AppServices from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import NFTsSuccessModal from "../../components/Model/NFTsSuccessModal";
import ErrorModal from "../../components/Model/ErrorModal";
import PushNotification from "../../components/Model/PushNotification";
import Button from "../../components/Button/Button";
import UserAssetsCard from "../../components/UserAssetsCard/UserAssetsCard";
import UserTemplatesModal from "../../components/Model/UserTemplatesModal";
import LoaderIcon from "../../assets/img/loader.png";
import { Link } from "react-router-dom";
import BuyRamModal from "../../components/Model/BuyRamModal";

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes =
  "image/bmp, image/svg+xml, image/x-png, image/png, image/jpg, image/jpeg, image/webp, image/gif, audio/mpeg, audio/wav, audio/ogg ,video/mp4, video/quicktime";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

export default class MultipleNfts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      showBackdrop: false,
      showModal: false,
      showTemplateModal: false,
      showErrorModal: false,
      showLoader: false,
      modalAnimation: false,
      showNotification: false,
      notificationTitle: "",
      errorMsg: "",
      userAssetsData: {
        img: "",
        title: "",
        description: "",
        rarity: "",
        category: "",
        prevImg: "",
        prevVideo: "",
        prevAudio: "",
        fileName: "",
      },
      userUpdatedAssetsData: [],
      userTemplates: [],
      userAccount: "",
      userCollection: "",
      isCollectionCreated: false,
      userSchema: "",
      isSchemaCreated: false,
      userAsset: "",
      imageIpfsUrl: "",
      page: 1,
      limit: 12,
      loading: true,
      totalPages: 0,
      numberOfCopies: 1,
      showInput: false,
      enableBuyRamBtn: false,
      showBuyRamModal: false,
    };

    this.apiService = new ApiServices();
    this.appService = new AppServices();
    this.blockchainService = new BlockchainService();

    this.defaultSchemaName = process.env.REACT_APP_DEFAULT_SCHEMA;
  }

  async componentDidMount() {
    await this.getData();
    await this.getUserTemplates(
      this.state.userAccount,
      this.state.page,
      this.state.limit
    );
    await this.totalNumberOfPages(this.state.userAccount);
  }

  showModalHandler = () => {
    this.setState({ showModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  showTemplateModalHandler = () => {
    this.setState({ showTemplateModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  showLoaderHandler = () => {
    this.setState({ showLoader: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  showErrorModalHandler = () => {
    this.setState({ showErrorModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  closeModalHandler = () => {
    this.setState({
      showModal: false,
      showTemplateModal: false,
      showErrorModal: false,
      showLoader: false,
      modalAnimation: true,
      showBackdrop: false,
      showBuyRamModal: false,
    });
  };

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        this.showNotificationHandler(
          "This file is not allowed." + currentFileSize + " bytes too large"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        this.showNotificationHandler(
          "This file is not supported or file size is too large"
        );
        return false;
      }
      return true;
    }
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        this.setState((prevState) => {
          let userAssetsData = { ...prevState.userAssetsData };
          userAssetsData.img = currentFile;
          return { userAssetsData };
        });
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;

            this.setState((prevState) => {
              let userAssetsData = { ...prevState.userAssetsData };

              userAssetsData.prevVideo = "";
              userAssetsData.prevImg = "";
              userAssetsData.prevAudio = "";

              if (currentFile.type === "video/mp4") {
                userAssetsData.prevVideo = myResult;
              } else if (
                currentFile.type === "audio/mpeg" ||
                currentFile.type === "audio/wav" ||
                currentFile.type === "audio/ogg"
              ) {
                userAssetsData.prevAudio = myResult;
              } else {
                userAssetsData.prevImg = myResult;
              }
              return { userAssetsData };
            });
            this.setState((prevState) => {
              let userAssetsData = { ...prevState.userAssetsData };
              userAssetsData.fileName = currentFile.name;
              return { userAssetsData };
            });
          },
          false
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  userDataHandler = (e, type) => {
    this.setState((prevState) => {
      let userAssetsData = { ...prevState.userAssetsData };
      if (type === "title") userAssetsData.title = e.target.value;
      else if (type === "description")
        userAssetsData.description = e.target.value;
      else if (type === "rarity") userAssetsData.rarity = e;
      else if (type === "category") userAssetsData.category = e;
      else if (type === "copies")
        userAssetsData.numberOfCopies = e.target.value;

      return { userAssetsData };
    });
  };
  mintNFT = async () => {
    if (this.state.loading || !this.state.showInput) {
      this.setState({ errorMsg: "Template not selected!" });
    } else {
      let userAccount = this.state.userAccount;
      let schemaName = this.defaultSchemaName;
      let templateId = this.state.userAssetsData.template_id;
      let numberOfCopies = this.state.numberOfCopies;
      let mintingAction = { actions: [] };
      for (var i = 0; i < numberOfCopies; i++) {
        let mintNFTAction = this.blockchainService.createMintAssetAction(
          userAccount,
          schemaName,
          templateId
        );
        mintingAction.actions.push(mintNFTAction.actions[0]);
      }

      const result2 = await this.blockchainService.pushTransaction(
        mintingAction,
        this.props.ual
      );

      if (result2.success) {
        this.showModalHandler();
      } else {
        if (result2.message.includes("insufficient ram")) {
          this.setState({
            errorMsg: result2.message,
            pending: false,
            enableBuyRamBtn: true,
          });
          this.showErrorModalHandler();
          return;
        }
        this.setState({ errorMsg: result2.message });
        this.showErrorModalHandler();
      }
    }
  };
  getData = async () => {
    let isCollectionCreated = false,
      isSchemaCreated = false;
    let userSchema = this.defaultSchemaName;

    let userAccount = await this.getUserAccount();
    if (userAccount) {
      let userCollection = await this.getUserCollections(userAccount);
      if (userCollection) {
        isCollectionCreated = true;
        let userSchemas = await this.getUserSchema(userAccount);
        if (userSchemas) {
          for (var i = 0; i < userSchemas.length; i++) {
            let element = userSchemas[i];
            if (element.schema_name === userSchema) {
              isSchemaCreated = true;
            }
          }
        }
      }
    }

    this.setState({
      userAccount,
      userCollection: userAccount,
      isCollectionCreated,
      userSchema,
      isSchemaCreated,
    });
  };

  getUserAccount = async () => {
    let userAccount = await this.appService.getAccountName(this.props.ual);
    return userAccount;
  };

  getUserCollections = async (userAccount) => {
    let res = await this.apiService.getUserCollection(userAccount);
    return res;
  };

  getUserSchema = async (collectionName) => {
    let userSchema = await this.apiService.getCollectionSchema(collectionName);
    return userSchema;
  };
  showNotificationHandler = (msg) => {
    this.setState({ showNotification: true, notificationTitle: msg });

    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };
  getUserTemplates = async (userAccount, page, limit) => {
    this.setState({ loading: true });
    let data = null;
    data = await this.apiService.getUserTemplate(userAccount, page, limit);

    if (data && data.length > 0) {
      let userTemplates = data.map((element, index) => {
        let tempAsset = {};
        tempAsset["title"] = element.immutable_data.name;
        tempAsset["rarity"] = element.immutable_data.rarity;
        tempAsset["category"] = element.immutable_data.category;
        tempAsset["detailsPath"] = "/templates/" + element.template_id;
        tempAsset["template_id"] = element.template_id;
        tempAsset["max_supply"] = element.max_supply;
        tempAsset["issued_supply"] = element.issued_supply;
        let contentType = "";
        let content = "";
        if (element.immutable_data.img) {
          contentType = "img";
          content = element.immutable_data.img;
        } else if (element.immutable_data.video) {
          contentType = "video";
          content = element.immutable_data.video;
        } else if (element.immutable_data.audio) {
          contentType = "audio";
          content = element.immutable_data.audio;
        }
        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        let inStock = 0;
        inStock = +element.max_supply - +element.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
      this.setState({ userTemplates });
    }
    this.setState({ loading: false });
    if (data == null) return false;
    else return true;
  };
  getNewPageData = async (page) => {
    if (page > 0) {
      window.scrollTo(0, 0);

      this.setState({ page, userTemplates: [] });
      await this.getUserTemplates(
        this.state.userAccount,
        page,
        this.state.limit
      );
    }
  };
  totalNumberOfPages = async (userAccount) => {
    let totalTemplates = await this.apiService.getTotalTemplatesNumber(
      userAccount
    );
    if (totalTemplates) {
      let totalPages = Math.ceil(totalTemplates.templates / this.state.limit);
      this.setState({ totalPages });
    }
  };
  handleTemplatePreview = (index) => {
    let selectedTemplate = this.state.userTemplates[index];

    let userAssetsData = {};
    if (selectedTemplate["type"] === "img") {
      userAssetsData["prevImg"] = selectedTemplate["content"];
    }
    if (selectedTemplate["type"] === "video") {
      userAssetsData["prevVideo"] = selectedTemplate["content"];
    }
    if (selectedTemplate["type"] === "audio") {
      userAssetsData["prevAudio"] = selectedTemplate["content"];
    }
    userAssetsData["title"] = selectedTemplate["title"];
    userAssetsData["category"] = selectedTemplate["category"];
    userAssetsData["rarity"] = selectedTemplate["rarity"];
    userAssetsData["template_id"] = selectedTemplate["template_id"];
    userAssetsData["max_supply"] = selectedTemplate["max_supply"];
    userAssetsData["issued_supply"] = selectedTemplate["issued_supply"];

    this.setState({
      userAssetsData,
      showTemplateModal: false,
      showBackdrop: false,
      showInput: true,
    });
  };
  handleNumberOfCopies = (e) => {
    const el = e.target || e;
    if (el.type === "number" && el.max && el.min) {
      let value = parseInt(el.value);
      el.value = value; // for 000 like input cleanup to 0
      let max = parseInt(el.max);
      let min = parseInt(el.min);
      if (value > max) el.value = el.max;
      if (value < min) el.value = el.min;
    }
    this.setState({ numberOfCopies: el.value });
  };
  openBuyRamModal = () => {
    this.setState({ showBuyRamModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  removeBuyRamError = () => {
    this.setState({ enableBuyRamBtn: false, errorMsg: "" });
  };
  render() {
    return (
      <>
        <div className={classes.SingleCollectibleMainContainer}>
          <SingleCollectiblePageTitleAndSwithBtn />
          <Link to="/create-template">
            <Button secondary>
              <div>Create Template</div>
            </Button>
          </Link>
          <div className={classes.topSingleCollectibleTextContainer}>
            <div className={classes.FirstFlexContainer}>
              {this.state.loading ? (
                <div className={classes.loadMoreBtnContainer}>
                  <button>
                    <img
                      src={LoaderIcon}
                      alt="Loading..."
                      className={classes.loaderAnimationIcon}
                    />
                    <span>Loading...</span>
                  </button>
                </div>
              ) : (
                !this.state.loading &&
                this.state.userTemplates.length !== 0 && (
                  <div className={classes.userTemplatesMianContainer}>
                    <p>Select Template</p>
                    <div className={classes.userTemplatesContainer}>
                      {this.state.userTemplates
                        .slice(0, 4)
                        .map((item, index) => {
                          return (
                            <div
                              className={classes.userTemplatesCard}
                              key={index}
                              onClick={() => this.handleTemplatePreview(index)}
                            >
                              <UserAssetsCard
                                assetsContent={[item]}
                                title={item.title}
                                // stock={item.stock}
                                // detailsPath={item.detailsPath}
                                marginBottom={0}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <Button onClick={this.showTemplateModalHandler}>
                      <div>Show more</div>
                    </Button>
                  </div>
                )
              )}
              {this.state.showInput && (
                <>
                  <div className={classes.FormContainer}>
                    <div>No. of copies in flow</div>
                    <input
                      type="number"
                      min={1}
                      max={
                        this.state.userAssetsData.max_supply -
                        this.state.userAssetsData.issued_supply
                      }
                      value={this.state.numberOfCopies}
                      onChange={this.handleNumberOfCopies}
                      onKeyDown={this.handleNumberOfCopiesValue}
                    />
                  </div>
                  <p className={classes.remainingSupply}>
                    Remaining supply (
                    {this.state.userAssetsData.max_supply -
                      this.state.userAssetsData.issued_supply}
                    )
                  </p>
                </>
              )}
              {this.state.enableBuyRamBtn && (
                <Button onClick={this.openBuyRamModal}>Buy Ram</Button>
              )}
              <SingleCollectiblePageBtnAndAutoSave
                errorMsg={this.state.errorMsg}
                pending={this.state.pending}
                createItemBtnHandler={this.mintNFT}
                show={this.state.showInput}
                isMultipleNftsCreate={true}
              />
            </div>
            <div className={classes.SecondFlexContainer}>
              <SingleCollectiblePagePreview
                templatePreview={true}
                prevImg={this.state.userAssetsData.prevImg}
                prevVideo={this.state.userAssetsData.prevVideo}
                prevAudio={this.state.userAssetsData.prevAudio}
                title={this.state.userAssetsData.title}
                description={this.state.userAssetsData.description}
                category={this.state.userAssetsData.category}
                rarity={this.state.userAssetsData.rarity}
                clearAllHandler={this.clearAllHandler}
                id={1}
                totalPages={this.state.totalPages}
                max_supply={this.state.userAssetsData.max_supply}
              />
            </div>
          </div>
        </div>
        <div className={classes.modalsContainer}>
          <Backdrop
            show={this.state.showBackdrop}
            onClick={this.closeModalHandler}
            animation={this.state.modalAnimation}
          />
          <NFTsSuccessModal
            msgType="The NFT is created successfully"
            showModal={this.state.showModal}
            close={this.closeModalHandler}
          />
          <UserTemplatesModal
            showModal={this.state.showTemplateModal}
            close={this.closeModalHandler}
            userTemplates={this.state.userTemplates}
            loading={this.state.loading}
            getNewPageData={this.getNewPageData}
            page={this.state.page}
            limit={this.state.limit}
            userAssetsData={this.handleTemplatePreview}
            totalPages={this.state.totalPages}
          />
          <ErrorModal
            errorMsg={this.state.errorMsg}
            showModal={this.state.showErrorModal}
            close={this.closeModalHandler}
          />
          {this.state.showBuyRamModal && (
            <BuyRamModal
              msgType="Buy Ram"
              showModal={this.state.showBuyRamModal}
              close={this.closeModalHandler}
              ual={this.props.ual}
              showNotificationHandler={this.showNotificationHandler}
              removeError={this.removeBuyRamError}
            />
          )}
          <PushNotification
            title={this.state.notificationTitle}
            showNotification={this.state.showNotification}
          />
        </div>
      </>
    );
  }
}
