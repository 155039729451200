import React, { Component } from "react";
import classes from "./EditProfileTitle.module.css";

export default class EditProfileTitle extends Component {
  render() {
    return (
      <div className={classes.editProfilePageTitle}>
        <h1>Account Information</h1>
        <p>
          You can set preferred display name, create <span>your account</span>{" "}
          and manage other personal settings.
        </p>
      </div>
    );
  }
}
