import React, { useState, useEffect } from "react";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import classes from "./Market.module.css";
import MarketPageContent from "../../components/MarketPage/MarketPageContent/MarketPageContent";
import MarketPageFilters from "../../components/MarketPage/MarketPageFilters/MarketPageFilters";
import PageWrapper from "../../components/PageWrapper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_STATE,
  SET_ALL_COLL,
  SET_CATEGORY_Q,
  SET_CURRENCY_Q,
  SET_DISCOVER_ASSETS,
  SET_DROP_COLL,
  SET_DROP_COLL_Q,
  SET_FILTER_CURRENCY,
  SET_MATCH,
  SET_MAX_MINT,
  SET_MAX_PRICE,
  SET_MIN_MINT,
  SET_MIN_PRICE,
  SET_MINT_Q,
  SET_ORDER_Q,
  SET_PAGE,
  SET_PRICE_Q,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_ORDER,
  SET_SELECTED_TAB,
  SET_SUP_TOKENS,
  SET_TAB_Q,
  SET_URL_Q,
  SET_WHITELIST_COLL,
  SET_SHOW_QUERY,
} from "../../stores/reducers/marketReducer";
import { apiService, firebaseService } from "../../constants/Servises";
import { LIMIT, LISTING_TYPE, ORDER_TYPE } from "../../constants/Market";

const Market = ({ ual }) => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const queryData = useSelector((state) => state.market.query);
  const queryUrl = useSelector((state) => state.market.queryUrl);
  const selectedTab = useSelector((state) => state.market.selectedTab);
  const selectedOrder = useSelector((state) => state.market.selectedOrder);
  const dropCollection = useSelector((state) => state.market.dropCollection);
  const selectedCategory = useSelector(
    (state) => state.market.selectedCategory
  );
  const filterCurrencyDropDownValue = useSelector(
    (state) => state.market.filterCurrencyDropDownValue
  );

  const allCollections = useSelector((state) => state.market.allCollections);
  const minPrice = useSelector((state) => state.market.minPrice);
  const maxPrice = useSelector((state) => state.market.maxPrice);
  const minTemplateMint = useSelector((state) => state.market.minTemplateMint);
  const maxTemplateMint = useSelector((state) => state.market.maxTemplateMint);
  const templateId = useSelector((state) => state.market.templateId);
  const discoverAssets = useSelector((state) => state.market.discoverAssets);
  const page = useSelector((state) => state.market.page);
  const showQuery = useSelector((state) => state.market.showQuery);
  const whileListCollection = useSelector(
    (state) => state.market.whileListCollection
  );
  const match = useSelector((state) => state.market.match);
  const supportedTokens = useSelector((state) => state.market.supportedTokens);

  useEffect(() => {
    history.listen((location) => {
      if (
        !location.pathname.includes("/market") &&
        !location.pathname.includes("/sale") &&
        !location.pathname.includes("/auction")
      ) {
        dispatch(CLEAR_STATE());
      }
    });
  }, [history]);

  useEffect(() => {
    let {
      currencyQuery,
      priceQuery,
      mintQuery,
      categoryQuery,
      dropCollectionQuery,
      orderQuery,
      tabQuery,
      templateIdQuery,
    } = queryData;
    if (showQuery) {
      let url =
        `market?${tabQuery ? `tab=${tabQuery}` : ""}${
          orderQuery ? `&order=${orderQuery}` : ""
        }${
          dropCollectionQuery ? `&collection_name=${dropCollectionQuery}` : ""
        }` +
        `${categoryQuery ? `&data.category=${categoryQuery}` : ""}${
          priceQuery.min ? "&minPrice=" + priceQuery.min : ""
        }${priceQuery.max ? "&maxPrice=" + priceQuery.max : ""}` +
        `${mintQuery.min ? "&minTemplateMint=" + mintQuery.min : ""}${
          mintQuery.max ? "&maxTemplateMint=" + mintQuery.max : ""
        }` +
        `${currencyQuery ? "&currency=" + currencyQuery : ""}` +
        `${templateIdQuery ? "&template_id=" + templateIdQuery : ""}`;

      dispatch(SET_URL_Q(url));
      dispatch(SET_SHOW_QUERY(false));
    }

    history.push(queryUrl);
  }, [showQuery]);

  useEffect(() => {
    (async () => await getData())();
  }, [
    selectedTab,
    page,
    selectedCategory,
    dropCollection,
    minPrice,
    maxPrice,
    selectedOrder,
    minTemplateMint,
    maxTemplateMint,
    templateId,
    match,
    filterCurrencyDropDownValue,
  ]);

  useEffect(() => {
    (async () => await getWhiteListCollections())();
    (async () => await getCollections())();

    (async () =>
      await apiService.getSupportedTokens().then((supportedTokensFromApi) => {
        let allTokens = [
          ...supportedTokensFromApi.map((item) => item.token_symbol),
        ];
        dispatch(SET_SUP_TOKENS([...allTokens]));
      }))();
  }, []);

  const setPageHandler = (value) => {
    dispatch(SET_PAGE(value));
  };
  const setSearchHandler = (value) => {
    dispatch(SET_MATCH(value));
  };

  const getWhiteListCollections = async () => {
    let response = await firebaseService.getArtists();
    if (response) {
      let collections = response.data() ?? null;
      if (collections) {
        collections = collections.artists ?? [];
        dispatch(SET_WHITELIST_COLL(collections.toString()));
      }
    }
  };

  const updateCategory = (category) => {
    const page = 1;

    dispatch(SET_PAGE(page));
    dispatch(SET_SELECTED_CATEGORY(category));
    dispatch(SET_CATEGORY_Q(category));
    dispatch(SET_SHOW_QUERY(true));

    if (dropCollection !== "All creators") {
      dispatch(SET_DROP_COLL("All creators"));
      dispatch(SET_DROP_COLL_Q("All creators"));
    }
  };

  const dropdownCreatorHandler = (item) => {
    const page = 1;

    dispatch(SET_DROP_COLL(item));
    dispatch(SET_PAGE(page));
    dispatch(SET_DROP_COLL_Q(item));
    dispatch(SET_SHOW_QUERY(true));

    if (selectedCategory === "ZeptaNFTs") {
      dispatch(SET_SELECTED_CATEGORY("All items"));
      dispatch(SET_CATEGORY_Q("All items"));
    }
  };

  const selectTabHandler = (tab) => {
    const page = 1;

    dispatch(SET_SELECTED_TAB(tab));
    dispatch(SET_PAGE(page));
    dispatch(SET_TAB_Q(tab));
    dispatch(SET_SHOW_QUERY(true));
  };

  const getCollections = async () => {
    const collectionList = await apiService.getTopCollection(20);

    if (collectionList && collectionList.length > 0) {
      let collections = [];

      for (let i = 0; i < collectionList.length; i++) {
        let collection = collectionList[i];
        let title = collection.collection_name ?? "";
        collections.push(title);
      }
      dispatch(SET_ALL_COLL(collections));
    }
  };

  const getContent = (asset) => {
    const title = asset.data.name;
    let type = "";
    let content = "";

    if (asset.data.img) {
      type = "img";
      content = asset.data.img;
    } else if (asset.data.video) {
      type = "video";
      content = asset.data.video;
    } else if (asset.data.audio) {
      type = "audio";
      content = asset.data.audio;
    }

    return { title, type, content };
  };

  const handleData = (data, isAuction) => {
    let tempList = [];
    if (data) {
      tempList = data.map((element) => {
        const isBundle = element.assets.length > 1;
        let tempRecord = {};
        tempRecord["seller"] = element.seller;
        tempRecord["tokenSymbol"] = element.price.token_symbol;
        tempRecord["tokenPrecision"] = element.price.token_precision;
        tempRecord["title"] = isBundle
          ? `${element.assets.length} NFT Bundle`
          : element.assets[0].data.name;
        tempRecord["detailsPath"] = "/assets/" + element.assets[0].asset_id;
        tempRecord["purchasePath"] = isAuction
          ? "/auction/" + element.auction_id
          : "/sale/" + element.sale_id;
        tempRecord["aasetDetils"] = element;
        tempRecord["assetsContent"] = element.assets.reduce(
          (assetsContent, asset) => {
            assetsContent = [...assetsContent, getContent(asset)];
            return assetsContent;
          },
          []
        );

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempRecord["stock"] = inStock;

        tempRecord["tokenAmount"] = element.price.amount;

        if (isAuction === true) {
          if (element.bids?.length > 0) {
            tempRecord["tokenAmount"] =
              element.bids[element.bids.length - 1].amount;
          }

          tempRecord["purchasePath"] = "/auction/" + element.auction_id;
          tempRecord["auction"] = true;
        }

        return tempRecord;
      });
    }
    return tempList;
  };

  const getData = async () => {
    setLoading(true);
    dispatch(SET_DISCOVER_ASSETS([]));
    const category =
      selectedCategory === "All items" ? null : selectedCategory.toLowerCase();
    let collection = null;
    collection =
      category && category === "zeptanfts" ? whileListCollection : null;
    collection =
      dropCollection === "All creators" ? collection : dropCollection;
    const order = selectedOrder === ORDER_TYPE.asc ? "asc" : "desc";
    const symbol = filterCurrencyDropDownValue;
    let data = null;
    if (selectedTab === "Auctions")
      data = await apiService.getAuctionList(
        LIMIT,
        page,
        category,
        collection,
        minPrice,
        maxPrice,
        order,
        minTemplateMint,
        maxTemplateMint,
        templateId,
        symbol
      );
    else
      data = await apiService.getSales(
        LIMIT,
        page,
        category,
        collection,
        minPrice,
        maxPrice,
        order,
        minTemplateMint,
        maxTemplateMint,
        templateId,
        match,
        symbol
      );

    if (data && data.length > 0) {
      const assets = handleData(data, selectedTab === LISTING_TYPE.auctions);
      dispatch(SET_DISCOVER_ASSETS(assets));
    }
    setLoading(false);
  };

  const getNewPageData = (page) => {
    window.scrollTo(0, 0);
    dispatch(SET_PAGE(page));
  };

  const handleOrderType = (item) => {
    const page = 1;

    dispatch(SET_PAGE(page));
    dispatch(SET_SELECTED_ORDER(item));
    dispatch(SET_ORDER_Q(item));
    dispatch(SET_SHOW_QUERY(true));
  };

  const updatePrice = (amount, type) => {
    const page = 1;
    let min = minPrice;
    let max = maxPrice;

    dispatch(SET_PAGE(page));

    if (type === "min") {
      min = amount;
      dispatch(SET_MIN_PRICE(min));
      dispatch(SET_PRICE_Q({ min, max }));
      dispatch(SET_SHOW_QUERY(true));
    } else if (type === "max") {
      max = amount;
      dispatch(SET_MAX_PRICE(max));
      dispatch(SET_PRICE_Q({ min, max }));
      dispatch(SET_SHOW_QUERY(true));
    }
  };

  const updateMint = (mintNumber, type) => {
    const page = 1;
    let min = minTemplateMint;
    let max = maxTemplateMint;

    dispatch(SET_PAGE(page));

    if (type === "min") {
      min = mintNumber;
      dispatch(SET_MIN_MINT(min));
      dispatch(SET_MINT_Q({ min, max }));
      dispatch(SET_SHOW_QUERY(true));
    } else if (type === "max") {
      max = mintNumber;
      dispatch(SET_MAX_MINT(max));
      dispatch(SET_MINT_Q({ min, max }));
      dispatch(SET_SHOW_QUERY(true));
    }
  };

  const filterCurrencyDropDown = (item) => {
    const page = 1;

    dispatch(SET_PAGE(page));
    dispatch(SET_FILTER_CURRENCY(item));
    dispatch(SET_CURRENCY_Q(item));
    dispatch(SET_SHOW_QUERY(true));
  };

  return (
    <PageWrapper>
      <h1>Market</h1>
      <div className={classes.filterSearchContainer}>
        <FilterSearch
          match={match}
          setMatch={setSearchHandler}
          isMarket
          dropCollection={dropCollection}
        />
      </div>
      <div className={classes.searchFiltersContainer}>
        <MarketPageFilters
          orderListingTypes={[ORDER_TYPE.desc, ORDER_TYPE.asc]}
          handleOrderType={handleOrderType}
          selectedOrder={selectedOrder}
          updatePrice={updatePrice}
          minPrice={minPrice}
          maxPrice={maxPrice}
          collectionOptions={allCollections}
          setCollection={dropdownCreatorHandler}
          selectedCollection={dropCollection}
          updateMint={updateMint}
          minTemplateMint={minTemplateMint}
          maxTemplateMint={maxTemplateMint}
          setPage={setPageHandler}
          filterCurrencyDropDown={filterCurrencyDropDown}
          filterCurrencyDropDownValue={filterCurrencyDropDownValue}
          supportedTokens={supportedTokens}
        />
      </div>
      <div className={classes.searchContentContainer}>
        <MarketPageContent
          limit={LIMIT}
          discoverAssets={discoverAssets}
          loading={loading}
          tabs={["Sells", "Auctions"]}
          tabs2={[
            "All items",
            // "Art",
            // "Game",
            // "Photography",
            // "Music",
            // "Video",
            "ZeptaNFTs",
          ]}
          selectedTab={selectedTab}
          selectTabHandler={selectTabHandler}
          setTab2={updateCategory}
          selectedTab2={selectedCategory}
          getNewPageData={getNewPageData}
          page={page}
          ual={ual}
          match={match}
          filterCurrencyDropDownValue={filterCurrencyDropDownValue}
        />
      </div>
    </PageWrapper>
  );
};

export default Market;
