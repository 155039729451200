import React, { Component } from "react";
import classes from "./Footer.module.css";
import Logo from "../../assets/img/zeptagramLogo.png";
// import NavigationItems from "../Toolbar/Navbar/NavigationItems/NavigationItems";
import FooterLinks from "./FooterLinks/FooterLinks";
import { Link } from "react-router-dom";
import SocialLink from "../UI/SocialButton/SocialLink";
import { NETWORKS } from "../../constants/Footer";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchEmail: "",
      errorMsg: "",
    };
  }
  // searchEmailHandler = () => {
  //   let errorMsg = "";
  //   const emailRegex = /\S+@\S+\.\S+/;

  //   if (this.state.searchEmail !== "") {
  //     if (!emailRegex.test(this.state.searchEmail)) {
  //       errorMsg = "Please enter a valid email address!";
  //     } else {
  //       alert("Your email is " + this.state.searchEmail);
  //     }
  //   } else {
  //     errorMsg = "Email address required!";
  //   }
  //   this.setState({ errorMsg });
  // };
  render() {
    return (
      <div className={classes.footerMainContainer}>
        <div className={classes.footerSectionsMainContainer}>
          <section className={classes.footerBrandSection}>
            <div className={classes.logoContainer}>
              <Link to="/">
                <img src={Logo} alt="Zeptagram-footer-logo" />
              </Link>
            </div>
            <h2>The New Creative Economy.</h2>
          </section>
          <section className={classes.footerLinksSection}>
            <div className={classes.footerLinksMainContainer}>
              <div className={classes.linksContainer}>
                <h2>Explore</h2>
                <ul>
                  <FooterLinks title="Discover" link="/market" />
                  {/* <FooterLinks title="Connect wallet" link="/" /> */}
                  <FooterLinks title="Create NFT" link="/nft-creator" />
                  <FooterLinks title="FAQ" link="/works" />
                  <FooterLinks
                    title="Privacy Policy"
                    link="https://gateway.pinata.cloud/ipfs/QmX57MpLFfUQQQF52zQcV7F5uj8voKAMP5nrcrnYjAKvGC"
                  />
                </ul>
              </div>
              <div className={classes.linksContainer}>
                <h2>Developer</h2>
                <ul>
                  <FooterLinks title="Overview" link="/" />
                  <FooterLinks title="Docs" link="/" />
                  <FooterLinks title="Dev Group" link="/" />
                  <FooterLinks title="Asset API" link="/" />
                  <FooterLinks title="Marketplace API" link="/" />
                </ul>
              </div>
              <div className={classes.linksContainer}>
                <h2>Contact</h2>
                <ul className={classes.networks}>
                  {NETWORKS.map((net, index) => (
                    <SocialLink key={index} network={net.network} to={net.to} />
                  ))}
                </ul>
              </div>
            </div>
          </section>
          {/* <section className={classes.footerNewsletterSection}>
            <h2>Join Newsletter</h2>
            <p>
              Subscribe our newsletter to get more free design course and
              resource
            </p>
            <div className={classes.newsletterSearchbarContainer}>
              <input
                type="text"
                placeholder="Enter your email"
                value={this.state.searchEmail}
                onChange={(e) => this.setState({ searchEmail: e.target.value })}
              />
              <div
                className={classes.searchArrowIconContainer}
                onClick={this.searchEmailHandler}
              >
                <i className="fas fa-long-arrow-alt-right"></i>
                <i className="fas fa-long-arrow-alt-right"></i>
              </div>
              <div className={classes.searchErrorMsg}>
                {this.state.errorMsg}
              </div>
            </div>
          </section> */}
        </div>
        <div className={classes.copyRightsContainer}>
          <div className={classes.copyRightsText}>
            Copyright © {new Date().getFullYear()} Zeptagram. All right reserved
          </div>
          {/* <div className={classes.cookiesContainer}>
            We use cookies for better service. <button>Accept</button>
          </div> */}
        </div>
      </div>
    );
  }
}
