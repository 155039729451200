import React from "react";
import classes from "./NavigationItems.module.css";
import { NavLink } from "react-router-dom";

export default function NavigationItems(props) {
  return (
    <li className={classes.navigationItemContainer}>
      <NavLink to={props.link} activeStyle={{ color: "#23262f" }}>
        {props.title}
      </NavLink>
    </li>
  );
}
