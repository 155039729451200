import React, { Component } from "react";
import Button from "../../Button/Button";
import classes from "./EditProfilePageAccountInfo.module.css";

export default class EditProfilePageAccountInfo extends Component {
  render() {
    return (
      <div className={classes.infoAccountContainer}>
        <h2>Account info</h2>
        <div className={classes.labelAndInputFieldContainer}>
          <h3>
            Display Name
            <span className={classes.requiredField}>*</span>
          </h3>
          <div className={classes.inputFieldContainer}>
            <input
              type="text"
              placeholder="Enter your display name"
              value={this.props.userName}
              onChange={this.props.displayNameHandler}
            />
            <div className={classes.userInputErrorMsg}>
              {this.props.errorMsg}
            </div>
          </div>
        </div>
        <div className={classes.labelAndInputFieldContainer}>
          <h3>
            Bio
            <span className={classes.requiredField}>*</span>
          </h3>
          <div className={classes.textareaFieldContainer}>
            <textarea
              name="bio"
              id="bio"
              placeholder="About yourself in a few words"
              className={classes.inputFieldContainer}
              value={this.props.userBio}
              onChange={this.props.bioHandler}
            ></textarea>
            <div className={classes.userInputErrorMsg}>
              {this.props.errorMsgBio}
            </div>
          </div>
        </div>
        <h2>Social</h2>
        <div className={classes.labelAndInputFieldContainer}>
          <h3>facebook</h3>
          <div className={classes.inputFieldContainer}>
            <input
              type="text"
              placeholder="Enter facebook profile url"
              value={this.props.facebookProfile}
              onChange={this.props.facebookProfileHandler}
            />
            <div className={classes.userInputErrorMsg}>
              {this.props.errorMsgFB}
            </div>
          </div>
        </div>
        <div className={classes.labelAndInputFieldContainer}>
          <h3>twitter</h3>
          <div className={classes.inputFieldContainer}>
            <input
              type="text"
              placeholder="@twitter username"
              className={classes.twiterInputField}
              value={this.props.userTwitter}
              onChange={this.props.twitterHandler}
            />
            <div className={classes.userInputErrorMsg}>
              {this.props.errorMsgTwitter}
            </div>
          </div>
        </div>
        <div className={classes.labelAndInputFieldContainer}>
          <h3>Instagram</h3>
          <div className={classes.inputFieldContainer}>
            <input
              type="text"
              placeholder="@instagram username"
              className={classes.twitterInputField}
              value={this.props.instagramProfile}
              onChange={this.props.instagramProfileHandler}
            />
            <div className={classes.userInputErrorMsg}>
              {this.props.errorMsgInstagram}
            </div>
          </div>
        </div>
        <div className={classes.infoAccountBtnContainer}>
          <Button onClick={this.props.updateBtnHandler}>
            <div>Update Profile</div>
          </Button>
          <div
            className={classes.clearAllBtnContainer}
            onClick={this.props.clearAllHandler}
          >
            <i className="far fa-times-circle"></i>
            <div>Clear all</div>
          </div>
        </div>
      </div>
    );
  }
}
