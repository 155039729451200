// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar_searchBarContainer__2_lN7 {\r\n position: relative;\r\n display: flex;\r\n align-items: center;\r\n width: 100%;\r\n background: rgba(168, 187, 207, 0.12);\r\n border-radius: 31px;\r\n}\r\n.SearchBar_searchBarContainer__2_lN7 > input {\r\n padding: 16px 20px;\r\n width: 75%;\r\n outline: none;\r\n border: none;\r\n background: transparent;\r\n font-size: 12px;\r\n color: #777e91;\r\n letter-spacing: 1.2px;\r\n}\r\n.SearchBar_searchBarContainer__2_lN7 > button {\r\n font-size: 14px;\r\n color: #23262f;\r\n position: absolute;\r\n border: none;\r\n right: 15px;\r\n opacity: 0.5;\r\n cursor: pointer;\r\n}\r\n@media only screen and (max-width: 992px) {\r\n .SearchBar_searchBarContainer__2_lN7 > input {\r\n  padding: 14px;\r\n }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Toolbar/Navbar/Searchbar/SearchBar.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,mBAAmB;CACnB,WAAW;CACX,qCAAqC;CACrC,mBAAmB;AACpB;AACA;CACC,kBAAkB;CAClB,UAAU;CACV,aAAa;CACb,YAAY;CACZ,uBAAuB;CACvB,eAAe;CACf,cAAc;CACd,qBAAqB;AACtB;AACA;CACC,eAAe;CACf,cAAc;CACd,kBAAkB;CAClB,YAAY;CACZ,WAAW;CACX,YAAY;CACZ,eAAe;AAChB;AACA;CACC;EACC,aAAa;CACd;AACD","sourcesContent":[".searchBarContainer {\r\n position: relative;\r\n display: flex;\r\n align-items: center;\r\n width: 100%;\r\n background: rgba(168, 187, 207, 0.12);\r\n border-radius: 31px;\r\n}\r\n.searchBarContainer > input {\r\n padding: 16px 20px;\r\n width: 75%;\r\n outline: none;\r\n border: none;\r\n background: transparent;\r\n font-size: 12px;\r\n color: #777e91;\r\n letter-spacing: 1.2px;\r\n}\r\n.searchBarContainer > button {\r\n font-size: 14px;\r\n color: #23262f;\r\n position: absolute;\r\n border: none;\r\n right: 15px;\r\n opacity: 0.5;\r\n cursor: pointer;\r\n}\r\n@media only screen and (max-width: 992px) {\r\n .searchBarContainer > input {\r\n  padding: 14px;\r\n }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": "SearchBar_searchBarContainer__2_lN7"
};
export default ___CSS_LOADER_EXPORT___;
