import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import crypto from "crypto-js";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Toolbar/Navbar/Navbar";
import { Home } from "../screens/Home/Home";
import UploadItem from "../screens/UploadItem/UploadItem";
import { SingleCollectible } from "../screens/SingleCollectible/SingleCollectible";
import Profile from "../screens/Profile/Profile";
import Search from "../screens/Search/Search";
import SearchNotFound from "../screens/SearchNotFound/SearchNotFound";
import EditProfile from "../screens/EditProfile/EditProfile";
// import AuctionDetails from "../screens/AuctionDetails/AuctionDetails";
import PrivateRoute from "./PrivateRoute";
import Login from "../screens/Login/Login";
import HowItWorks from "../screens/HowItWorks/HowItWorks";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Market from "../screens/Market/Market";
import MultipleNfts from "../screens/MultipleNfts/MultipleNfts";
import CreateTemplate from "../screens/CreateTemplate/CreateTemplate";
import CookieConsent from "react-cookie-consent";
// import NetworksBlockchainsPage from "../components/NetworksBlockchainsPage/NetworksBlockchainsPage";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOGIN, SET_USER_PROFILE } from "../stores/reducers/authReducer";
import AppServices from "../services/AppService";
import { Policy } from "../components/Policy/Policy";
import { CreateNewCollection } from "../components/CreateNewCollection/CreateNewCollection";
import { CreateSchema } from "../components/CreateSchema/CreateSchema";
// import { SingleCollectibleRedux } from "../screens/SingleCollectible/SingleCollectible";
import NFTDetails from "../screens/NFTDetails";
import CollectionDetails from "../screens/CollectionDetails";
import TemplateDetails from "../screens/TemplateDetails";
import SchemaDetails from "../screens/SchemaDetails";
import EscterPayScreen from "../screens/EcsterPyment/EcsterPay";
import ApiServices from "../services/ApiServices";
const key = process.env.REACT_APP_SECRET_KEY;

export const Routing = (props) => {
  const dispatch = useDispatch();
  const appService = new AppServices(props);
  const apiService = new ApiServices();
  const userName = useSelector((state) => state.auth.userName);
  const cartDetails = useSelector((state) => state.sale.saleDetails);

  useEffect(() => {
    (async () => {
      try {
        let userLogin = await appService.getAccountName(props.ual);
        const token = localStorage.getItem("token");

        if (userLogin) {
          if (!token) {
            const walletName = props.ual.activeUser.accountName;
            const data = {
              walletName,
            };
            const hash = crypto
              .SHA256(JSON.stringify(data), key)
              .toString(crypto.enc.Hex);
            const result = await apiService.login({
              accountName: walletName,
              hash,
            });
            if (result.token) {
              dispatch(SET_LOGIN(userLogin));
            } else {
              appService.logout(props.ual);
            }
          }
        }
      } catch (error) {}
    })();
  }, [props.ual.activeUser]);
  const setUserProfileLogin = (user) => {
    dispatch(SET_USER_PROFILE(user));
  };

  return (
    <Router>
      <ScrollToTop />
      <Navbar ual={props.ual} />
      <div
        style={{
          width: "100%",
          height: "90px",
        }}
      ></div>
      <Switch>
        <Route exact path="/" component={() => <Home {...props} />} />
        <Route
          path="/profile/:id"
          component={() => (
            <Profile
              {...props}
              setUserProfileLogin={setUserProfileLogin}
              userName={userName}
            />
          )}
        />
        <Route exact path="/explorer" component={() => <Search {...props} />} />
        <Route exact path="/market" component={() => <Market {...props} />} />
        <Route
          exact
          path="/assets/:id"
          component={() => <NFTDetails {...props} />}
        />
        <Route
          exact
          path="/sale/:id"
          component={() => <NFTDetails {...props} />}
        />
        <Route
          exact
          path="/auction/:id"
          component={() => <NFTDetails {...props} />}
        />
        {/* <Route
          exact
          path="/NetworksBlockchainsPage"
          component={() => <NetworksBlockchainsPage />}
        /> */}
        <Route
          exact
          path="/collections/:name"
          component={() => <CollectionDetails {...props} />}
        />
        <Route
          exact
          path="/templates/:id"
          component={() => <TemplateDetails {...props} />}
        />
        <Route
          exact
          path="/schema/:collectionName/:schemaName"
          component={() => <SchemaDetails {...props} />}
        />
        <PrivateRoute
          exact
          path="/single-nft"
          ual={props.ual}
          render={() => <SingleCollectible {...props} />}
        />
        <PrivateRoute
          exact
          path="/create-schema"
          ual={props.ual}
          render={() => <CreateSchema {...props} />}
        />

        <PrivateRoute
          exact
          path="/create-new-collection"
          ual={props.ual}
          render={() => <CreateNewCollection {...props} />}
        />
        <PrivateRoute
          exact
          path="/multiple-nfts"
          ual={props.ual}
          render={() => <MultipleNfts {...props} />}
        />
        <PrivateRoute
          exact
          path="/create-template"
          ual={props.ual}
          render={() => <CreateTemplate {...props} />}
        />
        <PrivateRoute
          exact
          path="/payment/:id"
          ual={props.ual}
          render={() => <EscterPayScreen {...props} cartdata={cartDetails} />}
        />

        {/* <PrivateRoute
            exact
            path="/uploaditem"
            ual={props.ual}
            render={() => <UploadItem {...props} />}
          /> */}
        <Route
          exact
          path="/nft-creator"
          ual={props.ual}
          render={() => <UploadItem {...props} />}
        />

        <PrivateRoute
          exact
          path="/edit-profile"
          ual={props.ual}
          render={() => <EditProfile {...props} />}
        />

        <Route exact path="/login" component={() => <Login {...props} />} />
        <Route
          exact
          path="/works"
          component={() => <HowItWorks {...props} />}
        />
        <Route exact path="/privacy" component={() => <Policy />} />

        <Route component={SearchNotFound} />
      </Switch>
      <Footer />

      <CookieConsent
        cookieName={"CookieName"}
        onAccept={() => {}}
        location="bottom"
        style={{ background: "#f5f2e8", color: "#23262f", textAlign: "left" }}
        buttonStyle={{ color: "#23262f", background: "#ded2b0" }}
        buttonText="I Agree"
      >
        This website uses cookies to ensure you get the best experience on our
        website. See our <Link to="/privacy">Privacy Policy</Link> for more.
      </CookieConsent>
    </Router>
  );
};
