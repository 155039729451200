import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classes from "./SchemaDetails.module.css";
import PageWrapper from "../../components/PageWrapper";
import ApiServices from "../../services/ApiServices";
import Button from "../../components/Button/Button";
import Loader from "../../components/UI/Loader";
import { useHistory } from "react-router-dom";
import CollectionCard from "../../components/CollectionCard";
import SchemaTabsSections from "../../components/SchemaDetails/SchemaTabsSections";

const apiServices = new ApiServices();

const DESCRIPTION = "Schemas are used to describe the data structure of assets and templates";

const SchemaDetails = ({ ual }) => {
  const [schemaName, setSchemaName] = useState(null);
  const [collectionName, setCollectionName] = useState();
  const [schemaData, setSchemaData] = useState(null);
  const [collectionContent, setCollectionContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const goBackHandler = () => history.goBack();

  const getSchemaName = () => {
    const path = window.location.pathname;
    const splittedPath = path.split("/");
    if(splittedPath && splittedPath.length){
      setCollectionName(splittedPath[2]);
      setSchemaName(splittedPath[3])
    }
  }

  const getSchemaData = async (collectionName, schemaName) => {
    const data = await apiServices.getSchemaData(collectionName, schemaName);
    setSchemaData(data);
    setLoading(false);
  }

  const defineCollectionContent = async (collectionData) => {
    if(collectionData.img) {
      setCollectionContent(collectionData.img);
    } else {
      const collectionAssets = await apiServices.getTopCollectionAssets(collectionData.collection_name);
      if(collectionAssets && collectionAssets.length > 0) {
        setCollectionContent(collectionAssets[0].data.img);
      }
    }
  }

  useEffect(() => {
    getSchemaName();
  }, []);

  useEffect(() => {
    if(schemaName) {
      (async () => await getSchemaData(collectionName, schemaName))();
    }
    
  }, [schemaName]);

  useEffect(() => {
    if(schemaData) {
      (async () => await defineCollectionContent(schemaData[0].collection))();
    }
    
  }, [schemaData]);

  return (
    <PageWrapper>
      <Button secondary onClick={goBackHandler}>
        <div className={classes.goBackBtn}>
          <i className="fas fa-arrow-left"></i>
          {' '}
          Go back
        </div>
      </Button>
      {schemaData && !loading
        ? <div className={classes.contentWrapper}>
            <h1 className={classes.schemaTitle}>
              Schema:
              <span className={classes.name}>{` ${schemaName}`}</span>
            </h1>
            <p className={classes.schemaDescription}>
              {DESCRIPTION}
            </p>
            <div className={classes.secondContentWrapper}>
              <div className={classes.collectionWrapper}>
                <p className={classes.title}>Collection</p>
                <CollectionCard
                  content={collectionContent}
                  name={schemaData[0].collection.collection_name}
                />
              </div>
              <SchemaTabsSections schemaData={schemaData} />
            </div>
          </div>
        : <Loader loading={loading} />
      }
    </PageWrapper>
  );
}

export default SchemaDetails;
