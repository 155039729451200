import React from "react";
import classes from "./AssetAudio.module.css";
import AudioSpectrum from "react-audio-spectrum";
import AppServices from "../../../services/AppService";

const appService = new AppServices();

const AssetAudio = ({ id, content, onClick }) => {
  return ( 
    <div className={classes.audioSpectrumMainContainer} onClick={onClick}>
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <div className={classes.headPhoneIcon}>
          <i className="fas fa-headphones"></i>
        </div>
        <AudioSpectrum
          id={`audio-canvas${id}`}
          height={250}
          width={200}
          audioId={`audio-element${id}`}
          capColor={"#23262f "}
          capHeight={2}
          meterWidth={2}
          meterCount={512}
          meterColor={[
            { stop: 0, color: "#df7549" },
            { stop: 0.5, color: "#d6c89a" },
            { stop: 1, color: "#dc3825" },
          ]}
          gap={4}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          marginTop: -150,
        }}
      >
        <audio
          id={`audio-element${id}`}
          src={appService.getIpfsVidepUrl(content)}
          controls
          controlsList="nodownload"
          autoPlay={true}
          loop={true}
          muted={true}
          crossOrigin="anonymous"
        ></audio>
      </div>
    </div>
  );
}

export default AssetAudio;