import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userLogin: "",
  userName: "",
};

const authReducer = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    SET_LOGIN: (state, action) => {
      state.userLogin = action.payload;
    },
    SET_USER_PROFILE: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const { SET_LOGIN, SET_USER_PROFILE } = authReducer.actions;

export default authReducer.reducer;
