export default class AppServices  {

  constructor() {
    this.ipfsUrl = process.env.REACT_APP_IPFS_URL;
  }

  isLogin = () => {
    return localStorage.getItem("UALInvalidateAt");
  };

  login = async (ual) => {
    if (ual) await ual.showModal();
  };

  logout = (ual) => {
    localStorage.removeItem("token")
    if (ual.activeUser) ual.logout();
  };

  getAccountName = (ual) => {
    let accountName = null;
    if (ual.activeUser != null) {
      accountName = ual.activeUser.accountName ?? null;
    }
    return accountName;
  };

  getIpfsUrl = (ipfsId, thumbnail) => {
    let url = "";
    try {
      url = ipfsId?.includes("http")
        ? ipfsId
        : this.ipfsUrl +
        ipfsId
      // + "&h=" +
      // (thumbnail === true ? "100" : "370")
      // + "&output=gif&n=-1"
    } catch (err) { }


    return url;
  };

  getIpfsVidepUrl = (ipfsId) => {
    let url = "";

    try {
      url = ipfsId?.includes("http")
        ? ipfsId
        : "https://ipfs.io/ipfs/" + ipfsId;
    } catch (err) { }

    return url;
  };

  setUsdPrice = (price) => {
    localStorage.setItem("pusd", price);
  };

  getUsdPrice = () => {
    return localStorage.getItem("pusd");
  };
}


//https://resizer.atomichub.io/images/v1/preview?ipfs=QmaQTBr1egoQu1k2F7xVcU7DKuz9tHMWCGZt9fJgiscgcR&size=370
//https://images.weserv.nl/?url=https://ipfs.io/ipfs/QmaQTBr1egoQu1k2F7xVcU7DKuz9tHMWCGZt9fJgiscgcR&h=370