// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabContent_tabContentMainContainer__3mEwZ {\n  margin: 16px 10px;\n}\n\n@media only screen and (max-width: 1024px) {\n  .TabContent_tabContentMainContainer__3mEwZ {\n    overflow-x: scroll;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/FilterTabBar/TabContent.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".tabContentMainContainer {\n  margin: 16px 10px;\n}\n\n@media only screen and (max-width: 1024px) {\n  .tabContentMainContainer {\n    overflow-x: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContentMainContainer": "TabContent_tabContentMainContainer__3mEwZ"
};
export default ___CSS_LOADER_EXPORT___;
