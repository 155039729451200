import React, { useEffect, useState } from "react";
import classes from "./Timmer.module.css";

export default function Timer(props) {
  const calculateTimeLeft = () => {
    // let year = new Date().getFullYear();
    var d = new Date(0);
    d.setUTCSeconds(+props.remainingTime);

    const difference = +props.remainingTime - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.ceil(difference / 1000 / 60 / 60 / 24) - 1, //Math.floor(difference / (1000 * 60 * 60 * 24)),
        hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className={classes.timerSpan} key={interval}>
        <div>
          {timeLeft[interval]}
          <div>{interval}</div>
        </div>
      </div>
    );
  });
  return (
    <div>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
}
