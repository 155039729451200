import React from "react";
import classes from "./Backdrop.module.css";

export default function Backdrop(props) {
  return (
    <div
      className={
        props.show
          ? classes.backdropMainContainer
          : props.animation
            ? classes.backdropMainClose
            : null
      }
      onClick={props.onClick}
    ></div>
  );
}
