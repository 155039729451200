import React from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";
import TokenSelect from "../Model/TokenSelect";

export default function ListOnSaleModal({ showModal, close, msgType, value, onChange, ...props }) {

  return (
    <div
      className={classes.putOnSaleModal}
      style={
        showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.putOnSaleTitle}>
        <h2>Create Listing</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>Input your listing price for {msgType}</p>
      </div>
      <div className={classes.sellPriceWrapper}>
        <div className={classes.putOnSaleInput}>
          <p>Sell price</p>
          <input
            type="number"
            min={0.0001}
            placeholder="e.g. “1.0000“"
            value={value}
            onChange={onChange}
          />
          
        </div>
        <TokenSelect {...props}/>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button onClick={() => props.confirm(props.value)}>
          <div style={{ padding: "0px 40px" }}>Confirm</div>
        </Button>
      </div>
    </div>
  );
}
