const INFO_KEYS = [
  'Asset ID',
  'Offer ID',
  'Auction ID',
  'Mint Number',
  'Backed Token',
  'Collection Name',
  'Schema Name',
  'Template ID',
  'Properties'
];

const INFO_KEYS_BUNDLE = [
  'Offer ID',
  'Auction ID',
  'Collection Name',
  'Bundle Size'
];

const DETAILS_TABS = [
  "Info",
  "Attributes",
  "Price History",
  "Sale Logs"
];

const DETAILS_TABS_BUNDLE = [
  "Info",
  "Sale Logs"
];

const saleDetailsConst = { INFO_KEYS, DETAILS_TABS, DETAILS_TABS_BUNDLE, INFO_KEYS_BUNDLE };

export default saleDetailsConst;
