import React from 'react'
import classes from './FilterAssetSearch.module.css'

export default function FilterAssetSearch(props) {
  return (
    <div className={classes.filterAssetSearchMainContainer}>
      <i className="fas fa-search"></i>
      <input type="text" placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
    </div>
  )
}
