// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialLink_socialMainLink__REj2k {\r\n  text-decoration: none;\r\n  margin: 10px;\r\n  border-radius: 50%;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.SocialLink_socialMainLink__REj2k > img {\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/UI/SocialButton/SocialLink.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".socialMainLink {\r\n  text-decoration: none;\r\n  margin: 10px;\r\n  border-radius: 50%;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.socialMainLink > img {\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialMainLink": "SocialLink_socialMainLink__REj2k"
};
export default ___CSS_LOADER_EXPORT___;
