import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";
import ApiServices from "../../services/ApiServices";
import FirebaseService from "../../services/FirebaseService";
import AppServices from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import AssetsDetailsPageAssetsImg from "../AssetsDetailsPage/AssetsDetailsPageAssetsImg/AssetsDetailsPageAssetsImg";
import PriceBox from "../UI/PriceBox/PriceBox";
import BundleNFTs from "./BundleNFTs";
import MODAL_CONSTANTS from "../../constants/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SALE_DETAILS,
  REMOVE_SALE_DETAILS,
} from "../../stores/reducers/saleReducer";

const apiService = new ApiServices();
const firebaseService = new FirebaseService();
const appServices = new AppServices();
const blockchainService = new BlockchainService();

export default function NFTsPurchaseModal({
  showModal,
  close,
  terms,
  currentPurchasePath,
  closeModalHandler,
  ual,
  showModalHandler,
  setShowSuccessModal,
  setErrorMsg,
  setShowErrorModal,
  setSuccessMsg,
}) {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [isUSD, setUsdFlag] = useState(false);
  const [intendedDelphiMedian, setintendedDelphiMedian] = useState(false);
  const [saleId, setSaleId] = useState(null);
  const [assetsData, setAssetsData] = useState(null);
  const [isOwner, setIsOwner] = useState(null);
  const [ppbCardData, setPpbCardData] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [price, setPrice] = useState(null);
  const [usdPrice, setUsdPrice] = useState(null);
  const [fiat, setfiat] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useState(
    process.env.REACT_APP_TOKEN_SYMBOL
  );
  const [tokenPrecision, setTokenPrecision] = useState(
    process.env.REACT_APP_TOKEN_PRECISION
  );
  const [tokenContract, setTokenContract] = useState("eosio.token");
  const [isBundle, setIsBundle] = useState(false);
  const [bundleData, setBundleData] = useState(null);
  const [saleData, setSaleData] = useState(null);
  const history = useHistory();

  const getData = async () => {
    const saleId = await getSaleId(currentPurchasePath);
    const saleDetails = await getSaleData(saleId);
    setSaleId(saleId);
    if (saleDetails && saleDetails.assets && saleDetails.assets.length > 0) {
      await setAssetDetails(saleDetails);
      await addSaleData(saleDetails);
      formModalData(saleDetails);
      setTokenSymbol(saleDetails.price.token_symbol);
      setTokenPrecision(saleDetails.price.token_precision);
      setTokenContract(saleDetails.price.token_contract);
    }
  };

  const getSaleId = async (currentPurchasePath) => {
    let path = currentPurchasePath
      ? currentPurchasePath
      : window.location.pathname;
    let splitArray = path.split("/");
    let id = splitArray[2];
    return id;
  };

  const formModalData = (saleDetails) => {
    const isBundle = saleDetails.assets.length > 1;
    const saleId = {
      key: "Sale ID",
      value: `#${saleDetails.sale_id}` ?? "",
    };
    const collection = {
      key: "Collection",
      value: (
        <span
          className={classes.link}
          onClick={() =>
            moveToCollectionDetailsPage(saleDetails.collection.collection_name)
          }
        >
          {saleDetails.collection.name}
        </span>
      ),
    };
    const assetName = {
      key: "Asset Name",
      value: saleDetails.assets[0].name ?? "",
    };
    const assetId = {
      key: "Asset ID",
      value: `#${saleDetails.assets[0].asset_id}` ?? "",
    };
    const backedTokens = {
      key: "Backed Tokens",
      value: saleDetails.assets[0].backed_tokens.length
        ? getBackedTokens(saleDetails.assets[0].backed_tokens)
        : "None",
    };
    const bundleSize = {
      key: "Bundle Size",
      value: `${saleDetails.assets.length} NFTs`,
    };
    console.log("saleDetails", saleDetails);
    setSaleData({
      seller: saleDetails.seller,
      assetId: saleDetails.assets[0].asset_id,
      price: saleDetails.listing_price,
    });
    if (isBundle) {
      setModalData([saleId, collection, bundleSize]);
    } else {
      setModalData([saleId, collection, assetName, assetId, backedTokens]);
    }
  };

  const getBackedTokens = (array) =>
    array.reduce((str, item) => {
      return `${str}, ${item}`;
    }, "");

  const getPrice = (saleData) =>
    setPrice(
      +saleData.price.amount / Math.pow(10, saleData.price.token_precision)
    );
  const geUsdPrice = (price) =>
    setUsdPrice(price * (appServices.getUsdPrice() ?? 1));

  const getSaleData = async (saleId) => await apiService.getSaleData(saleId);

  const getMediaContent = (asset) => {
    let content = asset.data.img;
    let type = "img";
    if (content === undefined) {
      content = asset.data.video;
      type = "video";
    }
    if (content === undefined) {
      content = asset.data.audio;
      type = "audio";
    }

    return { content, type };
  };

  const getBundleData = (assets) => {
    const data = assets.reduce((assetsData, asset) => {
      return (assetsData = [
        ...assetsData,
        {
          detailsPath: "/assets/" + asset.asset_id ?? "",
          title: asset.data.name ?? "",
          ...getMediaContent(asset),
        },
      ]);
    }, []);

    setBundleData(data);
  };

  const setAssetDetails = async (saleDetails) => {
    if (saleDetails.assets.length > 1) {
      setIsBundle(true);
      getBundleData(saleDetails.assets);
    } else {
      setIsBundle(false);
      const asset = saleDetails.assets[0];
      const assetId = asset.asset_id ?? "";
      const owner = asset.owner ?? "";
      const title = asset.data.name ?? "";
      const description = asset.data.description ?? asset.data.details ?? "";

      setAssetsData({
        title,
        description,
        assetId,
        owner,
        ...getMediaContent(asset),
      });
    }
  };

  const getUserProfile = async (accountName) => {
    let userProfile = await firebaseService.getUser(accountName);

    if (userProfile) {
      userProfile = userProfile.data();
      if (userProfile) {
        return userProfile;
      }
    }
    return null;
  };

  const checkingOwnership = async (assetOwner) => {
    let loginUser = await appServices.getAccountName(ual);
    setIsOwner(assetOwner === loginUser ? true : false);
  };

  const purchaseNFT = async () => {
    closeModalHandler();
    // debugger;
    const saleIdNum = +saleId;
    let assetId = +assetsData.assetId;
    let userAccount = await appServices.getAccountName(ual);
    const walletBalance = await blockchainService.getUserBalance(
      process.env.REACT_APP_WALLET_NAME
    );
    let listingPrice =
      (+ppbCardData.price.toString().split(" ")[0]).toFixed(tokenPrecision) +
      " " +
      tokenSymbol;

    let listingPriceUsd = "1.00 USD";
    if (isUSD) {
      listingPriceUsd = usdPrice.toFixed(2) + " USD";
    }
    const symbol = `${tokenPrecision},${tokenSymbol}`;

    if (assetId !== "" && userAccount !== "") {
      let assertSalAction = blockchainService.assetSaleAction(
        userAccount,
        saleIdNum,
        assetId,
        isUSD === true ? listingPriceUsd : listingPrice,
        symbol
      );
      let transferTokenAction = blockchainService.transferToken(
        userAccount,
        "atomicmarket",
        listingPrice,
        "deposit",
        tokenContract
      );
      let purchaseSaleAction = blockchainService.purchaseSaleAction(
        userAccount,
        saleIdNum,
        isUSD === true ? intendedDelphiMedian : 0
      );

      assertSalAction.actions.push(transferTokenAction.actions[0]);
      assertSalAction.actions.push(purchaseSaleAction.actions[0]);
      const valueInUSD = Number(ppbCardData.priceUSD);
      const fee = (valueInUSD * 2.5) / 100;
      const usdValueWithFee = valueInUSD + fee;
      if (fiat) {
        try {
          if (Number(walletBalance) < ppbCardData.price) {
            setErrorMsg("we are out of liquidity");
            showModalHandler(setShowErrorModal);
            // showModalHandler("");
            return;
          }
          const cartResponse = await apiService.createEcsterCart(
            userAccount,
            "paymentType",
            Number(usdValueWithFee.toFixed(2)),
            fee
          );
          if (cartResponse?.data && cartResponse?.success) {
            const cartId = cartResponse.data.checkoutCart.key;
            dispatch(
              SET_SALE_DETAILS({
                action: assertSalAction,
                amount: listingPrice,
                fee: { amount: Number(fee.toFixed(2)), type: process.env.REACT_APP_TOKEN_SYMBOL ,paymentType:'fiat'},
                saleData,
              })
            );
            history.push(`/payment/${cartId}`);
          } else {
            setfiat(false);
            // showModalHandler("Something went wrong");
            setErrorMsg("Something went wrong");
            showModalHandler(setShowErrorModal);
            setTimeout(() => {
              // appServices.logout(ual);
              window.location.href = "/";
            }, 2000);
          }
          return;
        } catch (e) {
          console.log(e, ">erroror");
        }
      }
      const result = await blockchainService.pushTransaction(
        assertSalAction,
        ual,
        saleData
      );
      if (result.success) {
        // alert("NFT purchased");
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_PURCHASE);
        showModalHandler(setShowSuccessModal);
        await getData();
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const addSaleData = async (saleDetails) => {
    // debugger;
    let price =
      saleDetails.listing_price /
      Math.pow(10, saleDetails.price.token_precision);

    let usdPrice = await appServices.getUsdPrice();
    let priceUSD = price * usdPrice;

    if (saleDetails.listing_symbol === "USD") {
      priceUSD = +(saleDetails.listing_price / Math.pow(10, 2));
      price = priceUSD / usdPrice;
      setUsdFlag(true);
      setintendedDelphiMedian(saleDetails.price.median);
    }

    setPrice(price);
    setUsdPrice(priceUSD);

    checkingOwnership(saleDetails.seller);

    setPpbCardData({
      img:
        "https://wax.api.atomichub.io/v1/preview/avatar/" + saleDetails.seller,
      name: saleDetails.seller,
      price: price,
      priceUSD,
      state: saleDetails.state,
    });
  };

  const moveToUserProfile = (userName) => {
    close();
    history.push("/profile/" + userName);
  };

  const moveToCollectionDetailsPage = (collectionName) => {
    close();
    history.push(`/collections/${collectionName}`);
  };

  useEffect(() => {
    (async () => await getData())();
  }, []);

  useEffect(() => {
    if (currentPurchasePath) {
      (async () => await getData(currentPurchasePath))();
    }
  }, [currentPurchasePath]);

  useEffect(() => {
    if (price) {
      geUsdPrice(price);
    }
  }, [price]);

  return (
    <>
      {modalData && modalData.length ? (
        <div
          className={`${classes.removeFromSaleModal} ${classes.purchaseNFTModal}`}
          style={
            showModal
              ? { transform: "scale(1)", opacity: 1 }
              : { transform: "scale(0)", opacity: 0 }
          }
        >
          <div
            className={`${classes.modelInnerContainer} ${classes.closeWrapper}`}
          >
            <div
              className={classes.modelImageCancel}
              onClick={close}
              style={{ marginLeft: "auto" }}
            >
              <img src={CloseIcon} alt="Modal-close" />
            </div>
          </div>
          <div className={classes.titleWrapper}>
            <h2>{`Purchase ${isBundle ? "NFTs" : "the NFT"}`}</h2>
          </div>
          <div className={classes.saleDataWrapper}>
            {isBundle ? (
              <BundleNFTs bundleData={bundleData} />
            ) : (
              <div className={classes.imgWrapper}>
                <AssetsDetailsPageAssetsImg assetsData={assetsData} />
              </div>
            )}
            <div className={classes.saleDataInfoWrapper}>
              <p className={classes.summaryTitle}>Summary</p>
              <div className={classes.summaryContent}>
                {modalData &&
                  modalData.length &&
                  modalData.map((item) => (
                    <div key={item.key} className={classes.line}>
                      <div className={classes.key}>{item.key}</div>
                      <div className={classes.value}>{item.value}</div>
                    </div>
                  ))}

                {ppbCardData && (
                  <div className={`${classes.line} ${classes.sellerWrapper}`}>
                    <div className={classes.key}>Seller</div>
                    <div
                      className={`${classes.value} ${classes.sellerValue}`}
                      onClick={() => moveToUserProfile(ppbCardData.name)}
                    >
                      <div className={classes.avatarWrapper}>
                        {ppbCardData.img ? (
                          <img src={ppbCardData.img} alt="" />
                        ) : (
                          <i className="fas fa-user-circle"></i>
                        )}
                      </div>

                      <div>{ppbCardData.name}</div>
                    </div>
                  </div>
                )}
                <div className={classes.line}>
                  <div className={classes.key}>Price</div>
                  <div className={`${classes.value} ${classes.priceValue}`}>
                    <PriceBox price={price} symbol={tokenSymbol} />
                    <PriceBox price={usdPrice?.toFixed(2)} symbol="$" />
                  </div>
                </div>
              </div>
              <div
                className={classes.modelFlexTranscation}
                style={{ justifyContent: "center" }}
              >
                <div style={{ textAlign: "center" }}>
                  <p>Status</p>
                  <p style={{ fontSize: 24 }}>Waiting</p>
                </div>
              </div>

              <div className={classes.termsAndConditionContainer}>
                {terms && (
                  <>
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={() => setCheck(!check)}
                    />
                    <p>
                      I accept the{" "}
                      <a
                        href="https://gateway.pinata.cloud/ipfs/QmX57MpLFfUQQQF52zQcV7F5uj8voKAMP5nrcrnYjAKvGC"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms & conditions
                      </a>
                    </p>
                  </>
                )}

                {Number(ppbCardData.priceUSD) > 10 && (
                  <>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="checkbox"
                      checked={fiat}
                      onChange={() => setfiat(!fiat)}
                    />
                    <p>Buy With Fiat</p>
                  </>
                )}
              </div>
              {fiat && (
                <div style={{ textAlign: "center", margin: "5px" }}>
                  Purchase Amount + 2.5% fee = ${" "}
                  {(
                    Number(ppbCardData.priceUSD) +
                    Number(ppbCardData.priceUSD) * 0.025
                  ).toFixed(2)}
                </div>
              )}
              <div
                style={
                  check
                    ? { textAlign: "center" }
                    : {
                        textAlign: "center",
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                }
              >
                <Button onClick={purchaseNFT}>
                  <div style={{ padding: "0px 40px" }}>Confirm</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
