import React, { useEffect, useState } from "react";
import classes from "./SingleCollectible.module.css";
import SingleCollectiblePageTitleAndSwithBtn from "../../components/SingleCollectiblePage/SingleCollectiblePageTitleAndSwithBtn/SingleCollectiblePageTitleAndSwithBtn";
import SingleCollectiblePageUploadFile from "../../components/SingleCollectiblePage/SingleCollectiblePageUploadFile/SingleCollectiblePageUploadFile";
import SingleCollectiblePageBtnAndAutoSave from "../../components/SingleCollectiblePage/SingleCollectiblePageBtnAndAutoSave/SingleCollectiblePageBtnAndAutoSave";
import SingleCollectiblePagePreview from "../../components/SingleCollectiblePage/SingleCollectiblePagePreview/SingleCollectiblePagePreview";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import NFTsSuccessModal from "../../components/Model/NFTsSuccessModal";
import ErrorModal from "../../components/Model/ErrorModal";
import PushNotification from "../../components/Model/PushNotification";
import {
  acceptedFileTypes,
  acceptedFileTypesArray,
  imageMaxSize,
} from "../../constants/SingleCollectible";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ASSET_DATA,
  CLOSE_MODAL,
  SET_ASSET_FILENAME,
  SET_ASSET_IMG,
  SET_ASSET_TYPE_DATA,
  SET_ERROR_MSG,
  SET_IS_COLLECTION_CREATED,
  SET_PENDING,
  SET_USER_ACCOUNT,
  SET_USER_COLL_SCHEMAS,
  SET_USER_COLLECTION,
  SET_USER_COLLECTIONS,
  SET_USER_SCHEMA,
  SHOW_ERR_MODAL,
  SHOW_LOADER,
  SHOW_MODAL,
  SHOW_NOTIFICATION,
  SINGLE_COL_SET_IMAGE_IPFS_URL,
  TOGGLE_ADVANCED_SETTINGS,
  SHOW_BUY_RAM_MODAL,
} from "../../stores/reducers/singleCollectibleReducer";
import { SingleCollectiblePageItemDetails } from "../../components/SingleCollectiblePage/SingleCollectiblePageItemDetails/SingleCollectiblePageItemDetails";
import {
  apiService,
  appService,
  blockchainService,
} from "../../constants/Servises";
import Button from "../../components/Button/Button";
import BuyRamModal from "../../components/Model/BuyRamModal";

export const SingleCollectible = (props) => {
  const state = useSelector((state) => state.singleCollectible);
  const dispatch = useDispatch();
  const defaultSchemaName = process.env.REACT_APP_DEFAULT_SCHEMA;
  const [buyRam, SetBuyRam] = useState(false);
  const [boxErrorHighlight, setBoxErrorHighlight] = useState({});
  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const showModalHandler = () => {
    dispatch(SHOW_MODAL({ showModal: true, showBackdrop: true }));
    window.scrollTo(0, 0);
  };
  const showLoaderHandler = () => {
    dispatch(SHOW_LOADER({ showLoader: true, showBackdrop: true }));
    window.scrollTo(0, 0);
  };
  const showErrorModalHandler = () => {
    dispatch(SHOW_ERR_MODAL({ showErrorModal: true, showBackdrop: true }));
    window.scrollTo(0, 0);
  };
  const closeModalHandler = () => {
    dispatch(
      CLOSE_MODAL({
        showModal: false,
        showErrorModal: false,
        showLoader: false,
        modalAnimation: true,
        showBackdrop: false,
        showBuyRamModal: false,
      })
    );
  };

  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        showNotificationHandler(
          "This file is not allowed." + currentFileSize + " bytes too large"
        );
        return false;
      } else if (!acceptedFileTypesArray.includes(currentFileType)) {
        showNotificationHandler(
          "This file is not supported or file size is too large"
        );
        return false;
      }
      return true;
    }
  };

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];

        dispatch(SET_ASSET_IMG({ img: currentFile }));

        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;

            let userAssetsData = { ...state.userAssetsData };
            userAssetsData.prevVideo = "";
            userAssetsData.prevImg = "";
            userAssetsData.prevAudio = "";

            if (currentFile.type.includes("video") === true) {
              dispatch(
                SET_ASSET_TYPE_DATA({
                  prevVideo: myResult,
                  prevImg: "",
                  prevAudio: "",
                })
              );
            } else if (currentFile.type.includes("audio") === true) {
              dispatch(
                SET_ASSET_TYPE_DATA({
                  prevVideo: "",
                  prevImg: "",
                  prevAudio: myResult,
                })
              );
            } else {
              dispatch(
                SET_ASSET_TYPE_DATA({
                  prevVideo: "",
                  prevImg: myResult,
                  prevAudio: "",
                })
              );
            }
            dispatch(SET_ASSET_FILENAME({ fileName: currentFile.name }));
          },
          false
        );

        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  const setUserCollectionSchemas = (schemas) => {
    dispatch(SET_USER_COLL_SCHEMAS({ userCollectionSchemas: schemas }));
  };

  const setUsrSchema = (schema) => {
    dispatch(SET_USER_SCHEMA({ userSchema: schema }));
  };

  const setUserCollectionHandler = (collectionName) => {
    dispatch(SET_USER_COLLECTION({ userCollection: collectionName }));
  };

  const toggleAdvancedSettingsHandler = () => {
    window.scrollTo(0, 0);
    dispatch(TOGGLE_ADVANCED_SETTINGS());
  };

  const createItemBtnHandler = async () => {
    dispatch(SET_PENDING({ pending: true }));
    const userAssetsData = state.userAssetsData;
    let userUpdatedAssetsData = [...state.userUpdatedAssetsData];
    let userCollection = state.userCollection;
    let schemaName = state.userSchema;
    dispatch(SET_ERROR_MSG({ errorMsg: "" }));
    // const ramResource = await blockchainService.checkRamResources(props.ual);

    if (!state.showAdvancedSettings) {
      if (
        userAssetsData.title !== "" &&
        /^$|^([a-z1-5]{12})+$/.test(userAssetsData.title) &&
        userAssetsData.displayName !== "" &&
        userAssetsData.description !== "" &&
        (userAssetsData.img !== "" ||
          userAssetsData.prevVideo !== "" ||
          userAssetsData.prevAudio !== "")
      ) {
        // if (ramResource.success) {
        //   dispatch(SET_ERROR_MSG({ errorMsg: ramResource.message }));
        //   dispatch(SET_PENDING({ pending: false }));
        //   SetBuyRam(true);
        //   return;
        // }
        showLoaderHandler();
        userUpdatedAssetsData.push(userAssetsData);
        let res = await apiService.uploadFileOnIpfs(userAssetsData.img);
        let ipfsId = "";
        if (res) {
          console.log('info',res);

          ipfsId = res.data.IpfsHash;
          dispatch(SINGLE_COL_SET_IMAGE_IPFS_URL({ imageIpfsUrl: ipfsId }));
          mintNFT(ipfsId);
        } else {
          dispatch(
            SET_ERROR_MSG({ errorMsg: "Unable to store NFT content of IPFS" })
          );
          showErrorModalHandler();
        }
      } else {
        if (
          userAssetsData.title === "" ||
          !/^$|^([a-z1-5]{12})+$/.test(userAssetsData.title)
        ) {
          dispatch(
            SET_ERROR_MSG({
              errorMsg:
                "Name is required and must contain 12 character from 1 to 5 and from a to z!",
            })
          );
          setBoxErrorHighlight({ title: true });
        } else if (userAssetsData.displayName === "") {
          setBoxErrorHighlight({ displayName: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Display name is required!" }));
        } else if (userAssetsData.description === "") {
          setBoxErrorHighlight({ description: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Description is required!" }));
        } else if (
          userAssetsData.img === "" ||
          userAssetsData.prevVideo === "" ||
          userAssetsData.prevAudio === ""
        ) {
          setBoxErrorHighlight({ content: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Content is required!" }));
        }
      }
    } else {
      if (
        userCollection !== "" &&
        userAssetsData.title !== "" &&
        /^$|^([a-z1-5]{12})+$/.test(userAssetsData.title) &&
        schemaName !== "" &&
        userAssetsData.displayName !== "" &&
        userAssetsData.description !== "" &&
        (userAssetsData.img !== "" ||
          userAssetsData.prevVideo !== "" ||
          userAssetsData.prevAudio !== "")
      ) {
        // if (ramResource.success) {
        //   dispatch(SET_ERROR_MSG({ errorMsg: ramResource.message }));
        //   dispatch(SET_PENDING({ pending: false }));
        //   SetBuyRam(true);
        //   return;
        // }
        showLoaderHandler();
        userUpdatedAssetsData.push(userAssetsData);
        let res = await apiService.uploadFileOnIpfs(userAssetsData.img);
        let ipfsId = "";
        if (res) {
          console.log('info',res);

          ipfsId = res.data.IpfsHash;
          dispatch(SINGLE_COL_SET_IMAGE_IPFS_URL({ imageIpfsUrl: ipfsId }));
          mintNFT(ipfsId);
        } else {
          dispatch(
            SET_ERROR_MSG({ errorMsg: "Unable to store NFT content of IPFS" })
          );
          showErrorModalHandler();
        }
      } else {
        if (
          userAssetsData.title === "" ||
          !/^$|^([a-z1-5]{12})+$/.test(userAssetsData.title)
        ) {
          setBoxErrorHighlight({ title: true });
          dispatch(
            SET_ERROR_MSG({
              errorMsg:
                "Name is required and must contain 12 character from 1 to 5 and from a to z!",
            })
          );
        } else if (userAssetsData.displayName === "") {
          setBoxErrorHighlight({ displayName: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Display name is required!" }));
        } else if (userAssetsData.description === "") {
          setBoxErrorHighlight({ description: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Description is required!" }));
        } else if (userCollection === "") {
          setBoxErrorHighlight({ userCollection: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Collection is required!" }));
        } else if (schemaName === "") {
          setBoxErrorHighlight({ schemaName: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "SCHEMA is required!" }));
        } else if (
          userAssetsData.img === "" ||
          userAssetsData.prevVideo === "" ||
          userAssetsData.prevAudio === ""
        ) {
          setBoxErrorHighlight({ Content: true });
          dispatch(SET_ERROR_MSG({ errorMsg: "Content is required!" }));
        }
      }
    }
    dispatch(SET_PENDING({ pending: false }));
  };

  const clearAllHandler = () => {
    dispatch(CLEAR_ASSET_DATA());
  };

  const mintNFT = async (ipfsHash) => {
    setBoxErrorHighlight("");
    const userAssetsData = state.userAssetsData;
    let collectionDescription = "";
    let userAccount = state.userAccount;
    let displayName = state.userAccount;
    let collectionImage = "";
    let collectionUrl = "";

    let schemaName =
      state.userSchema !== "" ? state.userSchema : defaultSchemaName;

    let assetName = userAssetsData.displayName;

    let assetContentKey = "";
    let assetContentValue = "";
    let isSchemaCreated = false;
    let userCollection = state.userCollection;

    let userCollectionName =
      userCollection === "" ? userAccount : userCollection;
    let userSchemas;

    if (userCollectionName === userAccount) {
      userSchemas = await getUserSchema(userAccount);
    } else {
      userSchemas = await getUserSchema(userCollectionName);
    }

    for (let i = 0; i < userSchemas.length; i++) {
      let element = userSchemas[i];
      if (element.schema_name === schemaName) {
        isSchemaCreated = true;
      }
    }

    if (state.userAssetsData.prevImg !== "") {
      assetContentKey = "img";
      assetContentValue = ipfsHash;
    }

    if (state.userAssetsData.prevVideo !== "") {
      assetContentKey = "video";
      assetContentValue = ipfsHash;
    }

    if (state.userAssetsData.prevAudio !== "") {
      assetContentKey = "audio";
      assetContentValue = ipfsHash;
    }

    let assetDescription = userAssetsData.description;
    let assetRarity = userAssetsData.rarity.toLowerCase();
    let assetCategory = userAssetsData.category.toLowerCase();

    if (
      userAccount !== "" &&
      displayName !== "" &&
      schemaName !== "" &&
      assetName !== "" &&
      assetContentValue !== "" &&
      assetContentKey !== ""
    ) {
      let createCollectionAction;
      if (!state.showAdvancedSettings) {
        createCollectionAction = blockchainService.createCollectionAction(
          userAccount,
          displayName,
          collectionImage,
          collectionDescription,
          collectionUrl
        );
      }

      let createSchemaAction;

      if (!state.showAdvancedSettings) {
        createSchemaAction = blockchainService.createSchemaAction(
          userAccount,
          schemaName
        );
      }

      let mintNFTAction;
      if (!state.showAdvancedSettings) {
        mintNFTAction = blockchainService.createMintAssetAction(
          userAccount,
          schemaName,
          -1,
          assetName,
          assetDescription,
          assetRarity.toLowerCase(),
          assetCategory.toLowerCase(),
          assetContentKey,
          assetContentValue
        );
      } else {
        mintNFTAction = blockchainService.createMintAssetAction(
          userAccount,
          schemaName,
          -1,
          assetName,
          assetDescription,
          assetRarity.toLowerCase(),
          assetCategory.toLowerCase(),
          assetContentKey,
          assetContentValue,
          userCollection
        );
      }

      let finalActions = mintNFTAction;

      if (state.isCollectionCreated === false) {
        finalActions.actions.unshift(createCollectionAction.actions[0]);
      }

      if (isSchemaCreated === false) {
        finalActions.actions.unshift(createSchemaAction.actions[0]);
      }

      const result = await blockchainService.pushTransaction(
        finalActions,
        props.ual
      );
      if (result.success) {
        showModalHandler();
        clearAllHandler();
      } else {
        if (result.message.includes("insufficient ram")) {
          dispatch(SET_ERROR_MSG({ errorMsg: result.message }));
          dispatch(SET_PENDING({ pending: false }));
          showErrorModalHandler();
          SetBuyRam(true);
          return;
        }
        dispatch(SET_ERROR_MSG({ errorMsg: result.message }));
        showErrorModalHandler();
      }
    }
  };

  const getData = async () => {
    let isCollectionCreated = false;
    let isSchemaCreated = false;

    let userAccount = await getUserAccount();
    if (userAccount) {
      let userCollections = await apiService.getAuthorCollection(userAccount);
      if (userCollections) {
        dispatch(SET_IS_COLLECTION_CREATED({ isCollectionCreated: true }));
      }
      dispatch(SET_USER_COLLECTIONS({ userCollections }));
    }

    if (state.showAdvancedSettings) {
      dispatch(SET_USER_ACCOUNT({ userAccount }));
      dispatch(SET_USER_COLLECTION({ serCollection: userAccount }));
    } else {
      dispatch(SET_USER_ACCOUNT({ userAccount }));
    }
  };

  const getUserAccount = async () => {
    let userAccount = await appService.getAccountName(props.ual);
    return userAccount;
  };

  const getUserSchema = async (collectionName) => {
    let userSchema = await apiService.getCollectionSchema(collectionName);
    return userSchema;
  };
  const showNotificationHandler = (msg) => {
    dispatch(
      SHOW_NOTIFICATION({ showNotification: true, notificationTitle: msg })
    );

    setTimeout(() => {
      dispatch(
        SHOW_NOTIFICATION({ showNotification: false, notificationTitle: msg })
      );
    }, 3000);
  };

  const openBuyRamModal = () => {
    dispatch(SHOW_BUY_RAM_MODAL({ showBuyRamModal: true, showBackdrop: true }));
    window.scrollTo(0, 0);
  };

  const removeBuyRamError = () => {
    dispatch(SET_ERROR_MSG({ errorMsg: "" }));
    SetBuyRam(false);
  };
  return (
    <>
      <div className={classes.SingleCollectibleMainContainer}>
        <SingleCollectiblePageTitleAndSwithBtn isSingle={true} />
        <div className={classes.topSingleCollectibleTextContainer}>
          <div className={classes.FirstFlexContainer}>
            <div className={classes.uploadFileContainer}>
              <SingleCollectiblePageUploadFile
                acceptedFileTypes={acceptedFileTypes}
                imageMaxSize={imageMaxSize}
                handleOnDrop={handleOnDrop}
                fileName={state.userAssetsData.fileName}
                boxErrorHighlight={boxErrorHighlight}
              />
            </div>
            <SingleCollectiblePageItemDetails
              userLogin={state.userAccount}
              userAssetsData={state.userAssetsData}
              isSingle={false}
              showAdvancedSettings={state.showAdvancedSettings}
              toggleAdvancedSettingsHandler={toggleAdvancedSettingsHandler}
              setUserCollectionHandler={setUserCollectionHandler}
              userCollection={state.userCollection}
              userCollections={state.userCollections}
              userSchema={state.userSchema}
              userCollectionSchemas={state.userCollectionSchemas}
              setUserCollectionSchemas={setUserCollectionSchemas}
              setUsrSchema={setUsrSchema}
              boxErrorHighlight={boxErrorHighlight}
            />
            {buyRam && <Button onClick={openBuyRamModal}>Buy Ram</Button>}
            <SingleCollectiblePageBtnAndAutoSave
              errorMsg={state.errorMsg}
              pending={state.pending}
              createItemBtnHandler={createItemBtnHandler}
            />
          </div>
          <div className={classes.SecondFlexContainer}>
            <SingleCollectiblePagePreview
              prevImg={state.userAssetsData.prevImg}
              prevVideo={state.userAssetsData.prevVideo}
              prevAudio={state.userAssetsData.prevAudio}
              title={state.userAssetsData.title}
              displayName={state.userAssetsData.displayName}
              price={state.userAssetsData.price}
              inStock={state.userAssetsData.inStock}
              description={state.userAssetsData.description}
              category={
                state.userAssetsData.category
                  ? state.userAssetsData.category
                  : "Art"
              }
              rarity={
                state.userAssetsData.rarity
                  ? state.userAssetsData.rarity
                  : "Rare"
              }
              clearAllHandler={clearAllHandler}
            />
          </div>
        </div>
      </div>
      <div className={classes.modalsContainer}>
        <Backdrop
          show={state.showBackdrop}
          onClick={closeModalHandler}
          animation={state.modalAnimation}
        />
        <NFTsSuccessModal
          msgType="The NFT is created successfully"
          showModal={state.showModal}
          close={closeModalHandler}
        />
        <ErrorModal
          errorMsg={state.errorMsg}
          showModal={state.showErrorModal}
          close={closeModalHandler}
        />
        {state.showBuyRamModal && (
          <BuyRamModal
            msgType="Buy Ram"
            showModal={state.showBuyRamModal}
            close={closeModalHandler}
            ual={props.ual}
            showNotificationHandler={showNotificationHandler}
            removeError={removeBuyRamError}
          />
        )}
        <PushNotification
          title={state.notificationTitle}
          showNotification={state.showNotification}
        />
      </div>
    </>
  );
};
