import React, { useState, useEffect } from "react";
import classes from "./StatsTab.module.css";
import ApiServices from "../../../../services/ApiServices";
import StatsSlider from "./StatsSlider";
import HotNftCard from "./HotNftCard";
import TopSaleCard from "./TopSaleCard";
import ChartSection from "../../../SaleDetails/PriceHistorySectionTab/ChartSection";
import AppServices from "../../../../services/AppService";
import TopAccounts from "./TopAccounts";

const appServices = new AppServices();
const apiServices = new ApiServices();
const LIMIT = 12;

const StatsTab = ({ collectionData, collectionStats }) => {
  const [hotNfts, setHotNfts] = useState(null);
  const [loadingHotNfts, setLoadingHotNfts] = useState(true);
  const [topSales, setTopSales] = useState(null);
  const [loadingTopSales, setLoadingTopSales] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [graphSymbol, setGraphSymbol] = useState(null);
  const [totalVolume, setTotalVolume] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalUsdPrice, setTotalUsdPrice] = useState(null);
  const [topSellers, setTopSellers] = useState([]);
  const [topBuyers, setTopBuyers] = useState([]);
  const [loadingGraphData, setLoadingGraphData] = useState(true);

  const getHotNfts = async (collectionName) => {
    setLoadingHotNfts(true);
    const res = await apiServices.getHotNfts(LIMIT, 1, collectionName);
    setHotNfts(res);
    setLoadingHotNfts(false);
  };

  const getTopSales = async (collectionName) => {
    const state = 3;
    const maxAssets = 1;
    setLoadingTopSales(true);
    const res = await apiServices.getSales(
      LIMIT,
      null,
      null,
      collectionName,
      null,
      null,
      "desc",
      null,
      null,
      null,
      null,
      null,
      state,
      maxAssets,
      "price"
    );
    setTopSales(res);
    setLoadingTopSales(false);
  };

  const displayHotNftCard = (data, index) => (
    <HotNftCard data={data} index={index} />
  );

  const displayTopSaleCard = (data, index) => (
    <TopSaleCard data={data} index={index} />
  );

  const getSalesHistory = async (collectionName) => {
    setLoadingGraphData(true);
    const res = await apiServices.getCollectionGraph(collectionName);
    if (res && res.results) {
      setGraphData(res.results);
    }
    if (res && res.symbol) {
      setGraphSymbol(res.symbol);
    }
    setLoadingGraphData(false);
  };

  const getTotalStats = (data) => {
    const res = data.reduce((total, dayData) => {
      return total + parseInt(dayData.volume);
    }, 0);
    setTotalVolume(res);
  };

  const getTopAccounts = async (collectionName, isSeller, setAccounts) => {
    const accounts = await apiServices.getTopUsers(isSeller, collectionName);
    setAccounts(accounts);
  };

  useEffect(() => {
    if (collectionData) {
      (async () => await getHotNfts(collectionData.collection_name))();
      (async () => await getTopSales(collectionData.collection_name))();
      (async () => await getSalesHistory(collectionData.collection_name))();
      (async () =>
        await getTopAccounts(
          collectionData.collection_name,
          true,
          setTopSellers
        ))();
      (async () =>
        await getTopAccounts(
          collectionData.collection_name,
          false,
          setTopBuyers
        ))();
    }
  }, [collectionData]);

  useEffect(() => {
    if (graphData && graphSymbol) {
      getTotalStats(graphData);
    }
  }, [graphData, graphSymbol]);

  useEffect(() => {
    if (totalVolume && graphSymbol) {
      const totalPrice = (
        +totalVolume / Math.pow(10, graphSymbol.token_precision)
      ).toFixed(2);
      const totalPriceUsd = (
        totalPrice * (appServices.getUsdPrice() ?? 1)
      ).toFixed(2);
      setTotalPrice(totalPrice);
      setTotalUsdPrice(totalPriceUsd);
    }
  }, [totalVolume, graphSymbol]);

  return (
    <>
      <StatsSlider
        cardsData={hotNfts}
        loadingData={loadingHotNfts}
        title="Hot NFTs"
        displayCard={displayHotNftCard}
      />
      <StatsSlider
        cardsData={topSales}
        loadingData={loadingTopSales}
        title="Top Sales (All Time)"
        displayCard={displayTopSaleCard}
      />
      {graphData && graphSymbol && (
        <>
          <div className={classes.titleWrapper}>
            <h2 className={classes.sliderTitle}>Market Volume</h2>
            <div className={classes.totalVolumeWrapper}>
              <p className={classes.totalTitle}>Total Volume</p>
              {totalPrice ? (
                <>
                  <p
                    className={classes.tokenValue}
                  >{`${totalPrice} ${graphSymbol.token_symbol}`}</p>
                  <p className={classes.usdValue}>{`${totalUsdPrice} $`}</p>
                </>
              ) : (
                <div>None</div>
              )}
            </div>
          </div>
          <ChartSection
            sales={graphData}
            loading={loadingGraphData}
            symbol={graphSymbol}
            isCollectionDataPage
          />
          <div className={classes.topAccountsWrapper}>
            <TopAccounts
              accounts={topSellers}
              symbol={graphSymbol}
              title="Top Sellers"
              isTopSeller={true}
            />
            <TopAccounts
              accounts={topBuyers}
              symbol={graphSymbol}
              title="Top Buyers"
              isTopSeller={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default StatsTab;
