import React from "react";
import classes from "./Model.module.css";
import Button from "../../components/Button/Button";
import CloseIcon from "../../assets/img/closeIcon.svg";
import InfoIcon from "../../assets/img/modalInfoIcon.svg";

export default function Model(props) {
  return (
    <div
      className={classes.topModelContainer}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div className={classes.modelTitle}>
          <h1>Checkout</h1>
        </div>
        <div className={classes.modelImageCancel} onClick={props.close}>
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>

      <div className={classes.modeldesc}>
        <p>
          You are about to purchase {props.purchaseItem} from {props.from}
        </p>
      </div>

      <div className={classes.modelFlexContainer}>
        <p>{props.price}</p>
        <p>ZPC</p>
      </div>

      <div className={classes.modelBillContainer}>
        <div>
          Your balance <span>{props.balance} ZPC</span>
        </div>
        <div>
          Service fee <span>{props.fee} ZPC</span>
        </div>
        <div>
          You will pay <span>{props.willPay} ZPC</span>
        </div>
      </div>

      <div className={classes.modelCreator}>
        <div className={classes.modelInfoCircle}>
          <img src={InfoIcon} alt="Info" />
        </div>
        <div className={classes.creator}>
          <p>
            {props.isVerified
              ? "This creator is verified"
              : "This creator is not verified"}
          </p>
          <p>{!props.isVerified && "Purchase this item at your own risk"}</p>
        </div>
      </div>

      <div className={classes.modelbtn}>
        <Button onClick={() => props.continueBtn(props.nextModal)}>
          <div>I understand, continue</div>
        </Button>
        <Button secondary onClick={props.close}>
          <div>Cancel</div>
        </Button>
      </div>
    </div>
  );
}
