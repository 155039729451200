import React, { useEffect, useState } from "react";
import UserAssetsCard from "../../UserAssetsCard/UserAssetsCard";
import ApiServices from "../../../services/ApiServices";
import classes from "./HomePageNewListing.module.css";
import ModalsGroup from "../../Model/ModalsGroup/ModalsGroup";
import useModals from "../../Model/ModalsHook";

export default function HomePageNewListing({ ual }) {
  const apiService = new ApiServices();
  const [modalsStates, modalsFuncs] = useModals(ual);
  const [saleList, setSaleList] = useState([]);
  const [auctionList, setAuctionList] = useState([]);
  const [listingType, setListingType] = useState("sale");

  useEffect(() => {
    getSalesListing();
    getAuctionListing();
  }, []);

  const getSalesListing = async () => {
    const response = await apiService.getSales(4, 1);
    let tempSaleList = [];
    if (response) {
      tempSaleList = response.map((element, index) => {
        let tempRecord = {};

        tempRecord["tokenAmount"] = element.price.amount;
        tempRecord["tokenSymbol"] = element.price.token_symbol;
        tempRecord["tokenPrecision"] = element.price.token_precision;
        tempRecord["title"] = element.assets[0].data.name;
        tempRecord["detailsPath"] = "/assets/" + element.assets[0].asset_id;
        tempRecord["purchasePath"] = "/sale/" + element.sale_id;
        tempRecord["seller"] = element.seller;

        let contentType = "";
        let content = "";

        if (element.assets[0].data.img) {
          contentType = "img";
          content = element.assets[0].data.img;
        } else if (element.assets[0].data.video) {
          contentType = "video";
          content = element.assets[0].data.video;
        } else if (element.assets[0].data.audio) {
          contentType = "audio";
          content = element.assets[0].data.audio;
        }

        tempRecord["type"] = contentType;
        tempRecord["content"] = content;
        tempRecord["aasetDetils"] = element;

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempRecord["stock"] = inStock;

        return tempRecord;
      });
    }
    setSaleList(tempSaleList);
  };

  const updateListingType = (listingType) => {
    setListingType(listingType);
  };

  const getAuctionListing = async () => {
    const response = await apiService.getAuctionList(4, 1);
    let tempAuctionList = [];
    if (response) {
      tempAuctionList = response.map((element, index) => {
        let tempRecord = {};

        tempRecord["tokenAmount"] =
          element.bids.length > 0
            ? element.bids[element.bids.length - 1].amount
            : element.price.amount;
        tempRecord["tokenSymbol"] = element.price.token_symbol;
        tempRecord["tokenPrecision"] = element.price.token_precision;
        tempRecord["title"] = element.assets[0].data.name;
        tempRecord["detailsPath"] = "/assets/" + element.assets[0].asset_id;
        tempRecord["purchasePath"] = "/auction/" + element.auction_id;

        let contentType = "";
        let content = "";

        if (element.assets[0].data.img) {
          contentType = "img";
          content = element.assets[0].data.img;
        } else if (element.assets[0].data.video) {
          contentType = "video";
          content = element.assets[0].data.video;
        } else if (element.assets[0].data.audio) {
          contentType = "audio";
          content = element.assets[0].data.audio;
        }

        tempRecord["type"] = contentType;
        tempRecord["content"] = content;
        tempRecord["aasetDetils"] = element;

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempRecord["stock"] = inStock;

        return tempRecord;
      });
    }
    setAuctionList(tempAuctionList);
  };

  const displayData = () => {
    let data = null;

    if (listingType === "sale" && saleList.length > 0) {
      data = saleList.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              tokenAmount={item.tokenAmount}
              tokenSymbol={item.tokenSymbol}
              tokenPrecision={item.tokenPrecision}
              stock={item.stock}
              detailsPath={item.detailsPath}
              purchasePath={item.purchasePath}
              ual={ual}
              seller={item.seller}
              checkUserAuthentication={modalsFuncs.checkUserAuthentication}
              setCurrentPurchasePath={modalsStates.setCurrentPurchasePath}
              aasetDetils={item.aasetDetils}
            />
          </React.Fragment>
        );
      });
    } else if (listingType === "auction" && auctionList.length > 0) {
      data = auctionList.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              tokenAmount={item.tokenAmount}
              tokenSymbol={item.tokenSymbol}
              tokenPrecision={item.tokenPrecision}
              stock={item.stock}
              detailsPath={item.detailsPath}
              purchasePath={item.purchasePath}
              auction={true}
              checkUserAuthentication={modalsFuncs.checkUserAuthentication}
              setCurrentPurchasePath={modalsStates.setCurrentPurchasePath}
              aasetDetils={item.aasetDetils}
            />
          </React.Fragment>
        );
      });
    }
    return data;
  };

  return (
    <>
      <div className={classes.newListingMainContainer}>
        <h1>
          <p>New</p>Listing
        </h1>
        <div className={classes.newListingContentContainer}>
          {displayData()}
        </div>
      </div>

      <ModalsGroup
        ual={ual}
        modalsStates={modalsStates}
        modalsFuncs={modalsFuncs}
      />
    </>
  );
}
