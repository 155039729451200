import React, { useEffect, useState } from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";
import ApiServices from "../../services/ApiServices";
import AppServices from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import MODAL_CONSTANTS from "../../constants/Modal";

const apiService = new ApiServices();
const appServices = new AppServices();
const blockchainService = new BlockchainService();

export default function PlaceBidModal(props) {
  const [check, setCheck] = useState(false);
  const [auctionId, setAuctionId] = useState(null);
  const [isBundle, setIsBundle] = useState(false);
  const [bundleData, setBundleData] = useState(null);
  const [assetsData, setAssetsData] = useState(null);
  const [price, setPrice] = useState(null);
  const [listingData, setListingData] = useState({ price: "0.0001", duration: 5 * 60 });
  const [tokenSymbol, setTokenSymbol] = useState(process.env.REACT_APP_TOKEN_SYMBOL);
  const [tokenPrecision, setTokenPrecision] = useState(process.env.REACT_APP_TOKEN_PRECISION);
  const [tokenContract, setTokenContract] = useState('eosio.token');

  const getAuctionId = (currentPurchasePath) => {
    let path = currentPurchasePath ? currentPurchasePath : window.location.pathname;;
    let splitArray = path.split("/");
    let id = splitArray[2];
    setAuctionId(id);
  };

  const listingPriceHandler = (e) => {
    setListingData((prevState) => {
      return {...prevState, price: e.target.value};
    });
  };

  const getMediaContent = (asset) => {
    let content = asset.data.img;
    let type = "img";
    if (content === undefined) {
      content = asset.data.video;
      type = "video";
    }
    if (content === undefined) {
      content = asset.data.audio;
      type = "audio";
    }

    return { content, type }
  }

  const getBundleData = (assets) => {
    const data = assets.reduce((assetsData, asset) => {
      return assetsData = [...assetsData, {
          detailsPath: '/assets/' + asset.asset_id ?? '',
          title: asset.data.name ?? '',
          ...getMediaContent(asset)
        }]
    }, [])

    setBundleData(data);  
  }

  const setAssetDetails = async (details) => {
    if(details.assets.length > 1) {
      setIsBundle(true);
      getBundleData(details.assets);
    } else {
      setIsBundle(false);
      const asset = details.assets[0];
      const assetId = asset.asset_id ?? "";
      const owner = asset.owner ?? "";
      const title = asset.data.name ?? "";
      const description =
        asset.data.description ?? asset.data.details ?? "";

      setAssetsData({ title, description, assetId, owner, ...getMediaContent(asset) });
    }
  };

  const getPrice = (details) => {
    const pr = +(details.price.amount) / Math.pow(10, +details.price.token_precision);
    setPrice(pr)
  }

  const getData = async (auctionId) => {
    const details = await apiService.getAuctionData(auctionId);
    if (details && details.assets && details.assets.length > 0) {
      await setAssetDetails(details);
      await getPrice(details);
      setTokenSymbol(details.price.token_symbol);
      setTokenPrecision(details.price.token_precision);
      setTokenContract(details.price.token_contract);
    }
  };

  useEffect( () => {
    getAuctionId(props.currentPurchasePath)
  }, [props.currentPurchasePath])

  useEffect( () => {
    if(auctionId) {
      (async () => await getData(auctionId))()
    }
  }, [auctionId])

  const placeBidNFT = async () => {
    const tokenContract = 'eosio.token';
    const cardData = price
    const listing = listingData;
    if (listing.price < cardData) {
      props.setErrorMsg("Price must be greater than previous bid price ");
    } else {
      props.close();
      let itemId = +auctionId;
      let assetId = +assetsData.assetId;
      let userAccount = await appServices.getAccountName(props.ual);
      let listingPrice =
        (+listingData.price).toFixed(tokenPrecision) +
        " " +
        tokenSymbol;

      if (assetId !== "" && userAccount !== "") {
        let transferTokenAction = blockchainService.transferToken(
          userAccount,
          "atomicmarket",
          listingPrice,
          "deposit",
          tokenContract
        );
        let purchaseSaleAction = blockchainService.auctionBidAction(
          userAccount,
          itemId,
          listingPrice
        );

        transferTokenAction.actions.push(purchaseSaleAction.actions[0]);

        const result = await blockchainService.pushTransaction(
          transferTokenAction,
          props.ual
        );
        if (result.success) {
          props.setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_PLACE_BID);
          props.showModalHandler(props.setShowSuccessModal);
          await getData(itemId);
        } else {
          props.setErrorMsg(result.message);
          props.showModalHandler(props.setShowErrorModal);
        }
      }
    }
    setListingData((prevState) => {
      return {...prevState, price: '0.0001'};
    });
  };


  return (
    <div
      className={classes.putOnSaleModal}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.putOnSaleTitle}>
        <h2>Place Bid</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>Input your bid price for {props.msgType}</p>
      </div>
      <div className={classes.putOnSaleInput}>
        <p>Bid price</p>
        <input
          type="number"
          min={price}
          placeholder="e.g. “1.0000“"
          value={listingData.price}
          onChange={listingPriceHandler}
        />
        <span>{props.errorMsg}</span>
      </div>
      {props.terms && (
        <div className={classes.termsAndConditionContainer}>
          <input
            type="checkbox"
            checked={check}
            onChange={() => setCheck(!check)}
          />
          <p>
            I accept the{" "}
            <a
              href="https://gateway.pinata.cloud/ipfs/QmX57MpLFfUQQQF52zQcV7F5uj8voKAMP5nrcrnYjAKvGC"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms & conditions
            </a>
          </p>
        </div>
      )}
      <div
        style={
          check
            ? { textAlign: "center" }
            : { textAlign: "center", pointerEvents: "none", opacity: 0.5 }
        }
      >
        <Button onClick={placeBidNFT}>
          <div style={{ padding: "0px 40px" }}>Confirm</div>
        </Button>
      </div>
    </div>
  );
}
