import React, { useState, useEffect } from "react";
import classes from "./TabsSections.module.css";
import PriceHistoryStatsTab from "../../SaleDetails/PriceHistorySectionTab";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import AttributesTab from "../../SaleDetails/AttributesTab";
import PreviewDescription from "../../SingleCollectiblePage/SingleCollectiblePagePreview/PreviewDescription";
import CardsSection from "../../SearchPage/SearchPageContent/CardsSection";
import ApiServices from "../../../services/ApiServices";

const apiService = new ApiServices();

const TEMPLATE_TABS = ["Attributes", "Price History", "NFTs", "Accounts", "Sale Logs"];
const DESCRIPTION_LIMIT = 200;
const CARDS_LIMIT = 12;

const TabsSections = ({ templateData }) => {
  const [description, setDescription] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Attributes");
  const [assets, setAssets] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const getData = async (
    query,
    setData,
    page,
    limit,
    match,
    order,
    sort,
    collection = null,
    templateId = null,
  ) => {
    setLoading(true);
    setData(null);
    setPage(page);
    const cat = null;
    const data = await apiService.getSearchData(query, page, limit, match, cat, collection, templateId, order, sort);
    if (data && data.length > 0) {
      setData(data);
    }
    setLoading(false);

    return data === null;
  };

  const getNewPageData = async (page, dropSelected, setData, match) => {
    if (page > 0) {
      window.scrollTo(0, 0);

      setPage(page);
      setAssets([]);
      await getData(
        dropSelected.toLowerCase(),
        setData,
        page,
        CARDS_LIMIT,
        match,
        "desc",
        "created",
        templateData[0].collection.collection_name,
        templateData[0].template_id,
      );
    }
  }

  useEffect(() => {
    if(templateData) {
      (async () =>
        await getData(
          'assets',
          setAssets,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          templateData[0].collection.collection_name,
          templateData[0].template_id,
        ))();
      (async () =>
        await getData(
          'accounts',
          setAccounts,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          templateData[0].collection.collection_name,
          templateData[0].template_id,
        ))();
    }
  }, [templateData]);

  const handleTableData = () => {
    return (
      <>
        <TabContent isSelected={selectedTab === "Attributes"}>
          <AttributesTab
            asset={templateData[0]}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Price History"}>
          <PriceHistoryStatsTab asset={templateData[0]} loading={loading} />
        </TabContent>
        <TabContent isSelected={selectedTab === "NFTs"}>
          <CardsSection
            discoverAssets={assets}
            setDiscoverAssets={setAssets}
            loading={loading}
            dropSelected="Assets"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Accounts"}>
          <CardsSection
            accounts={accounts}
            setDiscoverAssets={setAccounts}
            loading={loading}
            dropSelected="Accounts"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
      </>
    );
  };

  useEffect(() => {
    if(templateData && templateData[0].immutable_data.description) {
      setDescription(templateData[0].immutable_data.description);
    } else if(templateData && templateData[0].immutable_data.bio) {
      setDescription(templateData[0].immutable_data.bio);
    }
  }, [templateData])

  return (
    <div className={classes.tabsSectionsWrapper}>
      <h1 className={classes.templateTitle}>Template:<span className={classes.id}>{` #${templateData[0].template_id}`}</span></h1>
      <PreviewDescription text={description} limit={DESCRIPTION_LIMIT} />
      <FilterTabBar
        tabs={TEMPLATE_TABS}
        selected={selectedTab}
        setSelected={setSelectedTab}
      >
        {handleTableData()}
      </FilterTabBar>
    </div>
  );
}

export default TabsSections;
