import React from "react";
import classes from "./TabContent.module.css";

export default function TabContent(props) {
  if (props.isSelected)
    return (
      <div className={classes.tabContentMainContainer}>{props.children}</div>
    );
  return null;
}
