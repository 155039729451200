// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n box-sizing: border-box;\n}\n.PriceBox_zpcPriceBox__20JWs {\n padding: 4px 8px 4px;\n background: #ded2b0;\n border: 2px solid #ded2b0;\n border-radius: 4px;\n font-weight: bold;\n font-size: 12px;\n color: #000;\n text-transform: uppercase;\n display: inline-block;\n width: auto;\n margin-right: 10px;\n margin-bottom: 5px;\n}\n.PriceBox_usdPriceBox__2gRUI {\n padding: 4px 8px 4px;\n background: transparent;\n border: 2px solid #ded2b0;\n border-radius: 4px;\n font-weight: bold;\n font-size: 12px;\n color: #4aaf05;\n text-transform: uppercase;\n display: inline-block;\n width: auto;\n margin-right: 10px;\n margin-bottom: 5px;\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/PriceBox/PriceBox.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;AACvB;AACA;CACC,oBAAoB;CACpB,mBAAmB;CACnB,yBAAyB;CACzB,kBAAkB;CAClB,iBAAiB;CACjB,eAAe;CACf,WAAW;CACX,yBAAyB;CACzB,qBAAqB;CACrB,WAAW;CACX,kBAAkB;CAClB,kBAAkB;AACnB;AACA;CACC,oBAAoB;CACpB,uBAAuB;CACvB,yBAAyB;CACzB,kBAAkB;CAClB,iBAAiB;CACjB,eAAe;CACf,cAAc;CACd,yBAAyB;CACzB,qBAAqB;CACrB,WAAW;CACX,kBAAkB;CAClB,kBAAkB;AACnB","sourcesContent":["* {\n box-sizing: border-box;\n}\n.zpcPriceBox {\n padding: 4px 8px 4px;\n background: #ded2b0;\n border: 2px solid #ded2b0;\n border-radius: 4px;\n font-weight: bold;\n font-size: 12px;\n color: #000;\n text-transform: uppercase;\n display: inline-block;\n width: auto;\n margin-right: 10px;\n margin-bottom: 5px;\n}\n.usdPriceBox {\n padding: 4px 8px 4px;\n background: transparent;\n border: 2px solid #ded2b0;\n border-radius: 4px;\n font-weight: bold;\n font-size: 12px;\n color: #4aaf05;\n text-transform: uppercase;\n display: inline-block;\n width: auto;\n margin-right: 10px;\n margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zpcPriceBox": "PriceBox_zpcPriceBox__20JWs",
	"usdPriceBox": "PriceBox_usdPriceBox__2gRUI"
};
export default ___CSS_LOADER_EXPORT___;
