import React, { useState } from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";

export default function NFTsRemoveSaleModal(props) {
  const [check, setCheck] = useState(false);

  return (
    <div
      className={classes.removeFromSaleModal}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.modelYayy}>
        <h2>Are you sure!</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>You want to {props.msgType}</p>
      </div>
      <div
        className={classes.modelFlexTranscation}
        style={{ justifyContent: "center" }}
      >
        <div style={{ textAlign: "center" }}>
          <p>Status</p>
          <p style={{ fontSize: 24 }}>Waiting</p>
        </div>
      </div>
      {props.terms && (
        <div className={classes.termsAndConditionContainer}>
          <input
            type="checkbox"
            checked={check}
            onChange={() => setCheck(!check)}
          />
          <p>
            I accept the{" "}
            <a
              href="https://gateway.pinata.cloud/ipfs/QmX57MpLFfUQQQF52zQcV7F5uj8voKAMP5nrcrnYjAKvGC"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms & conditions
            </a>
          </p>
        </div>
      )}
      <div
        style={
          check
            ? { textAlign: "center" }
            : { textAlign: "center", pointerEvents: "none", opacity: 0.5 }
        }
      >
        <Button onClick={() => props.confirm(props.isSalePage, props.isAuctionPage)}>
          <div style={{ padding: "0px 40px" }}>Confirm</div>
        </Button>
      </div>
    </div>
  );
}
