import React from "react";
import { useHistory } from "react-router";
import classes from "./Seller.module.css";


const Seller = ({ assetUsersInfo }) => {
  const history = useHistory();

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }

  return (
    <>
      {
        assetUsersInfo && assetUsersInfo.length ? (
          <div className={classes.sellerValue} onClick={() => moveToUserProfile(assetUsersInfo[0].name)}>
            <div className={classes.avatarWrapper}>
              {
                assetUsersInfo[0].img ? (
                  <img src={assetUsersInfo[0].img} alt="" /> ) : (<i className="fas fa-user-circle"></i>
                )
              }
            </div>
            <div>
              {assetUsersInfo[0].name}
            </div>
          </div>
        ) : null
      }
    </>
  )
}

export default Seller;
