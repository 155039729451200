// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItems_navigationItemContainer__3hFsX {\r\n  list-style: none;\r\n  font-weight: bold;\r\n  padding-right: 32px;\r\n}\r\n.NavigationItems_navigationItemContainer__3hFsX > a {\r\n  font-size: 14px;\r\n  color: #777e91;\r\n  text-decoration: none;\r\n  transition: all 0.4s ease-in-out;\r\n}\r\n.NavigationItems_navigationItemContainer__3hFsX:first-child{\r\n  padding: 0 32px;\r\n}\r\n.NavigationItems_navigationItemContainer__3hFsX > a:hover {\r\n  color: #23262f;\r\n}\r\n.NavigationItems_active__32QDB {\r\n  color: #23262f;\r\n}\r\n\r\n@media only screen and (max-width: 1200px) {\r\n  .NavigationItems_navigationItemContainer__3hFsX {\r\n    padding-right: 16px;\r\n  }\r\n  .NavigationItems_navigationItemContainer__3hFsX:first-child{\r\n    padding: 0 16px;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Toolbar/Navbar/NavigationItems/NavigationItems.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,gCAAgC;AAClC;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".navigationItemContainer {\r\n  list-style: none;\r\n  font-weight: bold;\r\n  padding-right: 32px;\r\n}\r\n.navigationItemContainer > a {\r\n  font-size: 14px;\r\n  color: #777e91;\r\n  text-decoration: none;\r\n  transition: all 0.4s ease-in-out;\r\n}\r\n.navigationItemContainer:first-child{\r\n  padding: 0 32px;\r\n}\r\n.navigationItemContainer > a:hover {\r\n  color: #23262f;\r\n}\r\n.active {\r\n  color: #23262f;\r\n}\r\n\r\n@media only screen and (max-width: 1200px) {\r\n  .navigationItemContainer {\r\n    padding-right: 16px;\r\n  }\r\n  .navigationItemContainer:first-child{\r\n    padding: 0 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationItemContainer": "NavigationItems_navigationItemContainer__3hFsX",
	"active": "NavigationItems_active__32QDB"
};
export default ___CSS_LOADER_EXPORT___;
