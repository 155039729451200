import React from "react";
import classes from "./Button.module.css";

function Button(props) {
  return (
    <button
      className={
        props.secondary ? classes.secondaryButton : classes.primaryButton
      }
      onClick={props.onClick}
      style={{
        background: props.background,
        borderColor: props.borderColor,
      }}
    >
      {props.children}
    </button>
  );
}

export default Button;
