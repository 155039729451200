import React, { Component } from "react";
import Button from "../../Button/Button";
import classes from "./SingleCollectiblePageBtnAndAutoSave.module.css";
import AutoSaveLoader from "../../../assets/img/autoSaveLoader.gif";

export default class SingleCollectiblePageBtnAndAutoSave extends Component {
  
  isButtonShown = (isMultipleNftsCreate, show) => 
    isMultipleNftsCreate ? show ? true : false : true;

  render() {
    return (
      <div className={classes.EndFLexMainContainer}>
        {
          this.isButtonShown(this.props.isMultipleNftsCreate, this.props.show) &&
            <div
              className={classes.EndFirstInner}
              style={
                this.props.pending ? { pointerEvents: "none", opacity: 0.5 } : null
              }
            >
              <div className={classes.userInputErrorMsg}>{this.props.errorMsg}</div>
              <Button
                onClick={this.props.createItemBtnHandler}
                secondary={this.props.pending}
              >
                <div className={classes.createItemBtn}>
                  Create item{" "}
                  {!this.props.pending ? (
                    <div>
                      <i className="fas fa-long-arrow-alt-right"></i>
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </div>
                  ) : (
                    <img src={AutoSaveLoader} alt="Auto-save-Loader-logo" />
                  )}
                </div>
              </Button>
            </div>
        }
        
        {/* <div className={classes.EndSecondInner}>
          <div>
            <h3>Auto saving</h3>
            <img src={AutoSaveLoader} alt="Auto-save-Loader-logo" />
          </div>
        </div> */}
      </div>
    );
  }
}
