import React, { useState, useEffect } from 'react';
import ApiServices from '../../../services/ApiServices';
import classes from './TokenSelect.module.css';
import FilterDropdown from '../../FilterDropdown/FilterDropdown';
const apiService = new ApiServices();

const TokenSelect = ({ currentTokenSymbol, setCurrentTokenSymbol, setTokenPrecision, getTokenSymbol }) => {
  const [config, setConfig] = useState(null);
  const [symbols, setSymbols] = useState(null);

  const getTokens = async () => {
    const config = await apiService.getConfig();
    if(config && config.data) {
      setConfig(config);
    }
  }

  const createPairs = (config) => {
    const tokens = config.data.supported_tokens.reduce((res, token) => {
        return [...res, token.token_symbol]
      }, []);
    const pairs = config.data.supported_pairs.reduce((res, pair) => {
        return [...res, `${pair.settlement_symbol} / ${pair.listing_symbol}`]
      }, []);
    setSymbols([...tokens, ...pairs]);
  }

  useEffect(() => {
    (async () => await getTokens())();
  }, []);

  useEffect(() => {
    if(config) {
      createPairs(config);
    }
  }, [config]);

  useEffect(() => {
    if(currentTokenSymbol && config && config.data) {
      const symbol = getTokenSymbol(currentTokenSymbol);
      const tokenConfig = config.data.supported_tokens.find((t) => t.token_symbol === symbol);
      const pairsConfig = config.data.supported_pairs.find((p) => p.listing_symbol === symbol);
      if(tokenConfig) {
        setTokenPrecision(tokenConfig.token_precision);
      } else if (pairsConfig) {
        setTokenPrecision(pairsConfig.data.quote_precision);
      }
    }
  }, [currentTokenSymbol]);

  return (
    <div className={classes.tokenSelect}>
      <FilterDropdown 
        height="auto"
        options={symbols}
        setSelected={setCurrentTokenSymbol}
        selected={currentTokenSymbol}
      />
    </div>
  );
}

export default TokenSelect;
