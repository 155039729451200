import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import classes from './TemplateLargeCard.module.css';
import useImageLoaded from "../../../hooks/useImageLoaded";
import AppServices from '../../../services/AppService';
import ImageIcon from "../../../assets/img/image.png";
import { TEMPLATE_INFO_KEYS } from '../../../constants/TemplateDetails';
import { MdOutlineLocalFireDepartment } from "react-icons/md";
import { CgArrowsExchange } from "react-icons/cg";
import Button from '../../Button/Button';
import {
  SET_DROP_COLL,
  SET_DROP_COLL_Q,
  SET_PAGE,
  SET_SHOW_QUERY,
  SET_TEMPLATE_ID,
  SET_TEMPLATE_Q
} from "../../../stores/reducers/marketReducer";

const RESIZER_URL = '//images.weserv.nl/?url=';

const appService = new AppServices();

const TemplateLargeCard = ({ templateStats, templateData, content }) => {
  const [templateInfo, setTemplateInfo] = useState([]);
  const [ref, loaded, onLoad] = useImageLoaded();

  const history = useHistory();

  const dispatch = useDispatch();

  const getImgUrl = () =>  appService.getIpfsUrl(content);

  const reloadImg = (e) => {
    if(e.target.src.includes(RESIZER_URL))
      e.target.src = getImgUrl(content);
  }

  const getIssuedSupplyValue = (stats) => 
    <span className={classes.issuedSupplyWrapper}>
      {stats.assets}(
        <span className={classes.id}>{stats.burned}</span>
        <MdOutlineLocalFireDepartment />
      )
    </span>

const getProperties = (asset) =>
  <div className={classes.propertiesWrapper}>
    <div className={classes.infoKey}>
      Properties
    </div>
    <div className={classes.propertiesSecondWrapper}>
      {
        asset.is_transferable &&
        <span className={classes.propertyWrapper}>
          <CgArrowsExchange />
          Transferable
        </span>
      }
      {
        asset.is_burnable &&
        <span className={classes.propertyWrapper}>
          <MdOutlineLocalFireDepartment />
          Burnable
        </span>
      }
      {
        !asset.is_transferable && !asset.is_burnable && 'None'
      }
    </div>
  </div>

const moveToMarket = (collectionName, templateId) => {
  const page = 1;

  dispatch(SET_DROP_COLL(collectionName));
  dispatch(SET_PAGE(page));
  dispatch(SET_DROP_COLL_Q(collectionName));
  dispatch(SET_SHOW_QUERY(true));
  dispatch(SET_TEMPLATE_ID(templateId));
  dispatch(SET_TEMPLATE_Q(templateId));
  history.push('/market');
} 

  const formTemplateInfo = (keys, templateData, templateStats) => {
    const res = keys.reduce((info, key) => {
      let value = null;
      if(key === "Template Id") {
        value = <span className={classes.id}>{`#${templateData[0].template_id}`}</span>;
      } else if(key === "Issued Supply") {
        value = getIssuedSupplyValue(templateStats);
      } else if(key === "Max Supply") {
        value = templateData[0].max_supply;
      }
      return info = [...info, {
        key,
        value
      }]
    }, [])
    setTemplateInfo(res);
  }

  useEffect(() => {
    if(templateData && templateStats) {
      formTemplateInfo(TEMPLATE_INFO_KEYS, templateData, templateStats);
    }
  }, [templateData, templateStats])
  
  return (
    <div className={classes.cardWrapper}>
      <div className={`${classes.imgWrapper} ${loaded ? null : content ? classes.loading : null}`}>
        {
          content ? (
            <img
              ref={ref}
              src={`//images.weserv.nl/?url=${getImgUrl(content)}&h=260&n=-1`} 
              onLoad={onLoad} alt="assetsImg"
              loading="lazy"
              onError={(e) => reloadImg(e)}
            />
          ) : (
            <div className={classes.iconWrapper}>
              <img src={ImageIcon} alt='' />
            </div>
          )
        }
      </div>
      <div className={classes.templateInfoWrapper}>
        {
          templateInfo.map((infoItem, index) =>
            <div className={classes.infoItemWrapper} key={index}>
              <p className={classes.infoKey}>
                {infoItem.key}
              </p>
              <p className={classes.infoValue}>
                {infoItem.value}
              </p>
            </div>
          )
        }
        {getProperties(templateData[0])}
      </div>
      <div className={classes.buttonsWrapper}>
        <Button
          onClick={() => moveToMarket(templateData[0].collection.collection_name, templateData[0].template_id)}
        >
          View on Market
        </Button>
      </div>
    </div>
  );
};

export default TemplateLargeCard;
