import React, { useState, useEffect } from "react";
import classes from "./StatsSlider.module.css";
import Slider from "react-slick";
import useWindowSize from "../../../../../hooks/useWindowSize";
import LoadingBackground from "../../../../UI/LoadingBackground";

const StatsSlider = ({ cardsData, loadingData, title, displayCard }) => {
  const [nftsSliderConfig, setNftsSliderConfig] = useState({});
  const [width, height] = useWindowSize();

  const slider = React.useRef(null);

  const getSlides = (cardsData, width) =>
    cardsData.length < 3 || width < 660
      ? 1
      : width < 860
      ? 2
      : width < 1160
      ? 3
      : width < 1400
      ? 2
      : 3;

  useEffect(() => {
    if (width && cardsData && cardsData.length) {
      setNftsSliderConfig({
        infinite: true,
        speed: 500,
        slidesToShow: getSlides(cardsData, width),
        slidesToScroll: getSlides(cardsData, width),
        arrows: false,
      });
      console.log(getSlides(cardsData, width));
    }
  }, [width, cardsData, loadingData]);

  return (
    <>
      <h2 className={classes.sliderTitle}>{title}</h2>
      {loadingData ? (
        <LoadingBackground height="280px" borderRadius="36px" />
      ) : cardsData && cardsData.length ? (
        <div className={classes.sliderWrapper}>
          <Slider {...nftsSliderConfig} ref={slider}>
            {cardsData &&
              cardsData.length &&
              cardsData.map((item, index) => {
                return item ? displayCard(item, index) : null;
              })}
          </Slider>
          <button
            className={classes.prevBtn}
            onClick={() => slider?.current?.slickPrev()}
          >
            <i className="fas fa-long-arrow-alt-left"></i>
          </button>
          <button
            className={classes.nextBtn}
            onClick={() => slider?.current?.slickNext()}
          >
            <i className="fas fa-long-arrow-alt-right"></i>
          </button>
        </div>
      ) : (
        <div>None</div>
      )}
    </>
  );
};

export default StatsSlider;
