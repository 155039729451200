import React from "react";
import classes from "./FooterLinks.module.css";
import { Link } from "react-router-dom";

export default function FooterLinks(props) {
  return (
    <li className={classes.footerLinksContainer}>
      {
        props.title !== "Privacy Policy" ?
          <Link to={props.link}>{props.title}</Link> :
          <a href={props.link} target="_blank" rel="noopener noreferrer">{props.title}</a>
      }

    </li>
  );
}
