const AUTH_MODAL_PURCHASE = 'To purchase the NFT you need to login first';
const AUTH_MODAL_AUCTION = 'To place bid for the NFT you need to login first';
const SUCCESS_MODAL_PURCHASE = 'The NFT is purchased successfully';
const SUCCESS_MODAL_REMOVED_FROM_SELL = 'The NFT is removed successfully from sell';
const SUCCESS_MODAL_UPDATE_SELL = 'The NFT price is updated successfully for sell';
const SUCCESS_MODAL_BURN = 'The NFT is burned successfully';
const SUCCESS_MODAL_LIST_SELL = 'The NFT is listed successfully on sale';
const SUCCESS_MODAL_LIST_AUCTION = 'The NFT is listed successfully on auction';
const SUCCESS_MODAL_PLACE_BID = 'The NFT is removed successfully from auction';
const REMOVE_MODAL_SELL = 'remove the NFT from sell';
const REMOVE_MODAL_BURN = 'burn the NFT';
const REMOVE_MODAL_AUCTION = 'remove the NFT from auction';
const SUCCESS_MODAL_CLAIM_BID = 'The NFT bid is claimed successfully';
const SUCCESS_MODAL_CLAIM = 'The NFT is claimed successfully';
const SUCCESS_MODAL_UPDATE_AUCTION = 'The NFT price is updated successfully for auction';

const MODAL_CONSTANTS = {
  AUTH_MODAL_PURCHASE, AUTH_MODAL_AUCTION, SUCCESS_MODAL_PURCHASE,
  SUCCESS_MODAL_REMOVED_FROM_SELL, SUCCESS_MODAL_UPDATE_SELL, SUCCESS_MODAL_BURN,
  SUCCESS_MODAL_LIST_SELL, SUCCESS_MODAL_LIST_AUCTION, REMOVE_MODAL_SELL,
  REMOVE_MODAL_BURN, REMOVE_MODAL_AUCTION, SUCCESS_MODAL_PLACE_BID,
  SUCCESS_MODAL_CLAIM_BID, SUCCESS_MODAL_CLAIM, SUCCESS_MODAL_UPDATE_AUCTION
}

export default MODAL_CONSTANTS;
