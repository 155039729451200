import React from "react";
import CloseIcon from "../../assets/img/closeIcon.svg";
import NetworksBlockchainsPage from "../NetworksBlockchainsPage/NetworksBlockchainsPage";
import classesGroup from "./ModalsGroup/ModalsGroup.module.css";
import classes from "./Model.module.css";

export default function NetworkSwitchModal({ showModal, close }) {
  return (
    <div
      className={
        showModal
          ? classesGroup.modalsContainerOpen
          : classesGroup.modalsContainerClose
      }
    >
      {showModal && (
        <div
          className={`${classes.removeFromSaleModal} ${classes.purchaseNFTModal}`}
          style={{
            transform: "scale(1)",
            opacity: 1,
            boxShadow: "1px 1px 4px 1px rgb(0 0 0 / 19%)",
          }}
        >
          <div
            className={classes.modelImageCancel}
            onClick={() => close()}
            style={{ marginLeft: "auto" }}
          >
            <img src={CloseIcon} alt="Modal-close" />
          </div>
          {/* <div
            className={`${classes.modelInnerContainer} ${classes.closeWrapper}`}
          > */}
          {/* <div
            style={window.screen.availWidth > 600 ? { height: "600px" } : ""}
          > */}
          <NetworksBlockchainsPage />
          {/* </div> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
}
