import React from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";

export default function ListOnAuctionModal(props) {
  return (
    <div
      className={classes.putOnAuctioneModal}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.putOnSaleTitle}>
        <h2>Create Listing</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>Input your listing price and duration for auction</p>
      </div>
      <div className={classes.putOnSaleInput}>
        <p>Auction price</p>
        <input
          type="number"
          min={0.0001}
          placeholder="e.g. “1.0000“"
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      <div className={classes.putOnSaleInput} style={{ paddingTop: 0 }}>
        <p>Auction duration</p>
        <div className={classes.autionDurationInputsContainer}>
          <div>
            <span>Days</span>
            <input
              type="number"
              min={0}
              max={30}
              placeholder="Days"
              value={props.days}
              onChange={props.onChangeDays}
            />
          </div>
          <div>
            <span>hours</span>
            <input
              type="number"
              min={0}
              max={24}
              placeholder="hours"
              value={props.hours}
              onChange={props.onChangeHours}
            />
          </div>
          <div>
            <span>minutes</span>
            <input
              type="number"
              min={0}
              max={60}
              placeholder="minutes"
              value={props.minutes}
              onChange={props.onChangeMinutes}
            />
          </div>
        </div>
        <span>{props.errorMsg}</span>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button onClick={props.confirm}>
          <div style={{ padding: "0px 40px" }}>Confirm</div>
        </Button>
      </div>
    </div>
  );
}
