// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCard_cardWrapper__3JTH5 {\n  width: 200px;\n  height: 100px;\n  margin: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid #e6e8ec;\n  border-radius: 16px;\n  cursor: pointer;\n}\n\n.AccountCard_cardWrapper__3JTH5:hover {\n  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);\n}\n\n.AccountCard_name__32phn {\n  color: #dc3825;\n}\n", "",{"version":3,"sources":["webpack://src/components/AccountCard/AccountCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".cardWrapper {\n  width: 200px;\n  height: 100px;\n  margin: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid #e6e8ec;\n  border-radius: 16px;\n  cursor: pointer;\n}\n\n.cardWrapper:hover {\n  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);\n}\n\n.name {\n  color: #dc3825;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": "AccountCard_cardWrapper__3JTH5",
	"name": "AccountCard_name__32phn"
};
export default ___CSS_LOADER_EXPORT___;
