import React from "react";
import { useHistory } from "react-router-dom";
import classes from "./DetailsOwnerInfo.module.css";


export default function DetailsOwnerInfo(props) {

  const history = useHistory();

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }
  return (
    <div className={classes.detailsOwnerInfoContainer} onClick={() => moveToUserProfile(props.collectionName)}>
      {props.img ? (
        <img src={props.img} alt="" />
      ) : (
        <i className="fas fa-user-circle"></i>
      )}
      <div className={classes.detailsOwnerTitleAndRankInfo}>
        <p>{props.rank}</p>
        <h1>{props.name}</h1>
      </div>
      {props.rankIcon && (
        <div className={classes.detailsOwnerRankIconContainer}>
          <img src={props.rankIcon} alt="Rank-icon" />
        </div>
      )}
    </div>
  );
}
