// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterAssetSearch_filterAssetSearchMainContainer__11m-O {\n display: flex;\n justify-content: flex-start;\n align-items: center;\n background: rgba(255, 255, 255, 0.02);\n border: 2px solid #ded2b0;\n border-radius: 31px;\n padding: 14px 20px;\n}\n.FilterAssetSearch_filterAssetSearchMainContainer__11m-O > i {\n font-size: 16px;\n color: #ded2b0;\n margin-right: 5px;\n}\n.FilterAssetSearch_filterAssetSearchMainContainer__11m-O > input {\n outline: none;\n border: none;\n background: none;\n width: 100%;\n font-weight: normal;\n font-size: 14px;\n color: #000;\n}\n", "",{"version":3,"sources":["webpack://src/components/FilterAssetSearch/FilterAssetSearch.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,2BAA2B;CAC3B,mBAAmB;CACnB,qCAAqC;CACrC,yBAAyB;CACzB,mBAAmB;CACnB,kBAAkB;AACnB;AACA;CACC,eAAe;CACf,cAAc;CACd,iBAAiB;AAClB;AACA;CACC,aAAa;CACb,YAAY;CACZ,gBAAgB;CAChB,WAAW;CACX,mBAAmB;CACnB,eAAe;CACf,WAAW;AACZ","sourcesContent":[".filterAssetSearchMainContainer {\n display: flex;\n justify-content: flex-start;\n align-items: center;\n background: rgba(255, 255, 255, 0.02);\n border: 2px solid #ded2b0;\n border-radius: 31px;\n padding: 14px 20px;\n}\n.filterAssetSearchMainContainer > i {\n font-size: 16px;\n color: #ded2b0;\n margin-right: 5px;\n}\n.filterAssetSearchMainContainer > input {\n outline: none;\n border: none;\n background: none;\n width: 100%;\n font-weight: normal;\n font-size: 14px;\n color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterAssetSearchMainContainer": "FilterAssetSearch_filterAssetSearchMainContainer__11m-O"
};
export default ___CSS_LOADER_EXPORT___;
