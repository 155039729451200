import React, { useEffect } from "react";
import classes from "./Model.module.css";

export default function PushNotification(props) {
  useEffect(() => {}, []);

  const addNotificationClass = (isSuccess, isError) => 
    isSuccess ? classes.successNotification : isError ? classes.errorNotification : null;

  return (
    <div
      className={`${classes.pushNotificationContainer} ${addNotificationClass(props.isSuccess, props.isError)}`}
      style={
        props.showNotification
          ? { transform: "translateX(0)", opacity: 1 }
          : { transform: "translateX(100%)", opacity: 0 }
      }
    >
      {props.title}
    </div>
  );
}
