import axios from "axios";

const MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

export default class ApiServices {
  constructor() {
    this.atomicMartUrl = process.env.REACT_APP_ATOMICMARKET_URL;
    this.atomicAssetUrl = process.env.REACT_APP_ATOMICASSET_URL;
    this.tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
    this.pinataApiKey = process.env.REACT_APP_PINATA_API_KEY;
    this.pinataApiSecret = process.env.REACT_APP_PINATA_API_SECRET;
    this.accountsUrl = process.env.REACT_APP_PINK_URL;
    this.apiUrl = process.env.REACT_APP_API_URL;

    this.ipfsPintaUrl = "https://api.pinata.cloud/pinning/pinFileToIPFS";
    this.coinMarketApiUrl = "https://min-api.cryptocompare.com/data";
    this.baseUri = process.env.REACT_APP_ECSTER_URL;

    this.headers = {
      "x-api-key": process.env.REACT_APP_ECSTER_KEY,
      "x-merchant-key": process.env.REACT_APP_ECSTER_MERCHANT_KEY,
      "Content-Type": "application/json",
    };
  }

  httpCall = (url, data) => {
    return axios.post(url, data, {
      headers: {
        "x-auth-token": `${window.localStorage.getItem("token")}`,
      },
    });
  };

  login = async (data) => {
    let res = {
      token: false,
      massage: "",
    };
    const result = await axios.post(this.apiUrl + "user/anchor/login", data);
    if (result.data.success) {
      res.token = true;
      localStorage.setItem("token", result.data.data.token);
    }
    return res;
  };

  getLimit = (timeLimit) => {
    const now = new Date();
    now.setTime(now.getTime() - timeLimit);
    return now;
  };

  getTopUsers = async (seller, whiteList) => {
    const timeLimit = this.getLimit(MONTH_IN_MILLISECONDS);
    const url = this.atomicMartUrl + "/stats/accounts";
    let query =
      "?limit=20&sort=" +
      (seller === true ? "sell_volume" : "buy_volume") +
      "&symbol=" +
      this.tokenSymbol +
      "&after=" +
      timeLimit.getTime();

    if (whiteList) query += "&collection_whitelist=" + whiteList;

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data.results;
    });
    return res;
  };

  getHotNfts = async (
    limit,
    page,
    collectionName,
    schemaName,
    templateId,
    order,
    sort
  ) => {
    const url = this.atomicMartUrl + "/stats/templates";
    const query =
      "?limit=" +
      limit +
      (page ? "&page=" + page : "") +
      (collectionName ? "&collection_name=" + collectionName : "") +
      (schemaName ? "&schema_name=" + schemaName : "") +
      (templateId ? "&template_id=" + templateId : "") +
      (templateId ? "&template_id=" + templateId : "") +
      (order ? "&order=" + order : "") +
      (sort ? "&sort=" + sort : "") +
      "&symbol=" +
      this.tokenSymbol;

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data.results;
    });
    return res;
  };

  getTopCollection = async (limit) => {
    const url = this.atomicMartUrl + "/stats/collections";
    const query =
      "?limit=" + (limit > 0 ? limit : "4") + "&symbol=" + this.tokenSymbol;

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data.results;
    });
    return res;
  };

  getCollectionStats = async (collection) => {
    const url = this.atomicAssetUrl + "/collections/" + collection + "/stats";
    var res = null;
    await axios.get(url).then((response) => {
      res = response.data.data;
    });
    return res;
  };

  getUserStats = async (userName) => {
    const url = this.atomicMartUrl + "/stats/accounts/" + userName;
    const query = "?symbol=" + this.tokenSymbol;
    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data;
    });
    return res;
  };

  getTopCollectionAssets = async (collection) => {
    const url = this.atomicAssetUrl + "/assets";
    const query = "?limit=3&page=1&collection_name=" + collection;

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data;
    });
    return res;
  };

  defineMatch = (query, match) => {
    if (query === "assets" || query === "templates") {
      return parseInt(match) ? "&ids=" + match : "&match=" + match;
    }
    return "&match=" + match;
  };

  getSearchData = async (
    query,
    page,
    limit,
    match = null,
    category = null,
    collection = null,
    templateId = null,
    order = "desc",
    sort = "created",
    schema = null,
    state = null,
    maxAssets = null
  ) => {
    try {
      const url = this.atomicAssetUrl + "/" + query;
      let fullQuery = "?limit=" + limit;
      if (page) fullQuery += "&page=" + page;
      if (category && category !== "zeptanfts")
        fullQuery += "&data.category=" + category;
      if (collection != null) fullQuery += "&collection_name=" + collection;
      if (templateId != null) fullQuery += "&template_id=" + templateId;
      if (match != null) fullQuery += this.defineMatch(query, match);
      if (schema != null) fullQuery += "&schema_name=" + schema;
      if (state) fullQuery += "&state=" + state;
      if (maxAssets) fullQuery += "&max_assets=" + maxAssets;
      fullQuery += "&order=" + order;
      fullQuery += "&sort=" + sort;

      const res = await axios.get(url + fullQuery);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  getUpperBound = (match) => {
    while (match.length !== 12) {
      match += "z";
    }
    return match;
  };

  getAccaunts = async (match = null) => {
    if (match && match.length < 13) {
      try {
        const data = {
          code: "eosio",
          lower_bound: match,
          limit: "20",
          table: "userres",
          upper_bound: this.getUpperBound(match),
        };
        const url = this.accountsUrl + "/chain/get_table_by_scope";
        const resp = await axios.post(url, data);
        return resp.data.rows;
      } catch (error) {
        console.log(error);
      }
    }
    return null;
  };

  getSales = async (
    limit = 12,
    page,
    category = null,
    collectionName = null,
    minPrice = null,
    maxPrice = null,
    order = "desc",
    minTemplateMint = null,
    maxTemplateMint = null,
    templateId = null,
    match,
    symbol = null,
    state = null,
    maxAssets = null,
    sort = null
  ) => {
    const url = this.atomicMartUrl + "/sales";
    var query = "?limit=" + limit;
    if (page) query += "&page=" + page;
    if (category && category !== "zeptanfts")
      query += "&data.category=" + category;
    if (collectionName) query += "&collection_name=" + collectionName;
    if (minPrice) query += "&min_price=" + minPrice;
    if (maxPrice) query += "&max_price=" + maxPrice;
    if (order) query += "&order=" + order;
    if (minTemplateMint) query += "&min_template_mint=" + minTemplateMint;
    if (maxTemplateMint) query += "&max_template_mint=" + maxTemplateMint;
    if (templateId) query += "&template_id=" + templateId;
    if (match) query += "&match=" + match;
    if (match) query += "&match=" + match;
    if (state) query += "&state=" + state;
    else query += "&state=1";
    if (maxAssets) query += "&max_assets=" + maxAssets;
    if (sort) query += "&sort=" + sort;
    else query += "&sort=sale_id";

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data;
    });
    return res;
  };

  getAuctionList = async (
    limit = 12,
    page = 1,
    category = null,
    collectionName = null,
    minPrice = null,
    maxPrice = null,
    order = "desc",
    minTemplateMint = null,
    maxTemplateMint = null,
    templateId = null,
    symbol = null
  ) => {
    const url = this.atomicMartUrl + "/auctions";
    var query = "?sort=auction_id&limit=" + limit + "&state=1&page=" + page;
    if (category && category !== "zeptanfts")
      query += "&data.category=" + category;
    if (collectionName) query += "&collection_name=" + collectionName;
    if (minPrice) query += "&min_price=" + minPrice;
    if (maxPrice) query += "&max_price=" + maxPrice;
    if (order && order === "asc") query += "&order=" + order;
    if (minTemplateMint) query += "&min_template_mint=" + minTemplateMint;
    if (maxTemplateMint) query += "&max_template_mint=" + maxTemplateMint;
    if (templateId) query += "&template_id=" + templateId;
    if (symbol) query += "&symbol=" + symbol;

    var res = null;
    await axios.get(url + query).then((response) => {
      res = response.data.data;
    });
    return res;
  };

  getAtomicAssets = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/assets";
    const query =
      "?owner=" +
      userName +
      "&order=desc&sort=asset_id&page=" +
      page +
      "&match=" +
      match;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getUserActiveListings = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/sales";
    const query =
      "?seller=" + userName + "&state=0,1,4&page=" + page + "&match=" + match;
    var res = null;

    //functions.logger.info("UserActiveListings :", { structuredData: true, url: (url + query) });
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getUserActiveAtomicAuctions = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/auctions";
    const query =
      "?seller=" + userName + "&state=0,1,4&page=" + page + "&match=" + match;
    var res = null;
    //functions.logger.info("UserAuctionSold :", { structuredData: true, url: (url + query) });
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getUserSold = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/sales";
    const query =
      "?seller=" + userName + "&state=3&page=" + page + "&match=" + match;
    var res = null;

    //functions.logger.info("UserActiveListings :", { structuredData: true, url: (url + query) });
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getUserBoughts = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/sales";
    const query =
      "?buyer=" + userName + "&state=3&page=" + page + "&match=" + match;
    var res = null;

    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getCreatedAssetByUser = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/assets";
    const query =
      "?authorized_account=" +
      userName +
      "&order=desc&sort=asset_id&page=" +
      page +
      "&match=" +
      match;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  getAssetData = async (assetId) => {
    const url = this.atomicMartUrl + "/assets/" + assetId;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        return null;
      });
    return res;
  };

  getSaleData = async (saleId) => {
    const url = this.atomicMartUrl + "/sales/" + saleId;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        return null;
      });
    return res;
  };

  getAuctionData = async (auctionId) => {
    const url = this.atomicMartUrl + "/auctions/" + auctionId;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        return null;
      });
    return res;
  };

  getUserCollection = async (userAccount) => {
    const url = this.atomicAssetUrl + "/collections/" + userAccount;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getAuthorCollection = async (userAccount) => {
    const url =
      this.atomicAssetUrl +
      "/collections?limit=20&page=1&order=desc&sort=created&author=" +
      userAccount;
    let res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getCollectionData = async (collectionName) => {
    const url = this.atomicAssetUrl + "/collections/" + collectionName;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getTemplateData = async (templateId) => {
    const url = this.atomicAssetUrl + "/templates";
    let query = "?ids=" + templateId;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getSchemaData = async (collectionName, schemaName) => {
    const url = this.atomicAssetUrl + "/schemas";
    let query = `?schema_name=${schemaName}&collection_name=${collectionName}`;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getTemplateStats = async (collectionName, templateId) => {
    const url = `${this.atomicAssetUrl}/templates/${collectionName}/${templateId}/stats`;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getCollectionSchema = async (collection) => {
    const url = this.atomicAssetUrl + "/schemas";
    const query = "?collection_name=" + collection;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getUserTemplate = async (userAccount, page, limit) => {
    const url = this.atomicAssetUrl + "/templates";
    const query =
      "?collection_name=" +
      userAccount +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=desc&sort=created";
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };
  getTotalTemplatesNumber = async (userAccount) => {
    const url = this.atomicAssetUrl + "/collections/" + userAccount + "/stats";
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };
  //IPFS
  uploadFileOnIpfs = async (img) => {
    let res = null;
    // const url = this.ipfsPintaUrl;
        const url = 'https://zepta-new-api.zeptagram.com/api/v1/user/file/upload/pinata';

    let data = new FormData();
    data.append("image", img);
    // const pinataOptions = JSON.stringify({
    //   cidVersion: 0,
    //   customPinPolicy: {
    //     regions: [
    //       {
    //         id: "FRA1",
    //         desiredReplicationCount: 1,
    //       },
    //       {
    //         id: "NYC1",
    //         desiredReplicationCount: 2,
    //       },
    //     ],
    //   },
    // });

    // data.append("pinataOptions", pinataOptions);

    // let config = {
    //   maxBodyLength: "Infinity",
    //   headers: {
    //     "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    //     pinata_api_key: this.pinataApiKey,
    //     pinata_secret_api_key: this.pinataApiSecret,
    //   },
    // };

    res = await axios
      .post(url, data)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return null;
      });

    return res;
  };

  //MarketApi
  getTokenPrice = async () => {
    let symbol = this.tokenSymbol;
    if (symbol === "WAX") symbol = "WAXP";

    const url = this.coinMarketApiUrl + "/price";
    const query = "?fsym=" + symbol + "&tsyms=USD";
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };

  getConfig = async () => {
    var res = null;
    await axios
      .get(`${this.atomicMartUrl}/config`)
      .then((response) => {
        res = response.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };
  getPriceHistory = async (templateId, schemaName) => {
    let url = null;
    if (templateId) {
      url = this.atomicMartUrl + `/prices/sales/days?template_id=${templateId}`;
    } else if (schemaName) {
      url = this.atomicMartUrl + `/prices/sales/days?schema_name=${schemaName}`;
    }

    if (url !== null) {
      var res = null;
      await axios.get(url).then((response) => {
        res = response.data.data;
      });
      return res;
    }
  };

  getSupportedTokens = async () => {
    const url = this.atomicMartUrl + "/config";

    let res = null;

    await axios.get(url).then((response) => {
      res = response.data.data["supported_tokens"];
    });

    return res;
  };

  getCollectionGraph = async (collectionName) => {
    let url = this.atomicMartUrl + "/stats/graph?symbol=" + this.tokenSymbol;
    if (collectionName) url += "&collection_whitelist=" + collectionName;

    let res = null;

    await axios.get(url).then((response) => {
      res = response.data.data;
    });

    return res;
  };
  getLogs = async (type, Id, index) => {
    const url =
      this.atomicMartUrl +
      `/${type}/` +
      Id +
      `/logs?page=${index}&limit=25&order=desc`;
    var res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        return null;
      });
    return res;
  };
  createEcsterCart = async (id, paymentType, paymentAmount, fee) => {
    const countryCode = "SE";
    const purchaseType = "B2C";
    const paymentObject = {
      countryCode: countryCode,
      purchaseType: purchaseType,
      orderRef: id,
      paymentType: paymentType,
      id: id,
      paymentAmount: paymentAmount,
      fee: Number(fee.toFixed(2)),
    };
    try {
      /* Make request */
      const data = await this.httpCall(
        process.env.REACT_APP_API_URL + "/user/initiate_payment",
        paymentObject
      );
      return data.data;
    } catch (err) {
      //  ual.logout();
      console.log(err, "err.message");
    }
  };
  confirmEcsterPayment = async (cartId) => {
    try {
      const paymentObject = {
        status: "confirm",
        cartId: cartId,
      };
      const data = await this.httpCall(
        process.env.REACT_APP_API_URL + "/user/confirmPayment",
        paymentObject
      );
      return data.data;
    } catch (err) {
      console.log({ ...err });
    }
  };
  getUsdPrice = async (firstToken, secondToken) => {
    try {
      /* Make request */
      const data = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${firstToken}&vs_currencies=${secondToken}`
      );
      return data.data;
    } catch (err) {
      console.log({ ...err });
    }
  };
}
