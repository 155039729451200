import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pending: false,
  showBackdrop: false,
  showModal: false,
  showBuyRamModal: false,
  showErrorModal: false,
  showLoader: false,
  modalAnimation: false,
  showNotification: false,
  notificationTitle: "",
  errorMsg: "",
  userAssetsData: {
    img: "",
    title: "",
    displayName: "",
    description: "",
    dropMarketFee: 6,
    websiteUrl: "",
    price: "",
    inStock: "",
    putOnSale: false,
    instantlySold: false,
    contentLock: false,
    rarity: "rare",
    category: "art",
    prevImg: "",
    prevVideo: "",
    prevAudio: "",
    fileName: "",
  },
  showAdvancedSettings: true,

  userUpdatedAssetsData: [],
  userAccount: "",
  userCollection: "",
  isCollectionCreated: false,
  userSchema: "",
  isSchemaCreated: false,
  userAsset: "",
  imageIpfsUrl: "",
  userCollections: [],
  userCollectionSchemas: [],
  currentCollection: "",
  rarityList: [
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary",
    "Mythic",
    "Other",
  ],
  assetTypeList: ["Art", "Game", "Photography", "Music", "Video", "Other"],
};

const singleCollectibleReducer = createSlice({
  name: "singleCollectibleReducer",
  initialState,
  reducers: {
    SET_MARKET_FEE: (state, action) => {
      state.userAssetsData.dropMarketFee = action.payload;
    },
    SET_PUT_ON_SALE: (state, action) => {
      state.putOnSale = action.payload;
    },
    SET_INSTANTLY_SOLD: (state, action) => {
      state.instantlySold = action.payload;
    },
    SET_CONTENT_LOCK: (state, action) => {
      state.contentLock = action.payload;
    },
    SET_CURRENT_COLLECTION: (state, action) => {
      state.currentCollection = action.payload;
    },
    SHOW_MODAL: (state, action) => {
      state.showModal = action.payload.showModal;
      state.showBackdrop = action.payload.showBackdrop;
    },
    SHOW_LOADER: (state, action) => {
      state.showLoader = action.payload.showLoader;
      state.showBackdrop = action.payload.showBackdrop;
    },
    SHOW_ERR_MODAL: (state, action) => {
      state.showErrorModal = action.payload.showErrorModal;
      state.showBackdrop = action.payload.showBackdrop;
    },
    SHOW_BUY_RAM_MODAL: (state, action) => {
      state.showBuyRamModal = action.payload.showBuyRamModal;
      state.showBackdrop = action.payload.showBackdrop;
    },
    SHOW_NOTIFICATION: (state, action) => {
      state.showNotification = action.payload.showNotification;
      state.notificationTitle = action.payload.notificationTitle;
    },
    CLOSE_MODAL: (state, action) => {
      state.showModal = action.payload.showModal;
      state.showErrorModal = action.payload.showErrorModal;
      state.showLoader = action.payload.showLoader;
      state.modalAnimation = action.payload.modalAnimation;
      state.showBackdrop = action.payload.showBackdrop;
      state.showBuyRamModal = action.payload.showBuyRamModal;
    },
    SET_ASSET_IMG: (state, action) => {
      state.userAssetsData.img = action.payload.img;
    },
    SET_ASSET_TYPE_DATA: (state, action) => {
      state.userAssetsData.prevVideo = action.payload.prevVideo;
      state.userAssetsData.prevImg = action.payload.prevImg;
      state.userAssetsData.prevAudio = action.payload.prevAudio;
    },
    SET_ASSET_FILENAME: (state, action) => {
      state.userAssetsData.fileName = action.payload.fileName;
    },
    SET_USER_COLL_SCHEMAS: (state, action) => {
      state.userCollectionSchemas = action.payload.userCollectionSchemas;
    },
    SET_USER_SCHEMA: (state, action) => {
      state.userSchema = action.payload.userSchema;
    },
    SET_USER_COLLECTION: (state, action) => {
      state.userCollection = action.payload.userCollection;
    },
    TOGGLE_ADVANCED_SETTINGS: (state, action) => {
      state.showAdvancedSettings = !state.showAdvancedSettings;
    },
    SET_PENDING: (state, action) => {
      state.pending = action.payload.pending;
    },
    SINGLE_COL_SET_IMAGE_IPFS_URL: (state, action) => {
      state.imageIpfsUrl = action.payload.imageIpfsUrl;
    },
    SET_ERROR_MSG: (state, action) => {
      state.errorMsg = action.payload.errorMsg;
    },
    CLEAR_ASSET_DATA: (state, action) => {
      state.userAssetsData = {
        img: "",
        title: "",
        displayName: "",
        description: "",
        dropMarketFee: 6,
        websiteUrl: "",
        price: "",
        inStock: "",
        putOnSale: false,
        instantlySold: false,
        contentLock: false,
        rarity: "rare",
        category: "art",
        prevImg: "",
        prevVideo: "",
        prevAudio: "",
        fileName: "",
      };
      state.errorMsg = "";
      state.userCollection = "";
      state.currentCollection = "";
      state.userSchema = "";
    },
    SET_IS_COLLECTION_CREATED: (state, action) => {
      state.isCollectionCreated = action.payload.isCollectionCreated;
    },
    SET_USER_COLLECTIONS: (state, action) => {
      state.userCollections = action.payload.userCollections;
    },
    SET_USER_ACCOUNT: (state, action) => {
      state.userAccount = action.payload.userAccount;
    },
    SET_ASSET_TITLE: (state, action) => {
      state.userAssetsData.title = action.payload;
    },
    SET_ASSET_DISPLAY_NAME: (state, action) => {
      state.userAssetsData.displayName = action.payload;
    },
    SET_ASSET_DESCRIPTION: (state, action) => {
      state.userAssetsData.description = action.payload;
    },
    SET_ASSET_MARKET_FEE: (state, action) => {
      state.userAssetsData.marketFee = action.payload;
    },
    SET_RARITY: (state, action) => {
      state.userAssetsData.rarity = action.payload;
    },
    SET_ASSET_TYPE: (state, action) => {
      state.userAssetsData.category = action.payload;
    },
    SET_ASSET_WEBSITE_URL: (state, action) => {
      state.userAssetsData.websiteUrl = action.payload;
    },
    SET_ASSET_PRICE: (state, action) => {
      state.userAssetsData.price = action.payload;
    },
    SET_ASSET_IN_STOCK: (state, action) => {
      state.userAssetsData.inStock = action.payload;
    },
    SET_ASSET_PUT_ON_SALE: (state, action) => {
      state.userAssetsData.putOnSale = action.payload;
    },
    SET_ASSET_INSTANTLY_SOLD: (state, action) => {
      state.userAssetsData.instantlySold = action.payload;
    },
    SET_ASSET_CONTENT_LOCK: (state, action) => {
      state.userAssetsData.contentLock = action.payload;
    },
    SET_ASSET_RARITY: (state, action) => {
      state.userAssetsData.rarity = action.payload;
    },
    SET_ASSET_CATEGORY: (state, action) => {
      state.userAssetsData.category = action.payload;
    },
  },
});

export const {
  SET_MARKET_FEE,
  SET_CURRENT_COLLECTION,
  SHOW_MODAL,
  SHOW_LOADER,
  SHOW_ERR_MODAL,
  SHOW_NOTIFICATION,
  CLOSE_MODAL,
  SET_ASSET_IMG,
  SET_ASSET_TYPE_DATA,
  SET_ASSET_FILENAME,
  SET_USER_COLL_SCHEMAS,
  SET_USER_SCHEMA,
  SET_USER_COLLECTION,
  TOGGLE_ADVANCED_SETTINGS,
  SET_PENDING,
  SINGLE_COL_SET_IMAGE_IPFS_URL,
  SET_ERROR_MSG,
  CLEAR_ASSET_DATA,
  SET_IS_COLLECTION_CREATED,
  SET_USER_COLLECTIONS,
  SET_USER_ACCOUNT,
  SET_ASSET_TITLE,
  SET_ASSET_DISPLAY_NAME,
  SET_ASSET_DESCRIPTION,
  SET_RARITY,
  SET_ASSET_TYPE,
  SET_ASSET_WEBSITE_URL,
  SET_ASSET_PRICE,
  SET_ASSET_IN_STOCK,
  SET_ASSET_PUT_ON_SALE,
  SET_ASSET_INSTANTLY_SOLD,
  SET_ASSET_CONTENT_LOCK,
  SET_ASSET_RARITY,
  SET_ASSET_CATEGORY,
  SHOW_BUY_RAM_MODAL,
} = singleCollectibleReducer.actions;

export default singleCollectibleReducer.reducer;
