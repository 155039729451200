import React, { cloneElement } from 'react';
import classes from './SchemaCard.module.css';
import { useHistory } from "react-router-dom";

const SchemaCard = ({ name, collection, attributes, index = 1 }) => {
  const history = useHistory();

  const navigateToScreen = (collectionName, schemaName) => {
    history.push(`/schema/${collectionName}/${schemaName}`);
    window.scrollTo(0, 0);
  };
  
  return (
    <div className={classes.cardWrapper} onClick={() => navigateToScreen(collection, name)} key={index}>
      <p className={classes.name}>
        {name}
      </p>
      <p className={classes.collection}>
        {collection}
      </p>
      <p className={classes.attributes}>
        {`${attributes} Attributes`}
      </p>
    </div>
  );
};

export default SchemaCard;
