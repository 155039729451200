// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatsColumn_columnWrapper__20-7B {\n  margin: 20px 0;\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-family: \"Poppins\", sans-serif;\n  text-align: center;\n}\n.StatsColumn_title__Wurad, .StatsColumn_value__3rDkN {\n  font-size: 18px;\n  font-weight: 800;\n  font-family: \"Poppins\", sans-serif;\n}\n.StatsColumn_tokensDollars__3sfDj {\n  font-weight: 500;\n}\n@media only screen and (max-width: 980px) {\n  .StatsColumn_title__Wurad, .StatsColumn_value__3rDkN {\n    font-size: 14px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/PriceHistorySectionTab/Stats/StatsColumn/StatsColumn.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".columnWrapper {\n  margin: 20px 0;\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-family: \"Poppins\", sans-serif;\n  text-align: center;\n}\n.title, .value {\n  font-size: 18px;\n  font-weight: 800;\n  font-family: \"Poppins\", sans-serif;\n}\n.tokensDollars {\n  font-weight: 500;\n}\n@media only screen and (max-width: 980px) {\n  .title, .value {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnWrapper": "StatsColumn_columnWrapper__20-7B",
	"title": "StatsColumn_title__Wurad",
	"value": "StatsColumn_value__3rDkN",
	"tokensDollars": "StatsColumn_tokensDollars__3sfDj"
};
export default ___CSS_LOADER_EXPORT___;
