import React, { useState, useEffect } from "react";
import classes from "./Stats.module.css";
import StatsColumn from "./StatsColumn";

const Stats = ({ asset, sales, totalSales }) => {
  const [lowestPrice, setLowestPrice] = useState(null);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [highestPrice, setHighestPrice] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(process.env.REACT_APP_TOKEN_SYMBOL);
  const [tokenPrecision, setTokenPrecision] = useState(process.env.REACT_APP_TOKEN_PRECISION);

  const getStats = (prices, sales) => {
    setLowestPrice(prices.min ?? 'None');
    setSuggestedPrice(prices.suggested_median ?? 'None');
    setHighestPrice(prices.max ?? 'None');
  }

  useEffect(() => {
    if(asset && sales && asset.prices && asset.prices[0]) {
      getStats(asset.prices[0], sales);
    } else if(asset && sales && !asset.prices) {
      setLowestPrice('None');
      setSuggestedPrice('None');
      setHighestPrice('None');
    }
  }, [asset, sales, totalSales])

  return (
    <div className={classes.statsWrapper}>
      <StatsColumn
        title='Lowest Listing'
        value={lowestPrice}
        isPrice
        tokenSymbol={tokenSymbol}
        tokenPrecision={tokenPrecision}
      />
      <StatsColumn
        title='Suggested Price'
        value={suggestedPrice}
        isPrice
        tokenSymbol={tokenSymbol}
        tokenPrecision={tokenPrecision}
      />
      <StatsColumn
        title='Highest Sale'
        value={highestPrice}
        isPrice
        tokenSymbol={tokenSymbol}
        tokenPrecision={tokenPrecision}
      />
      <StatsColumn title='Total Sales' value={totalSales}/>
    </div>
  )
}

export default Stats;
