import React, { Component } from "react";
import classes from "./Profile.module.css";
import CoverImg from "../../assets/img/coverImg.jpg";
import ProfilePageUserDescCard from "../../components/ProfilePage/ProfilePageUserDescCard/ProfilePageUserDescCard";
import ProfilePageUserAssets from "../../components/ProfilePage/ProfilePageUserAssets/ProfilePageUserAssets";
import SearchNotFound from "../SearchNotFound/SearchNotFound";
import BlockchainService from "../../services/BlockchainService";
import AppServices from "../../services/AppService";
import FirebaseService from "../../services/FirebaseService";
import Status from "../../components/UI/Status/Status";
import ApiServices from "../../services/ApiServices";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {
        img: "",
        name: "",
        address: "",
        desc: "",
        coverImg: CoverImg,
        facebookProfile: "",
        twitter: "",
        instagramProfile: "",
      },
      statusContent: [
        {
          title: "Total Bought",
          value: "",
        },
        {
          title: "Total Sold",
          value: "",
        },
      ],
      userAccount: "",
      isOwner: false,
    };

    this.blockchainService = new BlockchainService();
    this.appService = new AppServices();
    this.firebaseService = new FirebaseService();
    this.apiService = new ApiServices();

    this.tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
    this.tokenPrecision = process.env.REACT_APP_TOKEN_PRECISION;
  }

  async componentDidMount() {
    console.log("userAccountuserAccountuserAccount");
    let userAccount = await this.getUserAccount();
    let isExists = await this.isAccountExists(userAccount);
    if (isExists === null) {
      userAccount = null;
    } else {
      await this.checkingOwnership(userAccount);
    }
    this.setState({ userAccount });
    await this.getUserData(userAccount);
    await this.getUserStats(userAccount);
  }

  getUserData = async (userAccount) => {
    let fetchData = await this.firebaseService.getUser(userAccount);

    if (fetchData) {
      fetchData = fetchData.data();
      if (fetchData) {
        let userProfile = this.state.userProfile;
        userProfile.img = fetchData.image ?? "";
        userProfile.name = fetchData.name ?? "";
        userProfile.desc = fetchData.bio ?? "";
        userProfile.facebookProfile = fetchData.facebook ?? "";
        userProfile.twitter = fetchData.twitter ?? "";
        userProfile.instagramProfile = fetchData.instagram ?? "";
        userProfile.coverImg =
          fetchData.coverPhoto !== "" ? fetchData.coverPhoto : CoverImg;
        this.setState({ userProfile });
      }
    }
  };

  getUserStats = async (userAccount) => {
    let res = await this.apiService.getUserStats(userAccount);
    if (res) {
      let boughtVolume =
        +(
          +(res.result.buy_volume ?? 0) / Math.pow(10, this.tokenPrecision)
        ).toFixed(4) +
        " " +
        this.tokenSymbol;
      let selVolume =
        +(
          +(res.result.sell_volume ?? 0) / Math.pow(10, this.tokenPrecision)
        ).toFixed(4) +
        " " +
        this.tokenSymbol;

      let statusContent = this.state.statusContent;
      statusContent[0].value = boughtVolume;
      statusContent[1].value = selVolume;

      this.setState({ statusContent });
    }
  };

  isAccountExists = async (accountName) => {
    let res = await this.blockchainService.getAccountInfo(accountName);
    return res;
  };

  checkingOwnership = async (accountName) => {
    let isOwner = false;
    let loginUser = await this.appService.getAccountName(this.props.ual);
    if (accountName === loginUser) isOwner = true;

    this.setState({ isOwner });
  };

  getUserAccount = async () => {
    let path = window.location.pathname;
    let splitArray = path.split("/");
    let account = splitArray[2];
    this.props.setUserProfileLogin(account);
    return account;
  };

  editCoverPhotoHandler = (e) => {
    let file = e.target.files[0];

    const myFileItemReader = new FileReader();
    myFileItemReader.addEventListener(
      "load",
      () => {
        let userProfile = this.state.userProfile;
        const myResult = myFileItemReader.result;
        userProfile.coverImg = myResult;
        this.setState({ userProfile });
        this.addUser(myResult);
      },
      false
    );

    myFileItemReader.readAsDataURL(file);
  };

  addUser = async (coverImg) => {
    let userData = this.state.userProfile;
    let userName = await this.blockchainService.getAccountName(this.props.ual);
    this.firebaseService.addUser(userName, {
      name: userData.name,
      image: userData.img,
      bio: userData.desc,
      coverPhoto: coverImg,
      facebook: userData.facebookProfile,
      twitter: userData.twitter,
      instagram: userData.instagramProfile,
    });
  };

  render() {
    return (
      <div className={classes.profileMainContainer}>
        {this.state.userAccount == null ? (
          <SearchNotFound />
        ) : (
          <>
            <div className={classes.profileDescContainer}>
              <div className={classes.userDescCardContainer}>
                <ProfilePageUserDescCard
                  userImg={this.state.userProfile.img}
                  userName={this.state.userProfile.name}
                  userAddress={this.state.userProfile.address}
                  userDesc={this.state.userProfile.desc}
                  facebook={this.state.userProfile.facebookProfile}
                  twitter={this.state.userProfile.twitter}
                  instagram={this.state.userProfile.instagramProfile}
                  isOwner={this.state.isOwner}
                />
              </div>
              <div className={classes.userAssetsContainer}>
                <Status title="Status" content={this.state.statusContent} />
                <ProfilePageUserAssets
                  account={this.state.userAccount}
                  isOwner={this.state.isOwner}
                  userName={this.props.userName}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Profile;
