import React, { useState, useEffect } from "react";
import classes from "./InfoTab.module.css";
import { CgArrowsExchange } from "react-icons/cg";
import { MdOutlineLocalFireDepartment } from "react-icons/md";
import DetailsOwnerInfo from "../../UI/DetailsOwnerInfo/DetailsOwnerInfo";
import saleDetailsConst from "../../../constants/SaleDetails";
import ApiServices from "../../../services/ApiServices";
import Tooltip from "../../UI/Tooltip";
import { useHistory } from "react-router-dom";

const apiServices = new ApiServices();

const InfoTab = ({
  data,
  assetUsersInfo,
  isSalePage,
  isBundle,
  bundleData,
  isAuctionPage,
}) => {
  const [infoAssetsDetails, setInfoAssetsDetails] = useState(null);
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [templateStats, setTemplateStats] = useState(null);

  const history = useHistory();

  const moveToDetailsPage = (object, objectId, collectionName) => {
    if (collectionName) {
      history.push(`/${object}/${collectionName}/${objectId}`);
    } else {
      history.push(`/${object}/${objectId}`);
    }
  };

  const getLink = (text, moveTo, object, objectId, collectionName) => {
    if (text && text !== "None") {
      return (
        <span
          className={classes.objectLink}
          onClick={() => moveTo(object, objectId, collectionName)}
        >
          {text}
        </span>
      );
    }
    return text;
  };

  const getOrdinalNum = (n) =>
    `${n}` + (["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th");
  const mintTooltipString = (getOrdinalNum, templateMint) => {
    const ordinalNum = getOrdinalNum(templateMint);
    return `This is the ${ordinalNum} asset created<br/>from this template`;
  };

  const getMintNumberString = (asset, templateStats) => {
    return (
      <>
        {asset.template_mint === "0" && !asset.template ? (
          <span className={classes.templateMint}>1 of 1 [No Template]</span>
        ) : asset.template_mint === "0" ? (
          <span className={classes.templateMint}>Calculating...</span>
        ) : (
          <Tooltip
            id="mint"
            tip={mintTooltipString(getOrdinalNum, asset.template_mint)}
            string={asset.template_mint}
          />
        )}

        {asset.template &&
          asset.template.issued_supply &&
          asset.template.max_supply &&
          ` of 
          ${asset.template.issued_supply} 
          (max: ${
            asset.template.max_supply && asset.template.max_supply !== "0"
              ? asset.template.max_supply
              : "?"
          }) `}
        {templateStats && templateStats.burned && templateStats.burned > 0 && (
          <span className={classes.burnedWrapper}>
            {` - ${templateStats.burned}`}
            <MdOutlineLocalFireDepartment />
          </span>
        )}
      </>
    );
  };

  const getBackendTokensString = (backendTokens) => {
    if (backendTokens && backendTokens.length) {
      return backendTokens.reduce((str, token) => {
        return `${str}, ${token.amount} ${token.token_symbol}`;
      }, "");
    }
    return "None";
  };

  const getProperties = (asset) => (
    <span className={classes.propertiesWrapper}>
      {asset.is_transferable && (
        <span className={classes.propertyWrapper}>
          <CgArrowsExchange />
          Transferable
        </span>
      )}
      {asset.is_burnable && (
        <span className={classes.propertyWrapper}>
          <MdOutlineLocalFireDepartment />
          Burnable
        </span>
      )}
      {!asset.is_transferable && !asset.is_burnable && "None"}
    </span>
  );

  const getTemplateId = (asset) => asset.template && asset.template.template_id;

  const getInfoValue = (itemData, key, bundleData, templateStats) => {
    let value = null;
    if (key === "Asset ID")
      value = <span className={classes.offerId}>{`#${data.asset_id}`}</span>;
    if (key === "Offer ID")
      value = <span className={classes.offerId}>{`#${data.offer_id}`}</span>;
    if (key === "Auction ID")
      value = <span className={classes.offerId}>{`#${data.auction_id}`}</span>;
    if (key === "Mint Number")
      value = getMintNumberString(itemData, templateStats);
    if (key === "Backed Token")
      value = getBackendTokensString(itemData.backed_tokens);
    if (key === "Collection Name")
      value = getLink(
        itemData.collection.name,
        moveToDetailsPage,
        "collections",
        itemData.collection.collection_name
      );
    if (key === "Schema Name")
      value = itemData.schema
        ? getLink(
            itemData.schema.schema_name,
            moveToDetailsPage,
            "schema",
            itemData.schema.schema_name,
            itemData.collection.collection_name
          )
        : "None";
    if (key === "Template ID")
      value = getTemplateId(itemData)
        ? getLink(
            `#${getTemplateId(itemData)}`,
            moveToDetailsPage,
            "templates",
            getTemplateId(itemData)
          )
        : "None";
    if (key === "Properties") value = getProperties(itemData);
    if (key === "Bundle Size") value = `${bundleData.length} NFTs`;
    return value;
  };

  const isNotValidKeys = (key, isSalePage, isAuctionPage) => {
    return (
      (key === "Asset ID" && (isSalePage || isAuctionPage)) ||
      (key === "Offer ID" && (isAuctionPage || !isSalePage)) ||
      (key === "Auction ID" && (isSalePage || !isAuctionPage))
    );
  };

  const getInfoAssetsDetails = (
    data,
    isBundle,
    bundleData,
    isSalePage,
    isAuctionPage,
    templateStats
  ) => {
    const keys = isBundle
      ? saleDetailsConst.INFO_KEYS_BUNDLE
      : saleDetailsConst.INFO_KEYS;
    const details = keys.reduce((infoArr, key) => {
      if (isNotValidKeys(key, isSalePage, isAuctionPage)) {
        return [...infoArr];
      }

      return [
        ...infoArr,
        { key, value: getInfoValue(data, key, bundleData, templateStats) },
      ];
    }, []);

    setInfoAssetsDetails(details);
  };

  useEffect(() => {
    if (data) {
      const itemData = isSalePage || isAuctionPage ? data.assets[0] : data;
      const collectionName = itemData.collection.collection_name;
      const templateId = getTemplateId(itemData);
      (async () => {
        const stats = await apiServices.getTemplateStats(
          collectionName,
          templateId
        );
        setTemplateStats(stats);
        setIsStatsLoading(false);
      })();
    }
  }, [data, isBundle, bundleData]);

  useEffect(() => {
    if (!isStatsLoading) {
      const itemData = isSalePage || isAuctionPage ? data.assets[0] : data;
      getInfoAssetsDetails(
        itemData,
        isBundle,
        bundleData,
        isSalePage,
        isAuctionPage,
        templateStats
      );
    }
  }, [isStatsLoading, templateStats]);

  const displayUsersInfo = () => {
    let usersInfo = null;
    if (assetUsersInfo)
      usersInfo = assetUsersInfo.map((item, index) => {
        return (
          <React.Fragment key={item.name + index}>
            <DetailsOwnerInfo
              img={item.img}
              rank={item.rank}
              name={item.name}
              rankIcon={item.rankIcon}
              collectionName={item.collectionName}
            />
          </React.Fragment>
        );
      });
    return usersInfo;
  };

  return (
    <div className={classes.infoMainContainer}>
      {displayUsersInfo()}
      <div className={classes.infoAssetsDetailsMainContainer}>
        {infoAssetsDetails && infoAssetsDetails.length
          ? infoAssetsDetails.map((item, index) => {
              return (
                <div
                  className={classes.infoAssetsDetail}
                  key={item.value + index}
                >
                  {item.key}
                  <span>{item.value}</span>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default InfoTab;
