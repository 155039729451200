// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_primaryButton__1r13o {\r\n outline: none;\r\n border: 2px solid #ded2b0;\r\n background: #ded2b0;\r\n border-radius: 26px;\r\n padding: 10px 15px;\r\n font-size: 14px;\r\n color: #000;\r\n font-weight: 700;\r\n cursor: pointer;\r\n}\r\n.Button_secondaryButton__122dY {\r\n outline: none;\r\n border: 2px solid #ded2b0;\r\n background: none;\r\n border-radius: 26px;\r\n padding: 10px 15px;\r\n font-size: 14px;\r\n color: #000;\r\n font-weight: 700;\r\n cursor: pointer;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,yBAAyB;CACzB,mBAAmB;CACnB,mBAAmB;CACnB,kBAAkB;CAClB,eAAe;CACf,WAAW;CACX,gBAAgB;CAChB,eAAe;AAChB;AACA;CACC,aAAa;CACb,yBAAyB;CACzB,gBAAgB;CAChB,mBAAmB;CACnB,kBAAkB;CAClB,eAAe;CACf,WAAW;CACX,gBAAgB;CAChB,eAAe;AAChB","sourcesContent":[".primaryButton {\r\n outline: none;\r\n border: 2px solid #ded2b0;\r\n background: #ded2b0;\r\n border-radius: 26px;\r\n padding: 10px 15px;\r\n font-size: 14px;\r\n color: #000;\r\n font-weight: 700;\r\n cursor: pointer;\r\n}\r\n.secondaryButton {\r\n outline: none;\r\n border: 2px solid #ded2b0;\r\n background: none;\r\n border-radius: 26px;\r\n padding: 10px 15px;\r\n font-size: 14px;\r\n color: #000;\r\n font-weight: 700;\r\n cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryButton": "Button_primaryButton__1r13o",
	"secondaryButton": "Button_secondaryButton__122dY"
};
export default ___CSS_LOADER_EXPORT___;
