import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classes from "./CollectionDetails.module.css";
import PageWrapper from "../../components/PageWrapper";
import ApiServices from "../../services/ApiServices";
import CollectionMainInfoCard from "../../components/CollectionDetails/CollectionMainInfoCard";
import Button from "../../components/Button/Button";
import CollectionFullInfo from "../../components/CollectionDetails/CollectionFullInfo";
import Loader from "../../components/UI/Loader";

const apiServices = new ApiServices();

const CollectionDetails = ({ ual, history }) => {
  const [collectionName, setCollectionName] = useState(null);
  const [collectionStats, setCollectionStats] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const goBackHandler = () => history.goBack();

  const getCollectionName = () => {
    const path = window.location.pathname;
    const splittedPath = path.split("/");
    if(splittedPath && splittedPath.length){
      setCollectionName(splittedPath[2]);
    }
  }

  const getCollectionStats = async (name) => {
    const stats = await apiServices.getCollectionStats(name);
    if(stats) {
      setCollectionStats(stats);
    }
  }

  const getCollectionData = async (name) => {
    setLoading(true);
    const data = await apiServices.getCollectionData(name);
    if(data) {
      setCollectionData(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    getCollectionName();
  }, []);

  useEffect(() => {
    if(collectionName) {
      (async () => await getCollectionData(collectionName))();
      (async () => await getCollectionStats(collectionName))();
    }
  }, [collectionName]);

  return (
    <PageWrapper>
      <Button secondary onClick={goBackHandler}>
        <div className={classes.goBackBtn}>
          <i className="fas fa-arrow-left"></i>
          {' '}
          Go back
        </div>
      </Button>
      {
        loading ?
            <Loader loading={loading}/>
          :
            <div className={classes.pageSecondWrapper}>
              <CollectionMainInfoCard
                collectionData={collectionData}
                collectionStats={collectionStats}
                history={history}
              />
              <CollectionFullInfo 
                collectionData={collectionData}
                collectionStats={collectionStats}
                history={history}
              />
            </div>
      }
    </PageWrapper>
  );
}

export default withRouter(CollectionDetails);
