import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ErrorModal from "../../components/Model/ErrorModal";
import NFTsSuccessModal from "../../components/Model/NFTsSuccessModal";
import PushNotification from "../../components/Model/PushNotification";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import ApiServices from "../../services/ApiServices";
import BlockchainService from "../../services/BlockchainService";
import { REMOVE_SALE_DETAILS } from "../../stores/reducers/saleReducer";
const blockchainService = new BlockchainService();
const apiServices = new ApiServices();
const EscterPayScreen = ({ cartdata, ual }) => {
  const { id } = useParams();
  const [cartId, setCartId] = useState("");
  const [saleId, setSaleId] = useState("");
  const [saleDetails, setSaleDetails] = useState("");
  const [title, setTitle] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("cartdata", cartdata);
  useEffect(() => {
    let data = {
      cartId: id,
    };
    setCartId(id);
    if (!cartdata) {
      history.push("/");
      return;
    } else {
      setSaleDetails(cartdata);
    }

    const toggleEcsterPayScreen = (data) => {
      /*global EcsterPay*/
      EcsterPay.start({
        cartKey: data.cartId,
        shopTermsUrl: "https://zepta-user.quillhash.com/privacy-policy",
        showCart: true,
        showPaymentResult: true,
        showDelivery: true,
        onCheckoutStartInit: (data) => {},
        onCheckoutStartSuccess: async (data) => {},
        onCheckoutStartFailure: (data) => {
          history.push("/");
          console.log("not done");
        },
        onPaymentSuccess: async () => {
          await apiServices.confirmEcsterPayment(data.cartId);
          const trasferToken = await blockchainService.tranferTokenToUser(
            blockchainService.getAccountName(ual),
            cartdata.amount,
            `${process.env.REACT_APP_TOKEN_SYMBOL} token  transfer to the user ${ual.activeUser?.accountName}`
          );
          if (trasferToken.success) {
            window.scrollTo(0, 0);
            const result = await blockchainService.pushTransaction(
              cartdata.action,
              ual,
              cartdata.saleData,
              cartdata.fee
            );
            if (result.success) {
              setTitle("The NFT is purchased successfully");
              PushNotifications();
            }
          } else {
            setTitle("something Went Wrong please try again");
            PushNotifications();
          }
        },
        onPaymentFailure: (data) => {
          history.push("/");
          console.log("not done");
        },
        onPaymentDenied: (data) => {
          history.push("/");
          console.log("not done");
        },
      });
    };
    toggleEcsterPayScreen(data);
  }, []);
  const PushNotifications = () => {
    window.scrollTo(0, 0);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
      dispatch(REMOVE_SALE_DETAILS());
      history.push("/");
    }, 10000);
  };
  return (
    <>
      <div className="wrapper container h-100 ecsterPay">
        <div id="ecster-pay-ctr"></div>
      </div>
      <Backdrop
        show={showNotification}
        onClick={() => setShowNotification(false)}
        animation={showNotification}
      />
      <ErrorModal
        close={() => setShowNotification(false)}
        errorMsg={title}
        showModal={
          title === "The NFT is purchased successfully"
            ? false
            : showNotification
        }
      />
      <NFTsSuccessModal
        close={() => setShowNotification(false)}
        msgType={title}
        showModal={
          title === "The NFT is purchased successfully"
            ? showNotification
            : false
        }
        fiat={true}
      />
    </>
  );
};

export default EscterPayScreen;
