export const NETWORKS = [ 
  {
    network: 'linkedin',
    to: 'https://www.linkedin.com/company/zeptagram/?originalSubdomain=se'
  },
  {
    network: 'instagram',
    to: 'https://www.instagram.com/zeptagramab/?igshid=1ixlp9l5bisks'
  },
  {
    network: 'facebook',
    to: 'https://www.facebook.com/zeptagram/'
  },
  {
    network: 'twitter',
    to: 'https://twitter.com/zeptagram'
  },
  {
    network: 'telegram',
    to: 'https://t.me/zeptagram_support'
  },
];