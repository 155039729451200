import React, { useEffect, useState } from "react";
import classes from "./TechnicalInfoSection.module.css";
import DropdownMenu from "../../../../DropdownMenu";
import { useHistory } from "react-router-dom";

const TechnicalInfoSection = ({ collectionData }) => {
  const [authorizedAccounts, setAuthorizedAccounts] = useState([]);
  const [notifyAccounts, setNotifyAccounts] = useState([]);

  const history = useHistory();

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }

  useEffect(() => {
    if(collectionData) {
      setAuthorizedAccounts(collectionData.authorized_accounts);
      setNotifyAccounts(collectionData.notify_accounts);
    }
  }, [collectionData]);

  return (
    <div className={classes.sectionWrapper}>
      <h2>Technical Info</h2>
      <div className={classes.menusWrapper}>
        <DropdownMenu items={authorizedAccounts} onItemClick={moveToUserProfile} title="Authorized Account" />
        <DropdownMenu items={notifyAccounts} onItemClick={moveToUserProfile} title="Notify Accounts" />
      </div>
    </div>
  );
}

export default TechnicalInfoSection;
