import React from 'react';
import classes from './TemplateCard.module.css';
import { useHistory } from "react-router-dom";
import useImageLoaded from '../../hooks/useImageLoaded';
import AppServices from '../../services/AppService';
import ImageIcon from "../../assets/img/image.png";

// const RESIZER_URL = '//images.weserv.nl/?url=';
const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";


const appService = new AppServices();

const TemplateCard = ({ content, collectionName, id, name, assets, index = 1 }) => {
  const [ref, loaded, onLoad] = useImageLoaded();
  const history = useHistory();

  const navigateToScreen = (templateId) => {
    history.push(`/templates/${templateId}`);
    window.scrollTo(0, 0);
  };

  const getImgUrl = () =>  appService.getIpfsUrl(content);

  const reloadImg = (e) => {
    if(e.target.src.includes(RESIZER_URL))
      e.target.src = getImgUrl(content);
  }
  
  return (
    <div className={classes.cardWrapper} key={index} onClick={() => navigateToScreen(id)}>
      <div className={`${classes.imgWrapper} ${loaded ? null : content ? classes.loading : null}`}>
        {
          content ? (
            <img
              ref={ref}
              src={content?.includes("http")
              ? getImgUrl(content)
              : `${RESIZER_URL}${content}&size=200`} 
              onLoad={onLoad} alt="assetsImg"
              loading="lazy"
              onError={(e) => reloadImg(e)}
            />
          ) : (
            <div className={classes.iconWrapper}>
              <img src={ImageIcon} alt='' />
            </div>
          )
        }
      </div>
      <div className={classes.collectionWrapper}>
        <p className={classes.collection}>
          {collectionName}
        </p>
      </div>
      <p className={classes.templateId}>
        {`#${id}`}
      </p>
      <p className={classes.templateName}>
        {name}
      </p>
      <p className={classes.assetsNum}>
        {`${assets} Assets`}
      </p>
    </div>
  );
};

export default TemplateCard;
