import classes from "./NetworksBlockChainsElement.module.css"

export default function NetworksBlockChainsElement(props) {
    const setMainnetUrlToLocalStorage = () => localStorage.setItem('MainnetUrl', props.linkMainnet)
    const setTestnetUrlToLocalStorage = () => localStorage.setItem('TestnetUrl', props.linkTestnet)


    return (
        <div className={classes.mainContainer}>
            <a href={props.linkMainnet} className={classes.linkToMainnet} onClick={setMainnetUrlToLocalStorage}>
                <div className={classes.NetworkBlochainsElement_Container}>
                    <div className={classes.imageContainer}>
                        <img src={props.image} alt={props.alt}/>
                    </div>
                    <div className={classes.descriptionContainer}>
                        {props.description}
                    </div>
                </div>
            </a>
            <a href={props.linkTestnet} className={classes.linkTesttnet} onClick={setTestnetUrlToLocalStorage}>Testnet</a>
        </div>
    )
}