// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HowItWorks_howItWorksMainContainer__2K7_M {\n  width: 85%;\n  max-width: 1200px;\n  margin: 80px auto;\n}\n.HowItWorks_howItWorksMainContainer__2K7_M > h1 {\n  font-size: 36px;\n  color: #23262f;\n  font-weight: bold;\n  text-align: center;\n}\n.HowItWorks_accordionContainer__2uyNQ {\n  margin-top: 50px;\n}\n", "",{"version":3,"sources":["webpack://src/screens/HowItWorks/HowItWorks.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".howItWorksMainContainer {\n  width: 85%;\n  max-width: 1200px;\n  margin: 80px auto;\n}\n.howItWorksMainContainer > h1 {\n  font-size: 36px;\n  color: #23262f;\n  font-weight: bold;\n  text-align: center;\n}\n.accordionContainer {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"howItWorksMainContainer": "HowItWorks_howItWorksMainContainer__2K7_M",
	"accordionContainer": "HowItWorks_accordionContainer__2uyNQ"
};
export default ___CSS_LOADER_EXPORT___;
