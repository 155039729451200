import React, { useEffect, useState } from "react";
import classes from "./TopSaleCard.module.css";
import AssetImage from "../../../../UserAssetsCard/AssetImage";
import AssetVideo from "../../../../UserAssetsCard/AssetVideo";
import AssetAudio from "../../../../UserAssetsCard/AssetAudio";
import PriceBox from "../../../../UI/PriceBox/PriceBox";
import AppServices from "../../../../../services/AppService";

const LIMIT = 18;
const appService = new AppServices();

const TopSaleCard = ({data, index}) => {
  const [type, setType] = useState(null);
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(null);
  const [usdPrice, setUsdPrice] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);

  const formatTemplateName = (name, limit) =>
    name && name.length < limit ? name : name ? `${name.substring(0, limit)}...` : "";

  const getContent = (asset) => {
    let type = "";
    let content = "";

    if (asset.data.img) {
      type = "img";
      content = asset.data.img;
    } else if (asset.data.video) {
      type = "video";
      content = asset.data.video;
    } else if (asset.data.audio) {
      type = "audio";
      content = asset.data.audio;
    }
    setTitle(asset.data.name)
    setContent(content);
    setType(type);
    if(asset.prices && asset.prices[0]) {
      const tokenAmount = asset.prices[0].max;
      const tokenPrecision = asset.prices[0].token.token_precision;
      const nftPrice = +tokenAmount / Math.pow(10, tokenPrecision);
      const usd = nftPrice * (appService.getUsdPrice() ?? 1);
      setPrice(nftPrice);
      setUsdPrice(usd);
      setTokenSymbol(asset.prices[0].token.token_symbol);
    }
  };

  const getAssetContentView = (type, content) => {
  
    if (type === "img") {
      return (
        <AssetImage content={content} />
      );
    } else if (type === "video") {
      return (
        <AssetVideo content={content} />
      );
    } else if (type === "audio") {
      return (
        <AssetAudio content={content} />
      );
    }
  };

  useEffect(() => {
    if(data && data.assets[0]) {
      getContent(data.assets[0]);
    }
  }, [data]);

  return (
    <div className={classes.cardWrapper} key={index} >
      <div className={classes.contentWrapper} >
        {getAssetContentView(
          type,
          content
        )}
      </div>
      <p className={classes.cardTitle}>{formatTemplateName(title, LIMIT)}</p>
      <div className={classes.priceWrapper}>
        <PriceBox price={price} symbol={tokenSymbol} />
        <PriceBox price={usdPrice?.toFixed(2)} symbol="$" />
      </div>
    </div>
  );
}

export default TopSaleCard;
