import React, { Component } from "react";
import classes from "./Navbar.module.css";
import Logo from "../../../assets/img/zeptagramLogo.png";
import NavigationItems from "./NavigationItems/NavigationItems";
import SearchBar from "./Searchbar/SearchBar";
import Button from "../../Button/Button";
import { Link, withRouter } from "react-router-dom";
import AppService from "../../../services/AppService";
import Backdrop from "../../UI/Backdrop/Backdrop";
import CoinIcon from "../../../assets/img/coinIconOrange.svg";
import MyProfileIcon from "../../../assets/img/myProfileIcon.svg";
import MyNFTsIcon from "../../../assets/img/myNFTsIcon.svg";
import LogoutIcon from "../../../assets/img/logoutIcon.svg";
import BlockchainService from "../../../services/BlockchainService";
import ApiServices from "../../../services/ApiServices";
import NetsDropdown from "../../NetsDropdown";
import BuyWaxModal from "../../Model/BuyWaxModal";
import FirebaseService from "../../../services/FirebaseService";
import waxLogo from "../../../assets/img/wax-waxp-logo.svg";
import Demo from "../../../assets/img/demo.webp";
import Tooltip from "../../UI/Tooltip";
import NetworkSwitchModal from "../../Model/NetworkSwitchModal";

const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
const LIMIT = 14;
const LIMIT_BACKDROP = 9;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      userDropDown: false,
      showMenuDropDown: false,
      showMenu: false,
      animation: false,
      dimension: 0,
      userImg: "",
      userBalance: 0,
      userName: "",
      userAddress: "",
      dropSelected: "WAX",
      dropOptions: ["WAX", "Telos", "Wax Testnet", "Telos Testnet"],
      showModal: false,
      showNetworkModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.refUserDrop = React.createRef();
    document.addEventListener("mousedown", this.handleClickOutsideDrop);

    this.appService = new AppService();
    this.apiService = new ApiServices();
    this.blochainService = new BlockchainService();
    this.firebaseService = new FirebaseService();

    this.waxUrl = "https://wax.zeptagram.com/";
    this.telosUrl = "https://telos.zeptagram.com/";
    this.waxTestUrl = "https://wax-testnet.zeptagram.com/";
    this.telosTestUrl = "https://telos-testnet.zeptagram.com/";
  }

  async componentDidMount() {
    this.networkSwitch();
    await this.setUsdPrice();
    await this.getUserData();
    window.addEventListener("resize", this.updateWindowDimensions);

    let currentUrl = window.location.href;
    if (currentUrl.toLocaleLowerCase().includes("wax"))
      this.setState({ dropSelected: "WAX" });
    if (currentUrl.toLocaleLowerCase().includes("telos"))
      this.setState({ dropSelected: "Telos" });
    if (currentUrl.toLocaleLowerCase().includes("wax-testnet"))
      this.setState({ dropSelected: "Wax Testnet" });
    if (currentUrl.toLocaleLowerCase().includes("telos-testnet"))
      this.setState({ dropSelected: "Telos Testnet" });
  }
  updateWindowDimensions = () => {
    this.setState({ dimension: window.innerWidth });
    if (this.state.dimension > 850) {
      this.setState({ showMenu: false, showMenuDropDown: false });
    }
  };
  handleClickOutsideDrop = (event) => {
    if (
      this.refUserDrop.current &&
      !this.refUserDrop.current.contains(event.target)
    ) {
      this.setState({ userDropDown: false });
    }
  };

  showBuyWaxModal = () => {
    this.setState({ showModal: true });
    this.setState({ userDropDown: false });
  };
  closeModal() {
    this.setState({ showModal: false, showNetworkModal: false });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("mousedown", this.handleClickOutsideDrop);
  }

  loginHandler = async () => {
    this.setState({
      userDropDown: false,
      showMenu: false,
      showMenuDropDown: false,
    });
    await this.appService.login(this.props.ual);
    await this.getUserData();
  };

  getUserData = async () => {
    if (this.props.ual.activeUser != null) {
      let { userAddress, userName, userImg, userBalance } = "";
      userAddress = await this.blochainService.getAccountName(this.props.ual);

      //Get UserName from firebase
      userName = userAddress;

      const userInfo = await this.firebaseService.getUser(userName);

      if (userInfo) {
        const data = userInfo.data();
        if (data) {
          userImg = data?.image ?? "";
          userName = data?.name ?? userName ?? "";
        }
      }

      //Get userBalance from blockchain
      userBalance = await this.blochainService.getUserBalance(userAddress);
      this.setState({ userAddress, userName, userImg, userBalance });
    }
  };

  logout = async () => {
    await this.appService.logout(this.props.ual);
    this.setState({
      userDropDown: false,
      showMenu: false,
      showMenuDropDown: false,
    });
    window.location.href = "/";
  };

  setUsdPrice = async () => {
    let usdPrice = await this.apiService.getTokenPrice();
    if (usdPrice && usdPrice.USD) this.appService.setUsdPrice(usdPrice.USD);
  };

  componentDidUpdate(prevProps) {
    if (this.props.ual.activeUser !== prevProps.ual.activeUser) {
      this.getUserData();
      let currentPath = window.location.pathname;
      if (currentPath === "/login")
        this.props.history.replace(window.history?.state?.state?.path ?? "/");
    }
  }

  selectNetwork = (item) => {
    this.setState({ dropSelected: item });
    if (item.toString().toLowerCase() === "wax testnet")
      window.location.href = this.waxTestUrl;
    if (item.toString().toLowerCase() === "telos testnet")
      window.location.href = this.telosTestUrl;
    if (item.toString().toLowerCase() === "wax")
      window.location.href = this.waxUrl;
    if (item.toString().toLowerCase() === "telos")
      window.location.href = this.telosUrl;
  };
  networkSwitch = () => {
    if (window.location.href !== "https://nft.zeptagram.com/") return;
    const url =
      localStorage.getItem("MainnetUrl") || localStorage.getItem("TestnetUrl");
    url
      ? (window.location.href = url)
      : this.setState({ showNetworkModal: true });
  };
  render() {
    return (
      <>
        <div className={classes.navBarFirstContainer}>
          <div className={classes.navBarMainContainer}>
            <section className={classes.leftSection}>
              <div className={classes.logoContainer}>
                <Link to="/">
                  <img src={Logo} alt="Zeptagram-navbar-logo" />
                </Link>
              </div>
              <ul>
                <NavigationItems title="Explorer" link="/explorer" />
                <NavigationItems title="Market" link="/market" />
                <NavigationItems title="NFT Creator" link="/nft-creator" />
                <NavigationItems title="How it works" link="/works" />
              </ul>
            </section>
            <section className={classes.leftSection}>
              <div className={classes.searchContainer}>
                <SearchBar
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
              </div>
              <div className={classes.navDropContainer}>
                <NetsDropdown
                  height="auto"
                  circleHeight="20px"
                  circleWidth="25px"
                  itemFontSize="12px"
                  selected={this.state.dropSelected}
                  options={this.state.dropOptions}
                  setSelected={this.selectNetwork}
                />
              </div>
              <div className={classes.navBarBtnContainer}>
                {this.props.ual.activeUser != null ? (
                  <div
                    className={classes.authUserContainerMainContainer}
                    ref={this.refUserDrop}
                  >
                    <div
                      className={classes.authUserContainer}
                      onClick={() =>
                        this.setState({
                          userDropDown: !this.state.userDropDown,
                        })
                      }
                    >
                      {this.state.userImg ? (
                        <img src={this.state.userImg} alt="user" />
                      ) : (
                        <i className="fas fa-user-circle"></i>
                      )}
                      <h1>
                        {this.state.userBalance}
                        <span>{tokenSymbol}</span>
                      </h1>
                    </div>
                    {this.state.userDropDown && (
                      <ul>
                        <div className={classes.authUserDetailsContainer}>
                          {this.state.userName &&
                          this.state.userName.length > LIMIT ? (
                            <Tooltip
                              id="user-name"
                              tip={this.state.userName}
                              string={this.state.userName}
                              limit={LIMIT}
                              isNavbar
                            />
                          ) : (
                            <h1>{this.state.userName}</h1>
                          )}
                          <h2>
                            {this.state.userAddress &&
                            this.state.userAddress.length > 12
                              ? this.state.userAddress.substring(0, 6) +
                                "..." +
                                this.state.userAddress.substring(
                                  this.state.userAddress.length - 6
                                )
                              : this.state.userAddress}
                            <img src={CoinIcon} alt="coinIcon" />
                          </h2>
                          <div
                            className={classes.authUserDetailsBalanceContainer}
                          >
                            <div className={classes.authUserLogoContainer}>
                              <img src={Logo} alt="zeptagram-logo" />
                            </div>
                            <h3>
                              <span>Balance</span>
                              {this.state.userBalance} {tokenSymbol}
                            </h3>
                          </div>
                          {tokenSymbol === "WAX" && (
                            <li onClick={() => this.showBuyWaxModal()}>
                              <img src={waxLogo} alt=" buy wax" />
                              BUY WAX
                            </li>
                          )}

                          <li>
                            <a
                              href={"/profile/" + this.state.userAddress}
                              onClick={() =>
                                this.setState({ userDropDown: false })
                              }
                            >
                              <img src={MyProfileIcon} alt="profile" />
                              My profile
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "/profile/" +
                                this.state.userAddress +
                                "?filter=Created"
                              }
                              onClick={() =>
                                this.setState({ userDropDown: false })
                              }
                            >
                              <img src={MyNFTsIcon} alt="NFTs" />
                              My NFT's
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href={
                                " https://www.youtube.com/watch?v=H25po8jXitU&list=RDMMnCS0bC62PjE&index=6"
                               }
                               target="_blank"
                             
                            >
                              <img src={Demo} alt="NFTs" />
                           Create NFT's Demo 
                            </a>
                          </li> */}
                          <li onClick={() => this.logout()}>
                            <img src={LogoutIcon} alt="logout" />
                            Logout
                          </li>
                        </div>
                      </ul>
                    )}
                  </div>
                ) : (
                  <Button secondary onClick={this.loginHandler}>
                    <div>Login</div>
                  </Button>
                )}
              </div>
              <a
                href={process.env.REACT_APP_DNFT_URL}
                className={classes.dnftButton}
                target="_blank"
              >
                DNFT'S
              </a>
            </section>
            <div
              className={classes.menuBtnContainer}
              style={
                this.state.showMenu ? { boxShadow: "0 0 3px #dc3825" } : null
              }
              onClick={() => {
                this.setState({
                  showMenu: !this.state.showMenu,
                  animation: true,
                });
              }}
            >
              <i className="fas fa-bars"></i>
            </div>
          </div>
          <div className={classes.navbarDivider} />
        </div>
        <div className={classes.menuMainContainer}>
          <Backdrop
            show={this.state.showMenu}
            animation={this.state.animation}
            onClick={() =>
              this.setState({ showMenu: false, showMenuDropDown: false })
            }
          />
          <ul
            className={classes.menuItemsContainer}
            style={
              this.state.showMenu
                ? { transform: "rotate(0deg)", opacity: 1 }
                : { transform: "rotate(-90deg)", opacity: 0 }
            }
          >
            <li>
              <Link
                to="/explorer"
                onClick={() =>
                  this.setState({ showMenu: false, showMenuDropDown: false })
                }
              >
                Explorer
              </Link>
              <Link
                to="/market"
                onClick={() =>
                  this.setState({ showMenu: false, showMenuDropDown: false })
                }
              >
                Market
              </Link>
              <Link
                to="/nft-creator"
                onClick={() =>
                  this.setState({ showMenu: false, showMenuDropDown: false })
                }
              >
                NFT Creator
              </Link>

              <Link
                to="/works"
                onClick={() =>
                  this.setState({ showMenu: false, showMenuDropDown: false })
                }
              >
                How it Works
              </Link>
              <button>
                {this.props.ual.activeUser != null ? (
                  <>
                    <div className={classes.showMenuUserContainer}>
                      <div
                        className={classes.userMenuWrapper}
                        onClick={() =>
                          this.setState({
                            showMenuDropDown: !this.state.showMenuDropDown,
                          })
                        }
                      >
                        {this.state.userImg ? (
                          <img src={this.state.userImg} alt="user" />
                        ) : (
                          <i className="fas fa-user-circle"></i>
                        )}
                        {this.state.userName &&
                        this.state.userName.length > 14 ? (
                          <Tooltip
                            id="user-name-drop-down"
                            tip={this.state.userName}
                            string={this.state.userName}
                            limit={LIMIT_BACKDROP}
                            isBackdropMenu
                          />
                        ) : (
                          <h1>{this.state.userName}</h1>
                        )}
                        <i
                          className="fas fa-angle-right"
                          style={
                            this.state.showMenuDropDown
                              ? { transform: "rotate(90deg)" }
                              : null
                          }
                        ></i>
                      </div>
                      {this.state.showMenuDropDown && (
                        <div className={classes.showMenuDropDownContainer}>
                          <h2>
                            {this.state.userBalance} <span>{tokenSymbol}</span>
                          </h2>
                          <h2>
                            {this.state.userAddress &&
                            this.state.userAddress.length > 12
                              ? this.state.userAddress.substring(0, 6) +
                                "..." +
                                this.state.userAddress.substring(
                                  this.state.userAddress.length - 6
                                )
                              : this.state.userAddress}
                          </h2>
                          {tokenSymbol === "WAX" && (
                            <div
                              style={{ marginTop: 30 }}
                              onClick={() => {
                                this.setState({ showMenu: false });
                                this.showBuyWaxModal();
                              }}
                            >
                              Buy Wax
                            </div>
                          )}

                          <a
                            href={"/profile/" + this.state.userAddress}
                            onClick={() =>
                              this.setState({
                                showMenu: false,
                                showMenuDropDown: false,
                              })
                            }
                          >
                            My profile
                          </a>
                          <a
                            href={
                              "/profile/" +
                              this.state.userAddress +
                              "?filter=Created"
                            }
                            onClick={() =>
                              this.setState({
                                showMenu: false,
                                showMenuDropDown: false,
                              })
                            }
                          >
                            My NFT's
                          </a>
                          {/* <a
                            href={
                             " https://www.youtube.com/watch?v=H25po8jXitU&list=RDMMnCS0bC62PjE&index=6"
                            }
                            target="_blank"
                           
                          >
                         Create NFT's Demo 
                          </a> */}
                        </div>
                      )}
                      <div
                        style={{ marginTop: 30 }}
                        onClick={() => this.logout()}
                      >
                        Logout
                      </div>
                    </div>
                  </>
                ) : (
                  <div onClick={this.loginHandler}>Login</div>
                )}
              </button>
              <a href={process.env.REACT_APP_DNFT_URL} target="_blank">
                DNFT'S
              </a>
            </li>
            <i
              className="fas fa-times"
              onClick={() =>
                this.setState({ showMenu: false, showMenuDropDown: false })
              }
            ></i>
          </ul>
        </div>
        <div className={classes.navbarDivider} />
        <Backdrop
          show={this.state.showModal || this.state.showNetworkModal}
          onClick={this.closeModal}
        />
        <BuyWaxModal
          close={this.closeModal}
          userBalance={this.state.userBalance}
          userAddress={this.state.userAddress}
          showModal={this.state.showModal}
        />
        <NetworkSwitchModal
          showModal={this.state.showNetworkModal}
          close={this.closeModal}
        />
      </>
    );
  }
}

export default withRouter(Navbar);
