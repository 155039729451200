/* eslint-disable no-unused-expressions */
import { useState } from "react";
import AppService from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import MODAL_CONSTANTS from "../../constants/Modal";

const appService = new AppService();
const blockchainService = new BlockchainService();

const useModals = (
  ual,
  getData,
  itemId,
  assetsData,
  ppbCardData,
  currentTokenSymbol,
  tokenPrecision
) => {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPurchaseNFTModal, setShowPurchaseNFTModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [modalAnimation, setModalAnimation] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentPurchasePath, setCurrentPurchasePath] = useState(null);
  const [listingData, setListingData] = useState({
    price: "0.0001",
    duration: 5 * 60,
  });
  const [showAuctionModal, setShowAuctionModal] = useState(false);
  const [auctionDuration, setAuctionDuration] = useState({
    days: "",
    hours: "",
    minutes: "",
  });
  const [showListOnSaleModal, setShowListOnSaleModal] = useState(false);
  const [authMsg, setAuthMsg] = useState("");
  const [showPlaceBidModal, setShowPlaceBidModal] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [removeMsg, setRemoveMsg] = useState("");
  const [showUpdatePriceAuctionModal, setShowUpdatePriceAuctionModal] =
    useState();

  const showModalHandler = (setShow) => {
    document.body.style.overflow = "hidden";
    setShow(true);
    setShowBackdrop(true);
  };

  const checkUserAuthentication = (isSalePage, isAuctionPage) => {
    const activeUser = ual.activeUser;
    if (activeUser !== null && isSalePage) {
      setAuthMsg(MODAL_CONSTANTS.AUTH_MODAL_PURCHASE);
      showModalHandler(setShowPurchaseNFTModal);
    } else if (activeUser !== null && isAuctionPage) {
      setAuthMsg(MODAL_CONSTANTS.AUTH_MODAL_AUCTION);
      showModalHandler(setShowPlaceBidModal);
    } else {
      showModalHandler(setShowAuthModal);
    }
  };

  const createShowModalArray = () => [
    setShowPurchaseNFTModal,
    setShowSuccessModal,
    setShowAuthModal,
    setShowErrorModal,
    setShowBackdrop,
    setShowModal,
    setShowModal1,
    setShowModal2,
    setShowRemoveModal,
    setShowUpdatePriceModal,
    setShowAuctionModal,
    setShowListOnSaleModal,
    setShowPlaceBidModal,
    setShowPlaceBidModal,
    setShowUpdatePriceAuctionModal,
  ];

  const closeModalHandler = () => {
    console.log("close");
    document.body.style.overflow = "initial";
    setModalAnimation(true);
    const showModalArray = createShowModalArray();
    showModalArray.map((setModal) => setModal(false));
  };

  const loginHandler = async () => {
    try {
      await appService.login(ual);
    } catch (error) {
    } finally {
      closeModalHandler();
    }
  };

  const updatePrice = async () => {
    closeModalHandler();
    let newSaleId = +itemId;
    let assetId = +assetsData.assetId;
    let userAccount = assetsData.owner;
    let listingPrice =
      (+listingData.price).toFixed(tokenPrecision) + " " + currentTokenSymbol;
    let settlementSymbol = `${tokenPrecision},${currentTokenSymbol}`;

    if (assetId !== "" && userAccount !== "") {
      let cancelSaleAction = blockchainService.cancelSaleAction(
        userAccount,
        newSaleId
      );
      let announceSaleAction = blockchainService.announceSaleAction(
        userAccount,
        assetId,
        listingPrice,
        settlementSymbol
      );
      let nftOfferAction = blockchainService.createOfferAction(
        userAccount,
        assetId
      );

      cancelSaleAction.actions.push(announceSaleAction.actions[0]);
      cancelSaleAction.actions.push(nftOfferAction.actions[0]);

      const result = await blockchainService.pushTransaction(
        cancelSaleAction,
        ual
      );
      if (result.success) {
        // alert("NFT sale priupdatePricece updated");
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_UPDATE_SELL);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
    setListingData((prevState) => {
      return { ...prevState, price: "1" };
    });
  };

  const removeFromSale = async () => {
    closeModalHandler();
    let newSaleId = +itemId;
    let userAccount = assetsData.owner;

    if (newSaleId !== "" && userAccount !== "") {
      let cancelSaleAction = blockchainService.cancelSaleAction(
        userAccount,
        newSaleId
      );

      const result = await blockchainService.pushTransaction(
        cancelSaleAction,
        ual
      );
      if (result.success) {
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_REMOVED_FROM_SELL);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const burnAsset = async () => {
    closeModalHandler();
    let assetId = assetsData.assetId;
    let userAccount = assetsData.owner;

    if (assetId !== "" && userAccount !== "") {
      let burnNFTAction = blockchainService.burnAssetAction(
        userAccount,
        assetId
      );

      const result = await blockchainService.pushTransaction(
        burnNFTAction,
        ual
      );
      if (result.success) {
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_BURN);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const totalDurationCalculation = () => {
    let days = auctionDuration.days;
    let hours = auctionDuration.hours;
    let minutes = auctionDuration.minutes;
    let totalDuration =
      (days !== "" ? parseInt(days) * (24 * 60 * 60) : 0) +
      (hours !== "" ? parseInt(hours) * (60 * 60) : 0) +
      (minutes !== "" ? parseInt(minutes) * 60 : 0);

    return totalDuration;
  };

  const getTokenSymbol = (currentSymbol) => {
    const splittedSymbol = currentSymbol.split(" / ");
    return splittedSymbol.length === 2 ? splittedSymbol[1] : currentSymbol;
  };

  const listOnAuction = async () => {
    let totalDuration = totalDurationCalculation();
    if (totalDuration === 0) {
      setErrorMsg("Duration required!");
    } else {
      closeModalHandler();
      let assetId = assetsData.assetId;
      let userAccount = assetsData.owner;
      let listingPrice =
        (+listingData.price).toFixed(tokenPrecision) +
        " " +
        getTokenSymbol(currentTokenSymbol);
      let duration = totalDuration;

      if (assetId !== "" && userAccount !== "") {
        let transferAction = blockchainService.transferNFTAction(
          userAccount,
          "atomicmarket",
          [assetId],
          "auction"
        );
        let announceAuctionAction = blockchainService.announceAuctionAction(
          userAccount,
          assetId,
          listingPrice,
          duration
        );
        announceAuctionAction.actions.push(transferAction.actions[0]);

        const result = await blockchainService.pushTransaction(
          announceAuctionAction,
          ual
        );
        if (result.success) {
          // alert("NFT listed on auction");
          setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_LIST_AUCTION);
          showModalHandler(setShowSuccessModal);
          await getData(itemId);
        } else {
          setErrorMsg(result.message);
          showModalHandler(setShowErrorModal);
        }
      }
    }
    setListingData((prevState) => {
      return { ...prevState, price: "0.0001" };
    });
  };

  const listingPriceHandler = (e) => {
    setListingData((prevState) => {
      return { ...prevState, price: e.target.value };
    });
  };

  const auctionDurationDaysHandler = (e) => {
    if (e.target.value > 30 || e.target.value < 0) {
      setAuctionDuration((prevState) => {
        e.target.value = prevState.days;
        return { ...prevState };
      });
    }
    setAuctionDuration((prevState) => {
      return { ...prevState, days: e.target.value };
    });
  };
  const auctionDurationHoursHandler = (e) => {
    if (e.target.value > 24 || e.target.value < 0) {
      setAuctionDuration((prevState) => {
        e.target.value = prevState.hours;
        return { ...prevState };
      });
    }
    setAuctionDuration((prevState) => {
      return { ...prevState, hours: e.target.value };
    });
  };
  const auctionDurationMinutesHandler = (e) => {
    if (e.target.value > 60 || e.target.value < 0) {
      setAuctionDuration((prevState) => {
        e.target.value = prevState.minutes;
        return { ...prevState };
      });
    }
    setAuctionDuration((prevState) => {
      return { ...prevState, minutes: e.target.value };
    });
  };

  const listOnSale = async () => {
    closeModalHandler();
    let assetId = +assetsData.assetId;
    let userAccount = assetsData.owner;
    let listingPrice =
      (+listingData.price).toFixed(tokenPrecision) +
      " " +
      getTokenSymbol(currentTokenSymbol);
    let settlementSymbol = `${tokenPrecision},${currentTokenSymbol}`;

    if (assetId !== "" && userAccount !== "") {
      let nftOfferAction = blockchainService.createOfferAction(
        userAccount,
        assetId
      );
      let announceSaleAction = blockchainService.announceSaleAction(
        userAccount,
        assetId,
        listingPrice,
        settlementSymbol
      );

      announceSaleAction.actions.push(nftOfferAction.actions[0]);

      var result = await blockchainService.pushTransaction(
        announceSaleAction,
        ual
      );
      if (result.success) {
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_LIST_SELL);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
    setListingData((prevState) => {
      return { ...prevState, price: "0.0001" };
    });
  };

  const placeBidNFT = async () => {
    const tokenContract = "eosio.token";
    const cardData = ppbCardData && ppbCardData.price;
    const listing = listingData;
    if (listing.price < cardData) {
      setErrorMsg("Price must be greater than previous bid price ");
    } else {
      closeModalHandler();
      let auctionId = +itemId;
      let assetId = +assetsData.assetId;
      let userAccount = await appService.getAccountName(ual);
      let listingPrice =
        (+listingData.price).toFixed(tokenPrecision) + " " + currentTokenSymbol;

      if (assetId !== "" && userAccount !== "") {
        let transferTokenAction = blockchainService.transferToken(
          userAccount,
          "atomicmarket",
          listingPrice,
          "deposit",
          tokenContract
        );
        let purchaseSaleAction = blockchainService.auctionBidAction(
          userAccount,
          auctionId,
          listingPrice
        );

        transferTokenAction.actions.push(purchaseSaleAction.actions[0]);

        const result = await blockchainService.pushTransaction(
          transferTokenAction,
          ual
        );
        if (result.success) {
          // alert("Bid placed");
          setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_PLACE_BID);
          showModalHandler(setShowSuccessModal);
          await getData(itemId);
        } else {
          setErrorMsg(result.message);
          showModalHandler(setShowErrorModal);
        }
      }
    }
    setListingData((prevState) => {
      return { ...prevState, price: "0.0001" };
    });
  };

  const claimAsset = async () => {
    closeModalHandler();
    let auctionId = +itemId;
    let userAccount = await appService.getAccountName(ual);

    if (auctionId !== "" && userAccount !== "") {
      let claimAssetAction = blockchainService.claimAssetAuctionAction(
        userAccount,
        auctionId
      );

      const result = await blockchainService.pushTransaction(
        claimAssetAction,
        ual
      );
      if (result.success) {
        // alert("Asset claimed");
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_CLAIM);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const claimBid = async () => {
    closeModalHandler();
    let auctionId = +itemId;
    let userAccount = await appService.getAccountName(ual);

    if (auctionId !== "" && userAccount !== "") {
      let claimBidAction = blockchainService.claimBidsAuctionAction(
        userAccount,
        auctionId
      );

      const result = await blockchainService.pushTransaction(
        claimBidAction,
        ual
      );
      if (result.success) {
        // alert("Bid claimed");
        setSuccessMsg(MODAL_CONSTANTS.SUCCESS_MODAL_CLAIM_BID);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const updatePriceAuction = async () => {
    closeModalHandler();
    let auctionId = +itemId;
    let assetId = +assetsData.assetId;
    let userAccount = await appService.getAccountName(ual);
    let listingPrice =
      (+listingData.price).toFixed(tokenPrecision) + " " + currentTokenSymbol;
    let duration = listingData.duration;

    if (assetId !== "" && userAccount !== "") {
      let cancelAuctionAction = blockchainService.cancelAuctionAction(
        userAccount,
        auctionId
      );

      let transferAction = blockchainService.transferNFTAction(
        userAccount,
        "atomicmarket",
        [assetId],
        "auction"
      );
      let announceAuctionAction = blockchainService.announceAuctionAction(
        userAccount,
        assetId,
        listingPrice,
        duration
      );

      cancelAuctionAction.actions.push(announceAuctionAction.actions[0]);
      cancelAuctionAction.actions.push(transferAction.actions[0]);

      const result = await blockchainService.pushTransaction(
        cancelAuctionAction,
        ual
      );
      if (result.success) {
        // alert("NFT price updated");
        showModalHandler(setShowSuccessModal);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
    setListingData((prevState) => {
      return { ...prevState, price: "1" };
    });
  };

  const removeFromAuction = async () => {
    closeModalHandler();

    let auctionId = +itemId;
    let assetId = +assetsData.assetId;
    let userAccount = await appService.getAccountName(ual);

    if (assetId !== "" && userAccount !== "") {
      let cancelAuctionAction = blockchainService.cancelAuctionAction(
        userAccount,
        auctionId
      );

      const result = await blockchainService.pushTransaction(
        cancelAuctionAction,
        ual
      );
      if (result.success) {
        // alert("NFT auction canceled");
        setSuccessMsg(MODAL_CONSTANTS.REMOVE_MODAL_AUCTION);
        showModalHandler(setShowSuccessModal);
        await getData(itemId);
      } else {
        setErrorMsg(result.message);
        showModalHandler(setShowErrorModal);
      }
    }
  };

  const remove = (isSalePage, isAuctionPage) => {
    if (isSalePage) {
      removeFromSale();
    } else if (isAuctionPage) {
      removeFromAuction();
    } else {
      burnAsset();
    }
  };

  const modalsStates = {
    showPurchaseNFTModal,
    showAuthModal,
    showBackdrop,
    errorMsg,
    setErrorMsg,
    modalAnimation,
    showErrorModal,
    setShowErrorModal,
    currentPurchasePath,
    showModal,
    showModal1,
    showModal2,
    showRemoveModal,
    showUpdatePriceModal,
    setShowModal1,
    setShowModal2,
    setShowUpdatePriceModal,
    setShowRemoveModal,
    listingData,
    setCurrentPurchasePath,
    showAuctionModal,
    setShowAuctionModal,
    auctionDuration,
    setShowListOnSaleModal,
    showListOnSaleModal,
    authMsg,
    setAuthMsg,
    successMsg,
    setSuccessMsg,
    setShowSuccessModal,
    showSuccessModal,
    removeMsg,
    setRemoveMsg,
    showPlaceBidModal,
    setShowPlaceBidModal,
    ppbCardData,
    showUpdatePriceAuctionModal,
    setShowUpdatePriceAuctionModal,
  };

  const modalsFuncs = {
    checkUserAuthentication,
    showModalHandler,
    closeModalHandler,
    loginHandler,
    updatePrice,
    listingPriceHandler,
    auctionDurationDaysHandler,
    auctionDurationHoursHandler,
    auctionDurationMinutesHandler,
    totalDurationCalculation,
    listOnAuction,
    listOnSale,
    getTokenSymbol,
    remove,
    placeBidNFT,
    claimBid,
    claimAsset,
    updatePriceAuction,
  };

  return [modalsStates, modalsFuncs];
};

export default useModals;
