import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import classes from "./Search.module.css";
import SearchPageContent from "../../components/SearchPage/SearchPageContent/SearchPageContent";
import ApiServices from "../../services/ApiServices";
import queryString from 'query-string';
import FirebaseService from "../../services/FirebaseService";
import PageWrapper from "../../components/PageWrapper";
import { useHistory } from "react-router-dom";

const apiService = new ApiServices();
const firebaseService = new FirebaseService();

const mapStateToProps = (state) => {
  return {
    searchObject: state.searchObject,
  };
};

const categories = {
  allItems: "All items",
  art: "Art",
  game: "Game",
  photography: "Photography",
  music: "Music",
  video: "Video",
  zeptaNFTs: "ZeptaNFTs"
}

const limit = 20;
// const whileListCollection = ""

const Search = ({ searchObject }) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("All items");
  const [dropSelected, setDropSelected] = useState("All");
  const [page, setPage] = useState(1);
  //const [templateId, setTemplateId] = useState(null);
  const [discoverAssets, setDiscoverAssets] = useState([]);
  const [whileListCollection, setWhileListCollection] = useState("");
  const [templates, setTemplates] = useState(null);
  const [collections, setCollections] = useState(null);
  const [schemas, setSchemas] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [match, setMatch] = useState(null);
  const [isFilterFromQuery, setIsFilterFromQuery] = useState(false);
  const history = useHistory();

  const getFilterFromQuery = () => {
    let query = queryString.parse(window.location.search);
    let match = null;
    let category = null;
    let templateId = null

    if (query && query.search) {
      match = query.search;
    }

    if (query && query.category) {
      category = query.category;
      if (Object.keys(categories).indexOf(category) !== -1)
        updateCategory(categories[category])
    }
    if (query && query.templateId) {
      templateId = query.templateId;
    }

    return { category, templateId, match }
  }

  const getData = async (
    query,
    setData,
    page,
    limit,
    match,
    category = null,
    order,
    sort,
    collection = null,
    templateId = null,
  ) => {
    setLoading(true);
    setData(null);
    const cat = (category === null || category === "All items")
      ? null : category.toLowerCase();
    const data = query === 'accounts'
      ? await apiService.getAccaunts(match)
      : await apiService.getSearchData(query, page, limit, match, cat, collection, templateId, order, sort);
    if (data && data.length > 0) {
      setData(data);
    }
    setLoading(false);

    return data === null;
  };

  const getWhiteListCollections = async () => {
    let response = await firebaseService.getArtists();
    if (response) {
      let collections = response.data() ?? null;
      if (collections) {
        collections = collections.artists ?? []

        let whileListCollection = collections.toString()
        setWhileListCollection(whileListCollection)
      }
    }
  }

  const getNewPageData = async (page, dropSelected, setData, match) => {
    if (page > 0) {
      window.scrollTo(0, 0);
      let collection = null;
      let category = null;
      if (dropSelected === 'Assets') {
        category = selectedTab;
        if (category.toLowerCase() === 'zeptanfts') {
          category = null
          collection = whileListCollection;
        }
      }

      setPage(page);
      setDiscoverAssets([]);
      await getData(
        dropSelected.toLowerCase(),
        setData,
        page,
        limit,
        match,
        category,
        "desc",
        "created",
        collection
      );
    }
  }

  const updateCategory = async (category) => {
    let page = 1;
    setPage(page);
    setSelectedTab(category);
    setDiscoverAssets([]);
    let collection = null
  
    if (category.toLowerCase() === 'zeptanfts') {
      category = 'All items'
      collection = whileListCollection;
    }

    await getData(
      'assets',
      setDiscoverAssets,
      page,
      limit,
      match,
      category,
      "desc",
      "created",
      collection
    );
  }

  const getAllListingData = async (dropSelected, match) => {
    if (!isFilterFromQuery) {
      if (match) {
        history.push(`/explorer?search=${match}`);
      } else {
        history.push(`/explorer`);
      }
    }
    setIsFilterFromQuery(false);
    if (dropSelected === 'Assets') {
      await getData(dropSelected.toLowerCase(), setDiscoverAssets, page, limit, match, selectedTab);
    } else if (dropSelected === 'Accounts') {
      await getData(dropSelected.toLowerCase(), setAccounts, page, limit, match);
    } else if (dropSelected === 'Templates') {
      await getData(dropSelected.toLowerCase(), setTemplates, page, limit, match);
    } else if (dropSelected === 'Collections') {
      await getData(dropSelected.toLowerCase(), setCollections, page, limit, match);
    } else if (dropSelected === 'Schemas') {
      await getData(dropSelected.toLowerCase(), setSchemas, page, limit, match);
    } else if (dropSelected === 'All') {
      Promise.all(
        [
          getData('assets', setDiscoverAssets, page, limit, match, selectedTab),
          getData('templates', setTemplates, page, limit, match),
          getData('collections', setCollections, page, limit, match),
          getData('schemas', setSchemas, page, limit, match),
          getData('accounts', setAccounts, page, limit, match)
        ]
      )
    }
  }

  useEffect(() => {
    (async () => await getWhiteListCollections())();
    const { category, templateId, match } = getFilterFromQuery();
    if (category) {
      setSelectedTab(category);
      setIsFilterFromQuery(true);
    }

    // if (templateId) {
    //   setTemplateId(templateId);
    //   setIsFilterFromQuery(true);
    // }

    if (match) {
      setMatch(match);
      setIsFilterFromQuery(true);
    }
  }, []);

  useEffect(() => {
    getDropData()
  }, [dropSelected, match]);

  const getDropData = async () => {
    if (dropSelected) {
      await getAllListingData(dropSelected, match, searchObject);
    }
  }

  useEffect(() => {
    if (searchObject && searchObject.search) {
      setMatch(searchObject.search);
    }
  }, [searchObject]);

  return (
    <PageWrapper>
      <h1>Explorer</h1>
      <div className={classes.filterSearchContainer}>
        <FilterSearch match={match} setMatch={setMatch} />
      </div>
      <div className={classes.searchDescContainer}>
        <div className={classes.searchContentContainer}>
          <SearchPageContent
            selectedTab={selectedTab}
            selectTabHandler={updateCategory}
            limit={limit}
            discoverAssets={discoverAssets}
            setDiscoverAssets={setDiscoverAssets}
            dropSelected={dropSelected}
            dropSetSelected={(item) => setDropSelected(item)}
            loading={loading}
            tabs={[
              "All items",
              "Art",
              "Game",
              "Photography",
              "Music",
              "Video",
              "ZeptaNFTs"
            ]}
            getNewPageData={getNewPageData}
            page={page}
            templates={templates}
            setTemplates={setTemplates}
            collections={collections}
            setCollections={setCollections}
            schemas={schemas}
            setSchemas={setSchemas}
            accounts={accounts}
            setAccounts={setAccounts}
            setPage={setPage}
            match={match}
          />
        </div>
      </div>
    </PageWrapper>
  );
}

export default connect(mapStateToProps)(Search);
