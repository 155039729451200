import { createSlice } from "@reduxjs/toolkit";

const defaultCurrency = process.env.REACT_APP_TOKEN_SYMBOL;

const initialState = {
  selectedTab: "Sells",
  selectedOrder: "Listings Newest",
  dropCollection: "All creators",
  selectedCategory: "All items",
  filterCurrencyDropDownValue: defaultCurrency,
  allCollections: [],
  minPrice: null,
  maxPrice: null,
  minTemplateMint: null,
  maxTemplateMint: null,
  templateId: null,
  discoverAssets: [],
  page: 1,
  whileListCollection: "",
  match: "",
  supportedTokens: [],
  query: {
    currencyQuery: "",
    priceQuery: { min: null, max: null },
    mintQuery: { min: null, max: null },
    categoryQuery: "",
    dropCollectionQuery: "",
    orderQuery: "",
    tabQuery: "",
    templateIdQuery: "",
  },
  queryUrl: "market",
  showQuery: false,
};

const marketReducer = createSlice({
  name: "marketReducer",
  initialState,
  reducers: {
    SET_SELECTED_TAB: (state, action) => {
      state.selectedTab = action.payload;
    },
    SET_SELECTED_ORDER: (state, action) => {
      state.selectedOrder = action.payload;
    },
    SET_DROP_COLL: (state, action) => {
      state.dropCollection = action.payload;
    },
    SET_SELECTED_CATEGORY: (state, action) => {
      state.selectedCategory = action.payload;
    },
    SET_FILTER_CURRENCY: (state, action) => {
      state.filterCurrencyDropDownValue = action.payload;
    },
    SET_ALL_COLL: (state, action) => {
      state.allCollections = action.payload;
    },
    SET_MIN_PRICE: (state, action) => {
      state.minPrice = action.payload;
    },
    SET_MAX_PRICE: (state, action) => {
      state.maxPrice = action.payload;
    },
    SET_MIN_MINT: (state, action) => {
      state.minTemplateMint = action.payload;
    },
    SET_MAX_MINT: (state, action) => {
      state.maxTemplateMint = action.payload;
    },
    SET_TEMPLATE_ID: (state, action) => {
      state.templateId = action.payload;
    },
    SET_DISCOVER_ASSETS: (state, action) => {
      state.discoverAssets = action.payload;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload;
    },
    SET_WHITELIST_COLL: (state, action) => {
      state.whileListCollection = action.payload;
    },
    SET_MATCH: (state, action) => {
      state.match = action.payload;
    },
    SET_SUP_TOKENS: (state, action) => {
      state.supportedTokens = action.payload;
    },
    SET_CURRENCY_Q: (state, action) => {
      state.query.currencyQuery = action.payload;
    },
    SET_PRICE_Q: (state, action) => {
      state.query.priceQuery.min = action.payload.min;
      state.query.priceQuery.max = action.payload.max;
    },
    SET_MINT_Q: (state, action) => {
      state.query.mintQuery.min = action.payload.min;
      state.query.mintQuery.max = action.payload.max;
    },
    SET_CATEGORY_Q: (state, action) => {
      state.query.categoryQuery = action.payload;
    },
    SET_DROP_COLL_Q: (state, action) => {
      state.query.dropCollectionQuery = action.payload;
    },
    SET_TEMPLATE_Q: (state, action) => {
      state.query.templateIdQuery = action.payload;
    },
    SET_ORDER_Q: (state, action) => {
      state.query.orderQuery = action.payload;
    },
    SET_TAB_Q: (state, action) => {
      state.query.tabQuery = action.payload;
    },
    SET_URL_Q: (state, action) => {
      state.queryUrl = action.payload;
    },
    CLEAR_STATE: (state, action) => {
      state.selectedTab = "Sells";
      state.selectedOrder = "Listings Newest";
      state.dropCollection = "All creators";
      state.selectedCategory = "All items";
      state.filterCurrencyDropDownValue = defaultCurrency;
      state.allCollection = [];
      state.minPrice = null;
      state.maxPrice = null;
      state.minTemplateMint = null;
      state.maxTemplateMint = null;
      state.templateId = null;
      state.discoverAssets = [];
      state.page = 1;
      state.whileListCollection = "";
      state.match = "";
      state.supportedTokens = [];
      state.query = {
        currencyQuery: "",
        priceQuery: { min: null, max: null },
        mintQuery: { min: null, max: null },
        categoryQuery: "",
        dropCollectionQuery: "",
        orderQuery: "",
        tabQuery: "",
        templateIdQuery: "",
      };
      state.queryUrl = "market";
      state.showQuery = false;
    },
    SET_SHOW_QUERY: (state, action) => {
      state.showQuery = action.payload;
    },
  },
});

export const {
  SET_SELECTED_TAB,
  SET_SELECTED_ORDER,
  SET_DROP_COLL,
  SET_SELECTED_CATEGORY,
  SET_FILTER_CURRENCY,
  SET_ALL_COLL,
  SET_MIN_PRICE,
  SET_MAX_PRICE,
  SET_MIN_MINT,
  SET_MAX_MINT,
  SET_TEMPLATE_ID,
  SET_DISCOVER_ASSETS,
  SET_PAGE,
  SET_WHITELIST_COLL,
  SET_MATCH,
  SET_SUP_TOKENS,
  SET_CURRENCY_Q,
  SET_PRICE_Q,
  SET_MINT_Q,
  SET_CATEGORY_Q,
  SET_DROP_COLL_Q,
  SET_ORDER_Q,
  SET_TAB_Q,
  SET_URL_Q,
  CLEAR_STATE,
  SET_SHOW_QUERY,
  SET_TEMPLATE_Q,
} = marketReducer.actions;

export default marketReducer.reducer;
