import { useRef, useState, useEffect } from "react";

const HAVE_FUTURE_DATA = 3;
const TAG_NAME_VIDEO = 'VIDEO';

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  const checkVideoLoading = (element) => {
    return element.tagName === TAG_NAME_VIDEO && element.readyState === HAVE_FUTURE_DATA;
  }

  const checkLoadState = (element) => {
    return element && (element.complete || checkVideoLoading(element));
  }

  const onLoad = () => {
    const isLoad = checkLoadState(ref.current);

    if (isLoad) {
      setLoaded(true);
    }
  }

  useEffect(() => {
    onLoad();
  })

  return [ref, loaded, onLoad]
}

export default useImageLoaded;
