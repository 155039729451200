/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import classes from "./SaleDetailsMainInfo.module.css";
import AssetsDetailsPageTabBar from "../../AssetsDetailsPage/AssetsDetailsPageTabBar/AssetsDetailsPageTabBar";
import AssetsDetailsPageAssetsDecs from "../../AssetsDetailsPage/AssetsDetailsPageAssetsDecs/AssetsDetailsPageAssetsDecs";
import DetailsPurchaseAndPlaceBidCard from "../../Cards/DetailsPurchaseAndPlaceBidCard/DetailsPurchaseAndPlaceBidCard";
import AppServices from "../../../services/AppService";
import ApiServices from "../../../services/ApiServices";
import AssetsDetailsPageActionsBtn from "../../AssetsDetailsPage/AssetsDetailsPageActionsBtn/AssetsDetailsPageActionsBtn";

const appServices = new AppServices();
const apiServices = new ApiServices();

const SaleDetailsMainInfo = ({
  ual,
  modalsStates,
  modalsFuncs,
  data,
  price,
  tokenSymbol,
  itemId,
  assetsData,
  ppbCardData,
  setPpbCardData,
  isSalePage,
  tokenPrecision,
  setTokenPrecision,
  setCurrentTokenSymbol,
  isBundle,
  bundleData,
  isAuctionPage,
  buyRamAction,
  logs,
}) => {
  const [usdPrice, setUsdPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stock, setStock] = useState("?");
  const [templateId, setTemplateId] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [isBuyer, setIsBuyer] = useState(false);

  const geUsdPrice = (price) =>
    setUsdPrice(price * (appServices.getUsdPrice() ?? 1));

  const getPageType = (isSalePage, isAuctionPage) => {
    const res =
      isSalePage || data?.sales?.length > 0
        ? "sale"
        : isAuctionPage || data?.auctions?.length > 0
        ? "auction"
        : "asset-detail";
    return res;
  };

  useEffect(() => {
    async function setSaleData(
      details,
      isSalePage,
      isAuctionPage,
      priceAuction
    ) {
      // debugger;
      let price = isSalePage
        ? details.listing_price / Math.pow(10, tokenPrecision)
        : priceAuction;

      let usdPrice = await appServices.getUsdPrice();
      let priceUSD = price * usdPrice;

      if (details.listing_symbol === "USD") {
        priceUSD = +(details.listing_price / Math.pow(10, 2));
        price = priceUSD / usdPrice;
      }

      let ppbCardData = null;

      if (isSalePage) {
        ppbCardData = {
          img:
            "https://wax.api.atomichub.io/v1/preview/avatar/" + details.seller,
          name: details.seller,
          price: price + " " + details.price.token_symbol,
          priceUSD,
          state: details.state,
          tokenSymbol: details.price.token_symbol,
        };
      }

      if (isAuctionPage) {
        ppbCardData = {
          img:
            "https://wax.api.atomichub.io/v1/preview/avatar/" + details.seller,
          name: details.seller,
          price: price + " " + tokenSymbol,
          tokenSymbol: tokenSymbol,
          priceUSD,
          remainingTime: details.end_time,
          state: details.state,
          seller: details.seller,
          buyer: details.buyer,
          claimedByBuyer: details.claimed_by_buyer,
          claimedBySeller: details.claimed_by_seller,
        };
      }

      setPpbCardData(ppbCardData);
    }

    if (data && data.assets && price) {
      setSaleData(data, isSalePage, isAuctionPage, price);

      if (data.assets[0].template) {
        setTemplateId(data.assets[0].template.template_id);
      }
    }
  }, [data, price]);

  useEffect(() => {
    if (price) {
      geUsdPrice(price);
    }
  }, [price]);

  useEffect(() => {
    const getData = async (templateId) => {
      setLoading(true);
      const limit = 100;
      const page = 1;

      const templateSales = await apiServices.getSales(
        limit,
        page,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        templateId
      );

      if (templateSales && templateSales.length > 0) {
        const stockNumber = templateSales.length;
        setStock(stockNumber);
      }
      setLoading(false);

      return data === null;
    };

    if (templateId) {
      getData(templateId);
    }
  }, [templateId]);
  return (
    <>
      {data && (
        <div className={classes.assetsDetailsDescContainer}>
          <AssetsDetailsPageAssetsDecs
            assetsData={assetsData}
            assetsPrice={Number(price).toFixed(2)}
            assetsPriceUSD={usdPrice?.toFixed(2)}
            symbol={tokenSymbol}
            stock={stock}
            loading={loading}
            isSalePage={isSalePage}
            isBundle={isBundle}
            bundleData={bundleData}
          />
          <AssetsDetailsPageTabBar
            data={data}
            setIsOwner={setIsOwner}
            setIsBuyer={setIsBuyer}
            ual={ual}
            isSalePage={isSalePage}
            isBundle={isBundle}
            bundleData={bundleData}
            isAuctionPage={isAuctionPage}
            logs={logs}
          />
          <div className={classes.assetsDetailsPPBCardContainer}>
            <DetailsPurchaseAndPlaceBidCard
              pppCardData={ppbCardData}
              type={getPageType(isSalePage, isAuctionPage, data)}
              onClick={modalsFuncs.checkUserAuthentication}
              accept={false}
              data={data}
              isAuction={isAuctionPage}
              isOwner={isOwner}
              isBuyer={isBuyer}
              assetId={assetsData.assetId}
              claimAsset={modalsFuncs.claimAsset}
              claimBid={modalsFuncs.claimBid}
              isSalePage={isSalePage}
            />
          </div>
          <div className={classes.assetsDetailsActionsBtnContainer}>
            <AssetsDetailsPageActionsBtn
              isOwner={isOwner}
              type={getPageType(isSalePage, isAuctionPage)}
              openModal={modalsFuncs.showModalHandler}
              listOnSaleModalState={modalsStates.setShowListOnSaleModal}
              listOnAuctionModalState={modalsStates.setShowAuctionModal}
              updatePriceModalState={modalsStates.setShowUpdatePriceModal}
              updatePriceAuctionModalState={
                modalsStates.setShowUpdatePriceAuctionModal
              }
              removeModalState={modalsStates.setShowRemoveModal}
              setRemoveMsg={modalsStates.setRemoveMsg}
              buyRamAction={buyRamAction}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SaleDetailsMainInfo;
