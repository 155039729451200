import React, { useState, useEffect } from "react";
import classes from "./Pagination.module.css";

const Pagination = ({
  discoverAssets,
  setDiscoverAssets,
  page,
  getNewPageData,
  dropSelected,
  accounts,
  loading,
  templates,
  setTemplates,
  collections,
  setCollections,
  schemas,
  setSchemas,
  match,
  isTemplateDetailsPage,
}) => {
  const [currentListing, setCurrentListing] = useState(null);
  const [isRenderingRequired, setIsRenderingRequired] = useState(false);

  const defineCurrentListing = (
    dropSelected,
    discoverAssets,
    templates,
    collections,
    schemas,
    accounts
  ) => {
    if (dropSelected === "Assets") {
      if (discoverAssets)
        setCurrentListing({
          name: dropSelected,
          listing: discoverAssets,
          setListing: setDiscoverAssets,
        });
    } else if (dropSelected === "Templates") {
      if (templates)
        setCurrentListing({
          name: dropSelected,
          listing: templates,
          setListing: setTemplates,
        });
    } else if (dropSelected === "Collections") {
      if (collections)
        setCurrentListing({
          name: dropSelected,
          listing: collections,
          setListing: setCollections,
        });
    } else if (dropSelected === "Schemas") {
      if (schemas)
        setCurrentListing({
          name: dropSelected,
          listing: schemas,
          setListing: setSchemas,
        });
    } else if (dropSelected === "Sales") {
      if (discoverAssets)
        setCurrentListing({
          name: dropSelected,
          listing: discoverAssets,
          setListing: null,
        });
    } else if (dropSelected === "Accounts" && isTemplateDetailsPage) {
      if (discoverAssets)
        setCurrentListing({
          name: dropSelected,
          listing: accounts,
          setListing: null,
        });
    } else {
      setCurrentListing(null);
    }
  };

  const showPages = () => {
    let pagesArray = [];
    let p = page;

    let initialValue = 1;
    if (p - 3 > 1) initialValue = p - 3;

    for (let i = initialValue; i < initialValue + 7; i++) {
      pagesArray.push(
        <button
          key={i}
          style={p === i ? { color: "black", fontWeight: "bold" } : {}}
          onClick={() =>
            getNewPageData(
              i,
              currentListing.name,
              currentListing.setListing,
              match
            )
          }
        >
          {i}
        </button>
      );
    }

    return pagesArray;
  };

  const defineIsRenderingRequired = (currentListing, isTemplateDetailsPage) => {
    const limit = isTemplateDetailsPage ? 11 : 19;
    const res =
      currentListing &&
      currentListing.listing &&
      currentListing.listing.length > limit &&
      !loading
        ? true
        : false;
    setIsRenderingRequired(res);
  };

  useEffect(() => {
    if (dropSelected) {
      defineCurrentListing(
        dropSelected,
        discoverAssets,
        templates,
        collections,
        schemas,
        accounts
      );
    }
  }, [dropSelected, discoverAssets, templates, collections, schemas, accounts]);

  useEffect(() => {
    if (currentListing) {
      defineIsRenderingRequired(currentListing, isTemplateDetailsPage);
    }
  }, [currentListing, isTemplateDetailsPage]);

  return (
    <>
      {isRenderingRequired && (
        <div className={classes.paginationContainer}>
          {
            <button
              onClick={() =>
                getNewPageData(
                  page - 1,
                  currentListing.name,
                  currentListing.setListing,
                  match
                )
              }
            >
              Previous
            </button>
          }
          <div className={classes.pagesContainer}>{showPages()}</div>
          {
            <button
              onClick={() =>
                getNewPageData(
                  page + 1,
                  currentListing.name,
                  currentListing.setListing,
                  match
                )
              }
            >
              Next
            </button>
          }
        </div>
      )}
    </>
  );
};

export default Pagination;
