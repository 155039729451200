import React from "react";
import classes from "./BundleNFTs.module.css";
import { useHistory } from "react-router-dom";
import AssetImage from "../../UserAssetsCard/AssetImage";
import AssetVideo from "../../UserAssetsCard/AssetVideo";
import AssetAudio from "../../UserAssetsCard/AssetAudio";





const BundleNFTs = ({ bundleData }) => {

  const history = useHistory();

  const navigateToScreen = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  const getAssetContentView = (type, content, onClick, id) => {
    if(type === "img") {
      return <AssetImage content={content} onClick={onClick}/>
    } else if(type === "video") {
      return <AssetVideo content={content} onClick={onClick}/>
    } else {
      return <AssetAudio id={id} content={content}  onClick={onClick}/>
    }
  }

  return (
    <div className={classes.bundleWrapper}>
      {bundleData &&
        <div className={classes.bundleInnerWrapper}>
          {bundleData.map((nft) => 
            <div className={classes.assetWrapper} onClick={() => navigateToScreen(nft.detailsPath)}>
              {getAssetContentView(nft.type, nft.content)}
              {nft.title}
            </div>
          )}
        </div>
      }
    </div>
  );
}

export default BundleNFTs;
