import React from "react";
import classes from "./ActionButton.module.css";
import UploadIcon from "../../../assets/img/uploadIcon.svg";
import MoreIcon from "../../../assets/img/moreIcon.svg";
import HeartIcon from "../../../assets/img/heartIcon.svg";
import CloseIcon from "../../../assets/img/closeIcon.svg";
import SearchIcon from "../../../assets/img/searchIcon.svg";

export default function ActionButton(props) {
  return (
    <>
      {props.title?.toLowerCase() === "details" ? (
        <button
          className={classes.detailsBtn}
          style={{ color: props.color }}
          onClick={props.onClick}
        >
          Details
        </button>
      ) : props.title?.toLowerCase() === "buy" ||
        props.title?.toLowerCase() === "bid" ? (
        <button className={classes.buyBtn} onClick={props.onClick}>
          {props.title}
        </button>
      ) : (
        <button
          type={props.type}
          className={classes.actionButton}
          style={props.search || props.close ? { background: "#384E66" } : null}
          onClick={props.onClick}
        >
          <img
            src={
              props.upload
                ? UploadIcon
                : props.more
                ? MoreIcon
                : props.heart
                ? HeartIcon
                : props.close
                ? CloseIcon
                : props.search
                ? SearchIcon
                : null
            }
            alt="Action-button"
          />
        </button>
      )}
    </>
  );
}
