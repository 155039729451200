import classes from "./NetworksBlockchainsContent.module.css"
import NetworksBlockChainsElement from "./  NetworksBlockchainsElement/NetworksBlockChainsElement";

export default function NetworksBlockchainsContent({currencies}) {

    return (
        <div className={classes.contentConteiner}>
            {currencies.map((item, index) => {
                return (
                    <NetworksBlockChainsElement key={index} {...item}/>
                )
            })}
        </div>
    )
}