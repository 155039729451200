import React, { useState, useEffect } from "react";
import classes from "./NFTDetails.module.css";
import DetailsPurchaseAndPlaceBidCard from "../../components/Cards/DetailsPurchaseAndPlaceBidCard/DetailsPurchaseAndPlaceBidCard";
import AssetsDetailsPageAssetsImg from "../../components/AssetsDetailsPage/AssetsDetailsPageAssetsImg/AssetsDetailsPageAssetsImg";
import SearchNotFound from "../SearchNotFound/SearchNotFound";
import Button from "../../components/Button/Button";
import { withRouter } from "react-router-dom";
import useModals from "../../components/Model/ModalsHook";
import ModalsGroup from "../../components/Model/ModalsGroup/ModalsGroup";
import AppService from "../../services/AppService";
import ApiServices from "../../services/ApiServices";
import SaleDetailsMainInfo from "../../components/SaleDetails/SaleDetailsMainInfo";
import Loader from "../../components/UI/Loader";
import BundleNFTs from "../../components/Model/BundleNFTs";
import BuyRamModal from "../../components/Model/BuyRamModal";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import PushNotification from "../../components/Model/PushNotification";

const appService = new AppService();
const apiService = new ApiServices();

const NFTDetails = ({ ual, history }) => {
  const [assetsData, setAssetsData] = useState({
    assetId: "",
    content: "",
    type: "img",
    title: "",
    description: "",
    creator: "",
    owner: "",
  });
  const [itemDetails, setItemDetails] = useState("");
  const [itemId, setItemId] = useState("");
  const [ppbCardData, setPpbCardData] = useState(null);
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSalePage, setIsSalePage] = useState(false);
  const [isAuctionPage, setIsAuctionPage] = useState(false);
  const [showBuyRamModal, SetShowBuyRamModal] = useState(false);
  const [tokenPrecision, setTokenPrecision] = useState(
    process.env.REACT_APP_TOKEN_PRECISION
  );
  const [currentTokenSymbol, setCurrentTokenSymbol] = useState(
    process.env.REACT_APP_TOKEN_SYMBOL
  );
  const [isBundle, setIsBundle] = useState(false);
  const [bundleData, setBundleData] = useState(null);
  const [modalAnimation, setModalAnimation] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [logs, setlogsDetails] = useState("");
  const [pageIndex, setPageIndex] = useState(1);

  const goBackHandler = () => {
    history.goBack();
  };

  const getPrice = (saleData) => {
    setPrice(
      +saleData.price.amount / Math.pow(10, +saleData.price.token_precision)
    );
  };

  const changePageIndex = (index) => {
    setPageIndex(index);
    getLogDetils(index);
  };
  const getLogDetils = async (pageIndex = 1) => {
    let logsDetails = await apiService.getLogs(
      isSalePage ? "sales" : isAuctionPage ? "auctions" : "assets",
      itemId,
      pageIndex
    );
    setlogsDetails({
      details: logsDetails,
      changePageIndex: changePageIndex,
      index: pageIndex,
    });
  };

  const getData = async (itemId) => {
    let details = isSalePage
      ? await apiService.getSaleData(itemId)
      : isAuctionPage
      ? await apiService.getAuctionData(itemId)
      : await apiService.getAssetData(itemId);
    setItemDetails(details);
    // if ((isSalePage || isAuctionPage) && details && details.state !== 1) {
    //   details = null;
    //   setItemDetails(details);
    // } else {
    //   setItemDetails(details);
    // }

    if (isSalePage || isAuctionPage) {
      if (details && details.assets && details.assets.length > 0) {
        await addAssetData(details);
        getPrice(details);
        setCurrentTokenSymbol(details.price.token_symbol);
        setIsLoading(false);
      }
    } else {
      if (details) {
        await addAssetData(details);
        setIsLoading(false);
      }
    }
    getLogDetils();
  };

  const purchasePath = () => window.location.pathname.split("/")[2];

  const getItemId = async (purchasePath) => {
    let path = purchasePath ? purchasePath : window.location.pathname;
    let splitArray = path.split("/");
    if (splitArray && splitArray[1] === "sale") setIsSalePage(true);
    if (splitArray && splitArray[1] === "auction") setIsAuctionPage(true);
    if (splitArray && splitArray[2]) setItemId(splitArray[2]);
  };

  const getMediaContent = (asset) => {
    let content = asset.data.img;
    let type = "img";
    if (content === undefined) {
      content = asset.data.video;
      type = "video";
    }
    if (content === undefined) {
      content = asset.data.audio;
      type = "audio";
    }

    return { content, type };
  };

  const getBundleData = (assets) => {
    const data = assets.reduce((assetsData, asset) => {
      return (assetsData = [
        ...assetsData,
        {
          detailsPath: "/assets/" + asset.asset_id ?? "",
          title: asset.data.name ?? "",
          ...getMediaContent(asset),
        },
      ]);
    }, []);

    setBundleData(data);
  };

  const addAssetData = async (details) => {
    if (details.assets && details.assets.length > 1) {
      setIsBundle(true);
      getBundleData(details.assets);
    } else if (details.assets) {
      const asset = details.assets[0];
      const assetId = asset.asset_id ?? "";
      const owner = asset.owner ?? "";
      const title = asset.data.name ?? "";
      const description = asset.data.description ?? asset.data.details ?? "";

      setAssetsData({
        title,
        description,
        assetId,
        owner,
        ...getMediaContent(asset),
      });
    } else {
      const asset = details;
      const assetId = asset.asset_id ?? "";
      const owner = asset.owner ?? "";
      const title = asset.data.name ?? "";
      const description = asset.data.description ?? asset.data.details ?? "";

      setAssetsData({
        title,
        description,
        assetId,
        owner,
        ...getMediaContent(asset),
      });
    }
  };

  useEffect(() => {
    (async () => getItemId())();
  }, []);

  useEffect(() => {
    if (itemId) {
      (async () => {
        await getData(itemId);
      })();
    }
  }, [itemId]);

  const [modalsStates, modalsFuncs] = useModals(
    ual,
    getData,
    itemId,
    assetsData,
    ppbCardData,
    currentTokenSymbol,
    tokenPrecision
  );

  const buyRamAction = (value) => {
    SetShowBuyRamModal(value);
    if (!value) {
      setModalAnimation(true);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const showNotificationHandler = (msg) => {
    setNotificationTitle(msg);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  return (
    <>
      {itemDetails === null ? (
        <SearchNotFound />
      ) : (
        <>
          <div className={classes.assetsDetailsMainContainer}>
            <Button secondary onClick={goBackHandler}>
              <div className={classes.goBackBtn}>
                <i className="fas fa-arrow-left"></i> Go back
              </div>
            </Button>
            <Loader loading={isLoading} />
            {!isLoading && (
              <>
                <div className={classes.assetsDetailsMainContentWrapper}>
                  {isBundle ? (
                    <BundleNFTs bundleData={bundleData} />
                  ) : (
                    <div className={classes.assetsDetailsImgContainer}>
                      <AssetsDetailsPageAssetsImg assetsData={assetsData} />
                    </div>
                  )}
                  <SaleDetailsMainInfo
                    buyRamAction={buyRamAction}
                    ual={ual}
                    modalsStates={modalsStates}
                    modalsFuncs={modalsFuncs}
                    data={itemDetails}
                    logs={logs}
                    price={price}
                    tokenSymbol={currentTokenSymbol}
                    itemId={itemId}
                    assetsData={assetsData}
                    ppbCardData={ppbCardData}
                    setPpbCardData={setPpbCardData}
                    isSalePage={isSalePage}
                    tokenPrecision={tokenPrecision}
                    setTokenPrecision={setTokenPrecision}
                    setCurrentTokenSymbol={setCurrentTokenSymbol}
                    isBundle={isBundle}
                    bundleData={bundleData}
                    isAuctionPage={isAuctionPage}
                  />
                </div>
              </>
            )}
          </div>
          <ModalsGroup
            ual={ual}
            modalsStates={modalsStates}
            modalsFuncs={modalsFuncs}
            currentTokenSymbol={currentTokenSymbol}
            setCurrentTokenSymbol={setCurrentTokenSymbol}
            setTokenPrecision={setTokenPrecision}
            isSalePage={isSalePage}
            isAuctionPage={isAuctionPage}
          />
         { showBuyRamModal&&<BuyRamModal
            ual={ual}
            showModal={showBuyRamModal}
            removeError={() => buyRamAction(false)}
            showNotificationHandler={showNotificationHandler}
            close={() => buyRamAction(false)}
          />}
          <Backdrop
            show={showBuyRamModal}
            onClick={() => buyRamAction(false)}
            animation={modalAnimation}
          />
          <PushNotification
            title={notificationTitle}
            showNotification={showNotification}
          />
        </>
      )}
    </>
  );
};

export default withRouter(NFTDetails);
