import React, { useEffect, useState } from "react";
import { CgArrowsExchange } from "react-icons/cg";
import { MdOutlineLocalFireDepartment } from "react-icons/md";
import ApiServices from "../../services/ApiServices";
import AppServices from "../../services/AppService";
import useImageLoaded from "../../hooks/useImageLoaded";
import classes from "./SaleHoverCard.module.css";
const appService = new AppServices();
const apiServices = new ApiServices();
const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";
export default function SaleHoverCard({ cardInfo }) {
  const {
    assets: [
      {
        data,
        template,
        schema,
        collection: { collection_name },
        minted_at_time,
        asset_id,
      },
    ],
    assets,
  } = cardInfo;
  const reloadImg = (e) => {
    if (e.target.src.includes(RESIZER_URL))
      e.target.src = appService.getIpfsUrl(data.img || data.video);
  };
  const [templateStats, setTemplateStats] = useState(null);
  const [lowestPrice, setLowestPrice] = useState(null);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [tokenSymbol] = useState(process.env.REACT_APP_TOKEN_SYMBOL);
  const [precision] = useState(process.env.REACT_APP_TOKEN_PRECISION);
  const [ref, loaded, onLoad] = useImageLoaded();

  useEffect(
    () => async () => {
      const stats = await apiServices.getTemplateStats(
        collection_name,
        template?.template_id
      );
      setTemplateStats(stats);
    },
    [template, schema, collection_name]
  );

  useEffect(() => {
    getPricesHistory(template?.template_id, schema.schema_name);
  }, []);

  const getStats = (prices, sales) => {
    setLowestPrice(
      `${prices.min / Math.pow(10, precision)} ${tokenSymbol}` ?? "None"
    );
    setSuggestedPrice(
      `${prices.suggested_median / Math.pow(10, precision)} ${tokenSymbol}` ??
        "None"
    );
  };
  const getPricesHistory = async (templateId, schemaName) => {
    const sales = await apiServices.getPriceHistory(templateId, schemaName);
    if (assets[0] && sales && assets[0].prices && assets[0].prices[0]) {
      getStats(assets[0].prices[0], sales);
    } else if (assets[0] && sales && !assets[0].prices) {
      setLowestPrice("None");
      setSuggestedPrice("None");
    }
  };
  const getMintNumberString = (asset, templateStats) => {
    return (
      <>
        {asset.template_mint === "0" && !asset.template ? (
          <span className={classes.templateMint}>1 of 1 [No Template]</span>
        ) : asset.template_mint === "0" ? (
          <span className={classes.templateMint}>Calculating...</span>
        ) : (
          <span className={classes.templateMint}>{asset.template_mint}</span>
        )}

        {asset.template &&
          asset.template.issued_supply &&
          asset.template.max_supply &&
          ` of 
          ${asset.template.issued_supply} 
          (max: ${
            asset.template.max_supply && asset.template.max_supply !== "0"
              ? asset.template.max_supply
              : "?"
          }) `}
        {templateStats && templateStats.burned && templateStats.burned > 0 && (
          <span className={classes.burnedWrapper}>
            {` - ${templateStats.burned}`}
            <MdOutlineLocalFireDepartment />
          </span>
        )}
      </>
    );
  };
  const getDate = (value) => {
    const date = new Date(parseInt(value));
    return `${
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      ", " +
      date.getHours() +
      ":" +
      date.getMinutes()
    }`;
  };
  const loder = () => (
    <div className="ph-row">
      <div
        style={{ width: "100px", borderRadius: "20px" }}
        className="ph-col-12"
      ></div>
    </div>
  );
  return (
    <>
      <div className={classes.cardContainer}>
        <div className={classes.imageContainer}>
          <div className={classes.imagediv}>
            {data.img ? (
              <img
                ref={ref}
                onLoad={onLoad}
                alt="assetsImg"
                loading="lazy"
                src={
                  data.img?.includes("http")
                    ? appService.getIpfsUrl(data.img)
                    : `${RESIZER_URL}${data.img}&size=200`
                }
                onError={(e) => reloadImg(e)}
              />
            ) : data.video ? (
              <video
                onLoadedData={onLoad}
                ref={ref}
                autoPlay={true}
                loop={true}
                muted={true}
                src={
                  data.img?.includes("http")
                    ? appService.getIpfsUrl(data.video)
                    : `${RESIZER_URL}${data.video}&size=200`
                }
                onError={(e) => reloadImg(e)}
                alt=""
              />
            ) : (
              <div className={classes.headPhoneIcon}>
                <i className="fas fa-headphones"></i>
              </div>
            )}
          </div>
        </div>
        <div className={classes.contentContainer}>
          <h1>
            {data.name}
            <span>ID:#{asset_id}</span>
          </h1>
          <p>
            Collection:
            <span>{collection_name}</span>
          </p>
          <p>
            schema:
            <span>{schema?.schema_name}</span>
          </p>
          <p>
            templateid:
            <span>{template?.template_id}</span>
          </p>
          <p>
            mint:
            <span>{getMintNumberString(assets[0], templateStats)}</span>
          </p>
          <p>
            owner:
            <span>{assets[0].owner}</span>
          </p>
          <p>
            backed token:
            <span>
              {assets[0].backed_tokens
                ? assets[0].backed_tokens.length
                : "none"}
            </span>
          </p>
          <p>
            owned template:
            <span>0</span>
          </p>
          <p>
            lowest listing:
            {lowestPrice ? <span>{lowestPrice}</span> : loder()}
          </p>
          <p>
            suggested price:
            {suggestedPrice ? <span>{suggestedPrice}</span> : loder()}
          </p>
        </div>
        <div className={classes.iconContainer}>
          <span>
            <CgArrowsExchange />
          </span>
          <span>
            <MdOutlineLocalFireDepartment />
          </span>
        </div>
      </div>
      <div className={classes.detailsContainer}>
        {Object.keys(data)
          .slice(0, 10)
          .map((key, index) => (
            <div className={classes.details} key={index}>
              <p className={classes.details_p1}>{key}</p>
              <p className={classes.details_p3}>:</p>
              <p className={classes.details_p2}>{data[key]}</p>
            </div>
          ))}
      </div>
      <div className={classes.minted}>
        <p>
          Minted:
          <span>{getDate(minted_at_time)}</span>
        </p>
      </div>
    </>
  );
}
