import React, { useState, useEffect } from "react";
import classes from "./CardsSection.module.css";
import TemplateLargeCard from "../TemplateLargeCard";
import SchemaCard from "../../SchemaCard";
import CollectionCard from "../../CollectionCard";
import ApiServices from "../../../services/ApiServices";

const apiService = new ApiServices();

const CardsSection = ({ templateData, templateStats }) => {
  const [collectionContent, setCollectionContent] = useState(null);

  const defineContent = async (collectionData) => {
    if(collectionData.img) {
      setCollectionContent(collectionData.img);
    } else {
      const collectionAssets = await apiService.getTopCollectionAssets(collectionData.collection_name);
      if(collectionAssets && collectionAssets.length > 0) {
        setCollectionContent(collectionAssets[0].data.img);
      }
    }
  }

  useEffect(() => {
    if(templateData && templateData[0].collection) {
      (async () => await defineContent(templateData[0].collection))();
    }
  },[templateData])

  return (
    <div className={classes.cardsSectionWrapper}>
      <h1 className={classes.templateTitle}>Template:<span className={classes.id}>{` #${templateData[0].template_id}`}</span></h1>
      {
        templateData && templateStats &&
        <>
          <div className={classes.templateWrapper}>
            <TemplateLargeCard
              templateStats={templateStats}
              templateData={templateData}
              content={templateData[0].immutable_data.img}
            />
          </div>
          <div className={classes.additionalCardsWrapper}>
            <div className={classes.collectionWrapper}>
              <p className={classes.title}>Collection</p>
              <CollectionCard
                content={collectionContent}
                name={templateData[0].collection.collection_name}
              />
            </div>
            <div className={classes.schemaWrapper}>
              <p className={classes.title}>Schema</p>
              <SchemaCard
                name={templateData[0].schema.schema_name}
                collection={templateData[0].collection.collection_name}
                attributes={templateData[0].schema.format.length}
              />
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default CardsSection;
