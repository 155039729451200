// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingBackground_loadingBackground__1Ffmv {\r\n  width: 100%;\r\n  background: linear-gradient(-90deg, #c7c7c7, #e7e2e4, #cacaca, #e6eeec);\r\n  background-size: 400% 400%;\r\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\r\n  animation: LoadingBackground_gradient__26eZh 3s ease infinite;\r\n}\r\n@keyframes LoadingBackground_gradient__26eZh {\r\n\t0% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n\t50% {\r\n\t\tbackground-position: 100% 50%;\r\n\t}\r\n\t100% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/UI/LoadingBackground/LoadingBackground.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uEAAuE;EACvE,0BAA0B;EAC1B,2FAA2F;EAC3F,6DAAoC;AACtC;AACA;CACC;EACC,2BAA2B;CAC5B;CACA;EACC,6BAA6B;CAC9B;CACA;EACC,2BAA2B;CAC5B;AACD","sourcesContent":[".loadingBackground {\r\n  width: 100%;\r\n  background: linear-gradient(-90deg, #c7c7c7, #e7e2e4, #cacaca, #e6eeec);\r\n  background-size: 400% 400%;\r\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\r\n  animation: gradient 3s ease infinite;\r\n}\r\n@keyframes gradient {\r\n\t0% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n\t50% {\r\n\t\tbackground-position: 100% 50%;\r\n\t}\r\n\t100% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingBackground": "LoadingBackground_loadingBackground__1Ffmv",
	"gradient": "LoadingBackground_gradient__26eZh"
};
export default ___CSS_LOADER_EXPORT___;
