// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SchemaCard_cardWrapper__3kKmi {\n  width: 200px;\n  margin: 10px 10px 20px 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: rgba(223, 117, 73, 0.12);;\n  border-radius: 20px;\n  cursor: pointer;\n  padding: 10px\n}\n\n.SchemaCard_cardWrapper__3kKmi:hover {\n  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);\n}\n\n.SchemaCard_name__2i_CS {\n  margin: 16px 0 20px;\n  color: #dc3825;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.SchemaCard_collection__N_sso {\n  font-size: 18px;\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/components/SchemaCard/SchemaCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,0CAA0C;EAC1C,mBAAmB;EACnB,eAAe;EACf;AACF;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".cardWrapper {\n  width: 200px;\n  margin: 10px 10px 20px 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: rgba(223, 117, 73, 0.12);;\n  border-radius: 20px;\n  cursor: pointer;\n  padding: 10px\n}\n\n.cardWrapper:hover {\n  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);\n}\n\n.name {\n  margin: 16px 0 20px;\n  color: #dc3825;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.collection {\n  font-size: 18px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": "SchemaCard_cardWrapper__3kKmi",
	"name": "SchemaCard_name__2i_CS",
	"collection": "SchemaCard_collection__N_sso"
};
export default ___CSS_LOADER_EXPORT___;
