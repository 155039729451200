import React, { Component } from "react";
import Dropzone from "react-dropzone";
import classes from "./SingleCollectiblePageUploadFile.module.css";
import UploadDropIcon from "../../../assets/img/dropIcon.svg";
import { UPLOAD_FILE_DESCRIPTION } from "../../../constants/SingleCollectible";

export default class SingleCollectiblePageUploadFile extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={classes.SingleCollectiblePageUploadFileContainer}>
        <div className={classes.UploadText}>
          <h1>Upload file</h1>
        </div>
        <div className={classes.UploadFileContainer}>
          <Dropzone
            onDrop={this.props.handleOnDrop}
            accept={this.props.acceptedFileTypes}
            multiple={false}
            maxSize={this.props.imageMaxSize}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: classes.UploadFileDropZone,
                })}
                style={
                  this.props.boxErrorHighlight &&
                  this.props.boxErrorHighlight.content
                    ? {
                        border: "2px solid red",
                      }
                    : null
                }
              >
                <input {...getInputProps()} />
                {this.props.fileName ? (
                  <div className={classes.uploaded}>
                    <img src={UploadDropIcon} alt="" />
                    <p>{this.props.fileName}</p>
                  </div>
                ) : (
                  <div>
                    <img src={UploadDropIcon} alt="" />
                    <p>Drag or choose your file to upload</p>
                    <div className={classes.descriptionsWrapper}>
                      {UPLOAD_FILE_DESCRIPTION.map((description) => (
                        <div className={classes.descriptionWrapper}>
                          <p className={classes.descriptionKey}>
                            {description.key}
                          </p>
                          <p className={classes.descriptionNameValue}>
                            {description.value}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}
