import React, { useEffect, useState } from "react";
import classes from "./SchemaAttributesTab.module.css"

const SchemaAttributesTab = ({ schemaData }) => {
  const [attributes, setAttributes] = useState(null);

  useEffect(() => {
    if(schemaData) {
      setAttributes(schemaData[0].format);
    }
  }, [schemaData])

  return (
    <>
      {
        attributes && attributes.length ? (
          <div className={classes.attributesTab}>
            {attributes.map((attribute, i) =>
              <div className={classes.lineWrapper}>
                <div className={classes.name}>{attribute.name}</div>
                <div className={classes.type}>{attribute.type}</div>
              </div>
            )}
          </div>
        ) : "None"
      }
    </>
  )
}

export default SchemaAttributesTab;
