import React from "react";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import classes from "./SearchPageContent.module.css";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import CardsSection from "./CardsSection";

const LISTING_TYPES = ["All", "Assets" ,"Templates", "Collections", "Schemas", "Accounts"];

export default function SearchPageContent({...props}) {

  return (
    <div className={classes.searchItemsContainer}>
      <div className={classes.filterDropContainer}>
        <FilterDropdown
          selected={props.dropSelected}
          options={LISTING_TYPES}
          setSelected={props.dropSetSelected}
          setPage={props.setPage}
          page={props.page}
        />          
      </div>
      <FilterTabBar
        tabs={props.tabs}
        selected={props.selectedTab}
        setSelected={props.selectTabHandler}
        dropSelected={props.dropSelected}
        isExplorePage
      >
        <TabContent isSelected={true}>
          <CardsSection {...props}/>
        </TabContent>
      </FilterTabBar>
    </div>
  );
}
