import React, { useEffect, useState } from "react";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import classes from "./AssetsDetailsPageTabBar.module.css";
import saleDetailsConst from "../../../constants/SaleDetails";
import AttributesTab from "../../SaleDetails/AttributesTab";
import PriceHistoryStatsTab from "../../SaleDetails/PriceHistorySectionTab";
import FirebaseService from "../../../services/FirebaseService";
import AppServices from "../../../services/AppService";
import InfoTab from "../../SaleDetails/InfoTab";
import SaleLogs from "../../SaleDetails/SaleLogs";

const firebaseService = new FirebaseService();
const appServices = new AppServices();

const AssetsDetailsPageTabBar = ({
  data,
  setIsOwner,
  setIsBuyer,
  ual,
  isSalePage,
  isBundle,
  bundleData,
  isAuctionPage,
  logs,
}) => {
  const [selectedTab, setSelectedTab] = useState("Info");
  const [assetUsersInfo, setAssetUsersInfo] = useState(null);

  const getUserProfile = async (accountName) => {
    let userProfile = await firebaseService.getUser(accountName);

    if (userProfile) {
      userProfile = userProfile.data();
      if (userProfile) {
        return userProfile;
      }
    }

    return null;
  };

  const getAsset = (data, isSalePage, isAuctionPage) => {
    return (isSalePage || isAuctionPage) && data ? data.assets[0] : data;
  };

  const handleTableData = () => {
    return (
      <>
        <TabContent isSelected={selectedTab === "Info"}>
          <InfoTab
            data={data}
            assetUsersInfo={assetUsersInfo}
            isSalePage={isSalePage}
            isBundle={isBundle}
            bundleData={bundleData}
            isAuctionPage={isAuctionPage}
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Attributes"}>
          <AttributesTab asset={getAsset(data, isSalePage, isAuctionPage)} />
        </TabContent>
        <TabContent isSelected={selectedTab === "Price History"}>
          <PriceHistoryStatsTab
            asset={getAsset(data, isSalePage, isAuctionPage)}
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Sale Logs"}>
          <SaleLogs logs={logs} />
        </TabContent>
      </>
    );
  };

  useEffect(() => {
    async function checkingOwnership(assetOwner, ual, set) {
      let isLoginUser = false;
      let loginUser = await appServices.getAccountName(ual);
      if (assetOwner === loginUser) isLoginUser = true;

      set(isLoginUser);
    }

    async function getUserData(name, rank, collectionName, set) {
      let userProfile = await getUserProfile(name);

      let userInfo = {
        img: userProfile?.image ?? "",
        rank: rank,
        name: userProfile?.name ?? name ?? "",
        collectionName: collectionName,
      };

      if (rank === "Seller" || rank === "Owner" || rank === "Buyer")
        checkingOwnership(name, ual, set);

      setAssetUsersInfo((prevState) => {
        if (prevState && prevState.length === 1)
          return [...prevState, userInfo];
        else return [userInfo];
      });
    }
    if (data) {
      if (isSalePage || isAuctionPage) {
        getUserData(data.seller, "Seller", data.collection.author, setIsOwner);
        if (isAuctionPage && data.buyer)
          getUserData(data.buyer, "Buyer", data.collection.author, setIsBuyer);
      } else {
        getUserData(data.owner, "Owner", data.collection.author, setIsOwner);
        getUserData(data.collection.author, "Author", data.collection.author);
      }
    }
  }, [data]);

  return (
    <div className={classes.assetsDetailsFilterTabBarContainer}>
      <FilterTabBar
        tabs={
          isBundle
            ? saleDetailsConst.DETAILS_TABS_BUNDLE
            : saleDetailsConst.DETAILS_TABS
        }
        selected={selectedTab}
        setSelected={setSelectedTab}
        isBundle={isBundle}
      >
        {handleTableData()}
      </FilterTabBar>
    </div>
  );
};

export default AssetsDetailsPageTabBar;
