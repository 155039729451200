import React, { useEffect } from 'react';
import classes from './CollectionCard.module.css';
import { useHistory } from "react-router-dom";
import useImageLoaded from '../../hooks/useImageLoaded';
import AppServices from '../../services/AppService';
import ActionButton from '../UI/ActionButton/ActionButton';
import ImageIcon from "../../assets/img/image.png";

// const RESIZER_URL = '//images.weserv.nl/?url=';
const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";


const appService = new AppServices();

const CollectionCard = ({ content, name, isDetailsPage, index = 1, isLoading }) => {
  const [ref, loaded, onLoad] = useImageLoaded();
  const history = useHistory();

  const navigateToScreen = (collectionName) => {
    history.push(`/collections/${collectionName}`);
    window.scrollTo(0, 0);
  };

  const getImgUrl = () =>  appService.getIpfsUrl(content);

  const reloadImg = (e) => {
    if(e.target.src.includes(RESIZER_URL))
      e.target.src = getImgUrl(content);
  }

  return (
    <>
      {
        isLoading ?
            <div className={classes.cardWrapper} key={index}>
              <div className={`${classes.imgWrapper} ${classes.loading}`}></div>
            </div> 
          :
            <div className={classes.cardWrapper} key={index}>
              <div className={`${classes.imgWrapper} ${loaded ? null : content ? classes.loading : null}`}>
                {
                  content ? (
                    <img
                      ref={ref}
                      src={content?.includes("http")
                      ? getImgUrl(content)
                      : `${RESIZER_URL}${content}&size=200`} 
                      onLoad={onLoad} alt="assetsImg"
                      loading="lazy"
                      onError={(e) => reloadImg(e)}
                    /> 
                  ) : (
                    <div className={classes.iconWrapper}>
                      <img src={ImageIcon} alt='' />
                    </div>
                  )
                }
              </div>
              <div className={classes.name}>
                {name}
              </div>
              {
                !isDetailsPage &&
                  <ActionButton title="Details" color="black" onClick={() => navigateToScreen(name)} />
              }
            </div>
      }
    </>
    
  );
};

export default CollectionCard;
