// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TechnicalInfoSection_sectionWrapper__3I8hi {\r\n  margin-top: 32px;\r\n}\r\n\r\n.TechnicalInfoSection_menusWrapper__21jEG {\r\n  margin-top: 16px;\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  grid-gap: 4%;\r\n  gap: 4%;\r\n}\r\n\r\n@media only screen and (max-width: 660px) {\r\n  .TechnicalInfoSection_menusWrapper__21jEG {\r\n    flex-direction: column;\r\n    grid-gap: 12px;\r\n    gap: 12px;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/CollectionDetails/CollectionTabs/MoreInfoTab/TechnicalInfoSection/TechnicalInfoSection.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,YAAO;EAAP,OAAO;AACT;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAS;IAAT,SAAS;EACX;AACF","sourcesContent":[".sectionWrapper {\r\n  margin-top: 32px;\r\n}\r\n\r\n.menusWrapper {\r\n  margin-top: 16px;\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  gap: 4%;\r\n}\r\n\r\n@media only screen and (max-width: 660px) {\r\n  .menusWrapper {\r\n    flex-direction: column;\r\n    gap: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": "TechnicalInfoSection_sectionWrapper__3I8hi",
	"menusWrapper": "TechnicalInfoSection_menusWrapper__21jEG"
};
export default ___CSS_LOADER_EXPORT___;
