// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetsDetailsPageTabBar_assetsDetailsFilterTabBarContainer__F9Tnq {\n min-height: 300px;\n}\n.AssetsDetailsPageTabBar_assetsDetailsFilterTabBarContainer__F9Tnq ul {\n width: max-content;\n border: 2px solid #e6e8ec;\n border-radius: 36px;\n margin-top: 0;\n padding: 0px 5px;\n}\n@media only screen and (max-width: 520px) {\n .AssetsDetailsPageTabBar_assetsDetailsFilterTabBarContainer__F9Tnq ul {\n  display: grid;\n  grid-template-columns: repeat(3, auto);\n  border: none;\n }\n .AssetsDetailsPageTabBar_assetsDetailsFilterTabBarContainer__F9Tnq ul li {\n  padding: 10px 0;\n }\n}\n", "",{"version":3,"sources":["webpack://src/components/AssetsDetailsPage/AssetsDetailsPageTabBar/AssetsDetailsPageTabBar.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;AACA;CACC,kBAAkB;CAClB,yBAAyB;CACzB,mBAAmB;CACnB,aAAa;CACb,gBAAgB;AACjB;AACA;CACC;EACC,aAAa;EACb,sCAAsC;EACtC,YAAY;CACb;CACA;EACC,eAAe;CAChB;AACD","sourcesContent":[".assetsDetailsFilterTabBarContainer {\n min-height: 300px;\n}\n.assetsDetailsFilterTabBarContainer ul {\n width: max-content;\n border: 2px solid #e6e8ec;\n border-radius: 36px;\n margin-top: 0;\n padding: 0px 5px;\n}\n@media only screen and (max-width: 520px) {\n .assetsDetailsFilterTabBarContainer ul {\n  display: grid;\n  grid-template-columns: repeat(3, auto);\n  border: none;\n }\n .assetsDetailsFilterTabBarContainer ul li {\n  padding: 10px 0;\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetsDetailsFilterTabBarContainer": "AssetsDetailsPageTabBar_assetsDetailsFilterTabBarContainer__F9Tnq"
};
export default ___CSS_LOADER_EXPORT___;
