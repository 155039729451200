// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MoreInfoDetailsSection_detailsWrapper__4I6gS {\r\n  margin-top: 32px;\r\n}\r\n\r\n.MoreInfoDetailsSection_title__1oW4f {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.MoreInfoDetailsSection_lineWrapper__xTh4H {\r\n  margin-bottom: 10px;\r\n  display: flex;\r\n}\r\n\r\n.MoreInfoDetailsSection_key__2fcyU, .MoreInfoDetailsSection_value__3uZXQ {\r\n  font-size: 16px;\r\n}\r\n\r\n.MoreInfoDetailsSection_key__2fcyU {\r\n  margin-right: 10px;\r\n  color: #777e91;\r\n}\r\n\r\n.MoreInfoDetailsSection_value__3uZXQ {\r\n  font-weight: bold;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/CollectionDetails/CollectionTabs/MoreInfoTab/MoreInfoDetailsSection/MoreInfoDetailsSection.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".detailsWrapper {\r\n  margin-top: 32px;\r\n}\r\n\r\n.title {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.lineWrapper {\r\n  margin-bottom: 10px;\r\n  display: flex;\r\n}\r\n\r\n.key, .value {\r\n  font-size: 16px;\r\n}\r\n\r\n.key {\r\n  margin-right: 10px;\r\n  color: #777e91;\r\n}\r\n\r\n.value {\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsWrapper": "MoreInfoDetailsSection_detailsWrapper__4I6gS",
	"title": "MoreInfoDetailsSection_title__1oW4f",
	"lineWrapper": "MoreInfoDetailsSection_lineWrapper__xTh4H",
	"key": "MoreInfoDetailsSection_key__2fcyU",
	"value": "MoreInfoDetailsSection_value__3uZXQ"
};
export default ___CSS_LOADER_EXPORT___;
