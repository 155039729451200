import React, { useEffect, useState } from "react";
import classes from "./PriceHistoryStatsTab.module.css";
import Stats from "./Stats";
import ApiServices from "../../../services/ApiServices";
import ChartSection from "./ChartSection";

const apiServices = new ApiServices();

const PriceHistoryStatsTab = ({ asset, loading }) => {
  const [sales, setSales] = useState(null);
  const [template, setTemplate] = useState("Loading");
  const [schema, setSchema] = useState(null);
  const [totalSales, setTotalSales] = useState(null);

  const isTemplate = (asset) => asset.template_id;

  const getStates = (asset) => {
    if (asset) {
      isTemplate(asset)
        ? setTemplate(asset)
        : asset.template
        ? setTemplate(asset.template)
        : setTemplate(null);
      asset.schema ? setSchema(asset.schema) : setSchema(null);
    }
  };

  const calcTotalSales = (sales) =>
    sales.flat().reduce((totalSales, day) => {
      return totalSales + parseInt(day.sales);
    }, 0);

  useEffect(() => {
    getStates(asset);
  }, [asset]);

  useEffect(() => {
    const getPricesHistory = async (templateId, schemaName) => {
      const res = await apiServices.getPriceHistory(templateId, schemaName);
      setSales(res);
    };

    if (template && template !== "Loading") {
      getPricesHistory(template.template_id);
    } else if (template === null && schema) {
      getPricesHistory(null, schema.name);
    }
  }, [template, schema]);

  useEffect(() => {
    if (sales) {
      const total = calcTotalSales(sales);
      setTotalSales(total);
    }
  }, [sales]);

  return (
    <div className={classes.statsSection}>
      <p className={classes.text}>
        This price history is based on all NFT sales with the same template ID,
        but the price might fluctuate for specific NFTs
      </p>
      <Stats asset={asset} sales={sales} totalSales={totalSales} />
      {totalSales ? <ChartSection sales={sales} loading={loading} /> : null}
    </div>
  );
};

export default PriceHistoryStatsTab;
