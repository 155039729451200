import React from 'react'
import classes from './Status.module.css'

export default function Status(props) {
  return (
    <div className={classes.statusMainContainer}>
      <div className={classes.statusTitle}>{props.title}</div>
      {
        props.content && props.content.map((item, index) => {
          return <div className={classes.contentMainContainer} key={index}>
            <div className={classes.contentValue}>{item.value ? parseFloat(item.value).toFixed(2) : 0}</div>
            <div className={classes.contentTitle}>{item.title}</div>
          </div>
        })
      }
    </div>
  )
}
