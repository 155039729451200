// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Seller_sellerValue__mo9uN {\n  display: flex;\n  align-items: center;\n  color: #dc3825;\n  cursor: pointer;\n}\n.Seller_avatarWrapper__XbaxI {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n}\n.Seller_avatarWrapper__XbaxI > img {\n  max-width: 30px;\n}\n@media only screen and (max-width: 980px) {\n  .Seller_avatarWrapper__XbaxI {\n    margin-right: 6px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/Seller.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".sellerValue {\n  display: flex;\n  align-items: center;\n  color: #dc3825;\n  cursor: pointer;\n}\n.avatarWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n}\n.avatarWrapper > img {\n  max-width: 30px;\n}\n@media only screen and (max-width: 980px) {\n  .avatarWrapper {\n    margin-right: 6px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellerValue": "Seller_sellerValue__mo9uN",
	"avatarWrapper": "Seller_avatarWrapper__XbaxI"
};
export default ___CSS_LOADER_EXPORT___;
