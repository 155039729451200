// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_loadMoreBtnContainer__3UTh3 > button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  outline: none;\n  background: none;\n  border: 2px solid #e6e8ec;\n  border-radius: 90px;\n  margin: auto;\n  padding: 10px 16px;\n  cursor: pointer;\n}\n\n.Loader_loadMoreBtnContainer__3UTh3 > button > span {\n  font-size: 14px;\n  color: #23262f;\n  font-weight: bold;\n  margin-left: 10px;\n}\n\n.Loader_loaderAnimationIcon__3LWah {\n    width: 16px;\n    height: 16px;\n    animation: Loader_loaderAnimation__3TuP2 1s infinite;\n  }\n  \n@keyframes Loader_loaderAnimation__3TuP2 {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/UI/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oDAAsC;EACxC;;AAEF;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loadMoreBtnContainer > button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  outline: none;\n  background: none;\n  border: 2px solid #e6e8ec;\n  border-radius: 90px;\n  margin: auto;\n  padding: 10px 16px;\n  cursor: pointer;\n}\n\n.loadMoreBtnContainer > button > span {\n  font-size: 14px;\n  color: #23262f;\n  font-weight: bold;\n  margin-left: 10px;\n}\n\n.loaderAnimationIcon {\n    width: 16px;\n    height: 16px;\n    animation: loaderAnimation 1s infinite;\n  }\n  \n@keyframes loaderAnimation {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadMoreBtnContainer": "Loader_loadMoreBtnContainer__3UTh3",
	"loaderAnimationIcon": "Loader_loaderAnimationIcon__3LWah",
	"loaderAnimation": "Loader_loaderAnimation__3TuP2"
};
export default ___CSS_LOADER_EXPORT___;
