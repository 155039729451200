import React from "react";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import FilterValue from "../../FilterValue/FilterValue";
import classes from "./MarketPageFilters.module.css";

export default function MarketPageFilters(props) {
  let allCollections = props.collectionOptions ?? [];
  allCollections = ["All creators", "Other", ...allCollections]
  allCollections = allCollections.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t === thing
      ))
  )

  let regex = new RegExp('^[0-9]*$');

  const changePriceHandler = (value, name) =>{
    if(regex.test(value)){
      props.updatePrice(value, name);
    }
  }
  const changeMintHandler = (value, name) =>{
    if(regex.test(value)){
      props.updateMint(value, name);
    }
  }
  return (
    <div className={classes.searchFiltersContainer}>
      <div className={classes.creatorDropContainer}>
        <FilterDropdown
          options={allCollections}
          setSelected={props.setCollection}
          selected={props.selectedCollection}
          setPage={props.setPage}
        />
      </div>
      <div className={classes.recentDropContainer}>
        <FilterDropdown
          options={props.orderListingTypes}
          setSelected={props.handleOrderType}
          selected={props.selectedOrder}
          setPage={props.setPage}
        />
      </div>
      <div className={classes.assetsAndMintFiltersContainter}>
        <div className={classes.chooseCurrencyContainer}>
          <h3>Choose Currency</h3>
          <FilterDropdown circleHeight="20px" circleWidth="25px" height="auto"
                          selected={props.filterCurrencyDropDownValue}
                          options={props.supportedTokens}
                          setSelected={props.filterCurrencyDropDown}
                          setPage={props.setPage}
          />
        </div>
        <FilterValue
          title="Asset Value"
          currency=""
          minValue={props.minPrice}
          onChangeMinValue={(e) => props.updatePrice(e.target.value, 'min')}
          maxValue={props.maxPrice}
          onChangeMaxValue={(e) => props.updatePrice(e.target.value, 'max')}
        />

        <FilterValue
          title="Minting"
          currency=""
          minValue={props.minTemplateMint}
          onChangeMinValue={(e) => props.updateMint(e.target.value, 'min')}
          maxValue={props.maxTemplateMint}
          onChangeMaxValue={(e) => props.updateMint(e.target.value, 'max')}
        />
      </div>
    </div>
  )
}
