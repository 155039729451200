import React, { useState, useLayoutEffect } from "react";
import classes from "./Accordion.module.css";

export default function Accordion(props) {
  const [toggle, setToggle] = useState(false);
  const screen = useWindowSize();
  function useWindowSize() {
    const [size, setSize] = useState([0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const toogleAccordionHandler = () => {
    setToggle(!toggle);
  };
  const content = props.content.map((item, index) => {
    return (
      <ul key={index} className={classes.contentContainer}>
        <li>
          <span>{index + 1}.</span>
          <div>{item}</div>
        </li>
      </ul>
    );
  });
  return (
    <div
      className={classes.accordionMainContainer}
      style={
        toggle && props.content.length < 7
          ? screen < 556
            ? { height: 550 }
            : { height: 400 }
          : toggle && props.content.length < 13
            ? screen < 556
              ? { height: 720 }
              : { height: 600 }
            : screen < 556
              ? { height: 100 }
              : { height: 70 }
      }
    >
      <div
        className={classes.accordionTitleAndIconContianer}
        onClick={toogleAccordionHandler}
      >
        <div
          className={classes.accordionTitle}
          style={toggle ? { color: "#dc3825" } : null}
        >
          <i className="fas fa-info-circle" style={{ color: "#777e91" }}></i>{" "}
          <div>{props.title}</div>
        </div>
        <div className={classes.accordionIcon}>
          <i
            class="fas fa-minus"
            style={toggle ? { opacity: 1 } : { opacity: 0 }}
          ></i>
          <i
            className="fas fa-plus"
            style={toggle ? { transform: "rotate(90deg)", opacity: 0 } : null}
          ></i>
        </div>
      </div>
      <div
        className={classes.accordionContentContainer}
        style={
          toggle
            ? { transform: "translateY(0)", opacity: 1 }
            : { transform: "translateY(10px)", opacity: 0 }
        }
      >
        <div className={classes.headingContainer}>{props.heading}</div>
        {content}
      </div>
    </div>
  );
}
