import { Field, Form, Formik } from "formik";
import classes from "./CreateNewCollection.module.css";
import SingleCollectiblePageUploadFile from "../SingleCollectiblePage/SingleCollectiblePageUploadFile/SingleCollectiblePageUploadFile";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_IMG,
  SET_ERR_MSG,
  SET_FILE_NAME,
  SET_IMAGE_IPFS_URL,
  SET_IMG,
  SET_IMG_ERR,
  SET_PREV_AUDIO,
  SET_PREV_IMG,
  SET_PREV_VIDEO,
} from "../../stores/reducers/newCollectionReducer";
import ApiServices from "../../services/ApiServices";
import BlockchainService from "../../services/BlockchainService";
import Backdrop from "../UI/Backdrop/Backdrop";
import NFTsSuccessModal from "../Model/NFTsSuccessModal";
import ErrorModal from "../Model/ErrorModal";
import PushNotification from "../Model/PushNotification";
import Button from "../Button/Button";
import {
  acceptedFileTypes,
  acceptedFileTypesArray,
  imageMaxSize,
  schemaName,
} from "../../constants/CreateNewCollection";
import BuyRamModal from "../Model/BuyRamModal";

export const CreateNewCollection = (props) => {
  const selectUserData = useSelector((state) => state.newCollection.userData);
  const imgErr = useSelector((state) => state.newCollection.imgErr);
  const userLogin = useSelector((state) => state.auth.userLogin);

  const dispatch = useDispatch();

  const collectionApi = new ApiServices();
  const blockchainService = new BlockchainService();

  const [notificationTitle, setNotificationTitle] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [pending, setPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(false);
  const [validErr, setValidErr] = useState(false);
  const [marketFee, setMarketFee] = useState(6);
  const [buyRam, SetBuyRam] = useState(false);
  const [showBuyRamModal, setBuyRamModal] = useState(false);

  const mintNFT = async (ipfsHash, values, resetForm) => {
    let assetContentKey = "";

    if (selectUserData.prevImg !== "") {
      assetContentKey = "img";
    }
    if (selectUserData.prevVideo !== "") {
      assetContentKey = "video";
    }
    if (selectUserData.prevAudio !== "") {
      assetContentKey = "audio";
    }
    if (
      userLogin !== "" &&
      values.displayName !== "" &&
      schemaName !== "" &&
      values.title !== "" &&
      ipfsHash !== "" &&
      assetContentKey !== ""
    ) {
      const marketFeeInPercents = marketFee * 0.01;

      let createCollectionAction = blockchainService.createCollectionAction(
        userLogin,
        values.displayName,
        ipfsHash,
        values.description,
        values.url,
        values.title,
        marketFeeInPercents
      );

      const result = await blockchainService.pushTransaction(
        createCollectionAction,
        props.ual
      );

      if (result.success) {
        setShowModal(true);
        setShowBackdrop(true);
        window.scrollTo(0, 0);
        dispatch(CLEAR_IMG());
        resetForm({ values: "" });
        setMarketFee(6);
      } else {
        if (result.message.includes("insufficient ram")) {
          setErrorMsg(result.message);
          setPending(false);
          SetBuyRam(true);
          setShowErrorModal(true);
          setShowBackdrop(true);
          window.scrollTo(0, 0);
          return;
        }
        dispatch(SET_ERR_MSG(result.message));
        setShowErrorModal(true);
        setShowBackdrop(true);
        window.scrollTo(0, 0);
      }
    }
  };

  const onSubmitHandler = async (values, resetForm) => {
    setValidErr(false);
    dispatch(SET_IMG_ERR(false));
    setPending(true);
    if (
      /^$|^([a-z1-5]{12})+$/.test(values.title) &&
      (selectUserData.img !== "" ||
        selectUserData.prevVideo !== "" ||
        selectUserData.prevAudio !== "")
    ) {
      setShowLoader(true);
      setShowBackdrop(true);
      window.scrollTo(0, 0);

      let res = await collectionApi.uploadFileOnIpfs(selectUserData.img);

      if (res) {
        console.log('res.data.IpfsHash',res.data.IpfsHash);
        dispatch(SET_IMAGE_IPFS_URL(res.data.IpfsHash));
        await mintNFT(res.data.IpfsHash, values, resetForm);
      } else {
        setShowErrorModal(true);
        setShowBackdrop(true);
        window.scrollTo(0, 0);
      }
    } else {
      if (!/^$|^([a-z1-5]{12})+$/.test(values.title)) {
        setValidErr(true);
      } else {
        dispatch(SET_IMG_ERR(true));
      }
    }

    // setErrorMsg("");
    // setPending(false);
  };

  const goBackHandler = () => {
    window.history.back();
  };

  const showNotificationHandler = (msg) => {
    setNotificationTitle(msg);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        showNotificationHandler(
          "This file is not allowed." + currentFileSize + " bytes too large"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        showNotificationHandler(
          "This file is not supported or file size is too large"
        );
        return false;
      }
      return true;
    }
  };

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];

        dispatch(SET_IMG(currentFile));

        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;

            dispatch(SET_PREV_VIDEO(""));
            dispatch(SET_PREV_AUDIO(""));
            dispatch(SET_PREV_IMG(""));

            if (currentFile.type === "video/mp4") {
              dispatch(SET_PREV_VIDEO(myResult));
            } else if (
              currentFile.type === "audio/mpeg" ||
              currentFile.type === "audio/wav" ||
              currentFile.type === "audio/ogg"
            ) {
              dispatch(SET_PREV_AUDIO(myResult));
            } else {
              dispatch(SET_PREV_IMG(myResult));
            }

            dispatch(SET_FILE_NAME(currentFile.name));
          },
          false
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
    dispatch(SET_IMG_ERR(false));
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setShowErrorModal(false);
    setShowLoader(false);
    setModalAnimation(true);
    setShowBackdrop(false);
    setBuyRamModal(false);
    dispatch(SET_ERR_MSG(""));
  };

  const marketFeeValidate = (e) => {
    if (/^[0-9]$/.test(e.currentTarget.value)) {
      setMarketFee(e.currentTarget.value);
    }
  };

  const openBuyRamModal = () => {
    setBuyRamModal(true);
    setShowBackdrop(true);
    window.scrollTo(0, 0);
  };
  const removeBuyRamError = () => {
    setErrorMsg("");
    SetBuyRam(false);
    setBuyRamModal(false);
  };
  return (
    <div className={classes.CreateNewCollectionWrapper}>
      <div className={classes.goBackBtnWrapper}>
        <Button secondary onClick={goBackHandler}>
          <div className={classes.goBackBtn}>
            <i className="fas fa-arrow-left"></i> Go back
          </div>
        </Button>
      </div>
      <h2 className={classes.mainTitle}>Create New Collection</h2>
      <Formik
        initialValues={{
          title: "",
          displayName: "",
          url: "",
          description: "",
          marketFee: 0,
        }}
        validateOnBlur
        onSubmit={(values, { resetForm }) => onSubmitHandler(values, resetForm)}
      >
        {({ values, errors, touched, isValidating }) => (
          <Form className={classes.formikForm}>
            <div className={classes.formAddImg}>
              <SingleCollectiblePageUploadFile
                acceptedFileTypes={acceptedFileTypes}
                imageMaxSize={imageMaxSize}
                handleOnDrop={handleOnDrop}
                fileName={selectUserData.fileName}
              />
            </div>
            <div className={classes.formSubmitArea}>
              <div className={classes.formAreaContainer}>
                <div className={classes.formLeftSide}>
                  <div className={classes.formLabel}>
                    Collection Name
                    <span className={classes.redStar}>*</span>
                    <br />
                    (12 Character, 1-5 & a-z)
                  </div>
                  <Field
                    type="text"
                    name="title"
                    className={classes.formField}
                    maxLength={12}
                    required={true}
                  />
                  <div className={classes.formLabel}>
                    Display Name<span className={classes.redStar}>*</span>
                  </div>

                  <Field
                    type="text"
                    name="displayName"
                    className={classes.formField}
                    required={true}
                  />

                  <div className={classes.formLabel}>Website URL</div>
                  <Field type="text" name="url" className={classes.formField} />
                </div>
                <div className={classes.formRightSide}>
                  <div className={classes.formLabel}>
                    Collection Description
                  </div>
                  <Field
                    as="textarea"
                    name="description"
                    className={`${classes.formField} ${classes.formFieldTextarea}`}
                  />

                  <div className={classes.formLabel}>
                    Market Fee (0% - 6%)
                    <span className={classes.redStar}>*</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    max="6"
                    name="marketFee"
                    value={marketFee}
                    className={`${classes.formField} ${classes.valueInputContainer}`}
                    onChange={(e) => marketFeeValidate(e)}
                  />
                </div>
              </div>
              <div className={classes.formValidErr}>
                {validErr ? (
                  <div>
                    Name contains invalid characters or less than 12 character
                  </div>
                ) : imgErr ? (
                  <div className={classes.errNotification}>
                    Content is required! please upload file
                  </div>
                ) : buyRam ? (
                  errorMsg
                ) : null}
              </div>
              {buyRam && (
                <div style={{ marginBottom: "10px" }}>
                  <Button onClick={openBuyRamModal}>Buy Ram</Button>
                </div>
              )}
              <button type="submit" className={classes.formSubmitBtn}>
                Create Collection
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className={classes.modalsContainer}>
        <Backdrop
          show={showBackdrop}
          onClick={closeModalHandler}
          animation={modalAnimation}
        />
        <NFTsSuccessModal
          msgType="The collection is created successfully"
          showModal={showModal}
          close={closeModalHandler}
        />
        <ErrorModal
          errorMsg={errorMsg}
          showModal={showErrorModal}
          close={closeModalHandler}
        />
        <PushNotification
          title={notificationTitle}
          showNotification={showNotification}
        />
        {showBuyRamModal && (
          <BuyRamModal
            msgType="Buy Ram"
            showModal={showBuyRamModal}
            close={closeModalHandler}
            ual={props.ual}
            showNotificationHandler={showNotificationHandler}
            removeError={removeBuyRamError}
          />
        )}
      </div>
    </div>
  );
};
