import React, { useState, useRef, useEffect } from "react";
import classes from "./FilterDropdown.module.css";
import WAXLogo from "../../assets/img/wax.webp";
import TelosLogo from "../../assets/img/telos.png";

export default function FilterDropdown(props) {
  const [toggle, setToogle] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const refDrop = useRef();
  let refInput = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDrop);
    if (showInput) {
      refInput.current.focus();
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDrop);
    };
  }, [showInput]);

  const handleClickOutsideDrop = (event) => {
    if (refDrop.current && !refDrop.current.contains(event.target)) {
      setShowInput(false);
      setToogle(false);
      setInputValue("");
    }
  };

  const inputValueHandler = () => {
    if (inputValue) {
      props.setSelected(inputValue);
    }
    setShowInput(false);
    setToogle(false);
    setInputValue("");
  };

  return (
    <div
      className={classes.filterDropdownContainer}
      ref={refDrop}
      style={{
        borderRadius: props.borderRadius,
        borderColor: props.borderColor,
        height: props.height,
      }}
    >
      <div className={classes.dropdownSelectedItem}>
        {props.selected === "WAX" ? (
          <img src={WAXLogo} alt="wax" />
        ) : props.selected === "Telos" ? (
          <img src={TelosLogo} alt="telos" />
        ) : null}
        {props.selected}
      </div>
      <div
        className={classes.dropdownIconContainer}
        onClick={() => {
          setToogle(!toggle);
          setShowInput(false);
        }}
        style={{
          border: props.border,
          color: props.color,
          height: props.circleHeight,
          width: props.circleWidth,
        }}
      >
        <i className="fas fa-angle-down"></i>
      </div>
      {toggle && (
        <ul
          style={
            props.height
              ? { height: props.height }
              : props.options.length > 5
              ? { height: 300 }
              : props.borderColor
              ? { borderColor: "#E6E8EC" }
              : null
          }
        >
          {props.options.map((item, index) => {
                if(item === "TLOS / USD" ){
                    return null
                }
            return item !== "Other" ? (
              <div
                key={index}
                onClick={() => {
                  if (props.setPage) props.setPage(1);
                  props.setSelected(item);
                  setShowInput(false);
                  setToogle(false);
                  setInputValue("");
                }}
              >
                {props.colorDrop && (
                  <div
                    className={classes.dropColor}
                    style={{ background: item, borderColor: item }}
                  />
                )}
                <li style={{ fontSize: props.itemFontSize }}>
                  {item === "WAX" ? (
                    <img src={WAXLogo} alt="wax" />
                  ) : item === "Telos" ? (
                    <img src={TelosLogo} alt="telos" />
                  ) : null}
                  {item}
                </li>
              </div>
            ) : (
              <div
                key={index}
                onClick={() => {
                  setShowInput(true);
                }}
              >
                <li>Other</li>
                {showInput && (
                  <div>
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      ref={refInput}
                    />
                    <div
                      style={
                        inputValue
                          ? { transform: "scale(1)", opacity: 1 }
                          : { transform: "scale(0)", opacity: 0 }
                      }
                      onClick={inputValueHandler}
                    >
                      <i className="fas fa-paper-plane"></i>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
}
