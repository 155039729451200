import React, {useEffect, useState} from "react";
import classes from "./HomePageHotCollection.module.css";
import ApiServices from "../../../services/ApiServices";
import AppServices from "../../../services/AppService";
import {DisplayCollection} from "./DisplayCollection/DisplayCollection";

const COLLECTION_CACHE = "COLLECTION_CACHE";
const FIVE_MIN = 1000 * 60 * 5;

export default function HomePageHotCollection() {
  const [loading, setLoading] = useState(true)
  const [hotCollection, setHotCollection] = useState([]);
  const [cacheFlag, setCacheFlag] = useState(false);


  const apiService = new ApiServices();
  const appService = new AppServices();


  useEffect(() => {
    setInterval(() => {
      sessionStorage.removeItem(COLLECTION_CACHE)
      setCacheFlag(true)
    }, FIVE_MIN)
  }, [])

  useEffect(() => {
    (async () => {
      await getTopCollections();
    })()
  }, [cacheFlag])


  const getTopCollections = async () => {

    setLoading(true);
    const collectionsFromLocal = JSON.parse(sessionStorage.getItem(COLLECTION_CACHE));

    if(collectionsFromLocal){

      setHotCollection(collectionsFromLocal)

    } else {

      let collectionList = await apiService.getTopCollection();
      if (collectionList && collectionList.length > 0) {

        let hotCollection = [];

        for (let i = 0; i < collectionList.length; i++) {

          let collection = collectionList[i];
          let { title, userImg, userName, path } = "";
          title = collection.collection_name;
          userImg = collection.img;
          userName = collection.author;
          path = `/collections/${collection.collection_name}`;

          let hotCollectionImges = [];
          let tempData = {};

          if(collection.img){

            tempData["original"] = collection.img
            hotCollectionImges.push(tempData);

          } else{

            let topCollectionAssets = await apiService.getTopCollectionAssets(title);

            if (topCollectionAssets && topCollectionAssets.length > 0) {

              let asset = topCollectionAssets[0];
              tempData["original"] = appService.getIpfsUrl(asset.data.img, false);
              hotCollectionImges.push(tempData);

            }
          }

          let item = {title, userImg, userName, hotCollectionImges, path};
          hotCollection.push(item);

          setHotCollection([...hotCollection]);
          sessionStorage.setItem(COLLECTION_CACHE, JSON.stringify(hotCollection))
          setCacheFlag(false);
        }

      }

    }
    setLoading(false);
  };

  return (
    <div className={classes.hotCollectionMainContainer}>
      <div className={classes.hotCollectionInnerConatiner}>
        <h1><p>Hot</p>Collections</h1>
        <div className={classes.hotCollectionGalleryContainer}>
          <DisplayCollection
            hotCollection={hotCollection}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
