import React from "react";
import classes from "./SaleLogs.module.css";
import externa_link_png from "../../../assets/img/external-link-symbol.png";

export default function SeleLogs({ logs }) {
  const query = () => {
    if (process.env.REACT_APP_APPNAME.includes("telos-mainnet")) {
      return "?network=telos";
    }
    if (process.env.REACT_APP_APPNAME.includes("telos-testnet")) {
      return "?network=telostest";
    }
    return "";
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div style={{ width: "25%", textAlign: "center" }}> event </div>
          <div style={{ width: "80%", textAlign: "center" }}>data</div>
          <div style={{ width: "30%", textAlign: "center" }}>date</div>
          <div style={{ width: "5%", textAlign: "center" }}>tnx</div>
        </div>
        {logs && logs?.details?.length > 0 ? (
          logs.details.map((data, index) => {
            const date = new Date(parseInt(data.created_at_time));
            return (
              <div key={index} className={classes.table}>
                <div className={classes.event}>{data.name}</div>
                <div style={{ width: "80%", marginLeft: "20px" }}>
                  {Object.keys(data.data).map((key, index) => (
                    <div
                      className={
                        index >= 1
                          ? classes.multiDataDetails
                          : index === 0
                          ? classes.dataDetails
                          : ""
                      }
                    >
                      <div
                        style={{
                          width: "70%",
                          textAlign: "center",
                          padding: "5px",
                          borderRight: "2px solid",
                        }}
                      >
                        {key}
                      </div>
                      <div
                        style={{
                          width: "30%",
                          textAlign: "center",
                          padding: "5px",
                        }}
                      >
                        {data.data[key] ? data.data[key] : `" "`}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ width: "30%", textAlign: "center" }}>
                  {`${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()}`}
                </div>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_EXPLORER_URL}transaction/${
                    data.txid
                  }git `}
                  style={{ width: "5%", textAlign: "center" }}
                >
                  <img src={externa_link_png} alt={"externa_link_png"} />
                </a>
              </div>
            );
          })
        ) : (
          <div className={classes.table}>
            <p className={classes.dataNotFound}>data not found</p>
          </div>
        )}
        <div style={{ float: "right", padding: "20px", width: "100px" }}>
          <i
            style={{ marginRight: "10px", cursor: "pointer" }}
            className="fas fa-arrow-left"
            onClick={() => {
              if (logs?.index > 1)
                logs?.changePageIndex(logs.index ? logs.index - 1 : 1);
            }}
          ></i>
          {/* <div>{logs?.index}</div> */}
          <i
            style={{ marginLeft: "10px", cursor: "pointer" }}
            className="fas fa-arrow-right"
            onClick={() => {
              if (logs?.details.length > 0)
                logs?.changePageIndex(logs.index ? logs.index + 1 : 1);
            }}
          ></i>
        </div>
      </div>
    </>
  );
}
