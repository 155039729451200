export const LISTING_TYPE = { sales: "Sells", auctions: "Auctions" };
export const CATEGORIES = {
  allItems: "All items",
  art: "Art",
  game: "Game",
  photography: "Photography",
  music: "Music",
  video: "Video",
  zeptaNFTs: "ZeptaNFTs"
};
export const ORDER_TYPE = { desc: "Listings Newest", asc: "Listings Oldest" };
export const LIMIT = 20;