import React from "react";
import ReactTooltip from "react-tooltip";
import classes from "./Tooltip.module.css";

const Tooltip = ({ id, tip, string, limit, isProfile, isNavbar, isBackdropMenu, isAsset, onClick }) => {
  return (
    <>
      <a
        className={isProfile 
          ? classes.tooltipProfileName
          : isNavbar
          ? classes.tooltipNavbar
          : isBackdropMenu
          ? classes.tooltipBackdropMenu
          : isAsset
          ? classes.tooltipAssetTitle
          : classes.tooltip
        }
        data-for={id}
        data-tip={tip}
        data-iscapture="true"
        onClick={onClick}
      >
        {limit ? `${string.substring(0, limit)}...` : string}
      </a>
      <ReactTooltip
        id={id}
        multiline={true}
        effect="solid"
      />
    </>
  );
}

export default Tooltip;