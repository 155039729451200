// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageWrapper_pageWrapper__3vIab {\n  width: 85%;\n  max-width: 1200px;\n  margin: 96px auto;\n  display: flex;\n  flex-direction: column;\n  font-family: \"DM Sans\", sans-serif;\n}\n\n.PageWrapper_pageWrapper__3vIab button {\n  align-self: flex-start;\n}\n\n@media only screen and (max-width: 980px) {\n  .PageWrapper_pageWrapper__3vIab {\n   margin: 40px auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/PageWrapper/PageWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;GACC,iBAAiB;EAClB;AACF","sourcesContent":[".pageWrapper {\n  width: 85%;\n  max-width: 1200px;\n  margin: 96px auto;\n  display: flex;\n  flex-direction: column;\n  font-family: \"DM Sans\", sans-serif;\n}\n\n.pageWrapper button {\n  align-self: flex-start;\n}\n\n@media only screen and (max-width: 980px) {\n  .pageWrapper {\n   margin: 40px auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": "PageWrapper_pageWrapper__3vIab"
};
export default ___CSS_LOADER_EXPORT___;
