import React, { Component } from "react";
import AppServices from "../../../services/AppService";
import classes from "./AssetsDetailsPageAssetsImg.module.css";
import AudioSpectrum from "react-audio-spectrum";

export default class AssetsDetailsPageAssetsImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: window.innerWidth,
      spectrumWidth: null,
      spectrumHeight: null,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ dimension: window.innerWidth });
    let spectrumWidth = 0;
    if (this.state.dimension > 420) {
      spectrumWidth = 200;
    }
    if (this.state.dimension > 520) {
      spectrumWidth = 230;
    }
    if (this.state.dimension > 620) {
      spectrumWidth = 240;
    }
    if (this.state.dimension > 720) {
      spectrumWidth = 250;
    }
    if (this.state.dimension > 768) {
      spectrumWidth = 217;
    }
    if (this.state.dimension > 820) {
      spectrumWidth = 235;
    }
    if (this.state.dimension > 920) {
      spectrumWidth = 268;
    }
    if (this.state.dimension > 992) {
      spectrumWidth = 300;
    }
    if (this.state.dimension > 1020) {
      spectrumWidth = 400;
    }
    if (this.state.dimension > 1120) {
      spectrumWidth = 400;
    }
    if (this.state.dimension > 1220) {
      spectrumWidth = 400;
    }
    if (this.state.dimension > 1320) {
      spectrumWidth = 400;
    }
    if (this.state.dimension > 1420) {
      spectrumWidth = 600;
    }
    if (this.state.dimension > 1520) {
      spectrumWidth = 650;
    }

    this.setState({ spectrumWidth });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    let item = this.props.assetsData ?? null;
    let appService = new AppServices();
    return (
      <>
        {item && (
          <div className={classes.assetsDetailsImgContainer}>
            {item.type === "img" ? (
              <img src={appService.getIpfsUrl(item.content)} alt="Assets" />
            ) : item.type === "video" ? (
              <video
                src={appService.getIpfsVidepUrl(item.content)}
                controls="controls"
                autoPlay={true}
                loop={true}
              />
            ) : (
              <div className={classes.audioSpectrumMainContainer}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className={classes.headPhoneIcon}>
                    <i className="fas fa-headphones"></i>
                  </div>
                  <AudioSpectrum
                    id={`audio-canvas`}
                    height={this.state.dimension > 992 ? 750 : 250}
                    width={
                      document.querySelector(
                        ".NFTDetails_assetsDetailsImgContainer__2bqrg"
                      )?.offsetWidth
                    }
                    audioId={`audio-element`}
                    capColor={"#23262f "}
                    capHeight={2}
                    meterWidth={2}
                    meterCount={1000}
                    meterColor={[
                      { stop: 0, color: "#df7549" },
                      { stop: 0.5, color: "#d6c89a" },
                      { stop: 1, color: "#dc3825" },
                    ]}
                    gap={4}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: -15,
                  }}
                >
                  <audio
                    id={`audio-element`}
                    src={appService.getIpfsVidepUrl(item.content)}
                    controls
                    controlsList="nodownload"
                    autoPlay={true}
                    loop={true}
                    muted={false}
                    crossOrigin="anonymous"
                  ></audio>
                </div>
              </div>
            )}

            {/* {item && (
              <div className={classes.assetsDetailsImgTagsContainer}>
                <div className={classes.imgTagsCategory}>
                  {item.category ?? item.title}
                </div>
              </div>
            )} */}
          </div>
        )}
      </>
    );
  }
}
