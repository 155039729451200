import React, { useState, useEffect } from "react";
import Button from "../../Button/Button";
import classes from "./DetailsPurchaseAndPlaceBidCard.module.css";
import { useHistory } from "react-router-dom";
import Timer from "../../Timer/Timer";
import FirebaseService from "../../../services/FirebaseService";

const firebaseService = new FirebaseService();

export default function DetailsPurchaseAndPlaceBidCard(props) {
  const [ownerInfo, setOwnerInfo] = useState(null);
  const history = useHistory();

  const getUserProfile = async (accountName) => {
    let userProfile = await firebaseService.getUser(accountName);

    if (userProfile) {
      userProfile = userProfile.data();
      if (userProfile) {
        formUserInfo(userProfile, accountName);
      }
    }
  };

  const moveToUserProfile = (userName) => {
    history.push("/profile/" + userName);
  };

  const formUserInfo = (userInfo, userName) => {
    setOwnerInfo({
      img: userInfo?.image ?? "",
      name: userInfo?.name ?? userName ?? "",
    });
  };

  useEffect(() => {
    if (props.pppCardData) {
      const userName = props.isAuction
        ? props.pppCardData.buyer
        : props.pppCardData.name;
      (async () => await getUserProfile(userName))();
    }
  }, [props.pppCardData, props.isAuction]);
  return (
    <>
      {props.pppCardData && props.isOwner === false && (
        <>
          {props.pppCardData.state === 1 ? (
            <div className={classes.detailsPPBCardContainer}>
              <div className={classes.highestBidDetailContainer}>
                {ownerInfo && ownerInfo.img ? (
                  <img src={ownerInfo.img} alt="icon" />
                ) : (
                  <i className="fas fa-user-circle"></i>
                )}
                <div className={classes.highestBidDescContainer}>
                  <p>
                    {props.isAuction === true ? (
                      <>
                        Highest bid by{" "}
                        <span
                          onClick={() =>
                            moveToUserProfile(props.pppCardData.buyer)
                          }
                        >
                          {props.pppCardData.buyer}
                        </span>
                      </>
                    ) : (
                      <>
                        Sell created By{" "}
                        <span
                          onClick={() =>
                            moveToUserProfile(props.pppCardData.name)
                          }
                        >
                          {props.pppCardData.name}
                        </span>
                      </>
                    )}
                  </p>
                  <h1>
                    {props.pppCardData.price.toString().split(" ").length > 1
                      ? props.pppCardData.price
                      : `${parseFloat(props.pppCardData.price).toFixed(2)}`}
                    <span>${props.pppCardData.priceUSD.toFixed(2)}</span>
                  </h1>
                </div>
              </div>
              {props.isAuction && (
                <div className={classes.auctionTimeContainer}>
                  <Timer remainingTime={props.pppCardData.remainingTime} />
                </div>
              )}
              <div className={classes.detailsPPBCardBtnContainer}>
                {props.isOwner === false &&
                  (props.isAuction === true ? (
                    <Button
                      onClick={() =>
                        props.onClick(props.isSalePage, props.isAuction)
                      }
                      background={props.accept ? "#2BD900" : null}
                      borderColor={props.accept ? "#2BD900" : null}
                    >
                      <div style={{ color: "white" }}>
                        {props.accept ? "Accept" : "Place a bid"}
                      </div>
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        props.onClick(props.isSalePage, props.isAuction)
                      }
                    >
                      <div style={{ color: "white" }}>Purchase now</div>
                    </Button>
                  ))}
              </div>
            </div>
          ) : (
            <>
              {props.pppCardData.state === 4 ? (
                <div className={classes.expiredTextContainer}>
                  {props.isAuction === true ? "Auction" : "Sell"} is expired.
                </div>
              ) : props.pppCardData.state === 3 ? (
                props.isOwner === true || props.isBuyer === true ? (
                  <div className={classes.claimBtnsContainer}>
                    {props.isOwner === true
                      ? props.pppCardData.claimedBySeller === false && (
                          <Button secondary onClick={props.claimBid}>
                            <div>Claim Bid</div>
                          </Button>
                        )
                      : props.pppCardData.claimedByBuyer === false && (
                          <Button onClick={props.claimAsset}>
                            <div>Claim Asset</div>
                          </Button>
                        )}
                  </div>
                ) : (
                  <>
                    <div className={classes.expiredTextContainer}>
                      This Asset Bought by{" "}
                      <span
                        onClick={() => moveToUserProfile(props.data.buyer)}
                        className={classes.buyer}
                      >
                        {props.data.buyer}
                      </span>{" "}
                      on{" "}
                      <span>
                        {new Date(
                          parseInt(props.data.updated_at_time)
                        ).toDateString()}
                      </span>
                    </div>
                    <div className={classes.priceDiv}>
                      Price : <span>{props.pppCardData.price}</span>
                      <span>(${props.pppCardData.priceUSD.toFixed(4)})</span>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
