import React from "react";
import classes from "./AccountCard.module.css";
import { useHistory } from "react-router-dom";
// import AppServices from '../../services/AppService';

const AccountCard = ({ name, index }) => {
  const history = useHistory();

  const navigateToScreen = (name) => {
    const path = "/profile/" + name;
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <div
      className={classes.cardWrapper}
      key={name + index}
      onClick={() => navigateToScreen(name)}
    >
      <div className={classes.name}>{name}</div>
    </div>
  );
};

export default AccountCard;
