import Logo from "../../assets/img/zeptagramLogo.png";
import classes from "./NetworksBlockchainsPage.module.css";
import WAXLogo from "../../assets/img/wax.webp";
import TelosLogo from "../../assets/img/telos.png";
import NetworksBlockchainsContent from "./NetworksBlockchainsContent/NetworksBlockchainsContent";
import { useEffect, useState } from "react";

const CURRENCY_ARRAY = [
  {
    image: WAXLogo,
    alt: "wax-logo",
    description: "WAX Mainnet",
    linkMainnet: "https://wax.zeptagram.com/",
    linkTestnet: "https://wax-testnet.zeptagram.com/",
  },
  {
    image: TelosLogo,
    alt: "telos-logo",
    description: "Telos Mainnet",
    linkMainnet: "https://telos.zeptagram.com/",
    linkTestnet: "https://telos-testnet.zeptagram.com/",
  },
];

export default function NetworksBlockchainsPage() {
  return (
    <div className={classes.networksBlockchainsPage_MainContaainer}>
      <div className={classes.logoContainer}>
        <img src={Logo} alt="Zeptagram-networks-blockchains-page-log" />
      </div>
      <div className={classes.listOfNetworksAndBlockchains}>
        <NetworksBlockchainsContent currencies={CURRENCY_ARRAY} />
      </div>
    </div>
  );
}
