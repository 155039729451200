// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BannerContent_bannerContentMainContainer__L7EAw {\n width: 70%;\n}\n@media only screen and (max-width: 768px) {\n .BannerContent_bannerContentMainContainer__L7EAw {\n  width: 100%;\n }\n}\n", "",{"version":3,"sources":["webpack://src/components/FilterBannerList/BannerContent.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;AACA;CACC;EACC,WAAW;CACZ;AACD","sourcesContent":[".bannerContentMainContainer {\n width: 70%;\n}\n@media only screen and (max-width: 768px) {\n .bannerContentMainContainer {\n  width: 100%;\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContentMainContainer": "BannerContent_bannerContentMainContainer__L7EAw"
};
export default ___CSS_LOADER_EXPORT___;
