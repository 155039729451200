import firebase from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
firebase.initializeApp(config);
const db = firebase.firestore();

class FirebaseService {
  addUser = async (
    userName,
    { name, image, bio, coverPhoto, facebook, twitter, instagram }
  ) => {
    if (userName) {
      try {
        const userRef = await db.collection("users").doc(userName).set({
          name,
          image,
          bio,
          coverPhoto,
          facebook,
          twitter,
          instagram,
        });
        return userRef;
      } catch (error) {
        return error;
      }
    }
  };

  getUser = async (userName) => {
    let res = null;
    if (userName) {
      const userRef = await db.collection("users").doc(userName).get();
      res = userRef;
    }

    return res;
  };

  getBanners = async () => {
    const userRef = await db
      .collection("banners")
      .doc("FTTWJM8zzMNSmqQfm8OT")
      .get();
    return userRef;
  };

  getArtists = async () => {
    const userRef = await db
      .collection("artists")
      .doc("Ed6MMogUAm1UdpCTHOMH")
      .get();
    return userRef;
  };
}

export default FirebaseService;
