import React from "react";
import AppServices from "../../../services/AppService";
import Timer from "../../Timer/Timer";
import ActionButton from "../../UI/ActionButton/ActionButton";
import PriceBox from "../../UI/PriceBox/PriceBox";
import classes from "./HomePageTopBanner.module.css";
import AudioSpectrum from "react-audio-spectrum";

const appServices = new AppServices();
export default function HomePageTopBanner({
  lists,
  selected,
  setSelected,
  getListingPrice,
  navigateToPath,
  UsdPrice,
}) {
  const AssetsCard = ({ firstCard, secondcCard, item }) => (
    <div className={firstCard ? classes.Cardbody : classes.Cardbody2}>
      <div className={firstCard ? classes.imageDiv : classes.imageDiv2}>
        {item.type === "img" ? (
          <img src={appServices.getIpfsUrl(item.content)} alt="Assets" />
        ) : item.type === "video" ? (
          <video
            src={appServices.getIpfsVidepUrl(item.content)}
            controls="controls"
            autoPlay={true}
            loop={true}
          />
        ) : (
          <div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div className={classes.headPhoneIcon}>
                <i className="fas fa-headphones"></i>
              </div>
              {firstCard && (
                <AudioSpectrum
                  id={`audio-canvas`}
                  height={295}
                  width={269}
                  audioId={`audio-element`}
                  capColor={"#23262f "}
                  capHeight={2}
                  meterWidth={2}
                  meterCount={1000}
                  meterColor={[
                    { stop: 0, color: "#df7549" },
                    { stop: 0.5, color: "#d6c89a" },
                    { stop: 1, color: "#dc3825" },
                  ]}
                  gap={4}
                />
              )}
            </div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                marginTop: -15,
              }}
            >
              {firstCard && (
                <audio
                  id={`audio-element`}
                  src={appServices.getIpfsVidepUrl(item.content)}
                  controlsList="nodownload"
                  autoPlay={true}
                  loop={true}
                  muted={false}
                  crossOrigin="anonymous"
                ></audio>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classes.cardFooter}>
        <p className={classes.cardText}>{item.title} </p>
        <div className={classes.cardContext}>
          <div className={classes.footerImage}>
            {item.type === "img" ? (
              <img src={appServices.getIpfsUrl(item.content)} alt="Assets" />
            ) : item.type === "video" ? (
              <video
                src={appServices.getIpfsVidepUrl(item.content)}
                controls="controls"
                autoPlay={true}
                loop={true}
              />
            ) : null}
          </div>
          <div className={classes.buyButton}>
            {item.purchasePath && (
              <button onClick={() => navigateToPath(item.purchasePath)}>
                {item.actionType === "sale" ? "Buy" : "Bid"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const NftContent = (index) => {
    let item = lists[index];
    console.log(item);
    return (
      <div className={classes.titleContainer}>
        <div className={classes.bannerDetailsContainer}>
          <h1>{item.title}</h1>
          {item.desc && <p>{item.desc}</p>}
          <div className={classes.priceAndStockContainer}>
            {item.tokenAmount && (
              <PriceBox
                price={getListingPrice(item.tokenAmount, item.tokenPrecision)}
                symbol={item.tokenSymbol}
              />
            )}
            {item.tokenAmount && (
              <PriceBox
                price={(
                  getListingPrice(item.tokenAmount, item.tokenPrecision) *
                  UsdPrice
                ).toFixed(2)}
                symbol="$"
              />
            )}
            {item.stock && <span>{item.stock} in stock</span>}
          </div>

          <div className={classes.actionBtnContainer}>
            <ActionButton
              title="details"
              onClick={() => navigateToPath(item.detailsPath)}
              color="black"
            />
            {item.purchasePath && (
              <ActionButton
                title={item.actionType === "sale" ? "Buy" : "Bid"}
                onClick={() => navigateToPath(item.purchasePath)}
              />
            )}
          </div>
          <div>
            {item.startAt !== null && <Timer remainingTime={item.startAt} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.topContainer}>
        {NftContent(selected)}
        <div className={classes.CardContainer}>
          <AssetsCard firstCard={true} item={lists[selected]} />
          {selected + 1 !== lists.length && window.screen.width > 1024 && (
            <AssetsCard secondcCard={true} item={lists[selected + 1]} />
          )}

          <div className={classes.arrowButton}>
            <i
              className="fas fa-arrow-left"
              onClick={() => {
                if (selected === 0) return;
                setSelected(selected - 1);
              }}
            ></i>

            {lists.map((data, index) => {
              return (
                <div
                  key={index}
                  className={
                    selected === index ? classes.activeDash : classes.dashIcone
                  }
                  onClick={() => {
                    if (selected === index) return;
                    setSelected(index);
                  }}
                ></div>
              );
            })}

            <i
              className="fas fa-arrow-right"
              onClick={() => {
                if (selected + 1 === lists.length) return;
                setSelected(selected + 1);
              }}
            ></i>
          </div>
        </div>
      </div>
    </>
  );
}
