import React, { useEffect, useState } from 'react';
import classes from './ChartSection.module.css';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';

const MAX_TICK_NUM = 30;

const ChartSection = ({ sales, loading, symbol, isCollectionDataPage }) => {
  const [chartData, setChartData] = useState(null);
  const [token, setToken] = useState(null);
  const [tickValues, setTickValues] = useState(null);

  const getPrice = (value, tokenPrecision) => Math.round(+(value) / Math.pow(10, tokenPrecision));

  const getNum = (length) => {
    let i = 2;
    let n = length;
    while (n > MAX_TICK_NUM) {
      n = length / i;
      i++;
    };
    return i;
  }

  const createTickValues = (sorted) => {
    if(sorted.length < MAX_TICK_NUM) {
      setTickValues(null);
    } else {
      setTickValues(sorted.reduce((filteredArr, item, i, array) => {
        return i % getNum(array.length) ? filteredArr : [...filteredArr, item.x];
      }, []))
    }
  }

  const getChartData = (sales, isCollectionDataPage, symbol) => {
    const res = sales.reduce((arr, sale) => {
      const value = isCollectionDataPage ? 
          {
            "x": moment(new Date(parseInt(sale.time))).format('DD.MM.YY'),
            "y": getPrice(sale.volume, symbol.token_precision)
          }
        :
          {
            "x": moment(new Date(sale.time)).format('DD.MM.YY'),
            "y": getPrice(sale.average, sale.token_precision)
          }
      return [ ...arr, value ];
    }, []);

    const sorted = res.sort((a, b) => moment(a.x, 'DD.MM.YY').valueOf() - moment(b.x, 'DD.MM.YY').valueOf());
    createTickValues(sorted);

    setChartData([{
      "id": "price",
      "color": "hsl(188, 70%, 50%)",
      "data": sorted
    }]);
  }

  useEffect(() => {
    if(sales && sales.length) {
      getChartData(sales, isCollectionDataPage, symbol);
      if(symbol) {
        setToken(symbol.token_symbol);
      } else {
        setToken(sales[0].token_symbol);
      }
    }
  }, [sales, symbol, isCollectionDataPage])
  
  return (
      <>
        {
          !loading && chartData ? (
            <div className={classes.chartWrapper}>
                  <ResponsiveLine
                    data={chartData}
                    margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    enablePoints={false}
                    curve='catmullRom'
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: token,
                        legendOffset: -45,
                        legendPosition: 'middle'
                    }}
                    axisBottom={{
                      tickValues: tickValues,
                      tickRotation: 90,
                      tickPadding: 0
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    gridXValues={tickValues}
                />
                </div>
          ) : (
            !loading && !chartData ?
                <div>None</div>
              :
                <div className={classes.loading}></div>
          )
        } 
      </>
  )
}

export default ChartSection;
