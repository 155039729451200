import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classes from "./TemplateDetails.module.css";
import PageWrapper from "../../components/PageWrapper";
import ApiServices from "../../services/ApiServices";
import Button from "../../components/Button/Button";
import CardsSection from "../../components/TemplateDetails/CardsSection";
import Loader from "../../components/UI/Loader";
import TabsSections from "../../components/TemplateDetails/TabsSections";

const apiServices = new ApiServices();

const TemplateDetails = ({ ual, history }) => {
  const [templateId, setTemplateId] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const [templateStats, setTemplateStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const goBackHandler = () => history.goBack();

  const getTemplateId = () => {
    const path = window.location.pathname;
    const splittedPath = path.split("/");
    if(splittedPath && splittedPath.length){
      setTemplateId(splittedPath[2]);
    }
  }

  const getTemplateStats = async (collectionName, id) => {
    const stats = await apiServices.getTemplateStats(collectionName, id);
    if(stats) {
      setTemplateStats(stats);
    }
    setLoading(false);
  }

  const getTemplateData = async (id) => {
    const data = await apiServices.getTemplateData(id);
    if(id) {
      setTemplateData(data);
    }
  }

  useEffect(() => {
    getTemplateId();
  }, []);

  useEffect(() => {
    if(templateId) {
      (async () => await getTemplateData(templateId))();
    }
  }, [templateId]);

  useEffect(() => {
    if(templateData
        && templateData.length
        && templateData[0].collection
        && templateData[0].collection.collection_name
        && templateId
      ) {
      (async () => await getTemplateStats(templateData[0].collection.collection_name, templateId))();
    }
  }, [templateData, templateId]);

  return (
    <PageWrapper>
      <Button secondary onClick={goBackHandler}>
        <div className={classes.goBackBtn}>
          <i className="fas fa-arrow-left"></i>
          {' '}
          Go back
        </div>
      </Button>
      {templateData && !loading
        ? <div className={classes.contentWrapper}>
            <CardsSection
              templateData={templateData}
              templateStats={templateStats}
            />
            <TabsSections templateData={templateData} />
          </div>
        : <Loader loading={loading} />
      }
    </PageWrapper>
  );
}

export default withRouter(TemplateDetails);
