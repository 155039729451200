import React, { Component } from "react";
import classes from "./SingleCollectiblePagePreview.module.css";
import AudioSpectrum from "react-audio-spectrum";
import AppServices from "../../../services/AppService";
import verify from "./../../../assets/img/ic_round-verified.svg"
import PreviewDescription from "./PreviewDescription";

const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
const DESCRIPTION_LIMIT = 50;

export default class SingleCollectiblePagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: window.innerWidth,
      spectrumWidth: null,
      spectrumHeight: null,
      usdPrice: null,
    };
    this.appService = new AppServices();
    this.appServices = new AppServices();
  }
  navigateToScreen = (path) => {
    window.history.push(path);
    window.scrollTo(0, 0);
  };
  async componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    let usdPrice = await this.appServices.getUsdPrice();
    this.setState({usdPrice: usdPrice});
  }
  updateWindowDimensions = () => {
    this.setState({ dimension: window.innerWidth });
    let spectrumWidth = 0;
    if (this.state.dimension < 320) {
      spectrumWidth = 200;
    }
    if (this.state.dimension > 320) {
      spectrumWidth = 230;
    }
    if (this.state.dimension > 420) {
      spectrumWidth = 315;
    }
    if (this.state.dimension > 520) {
      spectrumWidth = 400;
    }
    if (this.state.dimension > 620) {
      spectrumWidth = 483;
    }
    if (this.state.dimension > 720) {
      spectrumWidth = 568;
    }
    if (this.state.dimension > 768) {
      spectrumWidth = 217;
    }
    if (this.state.dimension > 820) {
      spectrumWidth = 235;
    }
    if (this.state.dimension > 920) {
      spectrumWidth = 268;
    }
    if (this.state.dimension > 992) {
      spectrumWidth = 188;
    }
    if (this.state.dimension > 1020) {
      spectrumWidth = 196;
    }
    if (this.state.dimension > 1120) {
      spectrumWidth = 221;
    }
    if (this.state.dimension > 1220) {
      spectrumWidth = 247;
    }
    if (this.state.dimension > 1320) {
      spectrumWidth = 272;
    }
    if (this.state.dimension > 1420) {
      spectrumWidth = 290;
    }
    if (this.state.dimension > 1520) {
      spectrumWidth = 300;
    }
    this.setState({ spectrumWidth });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {

    return (
      <>
        {this.props.templatePreview ?
          <div className={[classes.PreviewMainContainer, classes.previewTempMain].join(" ")}>
            <h1>Preview</h1>
            <div className={classes.previewAssetsCardContainer}>
              <div className={classes.PreviewImage}>
                {this.props.prevImg ? (
                  <img src={this.appService.getIpfsUrl(this.props.prevImg)} alt="assetsImg" />
                ) : this.props.prevVideo ? (
                  <video
                    src={this.appService.getIpfsVidepUrl(this.props.prevVideo)}
                    controls={true}
                    loop={true}
                    autoPlay={true}
                    controlsList="nodownload"
                  ></video>
                ) : this.props.prevAudio ? (
                  <div className={classes.audioSpectrumMainContainer}>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <AudioSpectrum
                        id={`audio-canvas${this.props.id}`}
                        height={this.state.dimension > 992 ? 350 : 200}
                        width={
                          this.state.dimension < 420
                            ? 230
                            : this.state.spectrumWidth
                        }
                        audioId={`audio-element${this.props.id}`}
                        capColor={"#23262f "}
                        capHeight={2}
                        meterWidth={2}
                        meterCount={512}
                        meterColor={[
                          { stop: 0, color: "#df7549" },
                          { stop: 0.5, color: "#d6c89a" },
                          { stop: 1, color: "#dc3825" },
                        ]}
                        gap={4}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: -15,
                      }}
                    >
                      <audio
                        id={`audio-element${this.props.id}`}
                        src={this.appService.getIpfsVidepUrl(this.props.prevAudio)}
                        controls
                        controlsList="nodownload"
                        autoPlay={true}
                        loop={true}
                        crossOrigin="anonymous"
                      ></audio>
                    </div>
                  </div>
                ) : (
                  <div>
                    <i className="fas fa-question"></i>
                  </div>
                )}
              </div>
              <div className={classes.PreviewDescContainer}>
                <h1>
                  {this.props.title && this.props.title.length > 22
                    ? this.props.title.substring(0, 20) + "..."
                    : this.props.title}
                </h1>

                {this.props.rarity &&
                  <div className={classes.rarityAndMaxSupplyContainer}>
                    <h3>{this.props.rarity}</h3>
                    {this.props.max_supply &&
                      <span> Max Supply <b>{this.props.max_supply}</b>
                      </span>
                    }
                  </div>
                }
                {this.props.category && (
                  <div className={classes.assetsCategoryContainer}>
                    {this.props.category}
                  </div>
                )}
              </div>
            </div>
          </div>
          :
          <div className={classes.PreviewMainContainer}>

            <h1>Preview</h1>
            <div
              className={classes.clearAllBtnContainer}
              onClick={this.props.clearAllHandler}
            >
              <i className="far fa-times-circle"></i>
              <div>Clear all</div>
            </div>
            <div className={classes.previewAssetsCardContainer}>
              <div className={classes.PreviewImage}>
                {this.props.prevImg ? (
                  <img src={this.props.prevImg} alt="assetsImg" />
                ) : this.props.prevVideo ? (
                  <video
                    src={this.props.prevVideo}
                    controls={true}
                    loop={true}
                    autoPlay={true}
                    controlsList="nodownload"
                  ></video>
                ) : this.props.prevAudio ? (
                  <div className={classes.audioSpectrumMainContainer}>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <AudioSpectrum
                        id="audio-canvas"
                        height={this.state.dimension > 992 ? 350 : 200}
                        width={
                          this.state.dimension < 420
                            ? 230
                            : this.state.spectrumWidth
                        }
                        audioId={"audio-element"}
                        capColor={"#23262f "}
                        capHeight={2}
                        meterWidth={2}
                        meterCount={512}
                        meterColor={[
                          { stop: 0, color: "#df7549" },
                          { stop: 0.5, color: "#d6c89a" },
                          { stop: 1, color: "#dc3825" },
                        ]}
                        gap={4}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: -15,
                      }}
                    >
                      <audio
                        id="audio-element"
                        src={this.props.prevAudio}
                        controls
                        controlsList="nodownload"
                        autoPlay={true}
                        loop={true}
                      ></audio>
                    </div>
                  </div>
                ) : (
                  <div>
                    <i className="fas fa-question"></i>
                  </div>
                )}
              </div>
              <div className={classes.PreviewDescContainer}>
                <h1 className={classes.displayNameWrapper}>
                  {this.props.displayName && this.props.displayName.length > 22
                    ? this.props.displayName.substring(0, 20) + "..."
                    : this.props.displayName}
                  <span>{this.props.displayName && this.props.displayName.length > 0
                    ? <img src={verify} alt="verify" className={classes.verifyImg}/>
                    : null}</span>
                </h1>
                <div className={classes.classesLine}/>
                <PreviewDescription text={this.props.description} limit={DESCRIPTION_LIMIT}/>
                {this.props.rarity &&
                  <div className={classes.rarityAndMaxSupplyContainer}>
                    <h3>{this.props.rarity}</h3>
                  </div>
                }
                {this.props.category && (
                  <div className={classes.assetsCategoryContainer}>
                    {this.props.category}
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
