import React from "react";
import classes from "./AssetVideo.module.css";
import useImageLoaded from "../../../hooks/useImageLoaded";
import AppServices from "../../../services/AppService";

const appService = new AppServices();

const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";

const AssetVideo = ({ content, onClick, isBundle }) => {
  const [ref, loaded, onLoad] = useImageLoaded();
  const reloadImg = (e) => {
    if (e.target.src.includes(RESIZER_URL))
      e.target.src = appService.getIpfsVidepUrl(content);
  };
console.log("content, onClick, isBundle",loaded)
  return (
    <div
      className={`
        ${classes.previewVideoWrapper} 
        ${isBundle && classes.bundlePreviewVideo}   
        ${isBundle ? classes.loadingBundle :null}
      `}
      // ${loaded ? null : isBundle ? classes.loadingBundle : classes.loading}
    >
      <video
        ref={ref}
        src={
          content?.includes("http")
            ? appService.getIpfsVidepUrl(content)
            : `${RESIZER_URL}${content}&size=370`
        }
        onLoadedData={onLoad}
        controls="controls"
        autoPlay={true}
        loop={true}
        muted={true}
        onError={(e) => reloadImg(e)}
        onClick={onClick}
      />
    </div>
  );
};

export default AssetVideo;
