import React, { useState } from "react";
import classes from "./PreviewDescription.module.css"


const ANGLE_UP =  "fas fa-angle-up";
const ANGLE_DOWN = "fas fa-angle-down";

const PreviewDescription = ({ text, limit }) => {
  const [isFullDescription, setIsFullDescription] = useState(false);
  
  const formBtn = (btnText, icon) =>
    <div
      className={classes.showMoreBtnContainer}
      onClick={() => setIsFullDescription(!isFullDescription)}
    >
      <div>{ btnText }</div>
      <i className={icon}></i>
    </div>

  const showDescriptionBtn = (isFullDescription, description) => {
    if(description.length > limit && isFullDescription) {
      return formBtn("Hide", ANGLE_UP);
    }
    if(description.length > limit && !isFullDescription) {
      return formBtn("Show more", ANGLE_DOWN);
    }
    return null;
  }
  
  return (
    <>
      {
        text && text.length && 
          <>
            { text.length > limit 
              ? <p className={classes.description}>
                  {isFullDescription
                    ? text
                    : `${text.substring(0, limit)}...`
                  }
                </p>
              : <p>{text}</p>
            }
            {showDescriptionBtn(isFullDescription, text)}
          </>
      }
    </>
  );
}

export default PreviewDescription;