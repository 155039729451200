import React, { Component } from "react";
import classes from "./SearchNotFoundPageExploreMore.module.css";
import ActivityImg1 from "../../../assets/img/activityImg1.png";
import ActivityImg2 from "../../../assets/img/activityImg2.png";
import ActivityImg3 from "../../../assets/img/activityImg3.png";
import ActivityImg4 from "../../../assets/img/activityImg4.png";
import ExploreMore from "../../UI/ExploreMore/ExploreMore";

export default class SearchNotFoundPageExploreMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exploreMoreData: [
        {
          img: ActivityImg1,
          title: "Art",
          totalItems: 0,
        },
        {
          img: ActivityImg2,
          title: "Photography",
          totalItems: 0,
        },
        {
          img: ActivityImg3,
          title: "Game",
          totalItems: 0,
        },
        {
          img: ActivityImg4,
          title: "Music",
          totalItems: 0,
        },
      ],
    };
  }
  render() {
    const exploreMoreData = this.state.exploreMoreData.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <ExploreMore
            img={item.img}
            title={item.title}
            totalItems={item.totalItems}
          />
        </React.Fragment>
      );
    });
    return (
      <div className={classes.searchNotFoundExploreMoreContainer}>
        <h2>Explore more</h2>
        <div className={classes.exploreMoreItemsContainer}>
          {exploreMoreData}
        </div>
      </div>
    );
  }
}
