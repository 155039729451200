import React from "react";
import classes from "./BannerContent.module.css";

export default function BannerContent(props) {
  if (props.isSelected)
    return (
      <div className={classes.bannerContentMainContainer}>{props.children}</div>
    );
  return null;
}
