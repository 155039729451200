import React from "react";
import classes from "./SingleCollectiblePageItemDetails.module.css";
import { ToggleSwitch } from "./ToggleSwitch/ToggleSwitch";
import Cross from "./../../../assets/img/cross.svg";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_MARKET_FEE,
  SET_ASSET_CONTENT_LOCK,
  SET_ASSET_DESCRIPTION,
  SET_ASSET_DISPLAY_NAME,
  SET_ASSET_IN_STOCK,
  SET_ASSET_INSTANTLY_SOLD,
  SET_ASSET_PRICE,
  SET_ASSET_PUT_ON_SALE,
  SET_ASSET_TITLE,
  SET_ASSET_TYPE,
  SET_ASSET_WEBSITE_URL,
  SET_CURRENT_COLLECTION,
  SET_RARITY,
  SET_USER_COLL_SCHEMAS,
} from "../../../stores/reducers/singleCollectibleReducer";
import { apiService } from "../../../constants/Servises";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";

const ITEM_NAME_MAX_LENGTH = 12;
const COLLECTION_COLORS = ["#45B26B", "#EF466F", "#9757D7"];

export const SingleCollectiblePageItemDetails = React.memo(
  ({
    userLogin,
    userAssetsData,
    showAdvancedSettings,
    toggleAdvancedSettingsHandler,
    setUserCollectionHandler,
    userCollectionSchemas,
    setUserCollectionSchemas,
    setUsrSchema,
    boxErrorHighlight = {},
  }) => {
    const collectionsArray = useSelector(
      (state) => state.singleCollectible.userCollections
    );
    const userSchemaSelect = useSelector(
      (state) => state.singleCollectible.userSchema
    );
    const currentCollectionSelect = useSelector(
      (state) => state.singleCollectible.currentCollection
    );
    const rarityList = useSelector(
      (state) => state.singleCollectible.rarityList
    );
    const assetTypeList = useSelector(
      (state) => state.singleCollectible.assetTypeList
    );
    const rarity = useSelector(
      (state) => state.singleCollectible.userAssetsData.rarity
    );
    const assetType = useSelector(
      (state) => state.singleCollectible.userAssetsData.category
    );
    const putOnSale = useSelector(
      (state) => state.singleCollectible.userAssetsData.putOnSale
    );
    const instantlySold = useSelector(
      (state) => state.singleCollectible.userAssetsData.instantlySold
    );
    const contentLock = useSelector(
      (state) => state.singleCollectible.userAssetsData.contentLock
    );

    const dispatch = useDispatch();

    const setRarityHandler = (item) => {
      dispatch(SET_RARITY(item));
    };
    const setAssetTypeHandler = (item) => {
      dispatch(SET_ASSET_TYPE(item));
    };

    const onChangePriceHandler = (e) => {
      if (/^-?\d*\.?\d*$/.test(e.target.value)) {
        dispatch(SET_ASSET_PRICE(e.currentTarget.value));
      }
    };
    const onChangeOnStockHandler = (e) => {
      if (/^-?[1-9]\d*$/.test(e.target.value)) {
        dispatch(SET_ASSET_IN_STOCK(e.currentTarget.value));
      }
    };
    const marketFeeValidate = (e) => {
      if (/^[0-9]$/.test(e.currentTarget.value)) {
        dispatch(SET_MARKET_FEE(e.currentTarget.value));
      }
    };
    const chooseCollectionHandler = async (collectionName) => {
      let collectionSchemas = await apiService.getCollectionSchema(
        collectionName
      );
      if (collectionSchemas) {
        setUserCollectionSchemas(collectionSchemas);
        dispatch(
          SET_USER_COLL_SCHEMAS({ userCollectionSchemas: collectionSchemas })
        );
      }
      dispatch(SET_CURRENT_COLLECTION(collectionName));
      setUserCollectionHandler(collectionName);
    };
    const chooseSchemaHandler = (schemaName) => {
      setUsrSchema(schemaName);
    };

    const toggleAdvanced = () => {
      if (showAdvancedSettings) {
        setUserCollectionHandler("");
        setUsrSchema("");
      }
      toggleAdvancedSettingsHandler();
    };

    const putOnSaleHandler = (e) =>
      dispatch(SET_ASSET_PUT_ON_SALE(e.target.checked));
    const instantlySoldHandler = (e) =>
      dispatch(SET_ASSET_INSTANTLY_SOLD(e.target.checked));
    const contentLockHandler = (e) =>
      dispatch(SET_ASSET_CONTENT_LOCK(e.target.checked));
    const setTitleHandler = (e) =>
      dispatch(SET_ASSET_TITLE(e.currentTarget.value));
    const setDisplayNameHandler = (e) =>
      dispatch(SET_ASSET_DISPLAY_NAME(e.currentTarget.value));
    const setDescriptionHandler = (e) =>
      dispatch(SET_ASSET_DESCRIPTION(e.currentTarget.value));
    const setWebsiteUrlHandler = (e) =>
      dispatch(SET_ASSET_WEBSITE_URL(e.currentTarget.value));

    return (
      <div className={classes.itemDetailContainer}>
        <h4>Item Detail</h4>
        <div className={classes.FormContainer}>
          <div>
            Item name
            <span className={classes.requiredField}>*</span>
            <br />
            <span className={classes.smallCharacters}>
              (12 Character, 1-5 & a-z)
            </span>
          </div>
          <input
            className={boxErrorHighlight.title && classes.errorInputBox}
            type="text"
            placeholder="e. g. “Redeemable Bitcoin Card with logo”"
            value={userAssetsData.title}
            onChange={setTitleHandler}
            maxLength={ITEM_NAME_MAX_LENGTH}
          />
        </div>
        <div className={classes.FormContainer}>
          <div>
            Display name<span className={classes.requiredField}>*</span>
          </div>
          <input
            className={boxErrorHighlight.displayName && classes.errorInputBox}
            type="text"
            placeholder="e. g. “Redeemable Bitcoin Card with logo”"
            value={userAssetsData.displayName}
            onChange={setDisplayNameHandler}
          />
        </div>
        <div className={classes.FormContainer}>
          <div>
            Description<span className={classes.requiredField}>*</span>
          </div>
          <textarea
            className={boxErrorHighlight.description && classes.errorInputBox}
            placeholder="e. g. “After purchasing you will able to received the logo...”"
            value={userAssetsData.description}
            onChange={setDescriptionHandler}
          />
        </div>

        <div className={classes.TemplateFlexContainer}>
          <div className={classes.FormContainer}>
            <div>Rarity</div>
            <FilterDropdown
              options={rarityList}
              setSelected={setRarityHandler}
              selected={rarity}
            />
          </div>
          <div className={classes.FormContainer}>
            <div>Asset Type</div>
            <FilterDropdown
              options={assetTypeList}
              setSelected={setAssetTypeHandler}
              selected={assetType}
            />
          </div>
        </div>

        <div className={showAdvancedSettings ? "" : classes.advancedSettings}>
          {/* <div className={classes.marketWebsiteWrapper}> */}
            {/* <div
              className={`${classes.FormContainer} ${classes.marketFeeWrapper}`}
            >
              <div>
                Market fee<span className={classes.requiredField}>*</span>
              </div>

              <div className={classes.optionWrapper}>
                <input
                  type="number"
                  min="0"
                  max="6"
                  value={userAssetsData.dropMarketFee}
                  onChange={marketFeeValidate}
                  className={classes.marketFeeSelect}
                />
              </div>
            </div> */}
            {/* <div
              className={`${classes.FormContainer} ${classes.websiteWrapper}`}
            >
              <div>Website url</div>
              <input
                type="text"
                placeholder="e. g. Size"
                value={userAssetsData.websiteUrl}
                onChange={setWebsiteUrlHandler}
              />
            </div> */}
          {/* </div> */}

          <div>
            {/* <div className={classes.marketWebsiteWrapper}>
              <div
                className={`${classes.FormContainer} ${classes.priceWrapper}`}
              >
                <div>Price</div>
                <input
                  type="text"
                  placeholder="Price"
                  value={userAssetsData.price}
                  onChange={onChangePriceHandler}
                />
              </div>
              <div
                className={`${classes.FormContainer} ${classes.stockWrapper}`}
              >
                <div>In stock</div>
                <input
                  type="text"
                  placeholder="In stock"
                  value={userAssetsData.inStock}
                  onChange={onChangeOnStockHandler}
                />
              </div>
            </div> */}

            <div className={classes.FormContainer}>
              <div className={classes.bottomWrapper}>Authorised accounts</div>
              <div className={classes.accAreaWrapper}>
                <div className={classes.styleUserLogin}>
                  {userLogin}
                  <span className={classes.requiredField}>*</span>
                </div>
                <button className={classes.accAddBtn}>Add</button>
              </div>
            </div>

            <div className={classes.FormContainer}>
              <div className={classes.bottomWrapper}>Notify accounts</div>
              <div className={classes.accAreaWrapper}>
                <div className={classes.styleUserLogin}>
                  {userLogin}
                  <span className={classes.requiredField}>*</span>
                </div>
                <button className={classes.accAddBtn}>Add</button>
              </div>
            </div>

            <div className={classes.dividingLine} />

            {/* <div className={classes.toggleContainer}>
              <div className={classes.toggleTextWrapper}>
                <div className={classes.toggleTitle}>Put on sale</div>
                <div className={classes.toggleSubTitle}>
                  You`ll receive bids on this item
                </div>
              </div>
              <ToggleSwitch callback={putOnSaleHandler} value={putOnSale} />
            </div> */}

            {/* <div className={classes.toggleContainer}>
              <div className={classes.toggleTextWrapper}>
                <div className={classes.toggleTitle}>Instant sale price</div>
                <div className={classes.toggleSubTitle}>
                  Enter the price for which the item will be instantly sold
                </div>
              </div>
              <ToggleSwitch
                callback={instantlySoldHandler}
                value={instantlySold}
              />
            </div> */}

            {/* <div className={classes.toggleContainer}>
              <div className={classes.toggleTextWrapper}>
                <div className={classes.toggleTitle}>Unlock once purchased</div>
                <div className={classes.toggleSubTitle}>
                  Content will be unlocked after successful transaction
                </div>
              </div>
              <ToggleSwitch callback={contentLockHandler} value={contentLock} />
            </div> */}

            <div className={classes.chooseCollectionWrapper}>
              <div className={classes.toggleTitle}>Choose collection</div>
              <div className={classes.toggleSubTitle}>
                Choose an exiting collection or create a new one
              </div>
            </div>

            <div className={classes.collectionsWrapper}>
              <div className={classes.collectionCreator}>
                <Link
                  className={`${classes.collectionAdd} ${classes.hoverOnAdd}`}
                  to="/create-new-collection"
                >
                  <img src={Cross} alt={"Cross"} />
                </Link>
                <div className={classes.collectionName}>Create collection</div>
              </div>
              {collectionsArray.length > 0 &&
                collectionsArray.map((element, i) => {
                  let color;
                  if (i === 0 || (i % 3 === 0 && i !== 1)) {
                    color = COLLECTION_COLORS[0];
                  } else if (i === 1 || i % 3 === 1) {
                    color = COLLECTION_COLORS[1];
                  } else if (i === 2 || i % 3 === 2) {
                    color = COLLECTION_COLORS[2];
                  }
                  return (
                    <div
                      className={`${classes.collectionCreator} ${
                        classes.collectionsPointer
                      }
                   ${
                     currentCollectionSelect === element.collection_name
                       ? classes.chosenCollection
                       : ""
                   }`}
                      onClick={() =>
                        chooseCollectionHandler(element.collection_name)
                      }
                    >
                      <div
                        className={classes.collectionAdd}
                        style={{ backgroundColor: color }}
                      ></div>
                      <div className={classes.collectionName}>
                        {element.collection_name}
                      </div>
                    </div>
                  );
                })}
            </div>
            {currentCollectionSelect && (
              <div className={classes.schemasWrapper}>
                <div className={classes.chooseCollectionWrapper}>
                  <div className={classes.toggleTitle}>Choose schema</div>
                  <div className={classes.toggleSubTitle}>
                    Choose an exiting schema or create a new one
                  </div>
                </div>

                <div className={classes.collectionsWrapper}>
                  <div className={classes.collectionCreator}>
                    <Link
                      className={`${classes.collectionAdd} ${classes.hoverOnAdd}`}
                      to="/create-schema"
                    >
                      <img src={Cross} alt={"Cross"} />
                    </Link>
                    <div className={classes.collectionName}>Create schema</div>
                  </div>
                  {userCollectionSchemas.length > 0 &&
                    userCollectionSchemas.map((element, i) => {
                      let color;
                      if (i === 0 || (i % 3 === 0 && i !== 1)) {
                        color = COLLECTION_COLORS[0];
                      } else if (i === 1 || i % 3 === 1) {
                        color = COLLECTION_COLORS[1];
                      } else if (i === 2 || i % 3 === 2) {
                        color = COLLECTION_COLORS[2];
                      }
                      return (
                        <div
                          className={`${classes.collectionCreator} ${
                            classes.collectionsPointer
                          }
                   ${
                     userSchemaSelect === element.schema_name
                       ? classes.chosenCollection
                       : ""
                   }`}
                          onClick={() =>
                            chooseSchemaHandler(element.schema_name)
                          }
                        >
                          <div
                            className={classes.collectionAdd}
                            style={{ backgroundColor: color }}
                          ></div>
                          <div className={classes.collectionName}>
                            {element.schema_name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
{/* 
        <div className={classes.btnWrapper}>
          <Button onClick={toggleAdvanced}>
            {!showAdvancedSettings ? "Show" : "Hide"} Advanced Details
          </Button>
        </div> */}
      </div>
    );
  }
);
