// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Timmer_timerSpan__2KOU3 {\r\n  display: inline-flex;\r\n  justify-content: center;\r\n  width: 80px;\r\n  text-align: center;\r\n  align-items: center;\r\n  margin: 10px 0;\r\n}\r\n.Timmer_timerSpan__2KOU3 > div {\r\n  font-size: 32px;\r\n  color: #141416;\r\n  font-weight: bold;\r\n}\r\n.Timmer_timerSpan__2KOU3 > div > div {\r\n  font-family: \"Poppins\", sans-serif;\r\n  font-size: 16px;\r\n  color: #777e91;\r\n  font-weight: 500;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Timer/Timmer.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,kCAAkC;EAClC,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".timerSpan {\r\n  display: inline-flex;\r\n  justify-content: center;\r\n  width: 80px;\r\n  text-align: center;\r\n  align-items: center;\r\n  margin: 10px 0;\r\n}\r\n.timerSpan > div {\r\n  font-size: 32px;\r\n  color: #141416;\r\n  font-weight: bold;\r\n}\r\n.timerSpan > div > div {\r\n  font-family: \"Poppins\", sans-serif;\r\n  font-size: 16px;\r\n  color: #777e91;\r\n  font-weight: 500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timerSpan": "Timmer_timerSpan__2KOU3"
};
export default ___CSS_LOADER_EXPORT___;
