import React from "react";
import Accordion from "../../components/UI/Accordion/Accordion";
import classes from "./HowItWorks.module.css";

export default function HowItWorks() {
  const accordionData = [
    {
      title: "How to create Single NFT",
      heading:
        "Creating NFTs on ZeptaGram platform is easier than ever. For creating NFT you just need A Blockchain Account and follow the steps.",
      content: [
        "Login using Crypto Wallet.",
        "Click on NFT Creator Button.",
        "Click on Create Single Button.",
        "Insert NFT details like content, title, description, rarity and asset-type.",
        "Before minting NFT on blockchain, preview it.",
        "Click on Create Item Button.",
        "Wait for Transaction creatation.",
        "Sign transaction.",
        "Congrats you have created your first NFT.",
      ],
    },
    {
      title: "How to create  Multiple NFTs",
      heading:
        "Creating NFTs on ZeptaGram platform is easier than ever and You can create more than 1 NFT having same content. For creating multiple NFT you just need a Blockchain Account and follow the steps.",
      content: [
        "Login using Crypto Wallet.",
        "Click on NFT Creator Button.",
        "Click on Create Multiple Button.",
        "Insert NFT details like content, title, description, rarity and asset-type.",
        "Add Number of copies you want to mint of a NFT.",
        "Before minting NFT on blockchain, preview it.",
        "Click on Create Item Button.",
        "Wait for Transaction creatation",
        "Sign transaction.",
        "Congrats you have created muliple NFTs.",
      ],
    },
    {
      title: "How to list NFT on Sell",
      heading:
        "Along with minting of NFT, ZeptaGram is also a marketplace of NFTs, therefore you can easily sell NFTs on sell. For creating sell of NFT follow the steps.",
      content: [
        "Login using a Crypto Wallet.",
        "Click on Blance Button.",
        "Click on My Profile Button.",
        "Go to your Inventory.",
        "Select any NFT that you want to put on sell and click on it.",
        "Now you are on asset detail page and can check your NFT.",
        "Click on 3 dots at top right corner.",
        "Select 'List on sell' option.",
        "Enter the price on which you want to sell  and click on confirm button",
        "Wait for Transaction creatation",
        "Sign transaction.",
        "Congrats you have listed your NFT on Sell.",
      ],
    },
    {
      title: "How to list NFT on Auction",
      heading:
        "Along with minting of NFT, ZeptaGram also a marketplace of NFTs, therefore you can easily create auction of NFTs. For creating auction of NFT follow the steps.",
      content: [
        "Login using Crypto Wallet.",
        "Click on Blance Button.",
        "Click on My Profile Button.",
        "Go to your Inventory.",
        "Select any NFT that you want to put on auction and click on it.",
        "Now you are on asset detail page and can check your NFT.",
        "Click on 3 dots at top right corner.",
        "Select 'List on Auction' option.",
        "Enter the price the starting Bid price and duration.",
        "Click on confirm button and wait for Transaction creatation.",
        "Sign transaction.",
        "Congrats you have listed your NFT on Auction.",
      ],
    },
    {
      title: "How to Purchase NFT",
      heading:
        "Along with minting of NFT, ZeptaGram is also a marketplace of NFTs, therefore you can easily buy and sell NFTs. For purchaing of NFT follow the steps.",
      content: [
        "Login using a Crypto Wallet.",
        "Go to Discover page.",
        "Select 'Sells' from listing option dropdown.",
        "Apply other filters if you want price, category and creator",
        "Select NFT that you want to purchase.",
        "Now you are on sell detail page and verify the NFT reator of NFT.",
        "Click on Purchase button.",
        "Wait for Transaction creatation",
        "Sign transaction",
        "Congrats you have purhased NFT. You can check it in your inventory section.",
      ],
    },
    {
      title: "How to Bid on the NFT",
      heading:
        "Along with minting of NFT, ZeptaGram is also a marketplace of NFTs, therefore you can easily buy and sell NFTs and bid on NFTs that are on Auction. For biding on NFT follow the steps.",
      content: [
        "Login using a lCrypto Wallet.",
        "Go to Discover page.",
        "Select 'Auction' from listing option dropdown.",
        "Apply other filters if you want like price, category and creator.",
        "Select NFT that you want to bid.",
        "Now you are on auction detail page and verify the NFT and creator of NFT.",
        "Click on 'Place a Bid' button.",
        "Enter your Bid and click on Confirm Button.",
        "Wait for Transaction creatation.",
        "Sign transaction.",
        "Congrats you have Bid on NFT. Now wait for Auction ending.",
      ],
    },
    {
      title: "How to Claim bids after successfull ending of Auction",
      heading:
        "Along with minting of NFT, ZeptaGram is also a marketplace of NFTs, therefore you can easily create sell  and auction of NFT. For claiming bids after a successfull ending of Auction of NFT follow the steps.",
      content: [
        "Login using a Crypto Wallet.",
        "Go to Auction page of NFT.",
        "Click on 'Claim Bid' button.",
        "Wait for Transaction creatation.",
        "Sign transaction.",
        "Congrats you have claimed bid amount of your Auction.",
      ],
    },
    {
      title: "How to Claim Asset after winning of Auction",
      heading:
        "Along with minting of NFT, ZeptaGram is also a marketplace of NFTs, therefore you can easily create sell and auction of NFT. For claiming Asset winning of Auction follow the steps.",
      content: [
        "Login using a  Crypto Wallet.",
        "Go to Auction page of NFT.",
        "Click on 'Claim Asset' button.",
        "Wait for Transaction creatation.",
        "Sign transaction.",
        "Congrats you have claimed NFT from your won Auction.",
      ],
    },
  ];
  const accordion = accordionData.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <Accordion
          title={item.title}
          heading={item.heading}
          content={item.content}
        />
      </React.Fragment>
    );
  });

  return (
    <div className={classes.howItWorksMainContainer}>
      <h1>How it works</h1>
      <div className={classes.accordionContainer}>{accordion}</div>
    </div>
  );
}