import classes from "./CreateSchema.module.css";
import React, { useEffect, useState } from "react";
import Backdrop from "../UI/Backdrop/Backdrop";
import NFTsSuccessModal from "../Model/NFTsSuccessModal";
import ErrorModal from "../Model/ErrorModal";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import { attTypes, simpleTypes } from "../../constants/CreateSchema";
import { blockchainService } from "../../constants/Servises";
import BuyRamModal from "../Model/BuyRamModal";
import PushNotification from "../Model/PushNotification";

export const CreateSchema = (props) => {
  const [schema, setSchema] = useState("");
  const [schemaValid, setSchemaValid] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [attribute, setAttribute] = useState([]);
  const [unicId, setUnicId] = useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [buyRam, SetBuyRam] = useState(false);
  const [showBuyRamModal, setBuyRamModal] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const currentCollection = useSelector(
    (state) => state.singleCollectible.currentCollection
  );

  useEffect(() => {
    if (errorMsg) {
      showErrorModalHandler();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (!/^$|^[a-z1-5]+$/.test(schema)) {
      setSchemaValid(false);
      setDisableBtn(true);
    } else if (/^$|^[a-z1-5]+$/.test(schema)) {
      setSchemaValid(true);
      if (!schema) {
        setDisableBtn(true);
      } else if (attribute.some((el) => !el.name && true)) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  }, [schema, attribute]);

  const changeSchemaNameHandler = (e) => {
    setSchema(e.target.value);
  };

  const addAttributeHandler = () => {
    setAttribute([
      ...attribute,
      { id: unicId, name: "", type: simpleTypes[0] },
    ]);
    setUnicId((prevState) => prevState + 1);
  };

  const selectChangeValue = (id, e) => {
    setAttribute([
      ...attribute.map((el) =>
        el.id === id ? { ...el, type: e.target.value } : el
      ),
    ]);
  };

  const changeInputValueHandler = (id, e) => {
    setAttribute([
      ...attribute.map((el) =>
        el.id === id ? { ...el, name: e.target.value } : el
      ),
    ]);
  };

  const changeAttTypesHandler = () => {
    setIsChecked(!isChecked);
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setShowErrorModal(false);
    setModalAnimation(true);
    setShowBackdrop(false);
    setBuyRamModal(false);
  };

  const deleteAttHandler = (id) => {
    setAttribute([...attribute.filter((el) => el.id !== id)]);
  };

  const showModalHandler = () => {
    setShowModal(true);
    setShowBackdrop(true);
    window.scrollTo(0, 0);
  };
  const showErrorModalHandler = () => {
    setShowErrorModal(true);
    setShowBackdrop(true);
    window.scrollTo(0, 0);
  };

  const clearAllHandler = () => {
    setSchema("");
    setAttribute([]);
    setUnicId(0);
    setErrorMsg("");
  };

  const submitBtnHandler = async () => {
    setErrorMsg("");
    if (currentCollection) {
      let userAccount = props.ual.activeUser.accountName;
      let schemaData = [];

      if (attribute.length > 0) {
        for (let i = 0; i < attribute.length; i++) {
          let name = attribute[i].name;
          let type;
          if (typeof attribute[i].type === "object") {
            type = attribute[i].type.type;
          } else {
            type = attribute[i].type;
          }
          schemaData.push({ name, type });
        }
      }

      let createSchemaAction = blockchainService.createSchemaAction(
        userAccount,
        schema,
        currentCollection,
        schemaData
      );
      const result = await blockchainService.pushTransaction(
        createSchemaAction,
        props.ual
      );

      if (result.success) {
        showModalHandler();
        clearAllHandler();
      } else {
        if (result.message.includes("insufficient ram")) {
          setErrorMsg(result.message);
          SetBuyRam(true);
          return;
        }
        setErrorMsg(result.message);
      }
    } else {
      setErrorMsg("No one collection is chosen");
    }
  };

  const goBackHandler = () => {
    window.history.back();
  };

  const openBuyRamModal = () => {
    setBuyRamModal(true);
    setShowBackdrop(true);
    window.scrollTo(0, 0);
  };
  const removeBuyRamError = () => {
    setErrorMsg("");
    SetBuyRam(false);
  };
  const showNotificationHandler = (msg) => {
    console.log(msg);
    setNotificationTitle(msg);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.goBackBtnWrapper}>
        <Button secondary onClick={goBackHandler}>
          <div className={classes.goBackBtn}>
            <i className="fas fa-arrow-left"></i> Go back
          </div>
        </Button>
      </div>
      <h1 className={classes.mainTitle}>Create Schema</h1>
      <h3 className={classes.subTitleText}>
        Schemas define which kind of attributes the assets will have. You can
        add attributes later.
      </h3>

      <div className={classes.schemaWrapper}>
        <div className={classes.createSchemaWrapper}>
          <h3 className={`${classes.subTitle} ${classes.schemaNameStyle}`}>
            Schema Name
          </h3>
          <input
            type="text"
            maxLength={12}
            className={classes.styledInput}
            placeholder="12 Characters Max"
            value={schema}
            onChange={(e) => changeSchemaNameHandler(e)}
          />

          {!schemaValid && (
            <div className={classes.schemaValidErr}>
              Name contains invalid characters
            </div>
          )}

          <button
            className={classes.createBtn}
            disabled={disableBtn}
            onClick={submitBtnHandler}
          >
            Create Schema
          </button>
          {buyRam && (
            <button className={classes.createBtn} onClick={openBuyRamModal}>
              Buy Ram
            </button>
          )}
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              className={classes.inputCheckbox}
              onChange={changeAttTypesHandler}
            />
            <span className={classes.labelText}>Simplified Types</span>
          </label>
        </div>

        <div className={classes.colWrapper}>
          <div className={classes.titlesWrapper}>
            <h3
              className={`${classes.subTitle} ${classes.colTitle} ${classes.attNameStyle}`}
            >
              Attribute Name
            </h3>
            <h3
              className={`${classes.subTitle} ${classes.colTitle} ${classes.attTypeStyle}`}
            >
              Attribute Type
            </h3>
          </div>
          <div className={classes.propertiesWrapper}>
            <div className={classes.property}>name</div>
            <div className={classes.property}>
              {isChecked ? "Text" : "string"}
            </div>
          </div>
          <div className={classes.propertiesWrapper}>
            <div className={classes.property}>img</div>
            <div className={classes.property}>image</div>
          </div>

          {attribute &&
            attribute.map((element) => {
              return (
                <div
                  key={element.id}
                  className={`${classes.propertiesWrapper} ${classes.deleteBtnContainer}`}
                >
                  {element.name === "" && (
                    <div className={classes.emptyTypeTitle}>
                      Name cannot be empty
                    </div>
                  )}

                  <div className={classes.errDispWrapper}>
                    <button
                      className={classes.deleteBtnWrapper}
                      onClick={() => deleteAttHandler(element.id)}
                    >
                      <div className={classes.deleteBrnCircle} />
                    </button>

                    <div className={classes.inputAttributeStyle}>
                      <input
                        className={`${classes.property} ${classes.selectedInput}`}
                        placeholder="New Attribute Name"
                        value={element.name}
                        onChange={(e) => changeInputValueHandler(element.id, e)}
                      />
                    </div>

                    <div
                      className={`${classes.property} ${classes.selectWrapper}`}
                    >
                      <select
                        value={element.type.name}
                        className={classes.selectStyle}
                        onChange={(e) => selectChangeValue(element.id, e)}
                      >
                        {isChecked
                          ? simpleTypes.map((el) => {
                              return (
                                <option key={el.type} value={el.type}>
                                  {el.name}
                                </option>
                              );
                            })
                          : attTypes.map((el) => {
                              return (
                                <option key={el.type} value={el.type}>
                                  {el.name}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                  </div>
                </div>
              );
            })}

          <div className={classes.addAttributeBtnWrapper}>
            <button
              className={classes.addAttributeBtn}
              onClick={addAttributeHandler}
            >
              Add New Attribute
            </button>
          </div>
        </div>
      </div>

      <div className={classes.modalsContainer}>
        <Backdrop
          show={showBackdrop}
          onClick={closeModalHandler}
          animation={modalAnimation}
        />
        <NFTsSuccessModal
          msgType="The NFT is created successfully"
          showModal={showModal}
          close={closeModalHandler}
        />
        <ErrorModal
          errorMsg={errorMsg}
          showModal={showErrorModal}
          close={closeModalHandler}
        />
       { showBuyRamModal&&<BuyRamModal
          msgType="Buy Ram"
          showModal={showBuyRamModal}
          close={closeModalHandler}
          ual={props.ual}
          showNotificationHandler={showNotificationHandler}
          removeError={removeBuyRamError}
        />}
        <PushNotification
          title={notificationTitle}
          showNotification={showNotification}
        />
      </div>
    </div>
  );
};
