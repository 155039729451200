// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollectionDetails_pageSecondWrapper__e97rC {\r\n  margin: 32px 0;\r\n  display: flex;\r\n  grid-gap: 2%;\r\n  gap: 2%;\r\n}\r\n.CollectionDetails_goBackBtn__ZRVLg {\r\n  width: 100px;\r\n}\r\n\r\n.CollectionDetails_titleWrapper__3b2nn {\r\n  display: none;\r\n}\r\n\r\n@media only screen and (max-width: 1160px) {\r\n  .CollectionDetails_pageSecondWrapper__e97rC {\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .CollectionDetails_titleWrapper__3b2nn {\r\n    margin-bottom: 32px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 100%;\r\n  }\r\n\r\n  .CollectionDetails_titleWrapper__3b2nn > button {\r\n    align-self: auto;\r\n    margin-top: 16px;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/screens/CollectionDetails/CollectionDetails.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,YAAO;EAAP,OAAO;AACT;AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;AACF","sourcesContent":[".pageSecondWrapper {\r\n  margin: 32px 0;\r\n  display: flex;\r\n  gap: 2%;\r\n}\r\n.goBackBtn {\r\n  width: 100px;\r\n}\r\n\r\n.titleWrapper {\r\n  display: none;\r\n}\r\n\r\n@media only screen and (max-width: 1160px) {\r\n  .pageSecondWrapper {\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .titleWrapper {\r\n    margin-bottom: 32px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 100%;\r\n  }\r\n\r\n  .titleWrapper > button {\r\n    align-self: auto;\r\n    margin-top: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageSecondWrapper": "CollectionDetails_pageSecondWrapper__e97rC",
	"goBackBtn": "CollectionDetails_goBackBtn__ZRVLg",
	"titleWrapper": "CollectionDetails_titleWrapper__3b2nn"
};
export default ___CSS_LOADER_EXPORT___;
