import React from "react";
import classes from "./AssetsDetailsPageAssetsDecs.module.css";

const AssetsDetailsPageAssetsDecs = ({
  assetsData,
  assetsPrice,
  symbol,
  assetsPriceUSD,
  stock,
  loading,
  isSalePage,
  isBundle,
  bundleData,
}) => {
  return (
    <>
      {assetsData && (
        <div className={classes.assetsDetailsDescContainer}>
          {isBundle ? (
            <h1>
              <i className="fas fa-layer-group" />
              {`${bundleData.length} NFT Bundle`}
            </h1>
          ) : (
            <>
              <h1>{assetsData.title}</h1>
              <p>#{assetsData.assetId}</p>
            </>
          )}
          <div className={classes.pricesAndStocksContainer}>
            {assetsPrice && isSalePage && (
              <>
                <div className={classes.assetsDetailsPriceZPC}>
                  {`${assetsPrice} ${symbol}`}
                </div>
                <div className={classes.assetsDetailsPriceUSD}>
                  ${assetsPriceUSD}
                </div>
                {!loading && !isBundle && (
                  <p className={classes.stockCount}>{stock} in stock</p>
                )}
              </>
            )}
          </div>
          <h2>{assetsData.description}</h2>
        </div>
      )}
    </>
  );
};

export default AssetsDetailsPageAssetsDecs;
