// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenSelect_tokenSelect__3kzJn {\n  margin: 10px 0;\n  width: 30%;\n}\n.TokenSelect_tokenSelect__3kzJn > div {\n  border-radius: 12px;\n}\n.TokenSelect_tokenSelect__3kzJn > div > div {\n  border: none !important;\n  font-size: 12px !important;\n}\n@media only screen and (max-width: 500px) {\n  .TokenSelect_tokenSelect__3kzJn {\n    margin: 10px 0;\n    width: 40%;\n  }\n}\n@media only screen and (max-width: 400px) {\n  .TokenSelect_tokenSelect__3kzJn {\n    margin: 10px 0;\n    width: 50%;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/Model/TokenSelect/TokenSelect.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,0BAA0B;AAC5B;AACA;EACE;IACE,cAAc;IACd,UAAU;EACZ;AACF;AACA;EACE;IACE,cAAc;IACd,UAAU;EACZ;AACF","sourcesContent":[".tokenSelect {\n  margin: 10px 0;\n  width: 30%;\n}\n.tokenSelect > div {\n  border-radius: 12px;\n}\n.tokenSelect > div > div {\n  border: none !important;\n  font-size: 12px !important;\n}\n@media only screen and (max-width: 500px) {\n  .tokenSelect {\n    margin: 10px 0;\n    width: 40%;\n  }\n}\n@media only screen and (max-width: 400px) {\n  .tokenSelect {\n    margin: 10px 0;\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenSelect": "TokenSelect_tokenSelect__3kzJn"
};
export default ___CSS_LOADER_EXPORT___;
