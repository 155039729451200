// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributesTab_attributesSection__1oALw {\n  display: flex;\n  grid-gap: 20px;\n  gap: 20px;\n  width: 100%;\n  margin-top: 30px;\n}\n@media only screen and (max-width: 710px) {\n  .AttributesTab_attributesSection__1oALw {\n    flex-direction: column;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/AttributesTab/AttributesTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAS;EAAT,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".attributesSection {\n  display: flex;\n  gap: 20px;\n  width: 100%;\n  margin-top: 30px;\n}\n@media only screen and (max-width: 710px) {\n  .attributesSection {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attributesSection": "AttributesTab_attributesSection__1oALw"
};
export default ___CSS_LOADER_EXPORT___;
