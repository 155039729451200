// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NetworksBlockchainsContent_contentConteiner__3N9Rr {\n    width: 1000px;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n@media only screen and (max-width: 500px) {\n    .NetworksBlockchainsContent_contentConteiner__3N9Rr{\n        flex-direction: column;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://src/components/NetworksBlockchainsPage/NetworksBlockchainsContent/NetworksBlockchainsContent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".contentConteiner {\n    width: 1000px;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n@media only screen and (max-width: 500px) {\n    .contentConteiner{\n        flex-direction: column;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentConteiner": "NetworksBlockchainsContent_contentConteiner__3N9Rr"
};
export default ___CSS_LOADER_EXPORT___;
