import React from "react";
import classes from "./AttributesWrapper.module.css"

const AttributesWrapper = ({ title, data }) => {
  return (
    <>
      {
        data ? (
          <div className={classes.attributesWrapper}>
            <div className={classes.title}>{title}</div>
            {
              Object.keys(data).length > 0 ? (
                <div className={classes.dataWrapper}>
                  {
                    Object.keys(data).map((item, i) => 
                      <div key={Object.values(data)[i] + i} className={classes.lineWrapper}>
                        <div className={classes.key}>
                          {`${item}: `}
                        </div>
                        <div className={classes.value}>
                          {Object.values(data)[i]}
                        </div>
                      </div>
                    )
                  }
                </div>
              ) : (
                <div className={classes.emptyWrapper}>
                  {`No ${title.toLowerCase()}`}
                </div>
              )
            }
            
          </div>
        ) : null
      }
    </>
  )
}

export default AttributesWrapper;
