import React, { useState, useEffect } from "react";
import classes from "./CollectionTabsSection.module.css";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import CardsSection from "../../SearchPage/SearchPageContent/CardsSection";
import ApiServices from "../../../services/ApiServices";
import StatsTab from "../CollectionTabs/StatsTab";
import MoreInfoTab from "../CollectionTabs/MoreInfoTab";

const apiService = new ApiServices();

const TEMPLATE_TABS = ["Stats", "More Info", "NFTs", "Templates", "Schemas", "Accounts"];
const CARDS_LIMIT = 12;

const CollectionTabsSections = ({ collectionData, collectionStats }) => {
  const [selectedTab, setSelectedTab] = useState("Stats");
  const [assets, setAssets] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [schemas, setSchemas] = useState([]);

  const getData = async (
    query,
    setData,
    page,
    limit,
    match,
    order,
    sort,
    collection = null,
    templateId = null,
  ) => {
    setLoading(true);
    setData([]);
    setPage(page);
    const cat = null;
    const data = await apiService.getSearchData(query, page, limit, match, cat, collection, templateId, order, sort);
    if (data && data.length > 0) {
      setData(data);
    }
    setLoading(false);

    return data === null;
  };

  const getNewPageData = async (page, dropSelected, setData, match) => {
    if (page > 0) {
      window.scrollTo(0, 0);

      setPage(page);
      setData([]);
      await getData(
        dropSelected.toLowerCase(),
        setData,
        page,
        CARDS_LIMIT,
        match,
        "desc",
        "created",
        collectionData.collection_name
      );
    }
  }

  useEffect(() => {
    if(collectionData) {
      (async () =>
        await getData(
          'assets',
          setAssets,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          collectionData.collection_name
        ))();
      (async () =>
        await getData(
          'templates',
          setTemplates,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          collectionData.collection_name
        ))();
      (async () =>
        await getData(
          'schemas',
          setSchemas,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          collectionData.collection_name
        ))();
      (async () =>
        await getData(
          'accounts',
          setAccounts,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          collectionData.collection_name
        ))();
    }
  }, [collectionData]);

  const handleTableData = () => {
    return (
      <>
        <TabContent isSelected={selectedTab === "Stats"}>
          <StatsTab
            collectionData={collectionData}
            collectionStats={collectionStats}
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "More Info"}>
          <MoreInfoTab
            collectionData={collectionData}
            collectionStats={collectionStats}
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "NFTs"}>
          <CardsSection
            discoverAssets={assets}
            setDiscoverAssets={setAssets}
            loading={loading}
            dropSelected="Assets"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Templates"}>
          <CardsSection
            templates={templates}
            setTemplates={setTemplates}
            loading={loading}
            dropSelected={selectedTab}
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Schemas"}>
          <CardsSection
            schemas={schemas}
            setSchemas={setSchemas}
            loading={loading}
            dropSelected={selectedTab}
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Accounts"}>
          <CardsSection
            accounts={accounts}
            setAccounts={setAccounts}
            loading={loading}
            dropSelected={selectedTab}
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
      </>
    );
  };

  return (
    <div className={classes.tabsSectionsWrapper}>
      <FilterTabBar
        tabs={TEMPLATE_TABS}
        selected={selectedTab}
        setSelected={setSelectedTab}
        setPage={setPage}
      >
        {handleTableData()}
      </FilterTabBar>
    </div>
  );
}

export default CollectionTabsSections;
