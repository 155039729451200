// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterSearch_filterSearchContainer__lgPx1 > form {\n display: flex;\n justify-content: space-between;\n align-items: center;\n width: 100%;\n padding-bottom: 15px;\n border-bottom: 1px solid #e6e8ec;\n}\n.FilterSearch_filterSearchContainer__lgPx1 > form > input {\n width: 100%;\n height: 40px;\n outline: none;\n border: none;\n font-family: \"Poppins\", sans-serif;\n font-size: 24px;\n color: #353945;\n font-weight: normal;\n letter-spacing: 1.2px;\n}\n@media only screen and (max-width: 600px) {\n .FilterSearch_filterSearchContainer__lgPx1 > input {\n  font-size: 18px;\n }\n}\n", "",{"version":3,"sources":["webpack://src/components/FilterSearch/FilterSearch.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,WAAW;CACX,oBAAoB;CACpB,gCAAgC;AACjC;AACA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,YAAY;CACZ,kCAAkC;CAClC,eAAe;CACf,cAAc;CACd,mBAAmB;CACnB,qBAAqB;AACtB;AACA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":[".filterSearchContainer > form {\n display: flex;\n justify-content: space-between;\n align-items: center;\n width: 100%;\n padding-bottom: 15px;\n border-bottom: 1px solid #e6e8ec;\n}\n.filterSearchContainer > form > input {\n width: 100%;\n height: 40px;\n outline: none;\n border: none;\n font-family: \"Poppins\", sans-serif;\n font-size: 24px;\n color: #353945;\n font-weight: normal;\n letter-spacing: 1.2px;\n}\n@media only screen and (max-width: 600px) {\n .filterSearchContainer > input {\n  font-size: 18px;\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterSearchContainer": "FilterSearch_filterSearchContainer__lgPx1"
};
export default ___CSS_LOADER_EXPORT___;
