import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classes from "./HomePageTopBanner.module.css";
import ApiServices from "../../../services/ApiServices";
import AppServices from "../../../services/AppService";
import PriceBox from "../../UI/PriceBox/PriceBox";
import ActionButton from "../../UI/ActionButton/ActionButton";
import FilterBannerList from "../../FilterBannerList/FilterBannerList";
import BannerContent from "../../FilterBannerList/BannerContent";
import FirebaseService from "../../../services/FirebaseService";
import Timer from "../../Timer/Timer";
import AudioSpectrum from "react-audio-spectrum";
import TopBannerLoader from "../../PlaceholderLoader/TopBannerLoader";
import HomePageTopBanner2 from "../HomePageTopBanner2/HomePageTopBanner";
import HomePageTopBanner3 from "../HomePageTopBanner3/HomePageTopBanner";

class HomePageTopBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nfts: [],
      nftBanners: [],
      saleList: [],
      selectedList: 0,
      bannerType: "design1",
    };

    this.tokenPrecision = process.env.REACT_APP_TOKEN_PRECISION;
    this.tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;

    this.apiService = new ApiServices();
    this.appService = new AppServices();
    this.firebaseService = new FirebaseService();
  }

  componentDidMount() {
    const fetchData = async () => {
      let response = await this.firebaseService.getBanners();
      if (response) {
        this.setState({ bannerType: response.data().bannerType || "design1" });
        let data = response.data()?.[process.env.REACT_APP_ENV] ?? null;

        if (data) {
          let nftBanners = [];
          for (var i = 0; i < data.length; i++) {
            let element = data[i];
            if (element.enable == true) {
              let tempObject = null;
              if (element["saleId"]) {
                tempObject = {};
                let saleData = await this.apiService.getSaleData(
                  element["saleId"]
                );
                // console.log("assetData", saleData);
                if (saleData) {
                  let assetData = saleData.assets[0];
                  let assetMetaData = assetData.data;
                  tempObject["actionType"] = "sale";
                  tempObject["title"] =
                    element.title ??
                    (assetMetaData.title
                      ? assetMetaData.title
                      : assetMetaData.name ?? "");
                  tempObject["desc"] = element.description ?? "";
                  tempObject["stock"] = assetData.template?.max_supply
                    ? assetData.template?.max_supply
                    : assetData.template?.issued_supply ?? "0";
                  tempObject["mintNumber"] = assetData.template_mint ?? "";
                  tempObject["tokenAmount"] = saleData.price.amount ?? "";
                  tempObject["tokenSymbol"] = saleData.price.token_symbol ?? "";
                  tempObject["tokenPrecision"] =
                    saleData.price.token_precision ?? "";
                  tempObject["detailsPath"] = "/assets/" + assetData.asset_id;
                  tempObject["purchasePath"] = "/sale/" + saleData.sale_id;
                  tempObject["startAt"] = element.startAt ?? null;
                  tempObject["state"] = saleData.state;

                  let content = element.img || assetMetaData.img;
                  let type = "img";
                  if (content === undefined) {
                    content = assetMetaData.video;
                    type = "video";
                  }
                  if (content === undefined) {
                    content = assetMetaData.audio;
                    type = "audio";
                  }

                  tempObject["type"] = type;
                  tempObject["content"] = content;
                }
              } else if (element["auctionId"]) {
                tempObject = {};
                let auctionData = await this.apiService.getAuctionData(
                  element["auctionId"]
                );
                if (auctionData) {
                  let assetData = auctionData.assets[0];
                  let assetMetaData = assetData.data;
                  tempObject["actionType"] = "auction";
                  tempObject["title"] =
                    element.title ??
                    (assetMetaData.title
                      ? assetMetaData.title
                      : assetMetaData.name ?? "");
                  tempObject["desc"] = element.description ?? "";
                  tempObject["stock"] = assetData.template?.max_supply
                    ? assetData.template?.max_supply
                    : assetData.template?.issued_supply ?? "0";
                  tempObject["mintNumber"] = assetData.template_mint ?? "";
                  tempObject["tokenAmount"] = auctionData.price.amount ?? "";
                  tempObject["tokenSymbol"] =
                    auctionData.price.token_symbol ?? "";
                  tempObject["tokenPrecision"] =
                    auctionData.price.token_precision ?? "";

                  tempObject["detailsPath"] = "/assets/" + assetData.asset_id;
                  tempObject["purchasePath"] =
                    "/auction/" + auctionData.auction_id;

                  tempObject["startAt"] = element.startAt ?? null;

                  let content = element.img || assetMetaData.img;
                  let type = "img";
                  if (content === undefined) {
                    content = assetMetaData.video;
                    type = "video";
                  }
                  if (content === undefined) {
                    content = assetMetaData.audio;
                    type = "audio";
                  }

                  tempObject["type"] = type;
                  tempObject["content"] = content;
                }
              } else if (element["collection"]) {
                tempObject = {};
                let collectionData = await this.apiService.getCollectionData(
                  element["collection"]
                );
                if (collectionData) {
                  let collectionMetaData = collectionData.data;
                  tempObject["actionType"] = "collection";
                  tempObject["title"] =
                    element.title ?? collectionMetaData.name ?? "";
                  tempObject["desc"] = element.description ?? "";
                  if (element["sale"])
                    tempObject["detailsPath"] =
                      "/market?listingType=sales&collection=" +
                      element["collection"];
                  else if (element["auction"])
                    tempObject["detailsPath"] =
                      "/market?listingType=auctions&collection=" +
                      element["collection"];
                  else
                    tempObject["detailsPath"] =
                      "/explorer?collection=" + element["collection"];

                  tempObject["startAt"] = element.startAt ?? null;

                  let content = element.img ?? collectionMetaData.img;
                  let type = "img";

                  tempObject["type"] = type;
                  tempObject["content"] = content;
                }
              } else if (element["templateId"]) {
                tempObject = {};
                let templateData = await this.apiService.getTemplateData(
                  element["templateId"]
                );
                if (templateData) {
                  templateData = templateData[0];
                  let templateMetaData = templateData.immutable_data;
                  tempObject["actionType"] = "template";
                  tempObject["title"] =
                    element.title ?? templateMetaData.name ?? "";
                  tempObject["desc"] = element.description ?? "";

                  if (element["sale"])
                    tempObject["detailsPath"] =
                      "/market?listingType=sales&templateId=" +
                      element["templateId"];
                  else if (element["auction"])
                    tempObject["detailsPath"] =
                      "/market?listingType=auctions&templateId=" +
                      element["templateId"];
                  else
                    tempObject["detailsPath"] =
                      "/explorer?templateId=" + element["templateId"];

                  // if (element['sale'])
                  //   tempObject['detailsPath'] = '/explorer?templateId=' + element['templateId'];

                  // else if (element['auction'])
                  //   tempObject['detailsPath'] = '/explorer?templateId=' + element['templateId'];

                  tempObject["startAt"] = element.startAt ?? null;

                  let content = element.img || templateMetaData.img;
                  let type = "img";
                  if (content === undefined) {
                    content = templateMetaData.video;
                    type = "video";
                  }
                  if (content === undefined) {
                    content = templateMetaData.audio;
                    type = "audio";
                  }

                  tempObject["type"] = type;
                  tempObject["content"] = content;
                }
              }

              if (tempObject) nftBanners.push(tempObject);
            }
          }
          this.setState({ nftBanners });
        }
      }
    };
    fetchData();
  }

  getListingPrice = (tokenAmount, tokenPrecision) => {
    if (tokenAmount) {
      const price = +tokenAmount / Math.pow(10, tokenPrecision);
      return price;
    }
  };

  navigateToPath = (path) => {
    this.props.history.push(path);
  };

  showSliderData = (index) => {
    let item = this.state.nftBanners[index];
    if (item) {
      if (window.screen.availWidth > 600) return this.normalView(item);
      else return this.mobileView(item);
    }
  };

  normalView = (item) => {
    return (
      <div className={classes.bannerDetailsMainContainer}>
        <div className={classes.bannerDetailsContainer}>
          <h1>{item.title}</h1>
          {item.desc && <p>{item.desc}</p>}
          <div className={classes.priceAndStockContainer}>
            {item.tokenAmount && (
              <PriceBox
                price={this.getListingPrice(
                  item.tokenAmount,
                  item.tokenPrecision
                )}
                symbol={item.tokenSymbol}
              />
            )}
            {item.tokenAmount && (
              <PriceBox
                price={(
                  this.getListingPrice(item.tokenAmount, item.tokenPrecision) *
                  (this.appService.getUsdPrice() ?? 1)
                ).toFixed(2)}
                symbol="$"
              />
            )}
            {item.stock && <span>{item.stock} in stock</span>}
          </div>
          <div className={classes.actionBtnContainer}>
            <ActionButton
              title="details"
              onClick={() => this.navigateToPath(item.detailsPath)}
              color="black"
            />
            {item.purchasePath && (
              <ActionButton
                title={item.actionType === "sale" ? "Buy" : "Bid"}
                onClick={() => this.navigateToPath(item.purchasePath)}
              />
            )}
          </div>
          <div>
            {item.startAt !== null && <Timer remainingTime={item.startAt} />}
          </div>
        </div>
        <div className={classes.imgAndDataMainContainer}>
          <div className={classes.bannerImgContainer}>
            {/* <img src={this.appService.getIpfsUrl(item?.img)} alt="asset" /> */}

            {item.type === "img" ? (
              <img
                src={this.appService.getIpfsUrl(item.content)}
                alt="Assets"
              />
            ) : item.type === "video" ? (
              <video
                src={this.appService.getIpfsVidepUrl(item.content)}
                controls="controls"
                autoPlay={true}
                loop={true}
              />
            ) : (
              <div className={classes.audioSpectrumMainContainer}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className={classes.headPhoneIcon}>
                    <i className="fas fa-headphones"></i>
                  </div>
                  <AudioSpectrum
                    id={`audio-canvas`}
                    height={this.state.dimension > 992 ? 750 : 250}
                    width={
                      this.state.dimension < 420
                        ? 200
                        : this.state.spectrumWidth
                    }
                    audioId={`audio-element`}
                    capColor={"#23262f "}
                    capHeight={2}
                    meterWidth={2}
                    meterCount={1000}
                    meterColor={[
                      { stop: 0, color: "#df7549" },
                      { stop: 0.5, color: "#d6c89a" },
                      { stop: 1, color: "#dc3825" },
                    ]}
                    gap={4}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: -15,
                  }}
                >
                  <audio
                    id={`audio-element`}
                    src={this.appService.getIpfsVidepUrl(item.content)}
                    controls
                    controlsList="nodownload"
                    autoPlay={true}
                    loop={true}
                    muted={false}
                    crossOrigin="anonymous"
                  ></audio>
                </div>
              </div>
            )}

            {item.mintNumber && (
              <div className={classes.mintNumberTag}>#{item.mintNumber}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  mobileView = (item) => {
    return (
      <div className={classes.bannerDetailsMainContainer}>
        <div className={classes.imgAndDataMainContainer}>
          <div className={classes.bannerImgContainerMobile}>
            {item.type === "img" ? (
              <img
                src={this.appService.getIpfsUrl(item.content)}
                alt="Assets"
              />
            ) : item.type === "video" ? (
              <video
                src={this.appService.getIpfsVidepUrl(item.content)}
                controls="controls"
                autoPlay={true}
                loop={true}
              />
            ) : (
              <div className={classes.audioSpectrumMainContainer}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className={classes.headPhoneIcon}>
                    <i className="fas fa-headphones"></i>
                  </div>
                  <AudioSpectrum
                    id={`audio-canvas`}
                    height={this.state.dimension > 992 ? 750 : 250}
                    width={
                      this.state.dimension < 420
                        ? 200
                        : this.state.spectrumWidth
                    }
                    audioId={`audio-element`}
                    capColor={"#23262f "}
                    capHeight={2}
                    meterWidth={2}
                    meterCount={1000}
                    meterColor={[
                      { stop: 0, color: "#df7549" },
                      { stop: 0.5, color: "#d6c89a" },
                      { stop: 1, color: "#dc3825" },
                    ]}
                    gap={4}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: -15,
                  }}
                >
                  <audio
                    id={`audio-element`}
                    src={this.appService.getIpfsVidepUrl(item.content)}
                    controls
                    controlsList="nodownload"
                    autoPlay={true}
                    loop={true}
                    muted={false}
                    crossOrigin="anonymous"
                  ></audio>
                </div>
              </div>
            )}

            {item.mintNumber && (
              <div className={classes.mintNumberTag}>#{item.mintNumber}</div>
            )}
          </div>
        </div>

        <div className={classes.bannerDetailsContainer}>
          <h1>{item.title}</h1>
          <div className={classes.priceAndStockContainer}>
            {item.tokenAmount && (
              <PriceBox
                price={this.getListingPrice(
                  item.tokenAmount,
                  item.tokenPrecision
                )}
                symbol={item.tokenSymbol}
              />
            )}
            {item.tokenAmount && (
              <PriceBox
                price={(
                  this.getListingPrice(item.tokenAmount, item.tokenPrecision) *
                  (this.appService.getUsdPrice() ?? 1)
                ).toFixed(2)}
                symbol="$"
              />
            )}
            {item.stock && <span>{item.stock} in stock</span>}
          </div>
          <div className={classes.actionBtnContainer}>
            <ActionButton
              title="details"
              onClick={() => this.navigateToPath(item.detailsPath)}
              color="black"
            />
            {item.purchasePath && (
              <ActionButton
                title={item.actionType === "sale" ? "Buy" : "Bid"}
                onClick={() => this.navigateToPath(item.purchasePath)}
              />
            )}
          </div>
          <div>
            {item.startAt !== null && <Timer remainingTime={item.startAt} />}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.nftBanners && this.state.nftBanners.length > 0 ? (
          <div
            className={
              this.state.bannerType === "design1" ||
              this.state.bannerType === "design3"
                ? classes.topBannerMainContainer
                : classes.topBannerMainContainer2
            }
          >
            <div className={classes.topBannerContainer}>
              {this.state.bannerType === "design1" ? (
                <FilterBannerList
                  lists={this.state.nftBanners}
                  selected={this.state.selectedList}
                  setSelected={(index) =>
                    this.setState({ selectedList: index })
                  }
                >
                  <BannerContent isSelected={true}>
                    {this.showSliderData(this.state.selectedList)}
                  </BannerContent>
                </FilterBannerList>
              ) : this.state.bannerType === "design2" ? (
                <HomePageTopBanner2
                  lists={this.state.nftBanners}
                  selected={this.state.selectedList}
                  setSelected={(index) =>
                    this.setState({ selectedList: index })
                  }
                  getListingPrice={this.getListingPrice}
                  navigateToPath={this.navigateToPath}
                  UsdPrice={this.appService.getUsdPrice() ?? 1}
                />
              ) : this.state.bannerType === "design3" ? (
                <HomePageTopBanner3
                  lists={this.state.nftBanners}
                  selected={this.state.selectedList}
                  setSelected={(index) =>
                    this.setState({ selectedList: index })
                  }
                  getListingPrice={this.getListingPrice}
                  navigateToPath={this.navigateToPath}
                  UsdPrice={this.appService.getUsdPrice() ?? 1}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <TopBannerLoader />
        )}
      </>
    );
  }
}

export default withRouter(HomePageTopBanner);
