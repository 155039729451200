import {configureStore} from '@reduxjs/toolkit'
import newCollectionReducer from "./reducers/newCollectionReducer";
import authReducer from "./reducers/authReducer";
import singleCollectibleReducer from "./reducers/singleCollectibleReducer";
import searchReducerSlice from './reducers/searchReducerSlice';
import marketReducer from "./reducers/marketReducer";
import saleReducer from './reducers/saleReducer';

export const store = configureStore({
  reducer: {
    newCollection: newCollectionReducer,
    auth: authReducer,
    singleCollectible: singleCollectibleReducer,
    searchObject: searchReducerSlice,
    market: marketReducer,
    sale:saleReducer
  },
})
