import React from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";

export default function UserAuthModal(props) {
  return (
    <div
      className={classes.errorModal}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.modelYayy}>
        <h2>Oops!</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>{props.msgType}</p>
      </div>
      <div
        className={classes.modelFlexTranscation}
        style={{ justifyContent: "center" }}
      >
        <div style={{ textAlign: "center" }}>
          <p>Status</p>
          <p style={{ fontSize: 24 }}>Failed</p>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button secondary onClick={props.login}>
          <div style={{ padding: "0px 40px" }}>Login</div>
        </Button>
      </div>
    </div>
  );
}
