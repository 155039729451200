// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartSection_chartWrapper__1Zvaw {\n  width: 100%;\n  height: 500px;\n}\n.ChartSection_loading__1MeQx {\n  width: 100%;\n  height: 500px;\n  border-radius: 36px;\n  background: linear-gradient(-90deg, #c7c7c7, #e7e2e4, #cacaca, #e6eeec);\n  background-size: 400% 400%;\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  animation: ChartSection_gradient__5_Naz 3s ease infinite;\n}\n@keyframes ChartSection_gradient__5_Naz {\n\t0% {\n\t\tbackground-position: 0% 50%;\n\t}\n\t50% {\n\t\tbackground-position: 100% 50%;\n\t}\n\t100% {\n\t\tbackground-position: 0% 50%;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/PriceHistorySectionTab/ChartSection/ChartSection.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uEAAuE;EACvE,0BAA0B;EAC1B,2FAA2F;EAC3F,wDAAoC;AACtC;AACA;CACC;EACC,2BAA2B;CAC5B;CACA;EACC,6BAA6B;CAC9B;CACA;EACC,2BAA2B;CAC5B;AACD","sourcesContent":[".chartWrapper {\n  width: 100%;\n  height: 500px;\n}\n.loading {\n  width: 100%;\n  height: 500px;\n  border-radius: 36px;\n  background: linear-gradient(-90deg, #c7c7c7, #e7e2e4, #cacaca, #e6eeec);\n  background-size: 400% 400%;\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  animation: gradient 3s ease infinite;\n}\n@keyframes gradient {\n\t0% {\n\t\tbackground-position: 0% 50%;\n\t}\n\t50% {\n\t\tbackground-position: 100% 50%;\n\t}\n\t100% {\n\t\tbackground-position: 0% 50%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartWrapper": "ChartSection_chartWrapper__1Zvaw",
	"loading": "ChartSection_loading__1MeQx",
	"gradient": "ChartSection_gradient__5_Naz"
};
export default ___CSS_LOADER_EXPORT___;
