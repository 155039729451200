// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/avenir/Avenir-Light.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n  font-family: 'Avenir';\r\n  src: local('Avenir'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\r\n}\r\n\r\n.pageWraper {\r\n width: 95%;\r\n margin: 0 auto;\r\n background: #fcfcfd;\r\n}\r\n\r\n.ecsterPay{\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  margin-top: 10px;\r\nmargin-bottom: 10px;\r\n}", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4EAAiF;AACnF;;AAEA;CACC,UAAU;CACV,cAAc;CACd,mBAAmB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB,mBAAmB;AACnB","sourcesContent":["@font-face {\r\n  font-family: 'Avenir';\r\n  src: local('Avenir'), url(./assets/fonts/avenir/Avenir-Light.woff) format('woff');\r\n}\r\n\r\n.pageWraper {\r\n width: 95%;\r\n margin: 0 auto;\r\n background: #fcfcfd;\r\n}\r\n\r\n.ecsterPay{\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  margin-top: 10px;\r\nmargin-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
