// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopAccounts_topAccountsWrapper__6L6cK {\r\n  width: 50%;\r\n}\r\n\r\n.TopAccounts_title__2V5jZ {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n@media only screen and (max-width: 860px) {\r\n  .TopAccounts_topAccountsWrapper__6L6cK {\r\n    width: 100%;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/CollectionDetails/CollectionTabs/StatsTab/TopAccounts/TopAccounts.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".topAccountsWrapper {\r\n  width: 50%;\r\n}\r\n\r\n.title {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n@media only screen and (max-width: 860px) {\r\n  .topAccountsWrapper {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topAccountsWrapper": "TopAccounts_topAccountsWrapper__6L6cK",
	"title": "TopAccounts_title__2V5jZ"
};
export default ___CSS_LOADER_EXPORT___;
