import React, { useState, useEffect } from "react";
import classes from "./SchemaTabsSections.module.css";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import CardsSection from "../../SearchPage/SearchPageContent/CardsSection";
import ApiServices from "../../../services/ApiServices";
import SchemaAttributesTab from "../SchemaAttributesTab";

const apiService = new ApiServices();

const TEMPLATE_TABS = ["Attributes", "NFTs", "Templates", "Accounts", "Sale Logs"];
const CARDS_LIMIT = 12;

const SchemaTabsSections = ({ schemaData }) => {
  const [selectedTab, setSelectedTab] = useState("Attributes");
  const [assets, setAssets] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [templates, setTemplates] = useState([]);

  const getData = async (
    query,
    setData,
    page,
    limit,
    match,
    order,
    sort,
    collection = null,
    templateId = null,
    schema = null
  ) => {
    setLoading(true);
    setData(null);
    setPage(page);
    const cat = null;
    const data = await apiService.getSearchData(query, page, limit, match, cat, collection, templateId, order, sort, schema);
    if (data && data.length > 0) {
      setData(data);
    }
    setLoading(false);

    return data === null;
  };

  const getNewPageData = async (page, dropSelected, setData, match) => {
    if (page > 0) {
      window.scrollTo(0, 0);

      setPage(page);
      setAssets([]);
      await getData(
        dropSelected.toLowerCase(),
        setData,
        page,
        CARDS_LIMIT,
        match,
        "desc",
        "created",
        schemaData[0].collection.collection_name,
        null,
        schemaData[0].schema_name
      );
    }
  }

  useEffect(() => {
    if(schemaData) {
      console.log(schemaData);
      (async () =>
        await getData(
          'assets',
          setAssets,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          schemaData[0].collection.collection_name,
          null,
          schemaData[0].schema_name
        ))();
      (async () =>
        await getData(
          'accounts',
          setAccounts,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          schemaData[0].collection.collection_name,
          null,
          schemaData[0].schema_name
        ))();
      (async () =>
        await getData(
          "templates",
          setTemplates,
          page,
          CARDS_LIMIT,
          match,
          "desc",
          "created",
          schemaData[0].collection.collection_name,
          null,
          schemaData[0].schema_name
        ))();
    }
  }, [schemaData]);

  const handleTableData = () => {
    return (
      <>
        <TabContent isSelected={selectedTab === "Attributes"}>
          <SchemaAttributesTab
            schemaData={schemaData}
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "NFTs"}>
          <CardsSection
            discoverAssets={assets}
            setDiscoverAssets={setAssets}
            loading={loading}
            dropSelected="Assets"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Templates"}>
          <CardsSection
            templates={templates}
            setTemplates={setTemplates}
            loading={loading}
            dropSelected="Templates"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
        <TabContent isSelected={selectedTab === "Accounts"}>
          <CardsSection
            accounts={accounts}
            setAccounts={setAccounts}
            loading={loading}
            dropSelected="Accounts"
            page={page}
            getNewPageData={getNewPageData}
            isTemplateDetailsPage
          />
        </TabContent>
      </>
    );
  };

  return (
    <div className={classes.tabsSectionsWrapper}>
      <FilterTabBar
        tabs={TEMPLATE_TABS}
        selected={selectedTab}
        setSelected={setSelectedTab}
      >
        {handleTableData()}
      </FilterTabBar>
    </div>
  );
}

export default SchemaTabsSections;
