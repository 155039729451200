import Button from "../Button/Button";
import classes from "./Policy.module.css";

export const Policy = () => {
  const onclickHandler = () => {
    window.open(
      "https://gateway.pinata.cloud/ipfs/QmX57MpLFfUQQQF52zQcV7F5uj8voKAMP5nrcrnYjAKvGC",
      "_blank"
    );
  };
  return (
    <div className={classes.policyWrapper}>
      <h2 className={classes.mainTitle}>Privacy Policy</h2>
      <div>
        <p className={classes.mainText}>
          The following Terms and Conditions (“T&Cs”), together with the
          Zeptagram Whitepaper (the “Whitepaper”), govern your (“you” or the
          “Purchaser” or the “User”) purchase of digital cryptographic tokens
          (called the “ZeptaCoin” or “ZPTC”) and their use within the in
          development Zeptagram App, Software and Platform (the “Platform”) from
          Zeptagram AB, a private entity registered in Sweden and with address
          in Sweden, BÅGSKYTTESTIGEN 2, 413 19 Göteborg, Västra Götalands län
          (the “Company” or “Sweden”) as well as the use of its Website (the
          “Website”) and Platform. Purchaser and Company may be referred as
          Party or Parties herein below. This document is not a solicitation for
          investment and does not pertain in any way to an offering of
          securities in any jurisdiction, including in the United States of
          America (U.S.A.).
        </p>
        <p className={classes.mainText}>
          These T&Cs describe the initial placement of the ZeptaCoins, the
          Initial Coin Offering (the “ICO”) and the rights and obligations
          arising from ZPTC, including the usage of the Company’s Website and
          Platform. Please read these T&Cs carefully before subscribing to
          purchase ZeptaCoins. By subscribing to the ICO or acquiring ZPTCs or
          using the Website or Platform in any way, you acknowledge that you
          have read these T&Cs and you agree to be fully bound by them. IF YOU
          DO NOT AGREE TO THESE T&CS, IN PART OR AT ALL, DO NOT PURCHASE ZPTC
          FROM THE COMPANY OR ANY OTHER SOURCE AND DO NOT USE IN ANY WAY OR FORM
          THE WEBSITE AND THE PLATFORM.
        </p>
      </div>

      <div className={classes.btnWrapper}>
        <Button onClick={onclickHandler}> See more</Button>
      </div>
    </div>
  );
};
