import React, { useEffect, useState } from "react";
import AppServices from "../../services/AppService";
import classes from "./FilterBannerList.module.css";
import Slider from "react-slick";
import useWindowSize from "../../hooks/useWindowSize";

export default function FilterBannerList(props) {
  const [width, height] = useWindowSize();
  const [bannerListConfig, setBannerListConfig] = useState({});
  const appService = new AppServices()

  const slider = React.useRef(null);

  useEffect(() => {
    if(width && props.lists && props.lists.length) {
      setBannerListConfig(
        {
          infinite: true,
          speed: 500,
          slidesToShow: props.lists.length > 1 ? 2 : 1, 
          slidesToScroll: 1,
          arrows: false,
        }
      )
    }
  }, [width, props.lists])

  return (
    <div className={classes.filterBannerListMainContainer}>
      {
        width < 600
          ?
            <div className={classes.bannerListContainer}>
              <Slider {...bannerListConfig} ref={slider}>
                {props.lists.map((list, index) => {
                  if (list.title)
                    return (
                      <div
                        className={classes.sliderItem}
                        style={
                          index === props.selected
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                        onClick={() => props.setSelected(index)}
                        key={index}
                      >
                        <img src={appService.getIpfsUrl(list.content)} alt={list.title}/>
                        <h3>{list.title}</h3>
                      </div>
                    );
                  else
                    return null
                })}
              </Slider>
              <button
                className={classes.prevBtn}
                onClick={() => slider?.current?.slickPrev()}
              >
                <i className="fas fa-long-arrow-alt-left"></i>
              </button>
              <button
                className={classes.nextBtn}
                onClick={() => slider?.current?.slickNext()}
              >
                <i className="fas fa-long-arrow-alt-right"></i>
              </button>
            </div>
          :
            <div className={classes.bannerListContainer}>
              {props.lists.map((list, index) => {
                if (list.title)
                  return (
                    <div
                      style={
                        index === props.selected
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      }
                      onClick={() => props.setSelected(index)}
                      key={index}
                    >
                      <img src={appService.getIpfsUrl(list.content)} alt={list.title} />
                      <h3>{list.title}</h3>
                    </div>
                  );
                else
                  return null
              })}
            </div>
      }
      {props.children}
    </div>
  );
}
