import React, { Component } from "react";
import ActionButton from "../../UI/ActionButton/ActionButton";
import classes from "./AssetsDetailsPageActionsBtn.module.css";
import MODAL_CONSTANTS from "../../../constants/Modal";

export default class AssetsDetailsPageActionsBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDropDown: false,
    };
    this.refMore = React.createRef();
    document.addEventListener("mousedown", this.handleClickOutsideDrop);
  }

  handleClickOutsideDrop = (event) => {
    if (this.refMore.current && !this.refMore.current.contains(event.target)) {
      this.setState({ showMoreDropDown: false });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideDrop);
  }

  remove = (type, setRemoveMsg, removeModalState, openModal) => {
    if (type === "auction") {
      setRemoveMsg(MODAL_CONSTANTS.REMOVE_MODAL_AUCTION);
    } else if (type === "sale") {
      setRemoveMsg(MODAL_CONSTANTS.REMOVE_MODAL_SELL);
    }
    openModal(removeModalState);
  };

  updatePrice = () => {
    if (this.props.type === "auction") {
      this.props.openModal(this.props.updatePriceAuctionModalState);
    } else {
      this.props.openModal(this.props.updatePriceModalState);
    }
  };

  render() {
    return (
      <div className={classes.assetsDetailsActionsBtnContainer}>
        {this.props.isOwner && (
          <div className={classes.moreBtnContainer} ref={this.refMore}>
            <ActionButton
              more
              onClick={() =>
                this.setState({
                  showMoreDropDown: !this.state.showMoreDropDown,
                })
              }
            />
            {this.state.showMoreDropDown && (
              <div className={classes.moreBtnDropDown}>
                <>
                  {this.props.type === "auction" ||
                  this.props.type === "sale" ? (
                    <>
                      <div onClick={() => this.updatePrice()}>
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          Change price
                        </li>
                      </div>
                      <div
                        onClick={() =>
                          this.remove(
                            this.props.type,
                            this.props.setRemoveMsg,
                            this.props.removeModalState,
                            this.props.openModal
                          )
                        }
                      >
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-times"></i>
                          </div>
                          Remove from{" "}
                          {this.props.type === "auction" ? "Auction" : "Sell"}
                        </li>
                      </div>
                      <div onClick={() => this.props.buyRamAction(true)}>
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-memory"></i>
                          </div>
                          Buy Ram
                        </li>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={() =>
                          this.props.openModal(this.props.listOnSaleModalState)
                        }
                      >
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          List on Sell
                        </li>
                      </div>
                      <div
                        onClick={() =>
                          this.props.openModal(
                            this.props.listOnAuctionModalState
                          )
                        }
                      >
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          List on Auction
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          this.props.setRemoveMsg(
                            MODAL_CONSTANTS.SUCCESS_MODAL_BURN
                          );
                          this.props.openModal(this.props.removeModalState);
                        }}
                      >
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-times"></i>
                          </div>
                          Burn
                        </li>
                      </div>
                      <div onClick={() => this.props.buyRamAction(true)}>
                        <li>
                          <div className={classes.moreBtnDropDownIconContainer}>
                            <i className="fas fa-memory"></i>
                          </div>
                          Buy Ram
                        </li>
                      </div>
                    </>
                  )}
                </>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
