import React from "react";
import classes from "./ModalsGroup.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import NFTsPurchaseModal from "../NFTsPurchaseModal";
import NFTsSuccessModal from "../NFTsSuccessModal";
import ErrorModal from "../ErrorModal";
import UserAuthModal from "../UserAuthModal";
import Model from "../Model";
import Model1 from "../Model1";
import Model2 from "../Model2";
import ListOnSaleModal from "../ListOnSaleModal";
import NFTsRemoveSaleModal from "../NFTsRemoveSaleModal";
import ListOnAuctionModal from "../ListOnAuctionModal";
import PlaceBidModal from "../PlaceBidModal";

const ModalsGroup = ({
  ual,
  modalsStates,
  modalsFuncs,
  currentTokenSymbol,
  setCurrentTokenSymbol,
  setTokenPrecision,
  isSalePage,
  isAuctionPage,
}) => {
  return (
    <div
      className={
        modalsStates.showBackdrop
          ? classes.modalsContainerOpen
          : classes.modalsContainerClose
      }
    >
      <Backdrop
        show={modalsStates.showBackdrop}
        onClick={modalsFuncs.closeModalHandler}
        animation={modalsStates.modalAnimation}
      />
      <ErrorModal
        errorMsg={modalsStates.errorMsg}
        showModal={modalsStates.showErrorModal}
        close={modalsFuncs.closeModalHandler}
      />
      <Model
        purchaseItem="C O I N Z"
        from="Kory"
        price={0.007}
        balance={8.498}
        fee={0}
        willPay={0.007}
        isVerified={false}
        showModal={modalsStates.showModal}
        close={modalsFuncs.closeModalHandler}
        continueBtn={modalsFuncs.showModalHandler}
        nextModal={modalsStates.setShowModal1}
      />
      <Model1
        isVerified={false}
        showModal={modalsStates.showModal1}
        close={modalsFuncs.closeModalHandler}
        continueBtn={modalsFuncs.showModalHandler}
        nextModal={modalsStates.setShowModal2}
      />
      <Model2
        purchaseItem="C O I N Z"
        from="Kory"
        status="Processing"
        transactionID="0msx836930...87r398"
        showModal={modalsStates.showModal2}
        close={modalsFuncs.closeModalHandler}
      />
      <UserAuthModal
        msgType={modalsStates.authMsg}
        showModal={modalsStates.showAuthModal}
        close={modalsFuncs.closeModalHandler}
        login={modalsFuncs.loginHandler}
      />
      <NFTsPurchaseModal
        showModal={modalsStates.showPurchaseNFTModal}
        close={modalsFuncs.closeModalHandler}
        terms={true}
        currentPurchasePath={modalsStates.currentPurchasePath}
        closeModalHandler={modalsFuncs.closeModalHandler}
        showModalHandler={modalsFuncs.showModalHandler}
        setShowSuccessModal={modalsStates.setShowSuccessModal}
        setSuccessMsg={modalsStates.setSuccessMsg}
        setErrorMsg={modalsStates.setErrorMsg}
        setShowErrorModal={modalsStates.setShowErrorModal}
        ual={ual}
      />
      <PlaceBidModal
        msgType="bid"
        errorMsg={modalsStates.errorMsg}
        showModal={modalsStates.showPlaceBidModal}
        close={modalsFuncs.closeModalHandler}
        terms={true}
        currentPurchasePath={modalsStates.currentPurchasePath}
        setShowSuccessModal={modalsStates.setShowSuccessModal}
        showModalHandler={modalsFuncs.showModalHandler}
        setSuccessMsg={modalsStates.setSuccessMsg}
        setErrorMsg={modalsStates.setErrorMsg}
        setShowErrorModal={modalsStates.setShowErrorModal}
        ual={ual}
      />
      <ListOnSaleModal
        msgType="update"
        showModal={modalsStates.showUpdatePriceModal}
        close={modalsFuncs.closeModalHandler}
        value={modalsStates.listingData.price}
        onChange={modalsFuncs.listingPriceHandler}
        confirm={modalsFuncs.updatePrice}
        currentTokenSymbol={currentTokenSymbol}
        setCurrentTokenSymbol={setCurrentTokenSymbol}
        setTokenPrecision={setTokenPrecision}
        getTokenSymbol={modalsFuncs.getTokenSymbol}
      />
      <ListOnSaleModal
        msgType="sell"
        showModal={modalsStates.showListOnSaleModal}
        close={modalsFuncs.closeModalHandler}
        value={modalsStates.listingData.price}
        onChange={modalsFuncs.listingPriceHandler}
        confirm={modalsFuncs.listOnSale}
        currentTokenSymbol={currentTokenSymbol}
        setCurrentTokenSymbol={setCurrentTokenSymbol}
        setTokenPrecision={setTokenPrecision}
        getTokenSymbol={modalsFuncs.getTokenSymbol}
      />
      <ListOnSaleModal
        msgType="auction"
        showModal={modalsStates.showUpdatePriceAuctionModal}
        close={modalsFuncs.closeModalHandler}
        value={modalsStates.listingData.price}
        onChange={modalsFuncs.listingPriceHandler}
        confirm={modalsFuncs.updatePriceAuction}
        currentTokenSymbol={currentTokenSymbol}
        setCurrentTokenSymbol={setCurrentTokenSymbol}
        setTokenPrecision={setTokenPrecision}
        getTokenSymbol={modalsFuncs.getTokenSymbol}
      />
      <ListOnAuctionModal
        showModal={modalsStates.showAuctionModal}
        close={modalsFuncs.closeModalHandler}
        value={modalsStates.listingData.price}
        onChange={modalsFuncs.listingPriceHandler}
        days={modalsStates.auctionDuration.days}
        onChangeDays={modalsFuncs.auctionDurationDaysHandler}
        hours={modalsStates.auctionDuration.hours}
        onChangeHours={modalsFuncs.auctionDurationHoursHandler}
        minutes={modalsStates.auctionDuration.minutes}
        onChangeMinutes={modalsFuncs.auctionDurationMinutesHandler}
        errorMsg={modalsStates.errorMsg}
        confirm={modalsFuncs.listOnAuction}
      />
      <NFTsRemoveSaleModal
        msgType={modalsStates.removeMsg}
        terms={true}
        showModal={modalsStates.showRemoveModal}
        close={modalsFuncs.closeModalHandler}
        confirm={modalsFuncs.remove}
        isAuctionPage={isAuctionPage}
        isSalePage={isSalePage}
      />
      <NFTsSuccessModal
        msgType={modalsStates.successMsg}
        showModal={modalsStates.showSuccessModal}
        close={modalsFuncs.closeModalHandler}
      />
      {/* <NFTsSuccessModal
        msgType="updated the NFT price for auction"
        showModal={this.state.showSuccessUpdatePriceModal}
        close={this.closeModalHandler}
      /> */}
    </div>
  );
};

export default ModalsGroup;
