import React, { useState, useEffect } from "react";
import classes from "./TopAccounts.module.css";
import TopAccountCard from "../TopAccountCard";

const TopAccounts = ({ accounts, symbol, title, isTopSeller }) => {
  return (
    <div className={classes.topAccountsWrapper}>
      <h2 className={classes.title}>{title}</h2>
      {
        accounts && accounts.length ?
          accounts.map((account, index) => 
              <TopAccountCard
                topAccount={account}
                symbol={symbol}
                index={index + 1}
                isTopSeller={isTopSeller}
              />
            )
          :
            <p>None</p>
      }
    </div>
  );
}

export default TopAccounts;
