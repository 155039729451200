import React from 'react'
import classes from './PriceBox.module.css'

export default function PriceBox(props) {
    return (

        <>
            {
                props.symbol === '$' ?
                    <div className={classes.usdPriceBox}>
                        {props.symbol}{props.price}
                    </div>
                    :
                    <div className={classes.zpcPriceBox}>
                        {Number(props.price).toFixed(2)} {props.symbol}
                    </div>

            }

        </>
    )
}
