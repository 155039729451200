// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SchemaAttributesTab_lineWrapper__rUjXD {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.SchemaAttributesTab_name__3aLYb, .SchemaAttributesTab_type__zTRqE {\r\n  min-width: 40%;\r\n  font-size: 18px;\r\n  text-align: center;\r\n  padding: 20px;\r\n  background-color: rgba(168, 187, 207, 0.12);\r\n  border-radius: 16px;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/SchemaDetails/SchemaAttributesTab/SchemaAttributesTab.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,2CAA2C;EAC3C,mBAAmB;AACrB","sourcesContent":[".lineWrapper {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.name, .type {\r\n  min-width: 40%;\r\n  font-size: 18px;\r\n  text-align: center;\r\n  padding: 20px;\r\n  background-color: rgba(168, 187, 207, 0.12);\r\n  border-radius: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineWrapper": "SchemaAttributesTab_lineWrapper__rUjXD",
	"name": "SchemaAttributesTab_name__3aLYb",
	"type": "SchemaAttributesTab_type__zTRqE"
};
export default ___CSS_LOADER_EXPORT___;
