import React, { useEffect, useState } from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import binance from "../../assets/img/binance.svg";
import Huobi from "../../assets/img/huobi.svg";
import kucoin from "../../assets/img/kucoin.svg";
import bittrex from "../../assets/img/bittrex.svg";
import buyWaxImg from "../../assets/img/buy_wax.png";
import classesGroup from "./ModalsGroup/ModalsGroup.module.css";
export default function BuyWaxModal({
  userBalance,
  userAddress,
  close,
  showModal = false,
}) {
  const [modal, setModal] = useState({
    cloudWallet: true,
    exchangeWallet: false,
  });

  return (
    <>
      <div
        className={
          showModal
            ? classesGroup.modalsContainerOpen
            : classesGroup.modalsContainerClose
        }
      >
        {showModal && (
          <div
            className={`${classes.removeFromSaleModal} ${classes.purchaseNFTModal}`}
            style={{
              transform: "scale(1)",
              opacity: 1,
              boxShadow: "1px 1px 4px 1px rgb(0 0 0 / 19%)",
            }}
          >
            <div
              className={`${classes.modelInnerContainer} ${classes.closeWrapper}`}
            >
              <div
                className={classes.modelImageCancel}
                onClick={() => close()}
                style={{ marginLeft: "auto" }}
              >
                <img src={CloseIcon} alt="Modal-close" />
              </div>
            </div>
            <div className={classes.titleWrapper}>
              <div style={{ width: "100%", padding: "10px 10px" }}>
                <h1 class="text-center">Buy WAX Tokens</h1>
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="d-flex pointer">
                        <div
                          onClick={() =>
                            setModal({
                              cloudWallet: true,
                              exchangeWallet: false,
                            })
                          }
                          className={
                            modal.cloudWallet
                              ? "border-shadow p-2 pointer active cloud-button"
                              : "border-shadow p-2 pointe cloud-button"
                          }
                        >
                          <div class="title">Cloud Wallet</div>
                          {/* <div class="info">
                                <span class="btn-grey">
                                  Best for Beginners!
                                </span>
                              </div> */}
                        </div>
                        <div
                          onClick={() =>
                            setModal({
                              cloudWallet: false,
                              exchangeWallet: true,
                            })
                          }
                          className={
                            modal.exchangeWallet
                              ? "border-shadow p-2 pointer active cloud-button"
                              : "border-shadow p-2 pointer cloud-button"
                          }
                        >
                          <div class="title">Exchange</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 mt-4 mt-md-0">
                      {modal.cloudWallet && (
                        <div class="p-4 selection border-shadow">
                          <div class="mb-4">
                            <div class="AccountBalance">
                              <div>
                                Address: <strong>{userAddress}</strong>
                              </div>
                              Balance:{userBalance}
                              <span class="balance zero">
                                <span class="PriceLabel">
                                  <span>
                                    <span class="price-color">0 WAX</span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <p>
                            You can buy WAX tokens from within the
                            <a
                              href="https://wallet.wax.io"
                              target="_blank"
                              rel="noreferrer"
                            >
                              WAX Cloud Wallet
                            </a>
                            with credit card or other crypto.
                          </p>
                          <div class="mb-3">
                            <img
                              src={buyWaxImg}
                              alt="wcw buy wax"
                              class="img-fluid shadow-lg"
                            />
                          </div>
                          <ul class="instructions">
                            <li>
                              Go to{""}
                              <a
                                href="https://wallet.wax.io"
                                target="_blank"
                                rel="noreferrer"
                              >
                                WAX Cloud Wallet
                              </a>
                            </li>
                            <li>
                              Click on <strong>"Buy WAXP"</strong>
                              button.
                            </li>
                            <li>Select the Exchange or Merchant option</li>
                            <li>
                              Enter your wax account name (
                              <strong>{userAddress}</strong>). and click on
                              continue button
                            </li>
                          </ul>
                        </div>
                      )}
                      {modal.exchangeWallet && (
                        <div class="p-4 selection border-shadow p-2">
                          <div class="mb-4">
                            <div class="AccountBalance">
                              <div>
                                Address: <strong>{userAddress}</strong>
                              </div>{" "}
                              Balance:
                              <span class="balance zero">
                                <span class="PriceLabel">
                                  <span>
                                    <span class="price-color">
                                      {userBalance} WAX
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <h3>Exchange platforms</h3>
                          <p>
                            Exchange platforms is only recommended for advanced
                            users. When you withdrawing tokens from the
                            exchange, enter your account name (
                            <strong>{userAddress}</strong>).
                          </p>
                          <div class="exchanges p-2 align mb-0 mb-md-5">
                            <a
                              href="https://www.binance.com/en/trade/WAXP_USDT"
                              className="inner-div pointer"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={binance} alt="Binance" />
                            </a>

                            <a
                              href="https://www.huobi.com/en-us/exchange/waxp_usdt"
                              className="inner-div pointer"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={Huobi} alt="Huobi" />
                            </a>

                            <a
                              href="https://global.bittrex.com/Market/Index?MarketName=BTC-WAXP"
                              className="inner-div pointer"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={bittrex} alt="Bittrex" />
                            </a>

                            <a
                              href="https://trade.kucoin.com/trade/WAX-USDT"
                              className="inner-div pointer"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={kucoin} alt="Kucoin" />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
