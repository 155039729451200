import React, { Component } from "react";
import QRImage from "../../../src/assets/img/QRImage.png";
import classes from "./Login.module.css";
import Button from "../../components/Button/Button";
import AppServices from "../../services/AppService";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
    };

    this.appService = new AppServices();
  }

  showModelHandler = () => {
    window.history.back();
  };

  handleLogin = async () => {
    await this.appService.login(this.props.ual);
  };

  render() {
    return (
      <div className={classes.LoginPageMainContainer}>
        <div className={classes.FormMainContainer}>
          <div className={classes.FormContainer}>
            <h1>
              Login
            </h1>
            <div>To View this page you need to login first</div>
            <Button onClick={this.handleLogin}>
              <div className={classes.upLoadBtnTextContainer}>Login</div>
            </Button>
          </div>
          <div className={classes.ImageContainer}>
            <img src={QRImage} alt="QRImage-logo" />
          </div>
        </div>
      </div>
    );
  }
}
