import React from "react";
import classes from "./Model.module.css";
import Button from "../../components/Button/Button";
import CloseIcon from "../../assets/img/closeIcon.svg";
import InfoIcon from "../../assets/img/modalInfoIcon.svg";
import UserProfile from "../../../src/assets/img/creatorUser.png";

export default function Model1(props) {
  return (
    <div
      className={classes.topModel1Container}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div className={classes.modelTitle}>
          <h1>Follow Step</h1>
        </div>
        <div className={classes.modelImageCancel} onClick={props.close}>
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>

      <div className={classes.modelTranscation}>
        <div className={classes.firstTranscation} />
        <div className={classes.secondTranscation}>
          <h3>Purchasing</h3>
          <p>Sending transaction with your wallet</p>
        </div>
      </div>

      <div className={classes.modelCreator}>
        <div className={classes.modelInfoCircle}>
          <img src={InfoIcon} alt="Info" />
        </div>
        <div className={classes.creator}>
          <p>
            {props.isVerified
              ? "This creator is verified"
              : "This creator is not verified"}
          </p>
          <p>{!props.isVerified && "Purchase this item at your own risk"}</p>
        </div>

        <div className={classes.salerImage}>
          <img src={UserProfile} alt="UserProfile-logo" />
        </div>
      </div>

      <div className={classes.modelbtn}>
        <Button onClick={() => props.continueBtn(props.nextModal)}>
          <div>I understand, continue</div>
        </Button>
        <Button secondary onClick={props.close}>
          <div>Cancel</div>
        </Button>
      </div>
    </div>
  );
}
