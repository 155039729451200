import React from "react";
import classes from "./UserAssetsCard.module.css";
import AppServices from "../../services/AppService";
import { useHistory } from "react-router-dom";
import PriceBox from "../UI/PriceBox/PriceBox";
import ActionButton from "../UI/ActionButton/ActionButton";
import AssetImage from "./AssetImage";
import AssetAudio from "./AssetAudio";
import AssetVideo from "./AssetVideo";
import Slider from "react-slick";
import Tooltip from "../UI/Tooltip";
import { usePopperTooltip } from "react-popper-tooltip";
import SaleHoverCard from "../HoverComponent/SaleHoverCard";

const MAX_TITLE_LENGTH = 23;

const appService = new AppServices();
const bannerListConfig = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};


export default function UserAssetsCard({
  assetsContent,
  title,
  tokenAmount,
  tokenSymbol,
  tokenPrecision,
  stock,
  detailsPath,
  purchasePath,
  auction,
  ual,
  checkUserAuthentication,
  setCurrentPurchasePath,
  border,
  marginBottom,
  id,
  collection,
  seller,
  aasetDetils = null,
  sold = false,
}) {
 
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "auto-start",
    delayShow: 500,
    delayHide: 300,
  });
  
  const history = useHistory();
  const slider = React.useRef(null);

  const navigateToScreen = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  const onClickDetails = () => {
    if (setCurrentPurchasePath) {
      setCurrentPurchasePath(purchasePath);
    }
    detailsPath && purchasePath
      ? navigateToScreen(purchasePath)
      : navigateToScreen(detailsPath);
  };

  const onClickBuy = () => {
    setCurrentPurchasePath(purchasePath);
    checkUserAuthentication(!auction, auction);
  };

  const price = +tokenAmount / Math.pow(10, tokenPrecision);
  const usdPrice = price * (appService.getUsdPrice() ?? 1);
  const isBundle = () => assetsContent && assetsContent.length > 1;
  const noneContent = () => (
    <div className={classes.noneContentIconWrapper}>
      <i className="fas fa-question"></i>
    </div>
  );

  const getAssetContentView = (type, content, onClick, isBundle) => {
    if (type === "img") {
      return (
        <AssetImage content={content} onClick={onClick} isBundle={isBundle} />
      );
    } else if (type === "video") {
      return (
        <AssetVideo content={content} onClick={onClick} isBundle={isBundle} />
      );
    } else if (type === "audio") {
      return (
        <AssetAudio
          id={id}
          content={content}
          onClick={onClick}
          isBundle={isBundle}
        />
      );
    }
  };

  const getAssetTitle = (assetTitle, isBundle, onClickDetails) =>
    isBundle ? (
      <h1 onClick={onClickDetails}>
        <span>
          <i className="fas fa-layer-group" /> {assetTitle}
        </span>
      </h1>
    ) : assetTitle && assetTitle.length > MAX_TITLE_LENGTH ? (
      <Tooltip
        id="asset-title"
        tip={assetTitle}
        string={assetTitle}
        limit={MAX_TITLE_LENGTH}
        isAsset
        onClick={onClickDetails}
      />
    ) : (
      <h1 onClick={onClickDetails}>{assetTitle}</h1>
    );

  return (
    <div
      className={classes.previewAssetsCardContainer}
      style={{ border: border, marginBottom: marginBottom }}
    >
      <div ref={setTriggerRef} className={classes.PreviewImage}>
        {assetsContent ? (
          isBundle() ? (
            <div className={classes.sliderWrapper}>
              <Slider {...bannerListConfig} ref={slider}>
                {assetsContent.map((asset) =>
                  getAssetContentView(
                    asset.type,
                    asset.content,
                    onClickDetails,
                    isBundle()
                  )
                )}
              </Slider>
              <button
                className={classes.prevBtn}
                onClick={() => slider?.current?.slickPrev()}
              >
                <i className="fas fa-long-arrow-alt-left"></i>
              </button>
              <button
                className={classes.nextBtn}
                onClick={() => slider?.current?.slickNext()}
              >
                <i className="fas fa-long-arrow-alt-right"></i>
              </button>
            </div>
          ) : (
            <div className={classes.singleNftWrapper} onClick={onClickDetails}>
              {getAssetContentView(
                assetsContent[0].type,
                assetsContent[0].content
              )}
            </div>
          )
        ) : (
          noneContent()
        )}
      </div>
      <div className={classes.PreviewDescContainer}>
        {getAssetTitle(title, isBundle(), onClickDetails)}
        {tokenAmount && (
          <div
            className={classes.priceAndStockContainer}
            style={{ display: "block" }}
          >
            {tokenAmount && <PriceBox price={price} symbol={tokenSymbol} />}
            {tokenAmount && (
              <PriceBox price={usdPrice?.toFixed(2)} symbol="$" />
            )}
            {stock && <span>{stock + "in stock"}</span>}
          </div>
        )}
        {collection === true ? (
          <></>
        ) : (
          <div className={classes.actionBtnContainer}>
            {detailsPath && !sold && (
              <ActionButton
                title="Details"
                color="black"
                onClick={onClickDetails}
              />
            )}
            {purchasePath &&
              seller !== ual?.activeUser?.accountName &&
              !sold && (
                <ActionButton
                  title={auction === true ? "Bid" : "Buy"}
                  onClick={onClickBuy}
                />
              )}
          </div>
        )}
      </div>

      {visible && aasetDetils && (
        <div
          style={{
            transform: "scale(1)",
            opacity: 1,
            boxShadow: "1px 1px 4px 1px rgb(0 0 0 / 19%)",
          }}
          className={classes.cardAnimation}
          ref={setTooltipRef}
          {...getTooltipProps({ className: classes["tooltip-container"] })}
        >
          <div {...getArrowProps({ className: classes["tooltip-arrow"] })} />
          <SaleHoverCard cardInfo={aasetDetils} />
        </div>
      )}
    </div>
  );
}
