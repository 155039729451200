import React, { Component } from "react";
import classes from "./Upload.module.css";
import QRCode from "../../../src/assets/img/img.png";
import QRCode1 from "../../../src/assets/img/img2.png";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";

export default class UploadItem extends Component {
  render() {
    return (
      <>
        <div className={classes.topUploadTextContainer}>
          <div>
            <h2>Create a New NFT</h2>
          </div>
        </div>
        <div className={classes.UploadItemMainContainer}>

          <div className={classes.MainFlexContainer}>
            <div className={classes.FirstFlexContainer}>
              <div className={classes.Image}>
                <img src={QRCode} alt="QRCode-logo" width="95%" height="60%" />
              </div>
              <Link to="/single-nft">
                <Button secondary>
                  <div>Create Single</div>
                </Button>
              </Link>
            </div>
            <div className={classes.FirstFlexContainer}>
              <div className={classes.Image}>
                <img src={QRCode1} alt="QRCode-logo" width="92%" height="50%" />
              </div>
              <Link to="/multiple-nfts">
                <Button>
                  <div>Create Multiple</div>
                </Button>
              </Link>
            </div>
          </div>
          <div className={classes.ParaghContainer}>
            <p>
              We do not own your private keys and cannot access your funds
              without your confirmation.
            </p>
          </div>
        </div>
      </>
    );
  }
}
