// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabsSections_id__2DlGu {\r\n  color: #dc3825;\r\n}\r\n\r\n.TabsSections_descriptionWrapper__lc25C {\r\n  margin: 26px 0;\r\n}\r\n\r\n.TabsSections_tabsSectionsWrapper__RkxTu {\r\n  width: 70%;\r\n}\r\n\r\n.TabsSections_tabsSectionsWrapper__RkxTu ul {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  border: 2px solid #e6e8ec;\r\n  border-radius: 36px;\r\n  margin-top: 32px;\r\n  padding: 0px 5px;\r\n}\r\n\r\n@media only screen and (max-width: 1000px) {\r\n  .TabsSections_tabsSectionsWrapper__RkxTu {\r\n    width: 100%;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/TemplateDetails/TabsSections/TabsSections.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".id {\r\n  color: #dc3825;\r\n}\r\n\r\n.descriptionWrapper {\r\n  margin: 26px 0;\r\n}\r\n\r\n.tabsSectionsWrapper {\r\n  width: 70%;\r\n}\r\n\r\n.tabsSectionsWrapper ul {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  border: 2px solid #e6e8ec;\r\n  border-radius: 36px;\r\n  margin-top: 32px;\r\n  padding: 0px 5px;\r\n}\r\n\r\n@media only screen and (max-width: 1000px) {\r\n  .tabsSectionsWrapper {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"id": "TabsSections_id__2DlGu",
	"descriptionWrapper": "TabsSections_descriptionWrapper__lc25C",
	"tabsSectionsWrapper": "TabsSections_tabsSectionsWrapper__RkxTu"
};
export default ___CSS_LOADER_EXPORT___;
