import React, { Component } from "react";
import classes from "./EditProfile.module.css";
import EditProfileTitle from "../../components/EditProfilePage/EditProfileTitle/EditProfileTitle";
import EditProfilePageUploadPhoto from "../../components/EditProfilePage/EditProfilePageUploadPhoto/EditProfilePageUploadPhoto";
import EditProfilePageAccountInfo from "../../components/EditProfilePage/EditProfilePageAccountInfo/EditProfilePageAccountInfo";
import FirebaseService from '../../services/FirebaseService';
import BlockchainService from '../../services/BlockchainService';
import EDIT_PROFILE_CONSTANTS from '../../constants/EditProfile';
import PushNotification from "../../components/Model/PushNotification";

const onlySpaceRegExp = /^\s+$/;

export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        img: "",
        imgBase64: "",
        name: "",
        bio: "",
        facebookProfile: "",
        twitter: "",
        instagramProfile: "",
        coverPhoto: "",
      },
      errorMsg: "",
      errorMsgPhoto: "",
      errorMsgBio: "",
      errorMsgFB: "",
      errorMsgInstagram: "",
      errorMsgTwitter: "",
      isLoading: false,
      notificationTitle: "",
      showNotification: false,
      isSuccess: false,
      isError: false
    };

    this.firebaseService = new FirebaseService()
    this.blockchainService = new BlockchainService()
  }

  showNotificationHandler = (msg) => {
    this.setState({ showNotification: true, notificationTitle: msg });

    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  componentDidMount = async () => {
    let userName = await this.blockchainService.getAccountName(this.props.ual);
    let fetchData = await this.firebaseService.getUser(userName)

    if (fetchData) {
      fetchData = fetchData.data()
      if (fetchData) {
        let userData = this.state.userData;
        userData.imgBase64 = fetchData.image ?? ""
        userData.img = fetchData.image ?? ""
        userData.name = fetchData.name ?? ""
        userData.bio = fetchData.bio ?? ""
        userData.facebookProfile = fetchData.facebook ?? ""
        userData.twitter = fetchData.twitter ?? ""
        userData.instagramProfile = fetchData.instagram ?? ""
        userData.coverPhoto = fetchData.coverPhoto ?? ""
        this.setState({ userData })
      }
    }

  }

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;

      if (currentFileSize > EDIT_PROFILE_CONSTANTS.PHOTO_BYTES_LIMIT) {
        const errorMsgPhoto =
          `This file is not allowed. ${currentFileSize} bytes is too large`;
        this.setState({ errorMsgPhoto })
        return false;
      } else if (!EDIT_PROFILE_CONSTANTS.FILE_TYPES.includes(currentFileType)) {
        const errorMsgPhoto =
          "This file is not supported";
        this.setState({ errorMsgPhoto })
        return false;
      }
      this.setState({ errorMsgPhoto: '' })
      return true;
    }
  };

  uploadPhotoHandler = (e) => {
    let userData = this.state.userData;
    let file = e.target.files[0];
    const isVerified = this.verifyFile(e.target.files);
    if(isVerified) {
      const myFileItemReader = new FileReader();

      myFileItemReader.addEventListener(
        "load",
        () => {
          const myResult = myFileItemReader.result;
          userData.img = myResult
          this.setState({ userData })
        },
        false
      );
  
      myFileItemReader.readAsDataURL(file);
    }
  };

  displayNameHandler = (e) => {
    this.setState((prevState) => {
      let userData = { ...prevState.userData };
      userData.name = e.target.value;
      return { userData };
    });
  };

  bioHandler = (e) => {
    this.setState((prevState) => {
      let userData = { ...prevState.userData };
      userData.bio = e.target.value;
      return { userData };
    });
  };

  facebookProfileHandler = (e) => {
    this.setState((prevState) => {
      let userData = { ...prevState.userData };
      userData.facebookProfile = e.target.value;
      return { userData };
    });
  };

  twitterHandler = (e) => {
    this.setState((prevState) => {
      let userData = { ...prevState.userData };
      userData.twitter = e.target.value;
      return { userData };
    });
  };

  instagramProfileHandler = (e) => {
    this.setState((prevState) => {
      let userData = { ...prevState.userData };
      userData.instagramProfile = e.target.value;
      return { userData };
    });
  };

  displayError = (userData) => {
    let isError = false;
    if(!userData.name) {
      isError = true;
      this.setState({ errorMsg: EDIT_PROFILE_CONSTANTS.NAME_ERROR });
    }
    if(userData.name && userData.name.length > EDIT_PROFILE_CONSTANTS.NAME_MAX_LIMIT) {
      isError = true;
      this.setState({ errorMsg: EDIT_PROFILE_CONSTANTS.NAME_MAX_LIMIT_ERROR });
    }
    if(userData.name && userData.name.length < EDIT_PROFILE_CONSTANTS.NAME_MIN_LIMIT) {
      isError = true;
      this.setState({ errorMsg: EDIT_PROFILE_CONSTANTS.NAME_MIN_LIMIT_ERROR });
    }
    if((userData.name && onlySpaceRegExp.test(userData.name))) {
      isError = true;
      this.setState({ errorMsg: EDIT_PROFILE_CONSTANTS.ONLY_SPACE });
    }
    if(!userData.bio) {
      isError = true;
      this.setState({ errorMsgBio: EDIT_PROFILE_CONSTANTS.BIO_ERROR });
    }
    if(userData.bio && userData.bio.length < EDIT_PROFILE_CONSTANTS.BIO_MIN_LIMIT) {
      isError = true;
      this.setState({ errorMsgBio: EDIT_PROFILE_CONSTANTS.BIO_MIN_LIMIT_ERROR });
    }
    if(userData.bio && userData.bio.length < EDIT_PROFILE_CONSTANTS.BIO_MIN_LIMIT) {
      isError = true;
      this.setState({ errorMsgBio: EDIT_PROFILE_CONSTANTS.BIO_MIN_LIMIT_ERROR });
    }
    if((userData.bio && onlySpaceRegExp.test(userData.bio))) {
      isError = true;
      this.setState({ errorMsgBio: EDIT_PROFILE_CONSTANTS.ONLY_SPACE });
    }
    if((userData.facebookProfile && onlySpaceRegExp.test(userData.facebookProfile))) {
      isError = true;
      this.setState({ errorMsgFB: EDIT_PROFILE_CONSTANTS.ONLY_SPACE });
    }
    if((userData.twitter && onlySpaceRegExp.test(userData.twitter))) {
      isError = true;
      this.setState({ errorMsgTwitter: EDIT_PROFILE_CONSTANTS.ONLY_SPACE });
    }
    if((userData.instagramProfile && onlySpaceRegExp.test(userData.instagramProfile))) {
      isError = true;
      this.setState({ errorMsgInstagram: EDIT_PROFILE_CONSTANTS.ONLY_SPACE });
    }
    if(this.state.errorMsgPhoto !== '') {
      isError = true;
    }
    // this.checkOnlySpace(userData, isError)
    return isError;
  }

  updateBtnHandler = () => {
    const userData = this.state.userData;
    //let userUpdatedData = this.state.userUpadtedData;

    if (!this.displayError(userData)) {
      this.setState({
        errorMsg: "",
        errorMsgBio: "",
        errorMsgPhoto: "",
        errorMsgFB: "",
        errorMsgTwitter: "",
        errorMsgInstagram: "",
      });
      this.addUser();
    }
  };
  clearAllHandler = () => {
    const userData = {
      img: "",
      name: "",
      bio: "",
      facebookProfile: "",
      twitter: "",
      instagramProfile: ""
    };
    this.setState({
      userData,
      errorMsg: "",
      errorMsgBio: "",
      errorMsgPhoto: "",
      errorMsgFB: "",
      errorMsgTwitter: "",
      errorMsgInstagram: "",
    });
  };

  addUser = async () => {
    this.setState({ isLoading: true })
    let userData = this.state.userData;
    let userName = await this.blockchainService.getAccountName(this.props.ual);
    const error =
      await this.firebaseService.addUser(
        userName,
        {
          name: userData.name,
          image: userData.img,
          bio: userData.bio,
          coverPhoto:
          userData.coverPhoto,
          facebook: userData.facebookProfile,
          twitter: userData.twitter,
          instagram: userData.instagramProfile
        }
      )
    if(error) {
      this.setState({ isError: true, isSuccess: false });
      this.showNotificationHandler(EDIT_PROFILE_CONSTANTS.ERROR_UPDATE_MESSAGE)
    } else {
      this.setState({ isSuccess: true, isError: false });
      this.showNotificationHandler(EDIT_PROFILE_CONSTANTS.SUCCESS_UPDATE_MESSAGE);
    }
    this.setState({ isLoading: false });
  }


  render() {
    return (
      <div className={classes.pageWrapper}>
        {
          this.state.isLoading &&
          <div className={classes.backDrop}>
            <div className={classes.backDropLoader} />
          </div>
        }
        <div className={classes.editProfileMainContainer}>
          <div className={classes.editProfileDescContainer}>
            <EditProfileTitle />
            <div className={classes.editProfileContentContainer}>
              <div className={classes.editProfileUploadPhotoContainer}>
                <EditProfilePageUploadPhoto
                  userImg={this.state.userData.img}
                  uploadPhotoHandler={this.uploadPhotoHandler}
                  errorMsg={this.state.errorMsgPhoto}
                />
              </div>
              <div className={classes.editProfilePageInfoContainer}>
                <EditProfilePageAccountInfo
                  userName={this.state.userData.name}
                  displayNameHandler={this.displayNameHandler}
                  errorMsg={this.state.errorMsg}
                  errorMsgBio={this.state.errorMsgBio}
                  errorMsgFB={this.state.errorMsgFB}
                  errorMsgTwitter={this.state.errorMsgTwitter}
                  errorMsgInstagram={this.state.errorMsgInstagram}
                  userBio={this.state.userData.bio}
                  bioHandler={this.bioHandler}
                  facebookProfile={this.state.userData.facebookProfile}
                  facebookProfileHandler={this.facebookProfileHandler}
                  userTwitter={this.state.userData.twitter}
                  twitterHandler={this.twitterHandler}
                  instagramProfile={this.state.userData.instagramProfile}
                  instagramProfileHandler={this.instagramProfileHandler}
                  updateBtnHandler={this.updateBtnHandler}
                  clearAllHandler={this.clearAllHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <PushNotification
          title={this.state.notificationTitle}
          showNotification={this.state.showNotification}
          isSuccess={this.state.isSuccess}
          isError={this.state.isError}
        />
      </div>
    );
  }
}
