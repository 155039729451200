import React, { Component } from "react";
import classes from "./CreateTemplate.module.css";
import SingleCollectiblePageTitleAndSwithBtn from "../../components/SingleCollectiblePage/SingleCollectiblePageTitleAndSwithBtn/SingleCollectiblePageTitleAndSwithBtn";
import SingleCollectiblePageUploadFile from "../../components/SingleCollectiblePage/SingleCollectiblePageUploadFile/SingleCollectiblePageUploadFile";
import { SingleCollectiblePageItemDetails } from "../../components/SingleCollectiblePage/SingleCollectiblePageItemDetails/SingleCollectiblePageItemDetailsWOR";
import SingleCollectiblePageBtnAndAutoSave from "../../components/SingleCollectiblePage/SingleCollectiblePageBtnAndAutoSave/SingleCollectiblePageBtnAndAutoSave";
import SingleCollectiblePagePreview from "../../components/SingleCollectiblePage/SingleCollectiblePagePreview/SingleCollectiblePagePreview";
import ApiServices from "../../services/ApiServices";
import AppServices from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import NFTsSuccessModal from "../../components/Model/NFTsSuccessModal";
import ErrorModal from "../../components/Model/ErrorModal";
import PushNotification from "../../components/Model/PushNotification";
import SingleCollectiblePageItemDetailsOldV from "../../components/SingleCollectiblePage/SingleCollectiblePageItemDetails/SingleCollectiblePageItemDetailsOldV";
import Button from "../../components/Button/Button";
import BuyRamModal from "../../components/Model/BuyRamModal";

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes =
  "image/bmp, image/svg+xml, image/x-png, image/png, image/jpg, image/jpeg, image/webp, image/gif, audio/mpeg, audio/wav, audio/ogg ,video/mp4, video/quicktime";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

export default class CreateTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      showBackdrop: false,
      showModal: false,
      showErrorModal: false,
      showLoader: false,
      modalAnimation: false,
      showNotification: false,
      notificationTitle: "",
      errorMsg: "",
      userAssetsData: {
        img: "",
        title: "",
        description: "",
        rarity: "rare",
        category: "art",
        prevImg: "",
        prevVideo: "",
        prevAudio: "",
        fileName: "",
        numberOfCopies: 1,
      },
      userUpdatedAssetsData: [],
      userAccount: "",
      userCollection: "",
      isCollectionCreated: false,
      userSchema: "",
      isSchemaCreated: false,
      userAsset: "",
      imageIpfsUrl: "",
      enableBuyRamBtn: false,
      showBuyRamModal: false,
    };

    this.apiService = new ApiServices();
    this.appService = new AppServices();
    this.blockchainService = new BlockchainService();

    this.defaultSchemaName = process.env.REACT_APP_DEFAULT_SCHEMA;
  }

  async componentDidMount() {
    await this.getData();
  }

  showModalHandler = () => {
    this.setState({ showModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };

  showLoaderHandler = () => {
    this.setState({ showLoader: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  showErrorModalHandler = () => {
    this.setState({ showErrorModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
  };
  closeModalHandler = () => {
    this.setState({
      showModal: false,
      showErrorModal: false,
      showLoader: false,
      modalAnimation: true,
      showBackdrop: false,
      showBuyRamModal: false,
    });
  };

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        this.showNotificationHandler(
          "This file is not allowed." + currentFileSize + " bytes too large"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        this.showNotificationHandler(
          "This file is not supported or file size is too large"
        );
        return false;
      }
      return true;
    }
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        this.setState((prevState) => {
          let userAssetsData = { ...prevState.userAssetsData };
          userAssetsData.img = currentFile;
          return { userAssetsData };
        });
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;

            this.setState((prevState) => {
              let userAssetsData = { ...prevState.userAssetsData };

              userAssetsData.prevVideo = "";
              userAssetsData.prevImg = "";
              userAssetsData.prevAudio = "";

              if (currentFile.type === "video/mp4") {
                userAssetsData.prevVideo = myResult;
              } else if (
                currentFile.type === "audio/mpeg" ||
                currentFile.type === "audio/wav" ||
                currentFile.type === "audio/ogg"
              ) {
                userAssetsData.prevAudio = myResult;
              } else {
                userAssetsData.prevImg = myResult;
              }
              return { userAssetsData };
            });
            this.setState((prevState) => {
              let userAssetsData = { ...prevState.userAssetsData };
              userAssetsData.fileName = currentFile.name;
              return { userAssetsData };
            });
          },
          false
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  userDataHandler = (e, type) => {
    this.setState((prevState) => {
      let userAssetsData = { ...prevState.userAssetsData };
      if (type === "title") userAssetsData.title = e.target.value;
      else if (type === "description")
        userAssetsData.description = e.target.value;
      else if (type === "rarity") userAssetsData.rarity = e;
      else if (type === "category") userAssetsData.category = e;
      else if (type === "copies")
        userAssetsData.numberOfCopies = e.target.value;

      return { userAssetsData };
    });
  };

  createItemBtnHandler = async () => {
    this.setState({ pending: true });
    const userAssetsData = this.state.userAssetsData;
    let userUpdatedAssetsData = this.state.userUpdatedAssetsData;
    let errorMsg = "";
    if (
      userAssetsData.title !== "" &&
      (userAssetsData.img !== "" ||
        userAssetsData.prevVideo !== "" ||
        userAssetsData.prevAudio !== "")
    ) {
      this.showLoaderHandler();
      userUpdatedAssetsData.push(userAssetsData);
      let res = await this.apiService.uploadFileOnIpfs(userAssetsData.img);
      let ipfsId = "";

      if (res) {
        console.log('info',res);

        ipfsId = res.data.IpfsHash;
        this.setState({ imageIpfsUrl: ipfsId });

        this.mintNFT(ipfsId);
      } else {
        this.setState({ errorMsg: "Unable to store NFT content of IPFS" });
        this.showErrorModalHandler();
      }
    } else {
      if (userAssetsData.title === "") {
        errorMsg = "Name is required!";
      } else if (
        userAssetsData.img === "" ||
        userAssetsData.prevVideo === "" ||
        userAssetsData.prevAudio === ""
      ) {
        errorMsg = "Content is required!";
      }
    }
    this.setState({ errorMsg, pending: false });
  };

  clearAllHandler = () => {
    const userAssetsData = {
      img: "",
      title: "",
      description: "",
      rarity: "rare",
      category: "art",
      prevImg: "",
      prevVideo: "",
      prevAudio: "",
      fileName: "",
      numberOfCopies: 1,
    };
    this.setState({ userAssetsData, errorMsg: "" });
  };

  mintNFT = async (ipfsHash) => {
    const userAssetsData = this.state.userAssetsData;

    let userAccount = this.state.userAccount;
    let displayName = this.state.userAccount;
    let collectionImage = "";
    let collectionDescription = "";
    let collectionUrl = "";

    let schemaName = this.defaultSchemaName;

    let assetName = userAssetsData.title;
    let assetContentKey = "";
    let assetContentValue = "";

    if (this.state.userAssetsData.prevImg !== "") {
      assetContentKey = "img";
      assetContentValue = ipfsHash;
    }

    if (this.state.userAssetsData.prevVideo !== "") {
      assetContentKey = "video";
      assetContentValue = ipfsHash;
    }

    if (this.state.userAssetsData.prevAudio !== "") {
      assetContentKey = "audio";
      assetContentValue = ipfsHash;
    }

    let assetDescription = userAssetsData.description;
    let assetRarity = userAssetsData.rarity.toLowerCase();
    let assetCategory = userAssetsData.category.toLowerCase();
    let numberOfCopies = +userAssetsData.numberOfCopies;

    if (
      userAccount !== "" &&
      displayName !== "" &&
      schemaName !== "" &&
      assetName !== "" &&
      assetContentValue !== "" &&
      assetContentKey !== ""
    ) {
      let createCollectionAction =
        this.blockchainService.createCollectionAction(
          userAccount,
          displayName,
          collectionImage,
          collectionDescription,
          collectionUrl
        );

      let createSchemaAction = this.blockchainService.createSchemaAction(
        userAccount,
        schemaName
      );

      let createTemplateAction = this.blockchainService.createTemplateAction(
        userAccount,
        schemaName,
        numberOfCopies,
        assetName,
        assetDescription,
        assetRarity.toLowerCase(),
        assetCategory.toLowerCase(),
        assetContentKey,
        assetContentValue
      );

      let finalActions = createTemplateAction;

      if (this.state.isSchemaCreated === false) {
        finalActions.actions.unshift(createSchemaAction.actions[0]);
      }

      if (this.state.isCollectionCreated === false) {
        finalActions.actions.unshift(createCollectionAction.actions[0]);
      }

      const result = await this.blockchainService.pushTransaction(
        finalActions,
        this.props.ual
      );
      if (result.success) {
        this.showNotificationHandler("Template created successfully");
        this.clearAllHandler();
        this.closeModalHandler();
      } else {
        if (result.message.includes("insufficient ram")) {
          this.setState(
            {
              errorMsg: result.message,
              pending: false,
              enableBuyRamBtn: true,
            },
            () => {
              this.showErrorModalHandler();
            }
          );

          return;
        }
        this.setState({ errorMsg: result.message });
        this.showErrorModalHandler();
      }
    }
  };

  getData = async () => {
    let isCollectionCreated = false,
      isSchemaCreated = false;
    let userSchema = this.defaultSchemaName;

    let userAccount = await this.getUserAccount();
    if (userAccount) {
      let userCollection = await this.getUserCollections(userAccount);
      if (userCollection) {
        isCollectionCreated = true;
        let userSchemas = await this.getUserSchema(userAccount);
        if (userSchemas) {
          for (var i = 0; i < userSchemas.length; i++) {
            let element = userSchemas[i];
            if (element.schema_name === userSchema) {
              isSchemaCreated = true;
            }
          }
        }
      }
    }

    this.setState({
      userAccount,
      userCollection: userAccount,
      isCollectionCreated,
      userSchema,
      isSchemaCreated,
    });
  };

  getUserAccount = async () => {
    let userAccount = await this.appService.getAccountName(this.props.ual);
    return userAccount;
  };

  getUserCollections = async (userAccount) => {
    let res = await this.apiService.getUserCollection(userAccount);
    return res;
  };

  getUserSchema = async (collectionName) => {
    let userSchema = await this.apiService.getCollectionSchema(collectionName);
    return userSchema;
  };

  getTemplateId = async (trxId) => {
    let templateId = -1;
    let transactionResponse =
      await this.blockchainService.getTransactionDetails(trxId);
    if (transactionResponse && transactionResponse.traces.length > 3) {
      templateId = transactionResponse.traces[3]?.act?.data?.template_id ?? -1;
    } else if (transactionResponse && transactionResponse.traces) {
      templateId = transactionResponse.traces[1]?.act?.data?.template_id ?? -1;
    }

    return templateId;
  };
  showNotificationHandler = (msg) => {
    this.setState({ showNotification: true, notificationTitle: msg });

    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };
  openBuyRamModal = () => {
    this.setState({ showBuyRamModal: true, showBackdrop: true });
    window.scrollTo(0, 0);
    console.log("openBuyRamModal");
  };
  removeBuyRamError = () => {
    this.setState({ enableBuyRamBtn: false, errorMsg: "" });
  };

  goBackHandler = () => {
    window.history.back();
  };

  render() {
    return (
      <>
        <div className={classes.SingleCollectibleMainContainer}>
          <div className={classes.goBackBtnWrapper}>
            <Button secondary onClick={this.goBackHandler}>
              <div className={classes.goBackBtn}>
                <i className="fas fa-arrow-left"></i> Go back
              </div>
            </Button>
          </div>
          <SingleCollectiblePageTitleAndSwithBtn template={true} />
          <div className={classes.topSingleCollectibleTextContainer}>
            <div className={classes.FirstFlexContainer}>
              <div className={classes.uploadFileContainer}>
                <SingleCollectiblePageUploadFile
                  acceptedFileTypes={acceptedFileTypes}
                  imageMaxSize={imageMaxSize}
                  handleOnDrop={this.handleOnDrop}
                  fileName={this.state.userAssetsData.fileName}
                />
              </div>
              <SingleCollectiblePageItemDetailsOldV
                userAssetsData={this.state.userAssetsData}
                userDataHandler={this.userDataHandler}
                isSingle={true}
              />
              {this.state.enableBuyRamBtn && (
                <Button onClick={this.openBuyRamModal}>Buy Ram</Button>
              )}
              <SingleCollectiblePageBtnAndAutoSave
                errorMsg={this.state.errorMsg}
                pending={this.state.pending}
                createItemBtnHandler={this.createItemBtnHandler}
              />
            </div>
            <div className={classes.SecondFlexContainer}>
              <SingleCollectiblePagePreview
                prevImg={this.state.userAssetsData.prevImg}
                prevVideo={this.state.userAssetsData.prevVideo}
                prevAudio={this.state.userAssetsData.prevAudio}
                title={this.state.userAssetsData.title}
                description={this.state.userAssetsData.description}
                category={
                  this.state.userAssetsData.category
                    ? this.state.userAssetsData.category
                    : "Art"
                }
                rarity={
                  this.state.userAssetsData.rarity
                    ? this.state.userAssetsData.rarity
                    : "Rare"
                }
                clearAllHandler={this.clearAllHandler}
              />
            </div>
          </div>
        </div>
        <div className={classes.modalsContainer}>
          <Backdrop
            show={this.state.showBackdrop}
            onClick={this.closeModalHandler}
            animation={this.state.modalAnimation}
          />
          <NFTsSuccessModal
            msgType="The NFT is created successfully"
            showModal={this.state.showModal}
            close={this.closeModalHandler}
          />
          <ErrorModal
            errorMsg={this.state.errorMsg}
            showModal={this.state.showErrorModal}
            close={this.closeModalHandler}
          />
         {this.state.showBuyRamModal&& <BuyRamModal
            msgType="Buy Ram"
            showModal={this.state.showBuyRamModal}
            close={this.closeModalHandler}
            ual={this.props.ual}
            showNotificationHandler={this.showNotificationHandler}
            removeError={this.removeBuyRamError}
          />}
          <PushNotification
            title={this.state.notificationTitle}
            showNotification={this.state.showNotification}
          />
        </div>
      </>
    );
  }
}
