// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchNotFound_pageWrapper__31a37 {\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/screens/SearchNotFound/SearchNotFound.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".pageWrapper {\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": "SearchNotFound_pageWrapper__31a37"
};
export default ___CSS_LOADER_EXPORT___;
