import React from 'react';
import classes from './Loader.module.css';
import LoaderIcon from '../../../assets/img/loader.png';

const Loader = ({ loading }) => {
  return (
    <>
      {
        loading &&
        <div className={classes.loadMoreBtnContainer}>
          <button>
            <img
              src={LoaderIcon}
              alt="Loading..."
              className={classes.loaderAnimationIcon}
            />
            <span>Loading...</span>
          </button>
        </div>
      }
    </>
  );
};

export default Loader;
