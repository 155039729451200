import React from "react";
import classes from "./SingleCollectiblePageItemDetails.module.css";
import { ToggleSwitch } from "./ToggleSwitch/ToggleSwitch";
import Cross from "./../../../assets/img/cross.svg"
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import { SET_CURRENT_COLLECTION } from "../../../stores/reducers/singleCollectibleReducer";
import { apiService } from "../../../constants/Servises";


const ITEM_NAME_MAX_LENGTH = 12;
const COLLECTION_COLORS = ["#45B26B", "#EF466F", "#9757D7"];

export const SingleCollectiblePageItemDetails = React.memo(({
  userLogin,
  userAssetsData,
  userDataHandler,
  showAdvancedSettings,
  toggleAdvancedSettingsHandler,
  setUserCollectionHandler,
  userCollection,
  userCollections,
  userSchema,
  userCollectionSchemas,
  setUserCollectionSchemas,
  setUsrSchema
}) => {
  const collectionsArray = userCollections;

  const dispatch = useDispatch();

  const onChangePriceHandler = (e) => {
    if ((/^-?\d*\.?\d*$/).test(e.target.value)) {
      userDataHandler(e, "price")
    }
  }
  const onChangeOnStockHandler = (e) => {
    if ((/^-?[1-9]\d*$/).test(e.target.value)) {
      userDataHandler(e, "in_stock")
    }
  }
  const marketFeeValidate = (e) => {
    if ((/^[0-9]$/).test(e.currentTarget.value)) {
      userDataHandler(e, "market_fee")
    }
  }
  const chooseCollectionHandler = async (collectionName) => {
    let collectionSchemas = await apiService.getCollectionSchema(collectionName);
    if (collectionSchemas) {
      setUserCollectionSchemas(collectionSchemas);
    }
    dispatch(SET_CURRENT_COLLECTION(collectionName));
    setUserCollectionHandler(collectionName);
  }
  const chooseSchemaHandler = (schemaName) => {
    setUsrSchema(schemaName)
  }

  const toggleAdvanced = () => {
    if (showAdvancedSettings) {
      setUserCollectionHandler("");
      setUsrSchema("");
    }
    toggleAdvancedSettingsHandler();
  }

  const putOnSaleHandler = (e) => userDataHandler(e.target.checked, "put_on_sale");
  const instantlySoldHandler = (e) => {
    userDataHandler(e.target.checked, "instantly_sold");
  }
  const contentLockHandler = (e) => userDataHandler(e.target.checked, "content_lock");

  return (
    <div className={classes.itemDetailContainer}>
      <h4>Item Detail</h4>
      <div className={classes.FormContainer}>
        <div>Item name
          <span className={classes.requiredField}>*</span>
          <br /><span className={classes.smallCharacters}>(12 Character, 1-5 & a-z)</span>
        </div>
        <input
          type="text"
          placeholder="e. g. “Redeemable Bitcoin Card with logo”"
          value={userAssetsData.title}
          onChange={(e) => userDataHandler(e, "title")}
          maxLength={ITEM_NAME_MAX_LENGTH}
        />
      </div>
      <div className={classes.FormContainer}>
        <div>Display name<span className={classes.requiredField}>*</span></div>
        <input
          type="text"
          placeholder="e. g. “Redeemable Bitcoin Card with logo”"
          value={userAssetsData.displayName}
          onChange={(e) => userDataHandler(e, "display_name")}
        />
      </div>
      <div className={classes.FormContainer}>
        <div>Description<span className={classes.requiredField}>*</span></div>
        <textarea
          placeholder="e. g. “After purchasing you will able to recived the logo...”"
          value={userAssetsData.description}
          onChange={(e) => userDataHandler(e, "description")}
        />
      </div>


      <div className={showAdvancedSettings ? "" : classes.advancedSettings}>
        <div className={classes.marketWebsiteWrapper}>
          <div className={`${classes.FormContainer} ${classes.marketFeeWrapper}`}>
            <div>Market fee<span className={classes.requiredField}>*</span></div>

            <div className={classes.optionWrapper}>
              <input type="number" min="0" max="6" value={userAssetsData.dropMarketFee}
                onChange={e => marketFeeValidate(e)}
                className={classes.marketFeeSelect} />
            </div>
          </div>
          <div className={`${classes.FormContainer} ${classes.websiteWrapper}`}>
            <div>Website url</div>
            <input
              type="text"
              placeholder="e. g. Size"
              value={userAssetsData.websiteUrl}
              onChange={(e) => userDataHandler(e, "website_url")}
            />
          </div>
        </div>

        <div>
          <div className={classes.marketWebsiteWrapper}>
            <div className={`${classes.FormContainer} ${classes.priceWrapper}`}>
              <div>Price</div>
              <input
                type="text"
                placeholder="Price"
                value={userAssetsData.price}
                onChange={onChangePriceHandler}
              />
            </div>
            <div className={`${classes.FormContainer} ${classes.stockWrapper}`}>
              <div>In stock</div>
              <input
                type="text"
                placeholder="In stock"
                value={userAssetsData.inStock}
                onChange={onChangeOnStockHandler}
              />
            </div>
          </div>

          <div className={classes.FormContainer}>
            <div className={classes.bottomWrapper}>Authorised accounts</div>
            <div className={classes.accAreaWrapper}>
              <div className={classes.styleUserLogin}>{userLogin}<span className={classes.requiredField}>*</span></div>
              <button className={classes.accAddBtn}>Add</button>
            </div>
          </div>

          <div className={classes.FormContainer}>
            <div className={classes.bottomWrapper}>Notify accounts</div>
            <div className={classes.accAreaWrapper}>
              <div className={classes.styleUserLogin}>{userLogin}<span className={classes.requiredField}>*</span></div>
              <button className={classes.accAddBtn}>Add</button>
            </div>
          </div>

          <div className={classes.dividingLine} />

          <div className={classes.toggleContainer}>
            <div className={classes.toggleTextWrapper}>
              <div className={classes.toggleTitle}>Put on sale</div>
              <div className={classes.toggleSubTitle}>You`ll receive birds on this item</div>
            </div>
            <ToggleSwitch callback={putOnSaleHandler} />
          </div>

          <div className={classes.toggleContainer}>
            <div className={classes.toggleTextWrapper}>
              <div className={classes.toggleTitle}>Instant sale price</div>
              <div className={classes.toggleSubTitle}>Enter the price for which the item will be instantly sold</div>
            </div>
            <ToggleSwitch callback={instantlySoldHandler} />
          </div>

          <div className={classes.toggleContainer}>
            <div className={classes.toggleTextWrapper}>
              <div className={classes.toggleTitle}>Unlock once purchased</div>
              <div className={classes.toggleSubTitle}>Content will be unlocked after successful transaction</div>
            </div>
            <ToggleSwitch callback={contentLockHandler} />
          </div>

          <div className={classes.chooseCollectionWrapper}>
            <div className={classes.toggleTitle}>Choose collection</div>
            <div className={classes.toggleSubTitle}>Choose an exiting collection or create a new one</div>
          </div>

          <div className={classes.collectionsWrapper}>
            <div className={classes.collectionCreator}>
              <Link className={`${classes.collectionAdd} ${classes.hoverOnAdd}`} to="/create-new-collection">
                <img src={Cross} alt={'Cross'} />
              </Link>
              <div className={classes.collectionName}>Create collection</div>
            </div>
            {collectionsArray.length > 0 && collectionsArray.map((element, i) => {
              let color;
              if (i % 3 === 0) {
                color = COLLECTION_COLORS[0];
              } else if (i % 3 === 1) {
                color = COLLECTION_COLORS[1];
              } else if (i % 3 === 2) {
                color = COLLECTION_COLORS[2];
              }
              return (
                <div className={`${classes.collectionCreator} ${classes.collectionsPointer}
                   ${userCollection === element.collection_name ? classes.chosenCollection : ""}`}
                  onClick={() => chooseCollectionHandler(element.collection_name)}>
                  <div className={classes.collectionAdd} style={{ backgroundColor: color }}>
                  </div>
                  <div className={classes.collectionName}>
                    {element.collection_name}
                  </div>
                </div>
              )
            })}
          </div>
          {userCollection &&
            <div className={classes.schemasWrapper}>

              <div className={classes.chooseCollectionWrapper}>
                <div className={classes.toggleTitle}>Choose schema</div>
                <div className={classes.toggleSubTitle}>Choose an exiting schema or create a new one</div>
              </div>

              <div className={classes.collectionsWrapper}>
                <div className={classes.collectionCreator}>
                  <Link className={`${classes.collectionAdd} ${classes.hoverOnAdd}`} to="/create-schema">
                    <img src={Cross} alt={'Cross'} />
                  </Link>
                  <div className={classes.collectionName}>Create schema</div>
                </div>
                {userCollectionSchemas.length > 0 && userCollectionSchemas.map((element, i) => {
                  let color;
                  if (i % 3 === 0) {
                    color = COLLECTION_COLORS[0];
                  } else if (i % 3 === 1) {
                    color = COLLECTION_COLORS[1];
                  } else if (i % 3 === 2) {
                    color = COLLECTION_COLORS[2];
                  }
                  return (
                    <div className={`${classes.collectionCreator} ${classes.collectionsPointer}
                   ${userSchema === element.schema_name ? classes.chosenCollection : ""}`}
                      onClick={() => chooseSchemaHandler(element.schema_name)}>
                      <div className={classes.collectionAdd} style={{ backgroundColor: color }}>
                      </div>
                      <div className={classes.collectionName}>
                        {element.schema_name}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>}


        </div>
      </div>

      <div className={classes.btnWrapper}>
        <Button onClick={toggleAdvanced}>
          {!showAdvancedSettings ? "Show" : "Hide"} Advanced Details
        </Button>
      </div>

    </div>
  );
})

