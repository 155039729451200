import React from "react";

export default function TopBannerLoader() {
  const loaderUI = ({ right, left }) => (
    <div>
      {Array.from(Array(5).keys()).map((data, index) => (
        <div className="ph-row" key={index}>
          {index !== 0 && (
            <div className="ph-col-12 empty" style={{ height: "30px" }}></div>
          )}
          {left && <div className="ph-col-2 empty"></div>}
          <div
            className="ph-col-2"
            style={
              left ? { height: "50px", marginLeft: "50px" } : { height: "50px" }
            }
          ></div>
          <div
            className="ph-col-6"
            style={{ height: "5px", marginTop: "30px", marginLeft: "30px" }}
          ></div>
          {right && <div className="ph-col-2 empty"></div>}
        </div>
      ))}
    </div>
  );
  if (window.screen.availWidth < 600) {
    return (
      <div>
        <div className="ph-item">
          <div className="ph-row" style={{ height: "450px", width: "100%" }}>
            <div className="ph-col-12" style={{ height: "100%" }}></div>
          </div>
          <div>
            <div className="ph-row" style={{ height: "100px" }}>
              <div className="ph-col-12 empty"></div>
              {Array.from(Array(2).keys()).map((data, index) => (
                <>
                  <div
                    className="ph-col-2"
                    style={{
                      height: "40px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div
                    className="ph-col-8"
                    style={{ height: "5px", marginTop: "25px" }}
                  ></div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ph-item">
      {loaderUI({ left: true })}
      <div>
        <div className="ph-row">
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-8" style={{ height: "400px" }}></div>
          <div className="ph-col-2 empty"></div>
        </div>
      </div>

      {loaderUI({ right: true })}
    </div>
  );
}
