import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  userData: {
    img: "",
    prevVideo: "",
    prevImg: "",
    prevAudio: "",
    fileName: "",
  },
  imageIpfsUrl: "",
  notificationTitle: "",
  notificationShow: false,
  isSchemaCreated: false,
  errMsg: "",
  imgErr: false,
}

const newCollectionReducer = createSlice({
  name: 'newCollectionReducer',
  initialState,
  reducers: {
    SET_IMG: (state, action) => {
      state.userData.img = action.payload
    },
    SET_PREV_VIDEO: (state, action) => {
      state.userData.prevVideo = action.payload
    },
    SET_PREV_IMG: (state, action) => {
      state.userData.prevImg = action.payload
    },
    SET_PREV_AUDIO: (state, action) => {
      state.userData.prevAudio = action.payload
    },
    SET_FILE_NAME: (state, action) => {
      state.userData.fileName = action.payload
    },
    SET_IMAGE_IPFS_URL: (state, action) => {
      state.imageIpfsUrl = action.payload
    },
    SET_ERR_MSG: (state, action) => {
      state.errMsg = action.payload
    },
    SET_IMG_ERR: (state, action) => {
      state.imgErr = action.payload
    },
    CLEAR_IMG: (state, action) => {
      state.userData.img = '';
      state.userData.prevVideo = '';
      state.userData.prevImg = '';
      state.userData.prevAudio = '';
      state.userData.fileName = '';
    }
  },
})

export const {
  SET_IMG,
  SET_PREV_VIDEO,
  SET_PREV_IMG,
  SET_PREV_AUDIO,
  SET_FILE_NAME,
  SET_IMAGE_IPFS_URL,
  SET_ERR_MSG,
  SET_IMG_ERR,
  CLEAR_IMG,

} = newCollectionReducer.actions

export default newCollectionReducer.reducer