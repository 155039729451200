import React, { useEffect, useState } from "react";
import classes from "./AttributesTab.module.css"
import AttributesWrapper from "./AttributesWrapper";

const AttributesTab = ({ asset, isTemplateDetailsPage }) => {

  return (
    <>
      {
        asset ? (
          <div className={classes.attributesTab}>
            <AttributesWrapper
              title='Immutable Attributes'
              data={isTemplateDetailsPage ? asset.immutable_data : asset.data}
            />
            {!isTemplateDetailsPage &&
              <AttributesWrapper title='Mutable Attributes' data={asset.mutable_data}/>
            }
          </div>
        ) : null
      }
    </>
  )
}

export default AttributesTab;
