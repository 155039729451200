import React, { useState, useEffect } from "react";
import ActionButton from "../UI/ActionButton/ActionButton";
import classes from "./FilterSearch.module.css";

const DEFAULT_MARKET_PLACEHOLDER = "Please first filter the collection";
const PLACEHOLDER = "Type your keywords";

const FilterSearch = ({ match, setMatch, isMarket, dropCollection }) => {
  const [input, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState(PLACEHOLDER);

  const handleSubmit = (e) => {
    if (isMarket && dropCollection === "All creators") {
      setInput("");
    } else {
      setMatch(input || "");
    }
    e.preventDefault();
  };

  useEffect(() => {
    setInput(match || "");
  }, [match]);

  useEffect(() => {
    if (isMarket && dropCollection === "All creators") {
      setPlaceholder(DEFAULT_MARKET_PLACEHOLDER);
    } else {
      setPlaceholder(PLACEHOLDER);
    }
  }, [dropCollection]);

  return (
    <div className={classes.filterSearchContainer}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          value={input}
          type="text"
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          disabled={placeholder === DEFAULT_MARKET_PLACEHOLDER}
        />
        <ActionButton type="submit" search />
      </form>
    </div>
  );
};

export default FilterSearch;
