// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stats_statsWrapper__3-LBG {\n  display: flex;\n  flex-wrap: wrap;\n}\n@media only screen and (max-width: 710px) {\n  .Stats_statsWrapper__3-LBG {\n    flex-wrap: wrap;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/PriceHistorySectionTab/Stats/Stats.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".statsWrapper {\n  display: flex;\n  flex-wrap: wrap;\n}\n@media only screen and (max-width: 710px) {\n  .statsWrapper {\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsWrapper": "Stats_statsWrapper__3-LBG"
};
export default ___CSS_LOADER_EXPORT___;
