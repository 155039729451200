import React, { useEffect, useState } from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import Button from "../Button/Button";
import { blockchainService } from "../../constants/Servises";
const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;

export default function BuyRamModal(props) {
  const [ramPice, setRamPrice] = useState(0);
  const [error, setError] = useState("");

  const [quantity, setQuantity] = useState(0);
  const ramPriceInBytes = async () => {
    const ramprice = await blockchainService.viewTableData();
    setRamPrice(ramprice);
  };
  useEffect(() => {
    ramPriceInBytes();
  }, [props]);

  const buyRamHandler = async () => {
    let symbol = tokenSymbol;

    let action = blockchainService.buyRam(
      props.ual.activeUser.accountName,
      quantity,
      symbol
    );
    let data = await blockchainService.pushTransaction(action, props.ual);
    if (data.success) {
      props.close();
      props.showNotificationHandler("Ram bought Successfully");
      resetState();
      props.removeError();
    } else {
      setError(data.message);
    }
  };
  const resetState = () => {
    setError("");
    setRamPrice(0);
    setQuantity(0);
  };
  return (
    <div
      className={
        props.showModal
          ? classes.modalsContainerOpen
          : classes.modalsContainerClose
      }
    >
      <div
        className={classes.errorModal}
        style={
          props.showModal
            ? { transform: "scale(1)", opacity: 1 }
            : { transform: "scale(0)", opacity: 0 }
        }
      >
        <div className={classes.modelInnerContainer}>
          <div
            className={classes.modelImageCancel}
            onClick={() => {
              props.close();
              resetState();
            }}
            style={{ marginLeft: "auto" }}
          >
            <img src={CloseIcon} alt="Modal-close" />
          </div>
        </div>
        <div className={classes.modeldescription}></div>
        <div
          className={classes.modelFlexTranscation}
          style={{ justifyContent: "center" }}
        >
          <div style={{ width: "100%" }}>
            <div>Cost of RAM({tokenSymbol})</div>
            <input
              type="number"
              value={quantity}
              onChange={(event) => {
                setQuantity(event.target.value);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "13px 10px",
                border: " 2px solid #e6e8ec",
                borderRadius: "12px",
                width: "100%",
                fontSize: "16px",
                lineHeight: "12px",
                color: "#353945",
                outline: "none",
                height: "48.75px",
              }}
            />
            <div style={{ marginTop: "10px" }}>
              <p style={{ color: "blue", textAlign: "center" }}>
                {quantity / ramPice >= 3 &&
                  ` Estimated Ram amount ≈ ${
                    (quantity / ramPice).toFixed(0) > 1000
                      ? (quantity / ramPice).toFixed(0) / 1000 + "kB"
                      : (quantity / ramPice).toFixed(0) + "B"
                  } `}
              </p>
              <p style={{ color: "red", textAlign: "center" }}>
                {0 < quantity / ramPice &&
                  quantity / ramPice < 3 &&
                  `A minimum of 3 bytes of RAM is required for transaction.`}
              </p>
              <p style={{ color: "red", textAlign: "center" }}>
                {error && error}
              </p>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button secondary onClick={() => buyRamHandler()}>
            <div style={{ padding: "0px 40px" }}>Buy Ram</div>
          </Button>
        </div>
      </div>
    </div>
  );
}
