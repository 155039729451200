import React, { Component } from "react";
import { Routing } from "./routing/Routing";
import "./App.css";
import { Anchor } from "ual-anchor";
// import { KeycatAuthenticator } from "@telosnetwork/ual-telos-keycat";
// import { Wombat } from "ual-wombat";
// import { Sqrl } from "@smontero/ual-sqrl";
import { Wax } from "@eosdacio/ual-wax";
import { UALProvider, withUAL } from "ual-reactjs-renderer";

const chain = {
  chainId: process.env.REACT_APP_CHAINID,
  name: "TLOS",
  rpcEndpoints: [
    {
      protocol: process.env.REACT_APP_NETWORK_PROTOCOL,
      host: process.env.REACT_APP_RPC,
      port: process.env.REACT_APP_NETWORK_PORT,
    },
  ],
};

const anchor = new Anchor([chain], {
  appName: process.env.REACT_APP_APPNAME,
});

let keyCat = null;
// let wombat = null;
// let sqrl = null;
let wax = null;

if (process.env.REACT_APP_ENV === "wax"
  || process.env.REACT_APP_ENV === "wax-testnet") {
  wax = new Wax([chain], {
    appName: process.env.REACT_APP_APPNAME,
  });
}

// if (
//   process.env.REACT_APP_ENV === "telos-testnet" ||
//   process.env.REACT_APP_ENV === "telos"
// ) {
//   keyCat = new KeycatAuthenticator([chain], {
//     appName: process.env.REACT_APP_APPNAME,
//   });

//   wombat = new Wombat([chain], {
//     appName: process.env.REACT_APP_APPNAME,
//   });

//   sqrl = new Sqrl([chain], {
//     appName: process.env.REACT_APP_APPNAME,
//   });
// }

export default class App extends Component {
  render() {
    let authenticators = [anchor];

    // if (
    //   process.env.REACT_APP_APPNAME === "zeptagram-telos-mainnet" ||
    //   process.env.REACT_APP_APPNAME === "zeptagram-telos-testnet"
    // ) {
    //   authenticators.push(keyCat);
    //   authenticators.push(wombat);
    //   authenticators.push(sqrl);
    // } else
    if (process.env.REACT_APP_APPNAME === "zeptagram-wax-mainnet") {
      authenticators.push(wax);
    }

    return (
      <UALProvider
        appName={process.env.REACT_APP_APPNAME}
        authenticators={authenticators}
        chains={[chain]}
        key={chain.chainId}
      >
        <AppConsumer />
      </UALProvider>
    );
  }
}

const AppConsumer = withUAL(Routing);
