import React, { Component } from "react";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import classes from "./SingleCollectiblePageItemDetails.module.css";

export default class SingleCollectiblePageItemDetailsOldV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropRarityType: "Rare",
      dropAssetType: "Art",
      rarityList: ["Common", "Uncommon", "Rare", "Epic", "Legendary", "Mythic", "Other"],
      assetTypeList: ["Art",
        "Game",
        "Photography",
        "Music",
        "Video",
        "Other",
      ]
    };

  }
  dropRarityTypeHandler = (item) => {
    this.setState({ dropRarityType: item });
    this.props.userDataHandler(item, "rarity");
  };
  dropAssetTypeHandler = (item) => {
    this.setState({ dropAssetType: item });
    this.props.userDataHandler(item, "category");
  };
  render() {
    return (
      <div className={classes.itemDetailContainer}>
        <h4>Item Detail</h4>
        <div className={classes.FormContainer}>
          <div>Item name</div>
          <input
            type="text"
            placeholder="e. g. “Redeemable Bitcoin Card with logo”"
            value={this.props.userAssetsData.title}
            onChange={(e) => this.props.userDataHandler(e, "title")}
          />
        </div>
        <div className={classes.FormContainer}>
          <div>Description</div>
          <textarea
            placeholder="e. g. “After purchasing you will able to recived the logo...”"
            value={this.props.userAssetsData.description}
            onChange={(e) => this.props.userDataHandler(e, "description")}
          />
        </div>
        <div className={classes.TemplateFlexContainer}>
          <div className={classes.FormContainer}>
            <div>Rarity</div>
            <FilterDropdown
              options={this.state.rarityList}
              setSelected={this.dropRarityTypeHandler}
              selected={
                this.props.userAssetsData.rarity
                  ? this.props.userAssetsData.rarity
                  : "Rare"
              }
            />
          </div>
          <div className={classes.FormContainer}>
            <div>Asset Type</div>
            <FilterDropdown
              options={this.state.assetTypeList}
              setSelected={this.dropAssetTypeHandler}
              selected={
                this.props.userAssetsData.category
                  ? this.props.userAssetsData.category
                  : "Art"
              }
            />
          </div>
          {this.props.isSingle === true && (
            <div className={classes.FormContainer} style={{ width: 100 }}>
              <div>Max supply</div>
              <input
                type="number"
                min={1}
                value={this.props.userAssetsData.numberOfCopies}
                onChange={(e) => this.props.userDataHandler(e, "copies")}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
