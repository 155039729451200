import axios from "axios";
import { JsonRpc, RpcError, Api } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
// const { TextDecoder, TextEncoder } = require("util");
const { TextEncoder, TextDecoder } = require("text-encoding");

const rpcUrl =
  process.env.REACT_APP_NETWORK_PROTOCOL +
  "://" +
  process.env.REACT_APP_RPC +
  ":" +
  process.env.REACT_APP_NETWORK_PORT;
const rpc = new JsonRpc(rpcUrl, { fetch });

const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;

export default class BlockchainService {
  constructor() {
    this.atomicAssetContract = "atomicassets";
    this.atomicMarketContract = "atomicmarket";
    this.eosioTokenContract = "eosio.token";
    this.tokenSymbol =
      process.env.REACT_APP_TOKEN_PRECISION +
      "," +
      process.env.REACT_APP_TOKEN_SYMBOL;
    this.marketPlaceName = process.env.REACT_APP_MARKET_NAME;
    this.defaultMarketFee = process.env.REACT_APP_MARKET_FEE;
    this.defaultSchemaName = process.env.REACT_APP_DEFAULT_SCHEMA;
    this.privateKey = process.env.REACT_APP_ADMIN_WALLET_KEY;
    this.signature = this.getSignatureProvider(this.privateKey);
  }

  httpCall = (url, data) => {
    return axios.post(url, data, {
      headers: {
        "x-auth-token": `${window.localStorage.getItem("token")}`,
      },
    });
  };
  getAccountInfo = async (accontName) => {
    let res = null;
    try {
      res = await rpc.get_account(accontName);
    } catch (err) {
      res = null;
    }
    console.log(res, "resresres");
    return res;
  };

  getAccountName = (ual) => {
    if (ual.activeUser) {
      let userName = ual.activeUser.accountName;
      return userName;
    }

    return "";
  };

  getUserBalance = async (userName) => {
    var res = await rpc.get_currency_balance(
      "eosio.token",
      userName,
      tokenSymbol
    );
    if (res) {
      res = res.toString();
      return res.substr(0, res.indexOf(".") + 3);
    }
    return 0;
  };

  getAuthorization = (actor, permission) => {
    return { actor, permission };
  };

  getAction = (contractName, actonName, data, authorization) => {
    return {
      actions: [
        {
          account: contractName,
          name: actonName,
          authorization,
          data,
        },
      ],
    };
  };

  pushTransaction = async (
    trx,
    ual,
    data,
    fee = { type: process.env.REACT_APP_TOKEN_SYMBOL, amount: 0 }
  ) => {
    console.log('optionalData',data)

    let res = {
      success: false,
      message: "",
    };
    try {
      const result = await ual.activeUser.signTransaction(trx, {
        blocksBehind: 12,
        expireSeconds: 120,
        broadcast: true,
        sign: true,
      });

      res.message = result;
      res.success = true;
      if (trx.actions[0]?.name === "assertsale") {
        let optionalData = {
          fee,
          assetId: data.assetId,
          buyer: this.getAccountName(ual),
          seller: data.seller,
          price: data.price,
        };
        // console.log('optionalData',optionalData,data)
        this.saveTnx(
          result,
          this.getAccountName(ual),
          "assertsale",
          optionalData,
          "user"
        );
      }
    } catch (e) {
      console.log("E", e);
      if (e.message.toString().includes("balance"))
        res.message = "Kindly buy more " + tokenSymbol + " for the transaction";
      else if (e.message.toString().includes("CPU"))
        res.message = "Kindly stake more CPU for the transaction";
      else if (e.message.toString().includes("net0"))
        res.message = "Kindly stake more NET for the transaction";
      else res.message = e.message;

      res.success = false;
    }

    return res;
  };

  defaultPushAction = async (trx) => {
    let res = {
      success: false,
      message: "",
    };

    try {
      const result = await this.api.transact(trx, {
        blocksBehind: 3,
        expireSeconds: 30,
      });
      res.message = result;
      res.success = true;
    } catch (e) {
      res.success = false;
      res.message = "Transaction Faild";
      if (e.json) {
        var errorJson = e.json.error.details[0].message;
        if (e instanceof RpcError) res.message = errorJson;
      }
    }

    return res;
  };

  getAssetFromNumber(amount, symbol, precision) {
    amount = amount.toString();
    let asset = amount;
    /* Append a decimal character in case not provided */
    if (amount.indexOf(".") === -1) {
      amount = amount + ".";
    }
    let decimalPlaces = amount.length - 1 - amount.indexOf(".");
    if (decimalPlaces > precision) {
      asset = amount.substring(0, amount.indexOf(".") + (precision + 1));
    } else if (decimalPlaces < precision) {
      asset = amount + "0".repeat(precision - decimalPlaces);
    }

    asset = `${asset} ${symbol}`;
    return asset;
  }

  buyRam = (userAccount, quantity, symbol, ramAmount) => {
    let contract = "eosio";
    let actionName = "buyram";
    let authorization = this.getAuthorization(userAccount, "active");
    let data = {
      payer: userAccount,
      receiver: userAccount,
      quant:
        symbol === "TLOS"
          ? this.getAssetFromNumber(quantity, symbol, 4)
          : symbol === "WAX"
          ? this.getAssetFromNumber(quantity, symbol, 8)
          : "",
    };
    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  createCollectionAction = (
    userAccount,
    displayName,
    image,
    description,
    url,
    title,
    marketFee
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "createcol";
    let authorization = this.getAuthorization(userAccount, "active");

    //collection-data
    let author = userAccount;
    let collection_name = title ?? userAccount;
    let allow_notify = true;
    let authorized_accounts = [userAccount];
    let notify_accounts = [userAccount];
    let market_fee = marketFee ?? this.defaultMarketFee;
    //let market_fee = this.defaultMarketFee;
    let data = [
      { key: "name", value: ["string", displayName] },
      { key: "img", value: ["string", image] },
      { key: "description", value: ["string", description] },
      { key: "url", value: ["string", url] },
    ];

    let actionData = {
      author,
      collection_name,
      allow_notify,
      authorized_accounts,
      notify_accounts,
      market_fee,
      data,
    };
    let action = this.getAction(contract, actionName, actionData, [
      authorization,
    ]);
    return action;
  };

  createSchemaAction = (
    userAccount,
    schemaName,
    collectionName,
    schemaData
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "createschema";
    let authorization = this.getAuthorization(userAccount, "active");

    //schema-data
    let authorized_creator = userAccount;
    let collection_name = collectionName ?? userAccount;
    let schema_name = schemaName ?? this.defaultSchemaName;
    let schema_format = [
      { name: "name", type: "string" },
      { name: "img", type: "string" },
      { name: "video", type: "string" },
      { name: "audio", type: "string" },
      { name: "description", type: "string" },
      { name: "rarity", type: "string" },
      { name: "category", type: "string" },
    ];

    if (schemaData && schemaData.length > 0) {
      schema_format.push(...schemaData);
    }

    let data = {
      authorized_creator,
      collection_name,
      schema_name,
      schema_format,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  createTemplateAction = (
    userAccount,
    schemaName,
    maxSupply,
    assetName,
    description,
    rarity,
    category,
    assetContentKey,
    assetContentValue
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "createtempl";
    let authorization = this.getAuthorization(userAccount, "active");

    //template-data
    let authorized_creator = userAccount;
    let collection_name = userAccount;
    let schema_name = schemaName ?? this.defaultSchemaName;
    let transferable = true;
    let burnable = true;
    let max_supply = maxSupply ?? 10;
    let immutable_data = [
      { key: "name", value: ["string", assetName] },
      { key: assetContentKey, value: ["string", assetContentValue] },
      { key: "description", value: ["string", description] },
      { key: "rarity", value: ["string", rarity] },
      { key: "category", value: ["string", category] },
    ];

    let data = {
      authorized_creator,
      collection_name,
      schema_name,
      transferable,
      burnable,
      max_supply,
      immutable_data,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  createMintAssetAction = (
    userAccount,
    schemaName,
    templateId,
    assetName,
    description,
    rarity,
    category,
    assetContentKey,
    assetContentValue,
    userCollection
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "mintasset";
    let authorization = this.getAuthorization(userAccount, "active");
    //template-data
    let authorized_minter = userAccount;
    let collection_name = userCollection ?? userAccount;
    let schema_name = schemaName ?? this.defaultSchemaName;
    let template_id = templateId ?? -1;
    let new_asset_owner = userAccount;
    let immutable_data =
      templateId > 0
        ? []
        : [
            { key: "name", value: ["string", assetName] },
            { key: assetContentKey, value: ["string", assetContentValue] },
            { key: "description", value: ["string", description] },
            { key: "rarity", value: ["string", rarity] },
            { key: "category", value: ["string", category] },
          ];
    let mutable_data = [];
    let tokens_to_back = [];

    let data = {
      authorized_minter,
      collection_name,
      schema_name,
      template_id,
      new_asset_owner,
      immutable_data,
      mutable_data,
      tokens_to_back,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  burnAssetAction = (userAccount, assetId) => {
    let contract = this.atomicAssetContract;
    let actionName = "burnasset";
    let authorization = this.getAuthorization(userAccount, "active");

    let asset_owner = userAccount;
    let asset_id = assetId;

    let data = {
      asset_owner,
      asset_id,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  announceSaleAction = (
    userAccount,
    assetId,
    listingPrice,
    settlementSymbol
  ) => {
    let contract = this.atomicMarketContract;
    let actionName = "announcesale";
    let authorization = this.getAuthorization(userAccount, "active");

    let seller = userAccount;
    let asset_ids = [assetId];
    let listing_price = listingPrice;
    let settlement_symbol = settlementSymbol;
    let maker_marketplace = this.marketPlaceName;

    let data = {
      seller,
      asset_ids,
      listing_price,
      settlement_symbol,
      maker_marketplace,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  createOfferAction = (userAccount, assetId) => {
    let contract = this.atomicAssetContract;
    let actionName = "createoffer";
    let authorization = this.getAuthorization(userAccount, "active");

    let sender = userAccount;
    let recipient = this.atomicMarketContract;
    let sender_asset_ids = [assetId];
    let recipient_asset_ids = [];
    let memo = "sale";

    let data = {
      sender,
      recipient,
      sender_asset_ids,
      recipient_asset_ids,
      memo,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  announceAuctionAction = (
    userAccount,
    assetId,
    listingPrice,
    durationTime
  ) => {
    let contract = this.atomicMarketContract;
    let actionName = "announceauct";
    let authorization = this.getAuthorization(userAccount, "active");

    let seller = userAccount;
    let asset_ids = [assetId];
    let starting_bid = listingPrice;
    let duration = durationTime;
    let maker_marketplace = this.marketPlaceName;

    let data = {
      seller,
      asset_ids,
      starting_bid,
      duration,
      maker_marketplace,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  transferNFTAction = (from, to, assetIds, memo) => {
    let contract = this.atomicAssetContract;
    let actionName = "transfer";
    let authorization = this.getAuthorization(from, "active");

    let data = {
      from,
      to,
      asset_ids: assetIds,
      memo,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  cancelSaleAction = (userAccount, saleId) => {
    let contract = this.atomicMarketContract;
    let actionName = "cancelsale";
    let authorization = this.getAuthorization(userAccount, "active");

    let data = {
      sale_id: saleId,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  assetSaleAction = (userAccount, saleId, assetId, listingPrice, symbol) => {
    let contract = this.atomicMarketContract;
    let actionName = "assertsale";
    let authorization = this.getAuthorization(userAccount, "active");

    let asset_ids_to_assert = [assetId];
    let listing_price_to_assert = listingPrice;
    let settlement_symbol_to_assert = symbol;

    let data = {
      sale_id: saleId,
      asset_ids_to_assert,
      listing_price_to_assert,
      settlement_symbol_to_assert,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  transferToken = (from, to, quantity, memo, tokenContract) => {
    let contract = tokenContract;
    let actionName = "transfer";
    let authorization = this.getAuthorization(from, "active");

    let data = {
      from,
      to,
      quantity,
      memo,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  purchaseSaleAction = (userAccount, saleId, intendedDelphiMedian = 0) => {
    let contract = this.atomicMarketContract;
    let actionName = "purchasesale";
    let authorization = this.getAuthorization(userAccount, "active");

    let buyer = userAccount;
    let sale_id = saleId;
    let intended_delphi_median = intendedDelphiMedian;
    let taker_marketplace = this.marketPlaceName;

    let data = {
      buyer,
      sale_id,
      intended_delphi_median,
      taker_marketplace,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  cancelAuctionAction = (userAccount, auctionId) => {
    let contract = this.atomicMarketContract;
    let actionName = "cancelauct";
    let authorization = this.getAuthorization(userAccount, "active");

    let data = {
      auction_id: auctionId,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  assetAuctionAction = (userAccount, auctionId, assetId) => {
    let contract = this.atomicMarketContract;
    let actionName = "assertauct";
    let authorization = this.getAuthorization(userAccount, "active");

    let asset_ids_to_assert = [assetId];

    let data = {
      auction_id: auctionId,
      asset_ids_to_assert,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  auctionBidAction = (userAccount, auctionId, amount) => {
    let contract = this.atomicMarketContract;
    let actionName = "auctionbid";
    let authorization = this.getAuthorization(userAccount, "active");

    let data = {
      bidder: userAccount,
      auction_id: auctionId,
      bid: amount,
      taker_marketplace: this.marketPlaceName,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  claimAssetAuctionAction = (userAccount, auctionId) => {
    let contract = this.atomicMarketContract;
    let actionName = "auctclaimbuy";
    let authorization = this.getAuthorization(userAccount, "active");

    let data = {
      auction_id: auctionId,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  claimBidsAuctionAction = (userAccount, auctionId) => {
    let contract = this.atomicMarketContract;
    let actionName = "auctclaimsel";
    let authorization = this.getAuthorization(userAccount, "active");

    let data = {
      auction_id: auctionId,
    };
    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  getTransactionDetails = async (trxId) => {
    let res = null;
    try {
      let transactDetails = await rpc.history_get_transaction(trxId);
      res = transactDetails;
    } catch (err) {
      res = null;
    }
    return res;
  };

  viewTableData = async (accontName) => {
    try {
      const rpcUrl2 = "https://testnet.wax.pink.gg:443";
      const rpc2 = new JsonRpc(rpcUrl2, { fetch });
      const tableQuery = {
        json: true, // Get the response as json
        code: "eosio", // Contract that we target
        scope: "eosio", // Account that owns the data
        table: "rammarket", // Table name
        limit: 10, // Maximum number of rows that we want to get
        reverse: false, // Optional: Get reversed data
        show_payer: false,
      };
      const data = await rpc2.get_table_rows(tableQuery);
      const connectorBalance = Number(data.rows[0].quote.balance.slice(0, -4));
      const CW = Number(data.rows[0].quote.weight);
      const outstandingSupply = Number(data.rows[0].base.balance.slice(0, -4));
      const ramPrice = connectorBalance / (outstandingSupply * CW) / 2;
      return ramPrice;
    } catch (err) {
      console.log("error", err);
    }
  };
  getSignatureProvider = (privateKey) => {
    const newSignatureProvider = new JsSignatureProvider([privateKey]);
    const apiInterface = new Api({
      rpc,
      signatureProvider: newSignatureProvider,
      textDecoder: new TextDecoder(),
      textEncoder: new TextEncoder(),
    });

    return apiInterface;
  };
  tranferTokenToUser = async (to, amount, memo) => {
    let res = {
      success: false,
      message: "",
    };
    try {
      const adminAccount = process.env.REACT_APP_WALLET_NAME;
      const action = this.transferToken(
        adminAccount,
        to,
        amount,
        memo,
        "eosio.token"
      );
      const result = await this.signature.transact(action, {
        blocksBehind: 3,
        expireSeconds: /*30*/ 100,
      });
      res = {
        success: true,
        message: result,
      };
      const tnxData = { transaction: result };
      this.saveTnx(
        tnxData,
        process.env.REACT_APP_WALLET_NAME,
        process.env.REACT_APP_TOKEN_SYMBOL === "WAX"
          ? "wax_transfer"
          : "telos_transfer",
        null,
        "admin"
      );
    } catch (e) {
      if (e.message.toString().includes("balance"))
        res.message = "Kindly buy more " + tokenSymbol + " for the transaction";
      else if (e.message.toString().includes("CPU"))
        res.message = "Kindly stake more CPU for the transaction";
      else if (e.message.toString().includes("net0"))
        res.message = "Kindly stake more NET for the transaction";
      else res.message = e;
      res.success = false;
    }
    return res;
  };
  saveTnx = async (result, accountName, txnType, optionalData = {}, type) => {
    let data = {};
    if (txnType !== "assertsale") {
      data = result.transaction.processed.action_traces[0].act.data;
    }
    const tnx = {
      txnId: result.transaction.transaction_id,
      blockNumber: result.transaction.processed.block_num,
      blockTime: result.transaction.processed.block_time,
      receipt: {
        status: result.transaction.processed.receipt.status,
        cpuUsage: result.transaction.processed.receipt.cpu_usage_us,
        netUsageWords: result.transaction.processed.receipt.net_usage_words,
        netUsage: result.transaction.processed.net_usage,
      },
      contractName:
        result.transaction.processed.action_traces[0].receipt.receiver,
      actionName: result.transaction.processed.action_traces[0].act.name,
      actors: [
        accountName,
        result.transaction.processed.action_traces[0].act.data?.to,
      ],
      txnType: txnType,
      status: "send",
      info: optionalData,
      txnData: {
        ...data,
        // ...optionalData,
      },
      confirmed: true,
    };

    const obj = {
      txnType: txnType,
      actors: tnx.actors,
      txn_object: tnx,
    };

    await this.httpCall(
      process.env.REACT_APP_API_URL +
        `/${type === "admin" ? "admin" : "user"}/saveTnx`,
      obj
    );
  };
}
