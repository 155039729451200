import React, { Component } from "react";
import Slider from "react-slick";
import PopularUserCard from "../../Cards/PopularUserCard/PopularUserCard";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import classes from "./HomePagePopularUser.module.css";
import OwnerRankIcon from "../../../assets/img/ownerRankIcon.svg";
import ApiServices from "../../../services/ApiServices";
import FirebaseService from "../../../services/FirebaseService";

const firebaseService = new FirebaseService();

export default class HomePagePopularUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDate: "Last month",
      showPopularType: false,
      popularType: "Sellers",
      popularTypeList: "Buyers",
      popularUser: [],
      dimension: 0,
      whiteList: "",
    };

    this.apiService = new ApiServices();
    this.tokenPrecision = process.env.REACT_APP_TOKEN_PRECISION;
    this.tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
    this.avatarURL = process.env.REACT_APP_AVATAR_URL;
  }

  componentDidMount() {
    (async () => {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
      const whiteList = await this.getWhiteListCollections();
      await this.getTopUsersData(whiteList);
    })();
  }

  async getWhiteListCollections() {
    let response = await firebaseService.getArtists();
    if (response) {
      let collections = response.data() ?? null;
      if (collections) {
        collections = collections.artists ?? [];
        return collections.toString();
      }
    }
  }

  async getTelosUserAvatar(name) {
    const user = await firebaseService.getUser(name);
    if (user && user.data()) {
      return user.data().image ?? null;
    }

    return null;
  }

  async getImage(account) {
    const img =
      this.tokenSymbol === "WAX"
        ? this.avatarURL + account
        : await this.getTelosUserAvatar(account);

    return img;
  }

  getTopUsersData = async (whiteList, isSeller = null) => {
    let popularUser = [];

    if (isSeller == null)
      isSeller = this.state.popularType === "Sellers" ? true : false;

    let topUsers = await this.apiService.getTopUsers(isSeller, whiteList);

    if (topUsers) {
      for (var i = 0; i < topUsers.length; i++) {
        popularUser.push({
          number: i + 1,
          img: this.getImage(topUsers[i].account),
          name: topUsers[i].account,
          rank: OwnerRankIcon,
          price:
            +(isSeller ? topUsers[i].sell_volume : topUsers[i].buy_volume) /
              Math.pow(10, this.tokenPrecision) +
            " " +
            this.tokenSymbol,
        });
      }
    }

    this.setState({ popularUser });
  };

  dropdownDateFitlerHandler = async (item) => {
    this.setState({ dropDate: item });
    await this.getTopUsersData();
  };

  popularTypeHandler = async () => {
    let isSeller = this.state.popularType === "Sellers" ? true : false;

    if (isSeller) {
      this.setState({
        popularType: "Buyers",
        popularTypeList: "Sellers",
        showPopularType: false,
      });
    } else {
      this.setState({
        popularType: "Sellers",
        popularTypeList: "Buyers",
        showPopularType: false,
      });
    }

    await this.getTopUsersData(!isSeller);
  };
  popularUserSliderNextBtn = () => {
    this.slider.slickNext();
  };
  popularUserSliderPrevBtn = () => {
    this.slider.slickPrev();
  };

  displayPopularUserData = () => {
    const popularUserData = this.state.popularUser.map((item, index) => {
      return (
        <div className={classes.popularSellerSliderContainer} key={index}>
          <PopularUserCard
            number={item.number}
            img={item.img}
            rank={item.rank}
            name={item.name}
            price={item.price}
            index={index}
          />
        </div>
      );
    });

    return popularUserData;
  };
  updateWindowDimensions = () => {
    this.setState({ dimension: window.innerWidth });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    let popularUserSliderConfig = {
      infinite: true,
      speed: 500,
      slidesToShow:
        this.state.popularUser.length > 5 && this.state.dimension < 600
          ? 1
          : this.state.popularUser.length > 5 && this.state.dimension < 800
          ? 2
          : this.state.popularUser.length > 5 && this.state.dimension < 1030
          ? 3
          : this.state.popularUser.length > 5 && this.state.dimension < 1200
          ? 4
          : this.state.popularUser.length > 5 && this.state.dimension > 1200
          ? 5
          : this.state.popularUser.length,
      slidesToScroll: 1,
      arrows: false,
    };

    return (
      <div className={classes.popularSellerMainContainer}>
        <div className={classes.popularSellerInnerContainer}>
          <div className={classes.popularSellerTitleAndDropdownContainer}>
            <div className={classes.popularSellerTitleContainer}>
              <p>Popular</p>
              <div>
                <h1>{this.state.popularType}</h1>
                <i
                  className="fas fa-angle-down"
                  onClick={() =>
                    this.setState({
                      showPopularType: !this.state.showPopularType,
                    })
                  }
                ></i>
                {this.state.showPopularType && (
                  <ul>
                    <li onClick={() => this.popularTypeHandler()}>
                      {this.state.popularTypeList}
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className={classes.popularSellarDropdownContainer}>
              <FilterDropdown
                options={["Last month"]}
                setSelected={this.dropdownDateFitlerHandler}
                selected={this.state.dropDate}
              />
            </div>
          </div>
          {this.state.popularUser.length > 0 && (
            <>
              <div className={classes.popularUserSliderMainContainer}>
                <Slider
                  {...popularUserSliderConfig}
                  ref={(c) => (this.slider = c)}
                >
                  {this.displayPopularUserData()}
                </Slider>
              </div>
              <button
                onClick={this.popularUserSliderPrevBtn}
                className={classes.popularUserPrevBtn}
                aria-label="arrow"
              >
                <i className="fas fa-long-arrow-alt-left"></i>
              </button>
              <button
                onClick={this.popularUserSliderNextBtn}
                className={classes.popularUserNextBtn}
                aria-label="arrow"
              >
                <i className="fas fa-long-arrow-alt-right"></i>
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}
