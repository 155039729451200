import React from "react";
import HomePageTopBanner from "../../components/HomePage/HomePageTopBanner/HomePageTopBanner";
import HomePagePopularUser from "../../components/HomePage/HomePagePopularUser/HomePagePopularUser";
//import HomePageHotBid from "../../components/HomePage/HomePageHotBid/HomePageHotBid";
import HomePageHotCollection from "../../components/HomePage/HomePageHotCollection/HomePageHotCollection";
//import HomePageDiscover from "../../components/HomePage/HomePageDiscover/HomePageDiscover";
import HomePageNewListing from "../../components/HomePage/HomePageNewListing/HomePageNewListing";
import HomePageRecentlySold from "../../components/HomePage/HomePageRecentlySold/HomePageRecentlySold";
//import HomePageTradeAssets from "../../components/HomePage/HomePageTradeAssets/HomePageTradeAssets";
//import HomePageStatictics from "../../components/HomePage/HomePageStatictics/HomePageStatictics";

export const Home = React.memo((props) => {
  return (
    <>
      <HomePageTopBanner />
      {/* <HomePageLatestUpdates /> */}
      <HomePageNewListing ual={props.ual} />
      <HomePageRecentlySold />
      {/* <HomePageTradeAssets /> */}
      {/* <HomePageStatictics /> */}
      {/* <HomePageHotBid /> */}
      <HomePageHotCollection />
      <HomePagePopularUser />
      {/* <HomePageDiscover /> */}
    </>
  );
});
