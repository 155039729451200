import React, { useEffect, useState } from "react";
import classes from "./TopAccountCard.module.css";
import { useHistory } from "react-router-dom";
import Seller from "../../../../SaleDetails/Seller";
import LoadingBackground from "../../../../UI/LoadingBackground";
import AppServices from "../../../../../services/AppService";

const appServices = new AppServices();

const TopAccountCard = ({ topAccount, index, symbol, isTopSeller }) => {
  const [accountName, setAccountName] = useState();
  const [amount, setAmount] = useState(null);
  const [usdAmount, setUsdAmount] = useState(null);

  const history = useHistory();

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }

  useEffect(() => {
    if(topAccount && topAccount.account) {
      setAccountName(topAccount.account);
      const accAmount = isTopSeller ? topAccount.sell_volume : topAccount.buy_volume;
      const price = (parseInt(accAmount) / Math.pow(10, symbol.token_precision)).toFixed(0);
      const priceUsd = (price * (appServices.getUsdPrice() ?? 1)).toFixed(0);
      setAmount(price);
      setUsdAmount(priceUsd);
    }
  }, [topAccount]);

  return (
    <>
      {accountName && amount && usdAmount ?
          <div
            className={classes.cardWrapper}
            key={index + accountName}
          >
            <div className={classes.accNameWrapper}>
              <p className={classes.index}>{`${index}.`}</p>
              <p
                className={classes.accountName}
                onClick={() => moveToUserProfile(accountName)}
              >
                {accountName}
              </p>
            </div>
            <div className={classes.amountsWrapper}>
              <p className={classes.tokenAmount}>{`${amount} ${symbol.token_symbol}`}</p>
              <p className={classes.usdAmount}>{`${usdAmount} $`}</p>
            </div>
          </div>
        :
          <LoadingBackground height="50xp" borderRadius="16px" />
      }
    </>
  );
}

export default TopAccountCard;
