import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  search: '',
}

const searchReducerSlice = createSlice({
  name: 'searchReducer',
  initialState,
  reducers: {
    SET_SEARCH: (state, action) => {
      state.search = action.payload
    },
  },
})

export const {
  SET_SEARCH,
} = searchReducerSlice.actions

export default searchReducerSlice.reducer
