import React from "react";
import classes from "./AssetImage.module.css";
import useImageLoaded from "../../../hooks/useImageLoaded";
import AppServices from "../../../services/AppService";

const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";

const appService = new AppServices();

const AssetImage = ({ content, onClick, isBundle }) => {

  const [ref, loaded, onLoad] = useImageLoaded();

  const getImgUrl = () => appService.getIpfsUrl(content);

  const reloadImg = (e) => {
    if (e.target.src.includes(RESIZER_URL)) e.target.src = getImgUrl(content);
  };

  return (
    <div
      className={`
        ${classes.PreviewImageWrapper} 
        ${isBundle && classes.bundlePreviewImage} 
        ${loaded ? null : isBundle ? classes.loadingBundle : classes.loading}
      `}
    >
      <img
        ref={ref}
        src={
          content?.includes("http")
            ? getImgUrl(content)
            : `${RESIZER_URL}${content}&size=200`
        }
        onLoad={onLoad}
        alt="assetsImg"
        loading="lazy"
        onError={(e) => reloadImg(e)}
        onClick={onClick}
      />
    </div>
  );
};

export default AssetImage;
