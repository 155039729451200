import React, { useEffect, useState } from "react";
import UserAssetsCard from "../../UserAssetsCard/UserAssetsCard";
import ApiServices from "../../../services/ApiServices";
import classes from "./HomePageRecentlySold.module.css";

export default function HomePageRecentlySold({ ual }) {
  const [saleList, setSaleList] = useState([]);

  const [listingType] = useState("sale");

  useEffect(() => {
    const apiService = new ApiServices();
    const getSalesListing = async () => {
      const response = await apiService.getSales(
        4,
        1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        3
      );
      let tempSaleList = [];
      if (response) {
        tempSaleList = response.map((element) => {
          let tempRecord = {};

          tempRecord["tokenAmount"] = element.price.amount;
          tempRecord["tokenSymbol"] = element.price.token_symbol;
          tempRecord["tokenPrecision"] = element.price.token_precision;
          tempRecord["title"] = element.assets[0].data.name;
          tempRecord["detailsPath"] = "/assets/" + element.assets[0].asset_id;
          tempRecord["purchasePath"] = "/sale/" + element.sale_id;
          tempRecord["seller"] = element.seller;

          let contentType = "";
          let content = "";

          if (element.assets[0].data.img) {
            contentType = "img";
            content = element.assets[0].data.img;
          } else if (element.assets[0].data.video) {
            contentType = "video";
            content = element.assets[0].data.video;
          } else if (element.assets[0].data.audio) {
            contentType = "audio";
            content = element.assets[0].data.audio;
          }

          tempRecord["type"] = contentType;
          tempRecord["content"] = content;
          tempRecord["aasetDetils"] = element;

          let inStock = 0;

          if (element.template)
            inStock =
              +element.template.max_supply - +element.template.issued_supply;

          if (inStock > 0) tempRecord["stock"] = inStock;

          return tempRecord;
        });
      }
      setSaleList(tempSaleList);
    };
    getSalesListing();
  }, []);

  const displayData = () => {
    let data = null;

    if (listingType === "sale" && saleList.length > 0) {
      data = saleList.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              tokenAmount={item.tokenAmount}
              tokenSymbol={item.tokenSymbol}
              tokenPrecision={item.tokenPrecision}
              stock={item.stock}
              detailsPath={item.detailsPath}
              purchasePath={item.purchasePath}
              ual={ual}
              seller={item.seller}
              aasetDetils={item.aasetDetils}
              sold={true}
            />
          </React.Fragment>
        );
      });
    }
    return data;
  };

  return (
    <>
      <div className={classes.newListingMainContainer}>
        <h1>
          <div className={classes.recently_Sold}>
            <p>Recently Sold</p>
            <div className={classes.pulse}></div>
          </div>
        </h1>

        <div className={classes.newListingContentContainer}>
          {displayData()}
        </div>
      </div>
    </>
  );
}
