import React, { useEffect, useState } from "react";
import classes from "./MoreInfoTab.module.css";
import MoreInfoDetailsSection from "./MoreInfoDetailsSection";
import { useHistory } from "react-router-dom";
import TechnicalInfoSection from "./TechnicalInfoSection";

const DETAILS_KEYS = ["Display Name", "Collection Name", "Author", "Market fee", "Allow Notify", "URL"];
const INFO_KEYS = ["Address", "Company", "Name", "Registration number"];
const LIMIT = 40;

const MoreInfoTab = ({ collectionData, collectionStats }) => {
  const [details, setDetails] = useState(null);
  const [information, setInformation] = useState(null);

  const history = useHistory();

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }

  const createUrl = (url, limit) => {
    if(url) {
      const fullUrl = url.split("/").indexOf("https:/") !== -1
        ? url : `https://${url}`;
      const displayedUrl = url.length > limit ? `${url.substring(0, limit)}...` : url;
      return <a href={fullUrl} target="_blank" rel="noreferrer">{displayedUrl}</a>
    }
    return "None";
  }


  const authorLink = (author) => 
    <span
      className={classes.authorLink}
      onClick={() => moveToUserProfile(author)}
    >
      {author}
    </span>

  const formDetails = (collectionData, keys) => {
    const res = keys.reduce((details, key) => {
      const value =
        key === "Display Name"
          ? collectionData.name
          : key === "Collection Name"
          ? collectionData.collection_name
          : key === "Author"
          ? authorLink(collectionData.author)
          : key === "Market fee"
          ? `${(collectionData.market_fee * 100).toFixed(2)}%`
          : key === "Allow Notify"
          ? (collectionData.allow_notify
            ? "Notification Allowed"
            : "Notification Disabled")
          : key === "URL"
          ? createUrl(collectionData.data.url, LIMIT)
          : null;
      return [...details, { key, value }];
    }, []);
    setDetails(res)
  }

  const formInformation = (collectionData, keys) => {
    const res = keys.reduce((details, key) => {
      const value =
        key === "Address"
          ? "None"
          : key === "Company"
          ? "None"
          : key === "Name"
          ? "None"
          : key === "Registration number"
          ? "None"
          : null;
      return [...details, { key, value }];
    }, []);
    setInformation(res)
  }

  useEffect(() => {
    if(collectionData) {
      formDetails(collectionData, DETAILS_KEYS);
      formInformation(collectionData, INFO_KEYS);
    }
  }, [collectionData]);

  return (
    <>
      <MoreInfoDetailsSection data={details} title="Collection Details" key={1}/>
      <MoreInfoDetailsSection data={information} title="Creator Information" key={2}/>
      <TechnicalInfoSection collectionData={collectionData}/>
    </>
  );
}

export default MoreInfoTab;
