import React from "react";
import classes from "./Model.module.css";
import SocialLink from "../UI/SocialButton/SocialLink";
import CloseIcon from "../../assets/img/closeIcon.svg";

export default function Model2(props) {
  return (
    <div
      className={classes.topModel2Container}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.modelYayy}>
        <h2>Yay! 🎉</h2>
      </div>
      <div className={classes.modeldescription}>
        <p>
          You are about to purchase <br /> <u>{props.purchaseItem}</u> from{" "}
          {props.from}
        </p>
      </div>
      <div className={classes.modelFlexTranscation}>
        <div>
          <p>Status</p>
          <p>{props.status}</p>
        </div>
        <div>
          <p>Transaction ID</p>
          <p style={{ color: "#23262F" }}>{props.transactionID}</p>
        </div>
      </div>
      <div className={classes.modelTimeShow}>Time to show-off</div>

      <div className={classes.modelSocialMediaIcon}>
        <div>
          <SocialLink network='facebook' />
        </div>
        <div>
          <SocialLink network='twitter' />
        </div>
        <div>
          <SocialLink network='instagram' />
        </div>
        <div>
          <SocialLink network='pinterest' />
        </div>
      </div>
    </div>
  );
}
