import React from "react";
import classes from "./FilterTabBar.module.css";

export default function FilterTabBar(props) {

  const handleTabClick = (tab, setTab, setPage) => {
    setTab(tab);
    if(setPage) {
      setPage(1);
    }
  }

  const isMarketPage = (tabs) =>
    tabs && tabs[0] && tabs[0] === 'Sells';

  return (
    <div className={`${classes.filterTabBarMainContainer} ${isMarketPage(props.tabs) && classes.marketFilterTabBarMainContainer}`}>
      {
        props.isExplorePage && props.dropSelected !== 'Assets'
          ? null
          : 
            <>
              <ul>
                {props.tabs.map((tab) => {
                  return (
                    <li key={tab}>
                      <span
                        style={
                          tab === props.selected
                            ? { color: "#FCFCFD", background: "#353945" }
                            : null
                        }
                        onClick={() => handleTabClick(tab, props.setSelected, props.setPage)}
                      >
                        {tab}
                      </span>
                    </li>
                  );
                })}
              </ul>
              {
                props.tabs2 &&
                  <ul>
                    {props.tabs2.map((tab) => {
                      return (
                        <li key={tab}>
                          <span
                            style={
                              tab === props.selectedTab2
                                ? { color: "#FCFCFD", background: "#353945" }
                                : null
                            }
                            onClick={() => handleTabClick(tab, props.setTab2, props.setPage)}
                          >
                            {tab}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                }
            </>
      }
      {props.children}
    </div>
  );
}
