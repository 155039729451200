import React, { Component } from "react";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import UserAssetsCard from "../../UserAssetsCard/UserAssetsCard";
import classes from "./ProfilePageUserAssets.module.css";
import ApiServices from "../../../services/ApiServices";
import BlockchainService from "../../../services/BlockchainService";
import AppServices from "../../../services/AppService";
import queryString from "query-string";
import FilterAssetSearch from "../../FilterAssetSearch/FilterAssetSearch";
import LoaderIcon from "../../../assets/img/loader.png";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";

export default class ProfilePageUserAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAssets: [],
      isFilterCreated: false,
      selected: "Inventory",
      tabs: ["Inventory", "My Listings", "My Auctions", "Sold", "Bought"],
      tabs2: ["Inventory", "Listings", "Auctions", "Sold", "Bought"],
      tabsHeading: {
        inventory: "Inventory",
        myListings: this.props.isOwner ? "My Listings" : "Listings",
        myAuctions: this.props.isOwner ? "My Auctions" : "Auctions",
        sold: "Sold",
        bought: "Bought",
      },
      page: 1,
      userAccount: this.props.account,
      filterAssetSearch: "",
      loading: true,
      userCollections: [],
      currentCollection: "All collections",
      userSchemas: [],
      currentSchema: "All Schemas",
    };

    this.tokenPrecision = process.env.REACT_APP_TOKEN_PRECISION;
    this.tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;

    this.apiService = new ApiServices();
    this.appService = new AppServices();
    this.blockchainService = new BlockchainService();
  }

  async componentDidMount() {
    await this.getFilterFromQuery();
    await this.getUserAssets();
    await this.getUserCollections();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCollection !== this.state.currentCollection) {
      this.setState({ currentSchema: "All Schemas" });
    }
  }

  setSelected = async (tab) => {
    this.setState({ selected: tab });
    await this.getUserAssets(tab);
  };

  getUserAccount = async () => {
    let path = window.location.pathname;
    let splitArray = path.split("/");
    let account = splitArray[2];
    return account;
  };

  getFilterFromQuery = () => {
    let query = queryString.parse(window.location.search);
    if (query && query.filter) {
      let filter = query.filter;
      if (filter === "Created") this.setState({ isFilterCreated: true });
      if (this.state.tabs.indexOf(filter) !== -1) this.setSelected(filter);
    }
  };

  getUserCollections = async () => {
    if (this.props.userName) {
      let collections = await this.apiService.getAuthorCollection(
        this.props.userName
      );
      this.setState({
        userCollections: [
          "All collections",
          ...collections.map((el) => el.collection_name),
        ],
      });
    }
  };

  getUserAssets = async (tab) => {
    this.setState({ loading: true });
    let account = await this.getUserAccount();
    if (account) {
      if (tab === undefined) {
        tab = this.state.selected;
      }
      if (tab === this.state.tabsHeading.inventory) {
        await this.getUserInventory(account);
      } else if (tab.toLowerCase().includes("listing")) {
        await this.getUserActiveListing(account);
      } else if (tab.toLowerCase().includes("auction")) {
        await this.getUserAuction(account);
      } else if (tab === this.state.tabsHeading.sold) {
        await this.getUserSold(account);
      } else if (tab === this.state.tabsHeading.bought) {
        await this.getUserBoughts(account);
      } else if (tab === this.state.tabsHeading.collections) {
        await this.getUserCreatedAssets(account);
      } else {
        this.setState({ userAssets: [] });
      }
    }
    this.setState({ loading: false });
  };

  getUserInventory = async (account) => {
    let assets = await this.apiService.getAtomicAssets(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    let userAssets = [];
    if (assets) {
      userAssets = assets.map((element, index) => {
        let tempAsset = {};
        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/assets/" + element.asset_id;
        tempAsset["isAuthor"] =
          element.collection && element.collection.author === account;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };

  getUserActiveListing = async (account) => {
    let assets = await this.apiService.getUserActiveListings(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    var userAssets = [];
    if (assets) {
      userAssets = assets.map((item, index) => {
        let tempAsset = {};
        let element = item.assets[0];
        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/sale/" + item.sale_id;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };

  getUserAuction = async (account) => {
    let assets = await this.apiService.getUserActiveAtomicAuctions(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    var userAssets = [];
    if (assets) {
      userAssets = assets.map((item, index) => {
        let tempAsset = {};
        let element = item.assets[0];
        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/auction/" + item.auction_id;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };

  getUserSold = async (account) => {
    let assets = await this.apiService.getUserSold(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    var userAssets = [];
    if (assets) {
      userAssets = assets.map((item, index) => {
        let tempAsset = {};
        let element = item.assets[0];
        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/assets/" + element.asset_id;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };

  getUserBoughts = async (account) => {
    let assets = await this.apiService.getUserBoughts(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    var userAssets = [];
    if (assets) {
      userAssets = assets.map((item, index) => {
        let tempAsset = {};
        let element = item.assets[0];
        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/assets/" + element.asset_id;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };
  handleFilterSearch = (value) => {
    this.setState({ filterAssetSearch: value }, () => {
      this.getUserAssets();
    });
  };

  getUserCreatedAssets = async (account) => {
    let assets = await this.apiService.getCreatedAssetByUser(
      account,
      this.state.page,
      this.state.filterAssetSearch
    );

    var userAssets = [];
    if (assets) {
      userAssets = assets.map((element, index) => {
        let tempAsset = {};

        tempAsset["title"] = element.data.name;
        tempAsset["detailsPath"] = "/assets/" + element.asset_id;

        let contentType = "";
        let content = "";

        if (element.data.img) {
          contentType = "img";
          content = element.data.img;
        } else if (element.data.video) {
          contentType = "video";
          content = element.data.video;
        } else if (element.data.audio) {
          contentType = "audio";
          content = element.data.audio;
        }

        tempAsset["type"] = contentType;
        tempAsset["content"] = content;
        tempAsset["assetDetails"] = { assets: [element] };

        let inStock = 0;

        if (element.template)
          inStock =
            +element.template.max_supply - +element.template.issued_supply;

        if (inStock > 0) tempAsset["stock"] = inStock;

        return tempAsset;
      });
    }

    this.setState({ userAssets });
  };

  displayUserAssets = () => {
    const userAssets = this.state.userAssets.map((item, index) => {
      if (this.state.selected !== "Inventory" || !this.state.isFilterCreated) {
        return (
          <div className={classes.onSaleCardContainer} key={index}>
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              stock={item.stock}
              detailsPath={item.detailsPath}
              aasetDetils={item.assetDetails}
            />
          </div>
        );
      } else if (this.state.isFilterCreated && item.isAuthor) {
        return (
          <div className={classes.onSaleCardContainer} key={index}>
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              stock={item.stock}
              detailsPath={item.detailsPath}
              aasetDetils={item.assetDetails}
            />
          </div>
        );
      }
      return null;
    });

    return userAssets;
  };

  getCollectionAssets = async (
    collectionName,
    currenSchema = "All Schemas"
  ) => {
    this.setState({ loading: true });
    let collection = await this.apiService.getCollectionAssets(collectionName);
    let schemas = [];

    let collectionAssets = collection.map((el) => {
      let contentType;
      let content;
      if (el.data.img) {
        contentType = "img";
        content = el.data.img;
      } else if (el.data.video) {
        contentType = "video";
        content = el.data.video;
      } else if (el.data.audio) {
        contentType = "audio";
        content = el.data.audio;
      }
      schemas.push(el.schema.schema_name);
      if (currenSchema === "All Schemas") {
        return {
          content: content,
          detailsPath: `/assets/${el.asset_id}`,
          isAuthor: el.owner === this.props.userName,
          title: el.data.name,
          type: contentType,
        };
      } else if (currenSchema === el.schema.schema_name) {
        return {
          content: content,
          detailsPath: `/assets/${el.asset_id}`,
          isAuthor: el.owner === this.props.userName,
          title: el.data.name,
          type: contentType,
        };
      }
    });
    this.setState({
      userAssets: collectionAssets.filter((el) => el !== undefined),
    });

    let uniqSchemas = new Set(schemas);
    this.setState({ userSchemas: ["All Schemas", ...uniqSchemas] });
    this.setState({ loading: false });
  };

  onChangeFilterHandler = async (value) => {
    this.setState({ currentCollection: value });
    if (value && value !== "All collections") {
      await this.getCollectionAssets(value);
    } else {
      await this.getUserInventory(this.props.userName);
    }
  };

  onChangeSchemaHandler = async (value) => {
    this.setState({ currentSchema: value });
    await this.getCollectionAssets(this.state.currentCollection, value);
  };

  render() {
    return (
      <div className={classes.profileDescAssetsContainer}>
        <FilterTabBar
          tabs={this.props.isOwner ? this.state.tabs : this.state.tabs2}
          selected={this.state.selected}
          setSelected={this.setSelected}
        >
          <div className={classes.filterAssetSearchContainer}>
            <div className={classes.flexWrapper}>
              <FilterAssetSearch
                placeholder="Search assets"
                value={this.state.filterAssetSearch}
                onChange={(e) => this.handleFilterSearch(e.target.value)}
              />
              <div className={classes.dropdownWrapper}>
                {this.state.selected === "Inventory" && (
                  <FilterDropdown
                    options={this.state.userCollections}
                    setSelected={this.onChangeFilterHandler}
                    selected={this.state.currentCollection}
                  />
                )}
              </div>
              <div className={classes.dropdownWrapper}>
                {this.state.userSchemas.length > 1 && (
                  <FilterDropdown
                    options={this.state.userSchemas}
                    setSelected={this.onChangeSchemaHandler}
                    selected={this.state.currentSchema}
                  />
                )}
              </div>
            </div>
          </div>
          <TabContent isSelected={this.state.selected}>
            <div className={classes.onSaleMainContainer}>
              {this.displayUserAssets()}
            </div>
            {this.state.loading && (
              <div className={classes.loadMoreBtnContainer}>
                <button>
                  <img
                    src={LoaderIcon}
                    alt="Loading..."
                    className={classes.loaderAnimationIcon}
                  />
                  <span>Loading...</span>
                </button>
              </div>
            )}
            {this.state.loading === false &&
              this.state.userAssets.length === 0 && (
                <div className={classes.noDataContainer}>No Data</div>
              )}
          </TabContent>
        </FilterTabBar>
      </div>
    );
  }
}
