import React from 'react';
import Loader from '../../../UI/Loader';
import Pagination from '../../../UI/Pagination';
import Cards from './Cards';

const CardsSection = ({...props}) => {
  return (
    <>
      <Cards {...props}/>
      <Loader loading={props.loading}/>
      <Pagination {...props}/>
    </>
  );
};

export default CardsSection;