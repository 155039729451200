import React from "react";
import AppServices from "../../../services/AppService";
import Timer from "../../Timer/Timer";
import ActionButton from "../../UI/ActionButton/ActionButton";
import PriceBox from "../../UI/PriceBox/PriceBox";
import classes from "./HomePageTopBanner.module.css";
import AudioSpectrum from "react-audio-spectrum";

const appServices = new AppServices();
export default function HomePageTopBanner({
  lists,
  selected,
  setSelected,
  getListingPrice,
  navigateToPath,
  UsdPrice,
}) {
  const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";

  const reloadImg = (e, content) => {
    if (e.target.src.includes(RESIZER_URL))
      e.target.src = appServices.getIpfsUrl(content);
  };
  const AssetsCard = ({ firstCard, secondcCard, item, index }) => {
    return <div
      onClick={() => {
        index !== undefined && setSelected(index);
      }}
      className={
        firstCard && lists?.length > 1
          ? classes.Cardbody
          : firstCard && lists?.length === 1
          ? classes.Cardbody1
          : classes.Cardbody2
      }
    >
      <div
        className={
          firstCard && lists?.length > 1
            ? classes.imageDiv
            : firstCard && lists?.length === 1
            ? classes.imageDiv1
            : classes.imageDiv2
        }
      >
        {item.type === "img" ? (
          <img
            src={`${RESIZER_URL}${
              item.content
            }&size=370`}
            alt="Assets"
            loading="lazy"
            onError={(e) => reloadImg(e, item.content)}
          />
        ) : item.type === "video" ? (
          <video
            src={appServices.getIpfsVidepUrl(item.content)}
            controls="controls"
            autoPlay={true}
            loop={true}
          />
        ) : (
          <div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div className={classes.headPhoneIcon}>
                <i
                  className="fas fa-headphones"
                  style={
                    firstCard
                      ? { marginTop: "100px", marginLeft: "-46px" }
                      : { marginTop: "50px", marginLeft: "-50px" }
                  }
                ></i>
              </div>
              {firstCard && (
                <AudioSpectrum
                  id={`audio-canvas`}
                  height={295}
                  width={269}
                  audioId={`audio-element`}
                  capColor={"#23262f "}
                  capHeight={2}
                  meterWidth={2}
                  meterCount={1000}
                  meterColor={[
                    { stop: 0, color: "#df7549" },
                    { stop: 0.5, color: "#d6c89a" },
                    { stop: 1, color: "#dc3825" },
                  ]}
                  gap={4}
                />
              )}
            </div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                marginTop: -15,
              }}
            >
              {firstCard && (
                <audio
                  id={`audio-element`}
                  src={appServices.getIpfsVidepUrl(item.content)}
                  // controls
                  controlsList="nodownload"
                  autoPlay={true}
                  loop={true}
                  muted={false}
                  crossOrigin="anonymous"
                ></audio>
              )}
            </div>
          </div>
        )}
      </div>
      {firstCard && lists?.length > 1 && (
        <div className={classes.cardFooter}>
          <p className={classes.cardText}>{item.title} </p>
          <div className={classes.cardContext}>
            <div className={classes.footerImage}>
              {item.type === "img" ? (
                <img
                  src={`${RESIZER_URL}${item.content}&size=370`}
                  alt="Assets"
                  loading="lazy"
                  onError={(e) => reloadImg(e, item.content)}
                />
              ) : item.type === "video" ? (
                <video
                  src={appServices.getIpfsVidepUrl(item.content)}
                  controls="controls"
                  autoPlay={true}
                  loop={true}
                />
              ) : null}
            </div>
            <div className={classes.buyButton}>
              {item.purchasePath && item.state !== 3 && (
                <button onClick={() => navigateToPath(item.purchasePath)}>
                  {item.actionType === "sale" ? "Buy" : "Bid"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  }

  const NftContent = (index) => {
    let item = lists[index];
    console.log(item);
    return (
      <div className={classes.titleContainer}>
        <div className={classes.bannerDetailsContainer}>
          <h1>{item.title?.slice(0, 20)}</h1>
          {item.desc && <p>{item.desc}</p>}
          <div className={classes.priceAndStockContainer}>
            {item.tokenAmount && (
              <PriceBox
                price={getListingPrice(item.tokenAmount, item.tokenPrecision)}
                symbol={item.tokenSymbol}
              />
            )}
            {item.tokenAmount && (
              <PriceBox
                price={(
                  getListingPrice(item.tokenAmount, item.tokenPrecision) *
                  UsdPrice
                ).toFixed(2)}
                symbol="$"
              />
            )}
            {item.stock && <span>{item.stock} in stock</span>}
          </div>

          <div className={classes.actionBtnContainer}>
            <ActionButton
              title="details"
              onClick={() => navigateToPath(item.detailsPath)}
              color="black"
            />
            {item.purchasePath && item.state !== 3 && (
              <ActionButton
                title={item.actionType === "sale" ? "Buy" : "Bid"}
                onClick={() => navigateToPath(item.purchasePath)}
              />
            )}
          </div>
          <div>
            {item.startAt !== null && <Timer remainingTime={item.startAt} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.CardContainer}>
          <div
            className={
              lists?.length > 1
                ? `${classes.detailsCard}`
                : `${classes.detailsCard2}`
            }
          >
            <AssetsCard firstCard={true} item={lists[selected]} />
          </div>
          {lists?.length > 1 && (
            <div className={`${classes.scrollCard} ${classes.itemCenter}`}>
              {lists.map((item, index) => (
                <AssetsCard
                  key={index}
                  index={index}
                  firstCard={false}
                  item={item}
                />
              ))}
            </div>
          )}
        </div>
        {/* <div className={classes.titleContainer}> */}
        {NftContent(selected)}
        {/* </div> */}
      </div>
    </>
  );
}
