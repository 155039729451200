import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./CollectionMainInfoCard.module.css";
import CollectionCard from "../../CollectionCard";
import { CARD_INFO_KEY } from "../../../constants/CollectionDetails";
import Tooltip from "../../UI/Tooltip";
import ApiServices from "../../../services/ApiServices";

const LIMIT = 18;

const apiService = new ApiServices();

const CollectionMainInfoCard = ({ collectionData, collectionsStats, history }) => {
  const [infoList, setInfoList] = useState([]);
  const [content, setContent] = useState(null);

  const createdDate = (createdAtTime) => {
    const date = new Date(parseInt(createdAtTime));
    return date.toDateString();
  }

  const createDisplayedName = (name) => 
    name && name.length > LIMIT
      ? <Tooltip id="coll-name" tip={name} string={name} limit={LIMIT}/>
      : name


  const createUrl = (url) => {
    if(url) {
      const fullUrl = url.split("/").indexOf("https:/") !== -1
        ? url : `https://${url}`;
      const displayedUrl = url.length > LIMIT ? `${url.substring(0, LIMIT)}...` : url;
      return <a href={fullUrl} target="_blank" rel="noreferrer">{displayedUrl}</a>
    }
    return "None";
  }

  const moveToUserProfile = (userName) => {
    history.push('/profile/' + userName)
  }

  const authorLink = (author) => 
    <span
      className={classes.authorLink}
      onClick={() => moveToUserProfile(author)}
    >
      {author}
    </span>

  const formMainInfoList = (collectionData, keysList) => {
    if(collectionData) {
      return keysList.reduce((list, key) => {
        const value =
          key === "Display Name"
            ? createDisplayedName(collectionData.name)
            : key === "Author"
            ? authorLink(collectionData.author)
            : key === "Market Fee"
            ? `${(collectionData.market_fee * 100).toFixed(2)}%`
            : key === "Created"
            ? createdDate(collectionData.created_at_time)
            : key === "URL"
            ? createUrl(collectionData.data.url)
            : null;
        return [...list, { key, value }];
      },[])
    }
  }

  const renderMainInfoList = (list) =>
    list && list.length && list.map((item) =>
      <div className={classes.mainInfoLine} key={item.key}>
        <div className={classes.mainInfoKey}>{item.key}</div>
        <div className={classes.mainInfoValue}>{item.value}</div>
      </div>
    )

  const defineContent = async (collectionData) => {
    if(collectionData.img) {
      setContent(collectionData.img);
    } else {
      const collectionAssets = await apiService.getTopCollectionAssets(collectionData.collection_name);
      if(collectionAssets && collectionAssets.length > 0) {
        setContent(collectionAssets[0].data.img);
      }
    }
  }

  useEffect(() => {
    if(collectionData) {
      const list = formMainInfoList(collectionData, CARD_INFO_KEY);
      setInfoList(list);
      (async () => await defineContent(collectionData))();
    }
  },[collectionData])

  return (
    <div className={classes.cardWrapper}>
      {
        collectionData ?
          <>
            <CollectionCard
              content={content}
              name={collectionData.collection_name}
              isDetailsPage
            />
            <div className={classes.mainInfoList}>
              { infoList &&
                  renderMainInfoList(infoList)
              }
            </div>
          </>
          : null
      }
    </div>
  );
}

export default CollectionMainInfoCard;
