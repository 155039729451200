import React, { Component } from "react";
import SearchNotFoundPageSearch from "../../components/SearchNotFoundPage/SearchNotFoundPageSearch/SearchNotFoundPageSearch";
import SearchNotFoundPageExploreMore from "../../components/SearchNotFoundPage/SearchNotFoundPageExploreMore/SearchNotFoundPageExploreMore";
import classes from "./SearchNotFound.module.css";

export default class SearchNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={classes.pageWrapper}>
        <SearchNotFoundPageSearch />
        <SearchNotFoundPageExploreMore />
      </div>
    );
  }
}
