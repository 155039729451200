import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saleDetails: null,
};

const saleReducer = createSlice({
  name: "saleReducer",
  initialState,
  reducers: {
    SET_SALE_DETAILS: (state, action) => {
      state.saleDetails = action.payload;
    },
    REMOVE_SALE_DETAILS: (state, action) => {
      state.saleDetails = null;
    },
  },
});

export const { SET_SALE_DETAILS, REMOVE_SALE_DETAILS } = saleReducer.actions;

export default saleReducer.reducer;
