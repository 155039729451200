import React from "react";
import classes from "../HomePageHotCollection.module.css";
import UserAssetsCard from "../../../UserAssetsCard/UserAssetsCard";


export const DisplayCollection = React.memo(({ hotCollection, loading }) => {


  return (
    <>
      {loading
        ? [...Array(4)].map((el, index) => <div className={classes.loading} key={index} />)
        :
        hotCollection.map((item, index) => {
          return (
            <div className={classes.hotCollectionContainer} key={index}>
              <UserAssetsCard
                assetsContent={[{
                  content: item.hotCollectionImges[0]?.original,
                  type: 'img'
                }]}
                title={item.title}
                collection={true}
                detailsPath={item.path}
              />
            </div>
          );
        })
      }
    </>
  )
});