import React, { useEffect, useState } from "react";
import classes from "./Cards.module.css";
import UserAssetsCard from "../../../../UserAssetsCard/UserAssetsCard";
import TemplateCard from "../../../../TemplateCard";
import CollectionCard from "../../../../CollectionCard";
import SchemaCard from "../../../../SchemaCard";
import AccountCard from "../../../../AccountCard";
import ApiServices from "../../../../../services/ApiServices";

const apiService = new ApiServices();

const Cards = ({
  discoverAssets,
  dropSelected,
  templates,
  collections,
  schemas,
  accounts,
  loading,
  match,
  isTemplateDetailsPage,
}) => {
  const [listings, setListings] = useState([]);
  const [definedCollectionData, setDefinedCollectionData] = useState([]);
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);

  const formAssetsData = (assets) => {
    let discoverAssets = assets.map((element) => {
      let tempAsset = {};

      tempAsset["title"] = element.data.name;
      tempAsset["detailsPath"] = "/assets/" + element.asset_id;

      let contentType = "";
      let content = "";

      if (element.data.img) {
        contentType = "img";
        content = element.data.img;
      } else if (element.data.video) {
        contentType = "video";
        content = element.data.video;
      } else if (element.data.audio) {
        contentType = "audio";
        content = element.data.audio;
      }

      tempAsset["type"] = contentType;
      tempAsset["content"] = content;
      tempAsset["aasetDetils"] = { assets: [element] };

      let inStock = 0;

      if (element.template)
        inStock =
          +element.template.max_supply - +element.template.issued_supply;

      if (inStock > 0) tempAsset["stock"] = inStock;

      return tempAsset;
    });
    return discoverAssets;
  };

  const defineContent = async (collections) => {
    let definedCollections = [];
    for (const collection of collections) {
      if (collection.img) {
        definedCollections = [
          ...definedCollections,
          { img: collection.img, name: collection.collection_name },
        ];
      } else {
        const collectionAssets = await apiService.getTopCollectionAssets(
          collection.collection_name
        );
        if (collectionAssets && collectionAssets.length > 0) {
          definedCollections = [
            ...definedCollections,
            {
              img: collectionAssets[0].data.img,
              name: collection.collection_name,
            },
          ];
        } else {
          definedCollections = [
            ...definedCollections,
            { img: null, name: collection.collection_name },
          ];
        }
      }
    }
    setDefinedCollectionData(definedCollections);
    setIsCollectionLoading(false);
  };

  const displaySearchData = (type) => {
    if (type === "Templates") {
      if (!templates) {
        return <div className={classes.empty}>No data</div>;
      }
      return templates.map((item, index) => {
        return (
          item && (
            <TemplateCard
              content={item.immutable_data.img}
              collectionName={item.collection.collection_name}
              id={item.template_id}
              name={item.immutable_data.name}
              assets={item.issued_supply}
              index={index}
            />
          )
        );
      });
    } else if (type === "Assets") {
      if (!discoverAssets) {
        return <div className={classes.empty}>No data</div>;
      }
      return formAssetsData(discoverAssets).map((item, index) => {
        return (
          item && (
            <div className={classes.discoverAssetsContainer} key={index}>
              <UserAssetsCard
                assetsContent={[item]}
                title={item.title}
                stock={item.stock}
                detailsPath={item.detailsPath}
                aasetDetils={item.aasetDetils}
              />
            </div>
          )
        );
      });
    } else if (type === "Collections") {
      if (!collections) {
        return <div className={classes.empty}>No data</div>;
      }
      return definedCollectionData.map((item, index) => {
        return (
          item && (
            <CollectionCard
              key={index}
              content={item.img}
              name={item.name}
              index={index}
              isLoading={isCollectionLoading}
            />
          )
        );
      });
    } else if (type === "Schemas") {
      if (!schemas) {
        return <div className={classes.empty}>No data</div>;
      }
      return schemas.map((item, index) => {
        return (
          item && (
            <SchemaCard
              key={index}
              name={item.schema_name}
              collection={item.collection.collection_name}
              attributes={item.format.length}
              index={index}
            />
          )
        );
      });
    } else if (type === "Accounts") {
      if (!accounts) {
        return <div className={classes.empty}>No data</div>;
      }
      return accounts.map((item, index) => {
        return (
          item && (
            <AccountCard
              key={index}
              name={isTemplateDetailsPage ? item.account : item.payer}
              index={index}
            />
          )
        );
      });
    }
  };

  const getListings = (dropSelected) => {
    if (dropSelected === "All") {
      setListings([
        "Assets",
        "Templates",
        "Collections",
        "Schemas",
        "Accounts",
      ]);
    } else if (dropSelected === "Assets") {
      setListings(["Assets"]);
    } else if (dropSelected === "Templates") {
      setListings(["Templates"]);
    } else if (dropSelected === "Collections") {
      setListings(["Collections"]);
    } else if (dropSelected === "Schemas") {
      setListings(["Schemas"]);
    } else if (dropSelected === "Accounts") {
      setListings(["Accounts"]);
    }
  };

  useEffect(() => {
    if (collections && collections.length) {
      (async () => await defineContent(collections))();
    }
  }, [collections]);

  useEffect(() => {
    if (dropSelected) {
      setIsCollectionLoading(true);
      getListings(dropSelected);
    }
  }, [dropSelected, templates, collections, schemas, accounts, discoverAssets]);

  return (
    <>
      {listings.length > 0 &&
        !loading &&
        listings.map((listing, index) => (
          <div key={index}>
            {!isTemplateDetailsPage && (
              <div className={classes.title}>{listing}</div>
            )}
            <div
              className={
                isTemplateDetailsPage
                  ? `${classes.allItemsMainContainer} ${classes.templateDetails}`
                  : classes.allItemsMainContainer
              }
            >
              {displaySearchData(listing)}
            </div>
          </div>
        ))}
    </>
  );
};

export default Cards;
