// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PriceHistoryStatsTab_statsSection__2qCDl {\n  width: 100%;\n  margin-top: 30px;\n}\n.PriceHistoryStatsTab_text__c7gGt {\n  font-size: 18px;\n  font-family: \"Poppins\", sans-serif;\n}\n@media only screen and (max-width: 980px) {\n  .PriceHistoryStatsTab_text__c7gGt {\n    font-size: 14px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/SaleDetails/PriceHistorySectionTab/PriceHistoryStatsTab.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kCAAkC;AACpC;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".statsSection {\n  width: 100%;\n  margin-top: 30px;\n}\n.text {\n  font-size: 18px;\n  font-family: \"Poppins\", sans-serif;\n}\n@media only screen and (max-width: 980px) {\n  .text {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsSection": "PriceHistoryStatsTab_statsSection__2qCDl",
	"text": "PriceHistoryStatsTab_text__c7gGt"
};
export default ___CSS_LOADER_EXPORT___;
