import React, { useState } from "react";
import classes from "./SearchBar.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_SEARCH } from "../../../../stores/reducers/searchReducerSlice";


export default function SearchBar(props) {
  const [input, setInput] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    if(input !== '') {
      history.push(`/explorer?search=${input}`);
      dispatch(SET_SEARCH(input));
    }
    setInput('');
    e.preventDefault();
  }

  return (
    <form className={classes.searchBarContainer} onSubmit={(e) => handleSubmit(e)}>
      <input
        type="text"
        placeholder={props.placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button type="submit"><i className="fas fa-search" ></i></button>
    </form>
  );
}
