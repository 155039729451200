import React  from "react";
import classes from "./MoreInfoDetailsSection.module.css";

const MoreInfoDetailsSection = ({ data, title }) => {

  return (
    <div className={classes.detailsWrapper}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.linesWrapper}>
        { data && data.length ?
              data.map((item, index) => 
                <div className={classes.lineWrapper} key={index}>
                  <div className={classes.key}>{`${item.key}:`}</div>
                  <div className={classes.value}>{item.value}</div>
                </div>
              )
            :
              <div>None</div>
        }
      </div>
    </div>
  );
}

export default MoreInfoDetailsSection;
