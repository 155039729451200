import React, { Component } from "react";
import classes from "./EditProfilePageUploadPhoto.module.css";

export default class EditProfilePageUploadPhoto extends Component {
  render() {
    return (
      <div className={classes.uploadPhotoContainer}>
        {this.props.userImg ? (
          <img src={this.props.userImg} alt="User-profile" />
        ) : (
          <i className="fas fa-user-circle"></i>
        )}

        <div className={classes.uploadPhotoDescContainer}>
          <h2>Profile photo</h2>
          <p>We recommend an image of at least 400x400.</p>
          <p>Accepted file formats: PNG, JPEG, GIF</p>
          <p>Accepted file size: max 1 MB</p>
          <div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="upload-photo"
              onChange={this.props.uploadPhotoHandler}
            />

            <label htmlFor="upload-photo" className={classes.uploadBtnLabel}>
              <div>Upload</div>
            </label>
          </div>
          {
            this.props.errorMsg &&
              <div className={classes.userInputErrorMsg}>
                {this.props.errorMsg}
              </div>
          }
        </div>
      </div>
    );
  }
}
