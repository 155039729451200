import React, { useState, useEffect, useRef } from "react";
import classes from "./DropdownMenu.module.css";

const DropdownMenu = ({ items, onItemClick, title }) => {
  const [toggle, setToogle] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const refDrop = useRef();
  let refInput = useRef(null);

  useEffect(() => {
      document.addEventListener("mousedown", handleClickOutsideDrop);
      if (showInput) {
          refInput.current.focus();
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutsideDrop);
      };
  }, [showInput]);

  const handleClickOutsideDrop = (event) => {
      if (refDrop.current && !refDrop.current.contains(event.target)) {
          setShowInput(false);
          setToogle(false);
      }
  };

  return (
    <div className={classes.dropdownContainer} ref={refDrop}>
            <div className={classes.dropdownTitle}>{`${title} (${items.length})`}</div>
            <div
                className={classes.dropdownIconContainer}
                onClick={() => {
                    setToogle(!toggle);
                    setShowInput(false);
                }}
            >
                <i className="fas fa-angle-down"></i>
            </div>
            {toggle && (
                <ul className={classes.dropdownItemsWrapper}>
                    {items.map((item, index) => 
                        <li className={classes.item} onClick={() => onItemClick(item)}>{item}</li>
                    )}
                </ul>
            )}
        </div>
  );
}

export default DropdownMenu;
