// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewDescription_showMoreBtnContainer__dwMh7 {\r\n  width: max-content;\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  font-family: \"DM Sans\", sans-serif;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n  color: #777e91;\r\n  cursor: pointer;\r\n  margin: 5px 0 24px 0;\r\n}\r\n\r\n.PreviewDescription_showMoreBtnContainer__dwMh7 > i {\r\n  margin-left: 5px;\r\n  font-size: 20px;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/SingleCollectiblePage/SingleCollectiblePagePreview/PreviewDescription/PreviewDescription.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kCAAkC;EAClC,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".showMoreBtnContainer {\r\n  width: max-content;\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  font-family: \"DM Sans\", sans-serif;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n  color: #777e91;\r\n  cursor: pointer;\r\n  margin: 5px 0 24px 0;\r\n}\r\n\r\n.showMoreBtnContainer > i {\r\n  margin-left: 5px;\r\n  font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showMoreBtnContainer": "PreviewDescription_showMoreBtnContainer__dwMh7"
};
export default ___CSS_LOADER_EXPORT___;
