import React from "react";
import classes from "./FilterValue.module.css";
import ExchangeArrow from "../../assets/img/exchangeArrow.svg";

export default function FilterValue(props) {
  return (
    <div className={classes.filterValueMainContainer}>
      <h3>{props.title}</h3>
      <div className={classes.valueInputContainer}>
        <span>{props.currency}</span>
        <input
          type="number"
          placeholder="Min"
          min="0"
          value={props.minValue || ""}
          onChange={props.onChangeMinValue}
        />
      </div>
      <img src={ExchangeArrow} alt="arrow" />
      <div className={classes.valueInputContainer}>
        <span>{props.currency}</span>
        <input
          type="number"
          placeholder="Max"
          min="0"
          value={props.maxValue || ""}
          onChange={props.onChangeMaxValue}
        />
      </div>
    </div>
  );
}
