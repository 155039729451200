import React from "react";
import FilterTabBar from "../../FilterTabBar/FilterTabBar";
import TabContent from "../../FilterTabBar/TabContent";
import classes from "./MarketPageContent.module.css";
import UserAssetsCard from "../../UserAssetsCard/UserAssetsCard";
import ModalsGroup from "../../Model/ModalsGroup/ModalsGroup";
import useModals from "../../Model/ModalsHook";
import Loader from "../../UI/Loader";
import Pagination from "../../UI/Pagination";

export default function MarketPageContent(props) {
  const [modalsStates, modalsFuncs] = useModals(props.ual);
  const DisplayAssets = () => {
    const discoverAssets = props.discoverAssets
      .slice(0, props.limit)
      .map((item, index) => {
        return (
          <>
            {item.title ? (
              <div className={classes.discoverAssetsContainer} key={index}>
                <UserAssetsCard
                  assetsContent={item.assetsContent}
                  title={item.title}
                  tokenAmount={item.tokenAmount}
                  tokenSymbol={item.tokenSymbol}
                  tokenPrecision={item.tokenPrecision}
                  stock={item.stock}
                  detailsPath={item.detailsPath}
                  purchasePath={item.purchasePath}
                  auction={props.selectedTab === "Auctions" ?? null}
                  ual={props.ual}
                  seller={item.seller}
                  checkUserAuthentication={modalsFuncs.checkUserAuthentication}
                  setCurrentPurchasePath={modalsStates.setCurrentPurchasePath}
                  aasetDetils={item.aasetDetils}
                />
              </div>
            ) : null}
          </>
        );
      });

    return discoverAssets;
  };

  return (
    <div className={classes.searchItemsContainer}>
      <FilterTabBar
        tabs={props.tabs}
        selected={props.selectedTab}
        setSelected={props.selectTabHandler}
        tabs2={props.tabs2}
        setTab2={props.setTab2}
        selectedTab2={props.selectedTab2}
      >
        <TabContent isSelected={true}>
          {props.loading ? (
            <Loader loading={props.loading} />
          ) : (
            <div className={classes.allItemsMainContainer}>
              <DisplayAssets />
            </div>
          )}
          <Pagination
            loading={props.loading}
            discoverAssets={props.discoverAssets}
            dropSelected={"Sales"}
            page={props.page}
            getNewPageData={props.getNewPageData}
          />
          {props.loading === false && props.discoverAssets.length === 0 && (
            <div className={classes.noDataContainer}>No Data</div>
          )}
        </TabContent>
      </FilterTabBar>
      <ModalsGroup
        ual={props.ual}
        modalsStates={modalsStates}
        modalsFuncs={modalsFuncs}
      />
    </div>
  );
}
