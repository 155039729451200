import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./CollectionFullInfo.module.css";
import CollectionCard from "../../CollectionCard";
import { CARD_INFO_KEY } from "../../../constants/CollectionDetails";
import Button from "../../Button/Button";
import { SET_DROP_COLL, SET_DROP_COLL_Q, SET_PAGE, SET_SHOW_QUERY } from "../../../stores/reducers/marketReducer";
import { useDispatch } from "react-redux";
import PreviewDescription from "../../SingleCollectiblePage/SingleCollectiblePagePreview/PreviewDescription";
import CollectionTabsSections from "../CollectionTabsSection";

const DESCRIPTION_LIMIT = 100;

const CollectionFullInfo = ({ collectionData, collectionStats, history }) => {

  const dispatch = useDispatch();

  const moveToMarket = (collectionName) => {
    const page = 1;

    dispatch(SET_DROP_COLL(collectionName));
    dispatch(SET_PAGE(page));
    dispatch(SET_DROP_COLL_Q(collectionName));
    dispatch(SET_SHOW_QUERY(true));
    history.push('/market');
  } 

  return (
    <>
      {
        collectionData ?
          <>
            <div className={classes.fullInfoWrapper}>
              <div className={classes.titleWrapper}>
                <h1>{collectionData.collection_name}</h1>
                <Button onClick={() => moveToMarket(collectionData.collection_name)}>View on Market</Button>
              </div>
              <h2>About the collection</h2>
              {
                collectionData.data.description
                  ? <PreviewDescription
                      text={collectionData.data.description}
                      limit={DESCRIPTION_LIMIT}
                    />
                  : <p>None</p>
              }
              <CollectionTabsSections 
                collectionData={collectionData}
                collectionStats={collectionStats}
              />
            </div>
          </>
          : null
          
      }
    </>
  );
}

export default CollectionFullInfo;
