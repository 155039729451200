const PHOTO_BYTES_LIMIT = 1048486;

const FILE_TYPES = 'image/png image/jpg image/jpeg image/gif';

const NAME_ERROR = 'Name is required!';

const NAME_MIN_LIMIT = 2;

const NAME_MAX_LIMIT = 50;

const NAME_MAX_LIMIT_ERROR = 'Maximum 50 characters allowed';

const NAME_MIN_LIMIT_ERROR = 'Minimum 2 characters allowed';

const BIO_ERROR = 'BIO is required!';

const BIO_MIN_LIMIT = 30;

const BIO_MIN_LIMIT_ERROR = 'Minimum 30 characters allowed';

const SUCCESS_UPDATE_MESSAGE = 'Profile updated successfully';

const ERROR_UPDATE_MESSAGE = 'An error occurred, please try again';

const ONLY_SPACE = 'Only space is not allowed';

const EDIT_PROFILE_CONSTANTS = {
  PHOTO_BYTES_LIMIT, FILE_TYPES, NAME_ERROR,
  NAME_MAX_LIMIT, NAME_MIN_LIMIT, NAME_MAX_LIMIT_ERROR,
  BIO_ERROR, BIO_MIN_LIMIT, NAME_MIN_LIMIT_ERROR,
  BIO_MIN_LIMIT_ERROR, SUCCESS_UPDATE_MESSAGE, ERROR_UPDATE_MESSAGE,
  ONLY_SPACE
}

export default EDIT_PROFILE_CONSTANTS;
