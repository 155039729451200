export const imageMaxSize = 1000000000; // bytes
export const acceptedFileTypes =
  "image/bmp, image/svg+xml, image/x-png, image/png, image/jpg, image/jpeg, image/webp, image/gif, audio/mpeg, audio/wav, audio/ogg ,video/mp4, video/quicktime";
export const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});
export const UPLOAD_FILE_DESCRIPTION = [
  {
    key: 'Accepted file formats',
    value: 'PNG, JPG, JPEG, BMP, SVG GIF, WEBP, MP4, OGG, WAV, or MP3'
  },
  {
    key: 'Accepted file size',
    value: 'max 1Gb'
  }
];
