import React from "react";
import classes from "./LoadingBackground.module.css";

const LoadingBackground = ({ height, borderRadius }) => {
  return (
    <div
      className={classes.loadingBackground}
      style={{height, borderRadius}}
    ></div>
  );
}

export default LoadingBackground;
