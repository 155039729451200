import React, { Component } from "react";
import classes from "./SearchNotFoundPageSearch.module.css";
import SearchNotFoundImg from "../../../assets/img/searchNotFoundImg.png";

export default class SearchNotFoundPageSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchItem: "",
      errorMsg: "",
    };
  }
  searchItemsHandler = () => {
    let errorMsg = "";

    if (this.state.searchItem !== "") {
      alert("Your Items are " + this.state.searchItem);
    } else {
      errorMsg = "Nothing to search!";
    }
    this.setState({ errorMsg });
  };
  render() {
    return (
      <div className={classes.searchNotFoundMainContainer}>
        <div className={classes.searchNotFoundImgContainer}>
          <img src={SearchNotFoundImg} alt="Search-not-found" />
        </div>
        <div className={classes.searchNotFoundDescContainer}>
          <h1>Sorry, we couldn’t find any results for this search.</h1>
          <p>Maybe give one of these a try?</p>
        </div>
        {/* <div className={classes.newsletterSearchbarContainer}>
          <input
            type="text"
            placeholder="Enter your search..."
            value={this.state.searchItem}
            onChange={(e) => this.setState({ searchItem: e.target.value })}
          />
          <div
            className={classes.searchArrowIconContainer}
            onClick={this.searchItemsHandler}
          >
            <i className="fas fa-long-arrow-alt-right"></i>
            <i className="fas fa-long-arrow-alt-right"></i>
          </div>
          <div className={classes.searchErrorMsg}>{this.state.errorMsg}</div>
        </div> */}
      </div>
    );
  }
}
