import React from "react";
import classes from "./Model.module.css";
import CloseIcon from "../../assets/img/closeIcon.svg";
import LoaderIcon from "../../assets/img/loader.png";
import UserAssetsCard from "../UserAssetsCard/UserAssetsCard";

export default function UserTemplatesModal(props) {


  const pageSize = 7;

  const displayTemplates = () => {
    const userTemplates = props.userTemplates
      // .slice(0, props.limit)
      .map((item, index) => {
        return (
          <div
            className={classes.discoverAssetsContainer}
            key={index}
            onClick={() => props.userAssetsData(index)}
          >
            <UserAssetsCard
              assetsContent={[item]}
              title={item.title}
              // stock={item.stock}
              // detailsPath={item.detailsPath}
              marginBottom={0}
            />
          </div>
        );
      });

    return userTemplates;
  };

  const showPages = () => {

    let pagesArray = [];
    let page = props.page;

    let initialValue = 1;
    if (page - 3 > 1)
      initialValue = (page - 3)

    let showPagesSize = props.totalPages;
    if (initialValue + pageSize < props.totalPages) {
      showPagesSize = initialValue + pageSize;
    }

    if (props.totalPages) {
      for (let i = initialValue; i <= showPagesSize; i++) {
        pagesArray.push(
          <button style={page === i ? { color: 'black', fontWeight: 'bold' } : {}} onClick={() => props.getNewPageData(i)}>{i}</button>
        )
      }
    }
    return pagesArray
  }
  return (
    <div
      className={classes.userTemplatesModal}
      style={
        props.showModal
          ? { transform: "scale(1)", opacity: 1 }
          : { transform: "scale(0)", opacity: 0 }
      }
    >
      <div className={classes.modelInnerContainer}>
        <h1>Select Template</h1>
        <div
          className={classes.modelImageCancel}
          onClick={props.close}
          style={{ marginLeft: "auto" }}
        >
          <img src={CloseIcon} alt="Modal-close" />
        </div>
      </div>
      <div className={classes.allItemsMainContainer}>
        {displayTemplates()}
      </div>
      {
        props.loading &&
        <div className={classes.loadMoreBtnContainer}>
          <button>
            <img
              src={LoaderIcon}
              alt="Loading..."
              className={classes.loaderAnimationIcon}
            />
            <span>Loading...</span>
          </button>
        </div>
      }
      {
        props.userTemplates.length > 0 &&
        <div className={classes.paginationContainer}>
          {
            // props.page - 1 > 0 &&
            <button onClick={() => props.getNewPageData(props.page - 1)}>Previous</button>
          }
          <div className={classes.pagesContainer}>
            {showPages()}
          </div>
          {
            //props.discoverAssets.length > 12 &&
            <button onClick={() => props.getNewPageData(props.page + 1)} style={props.page === props.totalPages ? { pointerEvents: 'none', opacity: 0.5 } : {}}>Next</button>
          }
        </div>
      }
      {
        props.loading === false && props.userTemplates.length === 0 &&
        <div className={classes.noDataContainer}>No Data</div>
      }
    </div>
  );
}
