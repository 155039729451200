import React, { useEffect, useState } from "react";
import classes from "./HotNftCard.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useImageLoaded from "../../../../../hooks/useImageLoaded";
import AppServices from "../../../../../services/AppService";
import ImageIcon from "../../../../../assets/img/image.png";
import {
  SET_DROP_COLL,
  SET_DROP_COLL_Q,
  SET_PAGE,
  SET_SHOW_QUERY,
  SET_TEMPLATE_ID,
  SET_TEMPLATE_Q,
} from "../../../../../stores/reducers/marketReducer";

const LIMIT = 18;

const appService = new AppServices();

const RESIZER_URL = "http://ipfs-resizer.ledgerwise.io/api/v1/resized?cid=";

const HotNftCard = ({ data, index }) => {
  const [ref, loaded, onLoad] = useImageLoaded();
  const [content, setContent] = useState();
  const [collectionName, setCollectionName] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [templateName, setTemplateName] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const getImgUrl = () => appService.getIpfsUrl(content);

  const reloadImg = (e) => {
    if (e.target.src.includes(RESIZER_URL)) e.target.src = getImgUrl(content);
  };

  const moveToMarket = (collectionName, templateId) => {
    const page = 1;

    dispatch(SET_DROP_COLL(collectionName));
    dispatch(SET_PAGE(page));
    dispatch(SET_DROP_COLL_Q(collectionName));
    dispatch(SET_SHOW_QUERY(true));
    dispatch(SET_TEMPLATE_ID(templateId));
    dispatch(SET_TEMPLATE_Q(templateId));
    history.push("/market");
  };

  const formatTemplateName = (name, limit) =>
    name && name.length < limit ? name : `${name.substring(0, limit)}...`;

  useEffect(() => {
    if (data && data.template) {
      const template = data.template;
      setCollectionName(template.collection.collection_name);
      setTemplateId(template.template_id);
      setTemplateName(formatTemplateName(template.immutable_data.name, LIMIT));
      if (template.immutable_data && template.immutable_data.img) {
        setContent(template.immutable_data.img);
      }
    }
  }, [data]);

  return (
    <div
      className={classes.cardWrapper}
      key={index}
      onClick={() => moveToMarket(collectionName, templateId)}
    >
      <div
        className={`${classes.imgWrapper} ${
          loaded ? null : content ? classes.loading : null
        }`}
      >
        {content ? (
          <img
            ref={ref}
            src={
              content?.includes("http")
                ? getImgUrl(content)
                : `${RESIZER_URL}${content}&size=200`
            }
            onLoad={onLoad}
            alt="assetsImg"
            loading="lazy"
            onError={(e) => reloadImg(e)}
          />
        ) : (
          <div className={classes.iconWrapper}>
            <img src={ImageIcon} alt="" />
          </div>
        )}
      </div>
      <p className={classes.templateName}>{templateName}</p>
      <p className={classes.viewOnMarket}>VIEW ON MARKET</p>
    </div>
  );
};

export default HotNftCard;
