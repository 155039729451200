// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterLinks_footerLinksContainer__1kqcX {\r\n  list-style: none;\r\n  font-weight: bold;\r\n  padding: 12px 0;\r\n}\r\n.FooterLinks_footerLinksContainer__1kqcX > a {\r\n  font-size: 14px;\r\n  color: #777e91;\r\n  text-decoration: none;\r\n  transition: all 0.4s ease-in-out;\r\n}\r\n.FooterLinks_footerLinksContainer__1kqcX > a:hover {\r\n  color: #23262f;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Footer/FooterLinks/FooterLinks.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB","sourcesContent":[".footerLinksContainer {\r\n  list-style: none;\r\n  font-weight: bold;\r\n  padding: 12px 0;\r\n}\r\n.footerLinksContainer > a {\r\n  font-size: 14px;\r\n  color: #777e91;\r\n  text-decoration: none;\r\n  transition: all 0.4s ease-in-out;\r\n}\r\n.footerLinksContainer > a:hover {\r\n  color: #23262f;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerLinksContainer": "FooterLinks_footerLinksContainer__1kqcX"
};
export default ___CSS_LOADER_EXPORT___;
