import React from "react";
import classes from "./PopularUser.module.css";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../assets/img/user-circle-solid.svg"

export default function PopularUserCard(props) {
  const history = useHistory();

  const colors = [
    "Burlywood",
    "DarkCyan",
    "#dc3825",
    "DarkKhaki",
    "DarkSeaGreen",
    "DarkSlateGrey",
    "DodgerBlue",
    "GoldenRod",
    "IndianRed",
    "PaleVioletRed",
    "#dc3825",
    "Salmon",
    "Teal",
    "Tomato",
    "YellowGreen",
    "CadetBlue",
    "Chocolate",
    "DarkOrange",
    "ForestGreen",
    "Black",
  ];

  const moveToProfile = (userAccount) => {
    history.push("/profile/" + userAccount);
  };
  
  const reloadImg = (e) => e.target.src = UserAvatar;

  return (
    <div
      className={classes.popularUserCardContainer}
      onClick={() => {
        moveToProfile(props.name);
      }}
    >
      <div className={classes.popularUserCardActionBtnContainer}>
        <div
          className={classes.popularUserActionBtnNumber}
          style={{ background: colors[props.index] }}
        >
          # {props.number}
        </div>
      </div>
      <div className={classes.popularUserCardDescContainer}>
        <div className={classes.popularUserCardDescImgContainer}>
          {props.img ? (
            <img src={props.img} alt="" onError={(e) => reloadImg(e)}/>
          ) : (
            <i className="fas fa-user-circle"></i>
          )}
        </div>
        <h1>{props.name}</h1>
        <p>
          <span>{props.price}</span>
        </p>
      </div>
    </div>
  );
}
