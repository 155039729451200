// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Policy_policyWrapper__BekZ3 {\n  margin: 80px auto 102px;\n  max-width: 1200px;\n  width: 85%;\n}\n.Policy_mainTitle__2-Cuz {\n  color: #23262f;\n  font-family: Monda;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 48px;\n  line-height: 56px;\n}\n.Policy_mainText__3g54y {\n  margin-top: 10px;\n  text-align: justify;\n}\n\n.Policy_btnWrapper__3uAn2 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n@media only screen and (max-width: 600px) {\n  .Policy_mainTitle__2-Cuz {\n    font-size: 32px;\n    line-height: 40px;\n  }\n  .Policy_mainText__3g54y {\n    font-size: 14px;\n  }\n  .Policy_policyWrapper__BekZ3 {\n    margin: 40px auto 60px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/Policy/Policy.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".policyWrapper {\n  margin: 80px auto 102px;\n  max-width: 1200px;\n  width: 85%;\n}\n.mainTitle {\n  color: #23262f;\n  font-family: Monda;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 48px;\n  line-height: 56px;\n}\n.mainText {\n  margin-top: 10px;\n  text-align: justify;\n}\n\n.btnWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n@media only screen and (max-width: 600px) {\n  .mainTitle {\n    font-size: 32px;\n    line-height: 40px;\n  }\n  .mainText {\n    font-size: 14px;\n  }\n  .policyWrapper {\n    margin: 40px auto 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyWrapper": "Policy_policyWrapper__BekZ3",
	"mainTitle": "Policy_mainTitle__2-Cuz",
	"mainText": "Policy_mainText__3g54y",
	"btnWrapper": "Policy_btnWrapper__3uAn2"
};
export default ___CSS_LOADER_EXPORT___;
