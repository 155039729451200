// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopAccountCard_cardWrapper__kWROV {\r\n  padding: 16px;\r\n  margin-bottom: 16px;\r\n  display: flex;\r\n  background: rgba(168, 187, 207, 0.12);\r\n  border-radius: 16px;\r\n}\r\n\r\n.TopAccountCard_accountName__3kQJj {\r\n  color: #dc3825;\r\n  cursor: pointer;\r\n  margin-right: 16px;\r\n}\r\n\r\n.TopAccountCard_index__3TbBb {\r\n  margin-right: 16px;\r\n}\r\n\r\n.TopAccountCard_tokenAmount__2NF3L {\r\n  color: #ded2b0;\r\n  margin-right: 16px;\r\n}\r\n\r\n.TopAccountCard_usdAmount__2Yfr2 {\r\n  color: #4aaf05;\r\n}\r\n\r\n.TopAccountCard_accNameWrapper__1y-pL, .TopAccountCard_amountsWrapper__2qutv {\r\n  display: flex;\r\n}\r\n\r\n@media only screen and (max-width: 500px) {\r\n  .TopAccountCard_cardWrapper__kWROV {\r\n    flex-direction: column;\r\n    grid-gap: 12px;\r\n    gap: 12px;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/CollectionDetails/CollectionTabs/StatsTab/TopAccountCard/TopAccountCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,qCAAqC;EACrC,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAS;IAAT,SAAS;EACX;AACF","sourcesContent":[".cardWrapper {\r\n  padding: 16px;\r\n  margin-bottom: 16px;\r\n  display: flex;\r\n  background: rgba(168, 187, 207, 0.12);\r\n  border-radius: 16px;\r\n}\r\n\r\n.accountName {\r\n  color: #dc3825;\r\n  cursor: pointer;\r\n  margin-right: 16px;\r\n}\r\n\r\n.index {\r\n  margin-right: 16px;\r\n}\r\n\r\n.tokenAmount {\r\n  color: #ded2b0;\r\n  margin-right: 16px;\r\n}\r\n\r\n.usdAmount {\r\n  color: #4aaf05;\r\n}\r\n\r\n.accNameWrapper, .amountsWrapper {\r\n  display: flex;\r\n}\r\n\r\n@media only screen and (max-width: 500px) {\r\n  .cardWrapper {\r\n    flex-direction: column;\r\n    gap: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": "TopAccountCard_cardWrapper__kWROV",
	"accountName": "TopAccountCard_accountName__3kQJj",
	"index": "TopAccountCard_index__3TbBb",
	"tokenAmount": "TopAccountCard_tokenAmount__2NF3L",
	"usdAmount": "TopAccountCard_usdAmount__2Yfr2",
	"accNameWrapper": "TopAccountCard_accNameWrapper__1y-pL",
	"amountsWrapper": "TopAccountCard_amountsWrapper__2qutv"
};
export default ___CSS_LOADER_EXPORT___;
