import React from "react";
import { useHistory } from 'react-router-dom';
import classes from "./ExploreMore.module.css";

export default function ExploreMore(props) {
  const history = useHistory();

  const moveToSearch = (category) => {
    history.push("/market?category=" + category)
  }
  return (
    <div className={classes.exploreMoreMainContainer} onClick={() => moveToSearch(props.title.toString().toLowerCase())}>
      <img src={props.img} alt="Explore-more" />
      <div className={classes.exploreMoreDescContainer}>
        <h3>{props.title}</h3>
        {props.totalItems && props.totalItems > 0 ? <p>{props.totalItems} items</p> : <></>}
      </div>
    </div>
  );
}
